import React, { useState } from "react";
import { useParams, Link, withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { apiPost } from "../../../shared/api.js";

function DogAdd(props) {
  const [PetName, setPetName] = useState("");
  const [DateOfBirth, setDateOfBirth] = useState(undefined);
  const [Breed, setBreed] = useState(undefined);
  const [Gender, setGender] = useState(undefined);
  const [PerformanceLevel, setPerformanceLevel] = useState("Beginners");
  const [SteeplechaseLevel, setSteeplechaseLevel] = useState("Beginners");
  const [HeightId, setHeightId] = useState(undefined);
  const [Notes, setNotes] = useState(undefined);
  const [IsFreeReg, setIsFreeReg] = useState(false);

  const setLevels = (val) => {
    setPerformanceLevel(val);
    setSteeplechaseLevel(val);
  };

  const { Id } = useParams();

  //Save a dog to the database
  const SaveDog = async () => {
    const ERROR_MSG = "Sorry, there was an error saving this dog. Please try again.";
    try {
      const fields = [
        PetName,
        DateOfBirth,
        Breed,
        Gender,
        PerformanceLevel,
        SteeplechaseLevel,
        HeightId,
      ];
      let ok = true;

      for (const f of fields) if (f === "" || f === undefined) ok = false;

      if (ok) {
        const data = {
          Id: null,
          MemberId: Id,
          PetName,
          Gender,
          Breed,
          PerformanceLevel,
          SteeplechaseLevel,
          HeightId,
          Notes,
          IsFreeReg,
        };
        data.DateOfBirth = moment(DateOfBirth).format("DD/MM/YYYY");

        const req = await apiPost("/dogs/create", data);
        if (req && req.ok && req.status === 201) {
          props.history.push(`/admin/memberview/doglist/${Id}`);
        } else {
          window.alert(ERROR_MSG);
        }
      } else {
        window.alert("You must complete every field before you can continue");
      }
    } catch (error) {
      console.log(error.message);
      window.alert(ERROR_MSG);
    }
  };

  return (
    <div className="card">
      <div className="card-header">Add a dog to this member's account</div>
      <div className="card-body">
        <p className="mb-2x">
          Please complete the details for this dog below and click 'Save Details' to
          update the database.
        </p>
        <div className="form-group">
          <label className="form-label">Pet Name</label>
          <input
            type="text"
            className="form-control width-50"
            value={PetName}
            onChange={(e) => setPetName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label className="form-label">Date of Birth</label>
          <DatePicker
            maxDate={new Date()}
            showMonthDropdown
            showYearDropdown
            className="form-control width-50"
            selected={DateOfBirth}
            dateFormat="dd/MM/yyyy"
            value={DateOfBirth}
            onChange={(val) => setDateOfBirth(val)}
          ></DatePicker>
        </div>
        <div className="form-group">
          <label className="form-label">Gender</label>
          <select
            className="form-control width-25"
            value={Gender}
            onChange={(e) => setGender(e.target.value)}
          >
            <option value="">Please select...</option>
            <option value="M">Male</option>
            <option value="F">Female</option>
          </select>
        </div>
        <div className="form-group">
          <label className="form-label">Breed</label>
          <input
            type="text"
            className="form-control width-50"
            value={Breed}
            onChange={(e) => setBreed(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label className="form-label">
            Has {PetName !== "" ? PetName : " your dog"} competed previously? If so, which
            level has been achieved?
          </label>
          <select
            className="form-control width-50"
            onChange={(e) => setLevels(e.target.value)}
          >
            <option value="Beginners">No</option>
            <option value="Beginners">Yes - KC Grade 1,2 or 3</option>
            <option value="Novice">Yes - KC Grade 4 or 5</option>
            <option value="Senior">Yes - KC Grade 6 or 7</option>
            <option value="Champion">Yes - KC CC or RC Winner </option>
          </select>
        </div>
        <div className="form-group">
          <label className="form-label">Levels</label>
          <p>{PerformanceLevel}</p>
        </div>
        <div className="form-group">
          <label className="form-label">Height</label>
          <select
            className="form-control width-25"
            value={HeightId}
            onChange={(e) => setHeightId(e.target.value)}
          >
            <option value="">Please select...</option>
            <option value="14">250</option>
            <option value="1">300</option>
            <option value="2">400</option>
            <option value="3">500</option>
            <option value="4">600</option>
          </select>
        </div>
        <div className="form-group">
          <label className="form-label">Notes</label>
          <input
            type="text"
            className="form-control width-50"
            value={Notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label className="form-label">Free registration?</label>
          <input
            type="checkbox"
            value={IsFreeReg}
            onChange={(e) => setIsFreeReg(e.target.checked)}
          />
        </div>
      </div>

      <div className="card-footer">
        <button className="button button-green mr-1x" onClick={SaveDog}>
          Save Dog
        </button>
        <Link to={`/admin/memberview/doglist/${Id}`}>Cancel</Link>
      </div>
    </div>
  );
}

export default withRouter(DogAdd);
