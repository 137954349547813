import React,{useContext} from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import RegistrationContext from '../../context/registration/RegistrationContext';


function RegStep3(props) {

    const {DateOfBirth, UpdateValue} = useContext(RegistrationContext);

    return (
        <>

            <h4>Junior Handler</h4>
            <p>
                If you're registering as a junior handler, your membership is free until your 16th birthday. Please enter your date of birth below.
            </p>
            <p className="mb-3x">
                If you're not registering as a junior, just click 'Next'
            </p>

            <div className="form-group mb-3x">
                <label className="form-label">Date of birth:</label>
                <DatePicker maxDate={new Date()} showMonthDropdown showYearDropdown className="form-control width-50" selected={DateOfBirth} dateFormat="dd/MM/yyyy" value={DateOfBirth} onChange={val => UpdateValue({DateOfBirth:val})}></DatePicker>
            </div>      
            <div className="form-group">
                <button className="button button-outline mr-1x" onClick={() => UpdateValue({CurrentStep: 2})}>Previous</button> <button className="button button-blue" onClick={() => UpdateValue({CurrentStep: 4})}>Next</button>
            </div>
        </>
    )
}

export default RegStep3;