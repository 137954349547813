import React, {useState} from 'react';
import {apiPost} from '../../../shared/api.js';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import {Workbook} from 'exceljs';
import {DownloadFile} from '../../../shared/clientsidefiledownload.js';


function UnknownBACSAssigned(props) {

    const [Data, setData] = useState([]);
    const [StartDate, setStartDate] = useState(new Date());



    const LoadData = async() => {

        const ERROR_MSG = 'Error loading data from server. Micky support time';

        try {

            const data = {
                StartDate: moment(StartDate).format('DD/MM/YYYY')
            }

            const req = await apiPost('/admin/wallet/reports/unknownbacsassigned', data);
            if(req && req.ok && req.status === 200) {
                const res = await req.json();
                setData(res);
                return res;
            } else {

            }
        }
        catch (error) {
            console.log(error.message);
            window.alert(ERROR_MSG);
        }
    }



    //Excel output
    const GetExcel = async() => {

        try {

            const data = await LoadData();

            let wb = new Workbook();
            let ws = wb.addWorksheet('Credits');

            ws.addRow(['Date/Time', 'Reference', 'Amount', 'Note', 'Date Assigned', 'Assigned To']);
            for (const itm of data) {

                const fullItem = JSON.parse(itm.JSONData);
                ws.addRow([
                    moment(itm.DateTimeStamp).format('DD/MM/YYYY HH:mm'), 
                    fullItem.reference, 
                    fullItem.sourceAmount, 
                    fullItem.forCustomer,
                    moment(itm.DateAssigned).format('DD/MM/YYYY'),
                    `${itm.FirstName} ${itm.LastName} - ${itm.AssignedTo}`
                ]);
            }

            const buf = await wb.xlsx.writeBuffer();
            const blob =  new Blob([buf], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            DownloadFile(blob,'UnknownBACSAssigned.xlsx');

        }
        catch (error) {
            console.log(error.message);
            window.alert('Error producing Excel sheet');
        }

    }


    //Summary
    const LineItems = Data.map((itm,idx) => {

        const fullItem = JSON.parse(itm.JSONData);

        return (
            <tr key={idx}>
                <td>{moment(itm.DateTimeStamp).format('DD/MM/YYYY HH:mm')}</td>
                <td>{fullItem.reference}</td>
                <td>£{fullItem.sourceAmount}</td>
                <td>{fullItem.forCustomer}</td>
                <td>{moment(itm.DateAssigned).format('DD/MM/YYYY')}</td>
                <td>{itm.FirstName} {itm.LastName} - {itm.AssignedTo}</td>
            </tr>
        );
    });



    return (
        <div className="card">
            <div className="card-header">Unknown BACS payments</div>
            <div className="card-body">

                <div className="mb-1x"><span className="mr-2x">Start Date: </span><DatePicker maxDate={new Date()} showMonthDropdown showYearDropdown className="form-control width-50 mr-2x" dateFormat="dd/MM/yyyy" value={StartDate} selected={StartDate} onChange={val => setStartDate(val)}></DatePicker></div>
                <div className="mb-2x"><button className="button mr-2x" onClick={LoadData}>View Data</button><button className="button mr-2x" onClick={GetExcel}><FontAwesomeIcon icon={faFileExcel} /> Excel</button></div>

                {
                    Data.length > 0 ?
                    <>
                        <table className="table mb-2x">
                            <thead>
                                <tr>
                                    <th>Date/Time</th>
                                    <th>Reference</th>
                                    <th>Amount</th>
                                    <th>Note</th>
                                    <th>Date Assigned</th>
                                    <th>Assigned To</th>
                                </tr>
                            </thead>
                            <tbody>
                                {LineItems}
                            </tbody>
                        </table>

                    </>
                    :
                    <></>
                }                        

            </div>
            <div className="card-footer"></div>
        </div>
    )
}

export default UnknownBACSAssigned;