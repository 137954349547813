import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { apiPost, apiGet } from "../../../shared/api.js";
import { withRouter } from "react-router-dom";
import UserContext from "../../../context/user/UserContext";
import { GetBalance } from "../../../shared/wallet.js";
import Message from "../../../shared/Message";
import ModalLoading from "../../../shared/ModalLoading";
import CreateSelect from "react-select/async-creatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import ModalContent from "../../../shared/ModalContent.jsx";

function AddDog(props) {
  const { User } = useContext(UserContext);

  const [PetName, setPetName] = useState("");
  const [DateOfBirth, setDateOfBirth] = useState(undefined);
  const [Breed, setBreed] = useState(undefined);
  const [Gender, setGender] = useState(undefined);
  const [PerformanceLevel, setPerformanceLevel] = useState("Beginners");
  const [SteeplechaseLevel, setSteeplechaseLevel] = useState("Beginners");
  const [HeightId, setHeightId] = useState(undefined);
  const [CanAddDog, setCanAddDog] = useState(false);
  const [Loading, setLoading] = useState(true);
  const [BreedList, setBreedList] = useState([]);
  const [ShowHelp, setShowHelp] = useState(false);
  const [HelpText, setHelpText] = useState("");
  const [ShowConfirmDOB, setShowConfirmDOB] = useState(false);
  const [MyDogs, setMyDogs] = useState([]);
  const [NameMatch, setNameMatch] = useState(false);
  const [DOBMatch, setDOBMatch] = useState(false);

  const setLevels = (val) => {
    setPerformanceLevel(val);
    setSteeplechaseLevel(val);
  };

  //Get balance and dog cost to check we can be here
  useEffect(() => {
    (async () => {
      try {
        let req, res;

        //My account balance
        const wallet = await GetBalance(User.Id);

        //Cost of a new dog
        req = await apiGet("/helpers/systemparameter/NewDogCost");
        if (req && req.ok && req.status === 200) {
          res = await req.json();
          const cost = res;
          if (parseFloat(wallet.Balance) >= parseFloat(cost)) setCanAddDog(true);
        }

        //List of my dogs
        req = await apiGet(`/dogs/list/${User.Id}`);
        if (req && req.ok) {
          res = await req.json();
          setMyDogs(res);
        }
      } catch (error) {
        setCanAddDog(false);
      } finally {
        setLoading(false);
      }
    })();
  }, [User]);

  //Load list of breeds
  useEffect(() => {
    (async () => {
      const ERROR_MSG = "Error loading list of dog breeds";

      try {
        const req = await apiGet("/dogs/breeds");
        if (req && req.ok && req.status === 200) {
          const res = await req.json();
          setBreedList(res);
        } else {
          window.alert(ERROR_MSG);
        }
      } catch (error) {
        console.log(error.message);
        window.alert(ERROR_MSG);
      }
    })();
  }, []);

  const CheckNameMatch = () => {
    if (MyDogs.length > 0 && PetName > "") {
      setNameMatch(MyDogs.find((d) => d.Status !== 3 && d.PetName.toLowerCase() === PetName.trim().toLowerCase()));
    }
  };

  //Find a breed for the list
  const FindBreed = async (SearchString) => {
    const matches = BreedList.filter((b) => b.BreedLabel.toLowerCase().startsWith(SearchString.toLowerCase()));
    return matches.map((itm) => {
      return { value: itm.BreedLabel, label: itm.BreedLabel };
    });
  };

  //Save a dog to the database
  const SaveDog = async () => {
    const ERROR_MSG = "Sorry, there was an error saving this dog. Please try again.";
    try {
      const fields = [PetName, DateOfBirth, Breed, Gender, PerformanceLevel, SteeplechaseLevel, HeightId];
      let ok = true;

      for (const f of fields) if (f === "" || f === undefined) ok = false;

      if (ok) {
        const data = {
          Id: null,
          MemberId: User.Id,
          PetName,
          Gender,
          Breed,
          PerformanceLevel,
          SteeplechaseLevel,
          HeightId,
        };
        data.DateOfBirth = moment(DateOfBirth).format("DD/MM/YYYY");

        let req, res;
        setLoading(true);

        //Check balance
        const wallet = await GetBalance(User.Id);
        req = await apiGet("/helpers/systemparameter/NewDogCost");
        if (req && req.ok && req.status === 200) {
          res = await req.json();
          const cost = res;
          if (parseFloat(wallet.Balance) >= parseFloat(cost)) {
            req = await apiPost("/dogs/create", data);
            if (req && req.ok && req.status === 201) {
              props.history.push("/dogs");
            } else {
              setLoading(false);
              window.alert(ERROR_MSG);
            }
          } else {
            setLoading(false);
            //window.alert("Insufficent wallet balance to make this payment");
            setCanAddDog(false);
          }
        }
      } else {
        window.alert("You must complete every field before you can continue");
      }
    } catch (error) {
      console.log(error.message);
      window.alert(ERROR_MSG);
    }
  };

  //Help modals
  const doHelp = (section) => {
    switch (section) {
      case "PetName":
        setHelpText(<p>The name you call your dog every day.</p>);
        break;
      case "Breed":
        setHelpText(
          <p>
            If your dog's breed does not appear in the list, please select Other and type the breed name you require in
            the text box.
          </p>
        );
        break;
      case "DoB":
        setHelpText(<p>Dog's date of birth. If unknown, enter your best estimate.</p>);
        break;
      case "Level":
        setHelpText(
          <p>
            You may only transfer from your British Kennel Club grade. If you only have results from other
            organisations, please select 'Not competed' in the drop-down box.
          </p>
        );
        break;
      case "Height":
        setHelpText(
          <>
            <p>
              Please select the height you think you will be competing at. You will need an official measure at your
              first show if you are jumping below 600 height.
            </p>
            <table>
              <thead>
                <tr>
                  <th style={{ paddingRight: "30px" }}>Jump Height Name</th>
                  <th>Dog's height at withers</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>250</td>
                  <td>320mm and under</td>
                </tr>
                <tr>
                  <td>300</td>
                  <td>380mm and under</td>
                </tr>
                <tr>
                  <td>400</td>
                  <td>440mm and under</td>
                </tr>
                <tr>
                  <td>500</td>
                  <td>500mm and under</td>
                </tr>
                <tr>
                  <td>600</td>
                  <td>Any</td>
                </tr>
              </tbody>
            </table>
          </>
        );
        break;
      default:
        setHelpText(<></>);
        break;
    }
    setShowHelp(true);
  };

  //Check DOB modal
  useEffect(() => {
    if (DateOfBirth > "") {
      setShowConfirmDOB(true);

      setDOBMatch(
        MyDogs.find(
          (d) => d.Status !== 3 && moment.utc(d.DateOfBirth).isSame(moment.utc(DateOfBirth, "DD/MM/YYYY"), "date")
        )
      );
    }
  }, [DateOfBirth]);

  //Loading dialog
  if (Loading) return <ModalLoading />;

  //Not enough balance to add a dog
  if (!CanAddDog)
    return (
      <Message
        Type="Error"
        Content="Sorry, you cannot add a dog at this time as you do not have sufficent funds in your account. Please click 'Go to wallet' to add funds. Registration costs £6 a dog."
      >
        <div className="mt-2x mb-2x">
          <Link to="/dogs" className="text-white button button-blue button-small mr-1x">
            Return to list of dogs
          </Link>
          <Link to="/wallet" className="text-white button button-blue button-small">
            Go to wallet
          </Link>
        </div>
      </Message>
    );

  return (
    <>
      {ShowConfirmDOB && (
        <ModalContent>
          <h2>CHECK YOUR DOG'S DATE OF BIRTH</h2>
          <p>You have entered your dog's date of birth as:</p>
          <p className="mt-2x mb-2x">{moment(DateOfBirth, "DD/MM/YYYY").format("Do MMMM YYYY")}</p>
          {moment(DateOfBirth, "DD/MM/YYYY").isAfter(moment().add(-3, "months")) && (
            <p style={{ color: "red" }}>
              Your dog is less than three months old. Are you sure you have the correct year?
            </p>
          )}
          <p>
            <p>If this is not correct, you must amend it NOW. You will not be able to do this later.</p>
            <p>You may have problems entering or competing at a show if you do not get this right.</p>
            <button className="button button-blue" onClick={() => setShowConfirmDOB(false)}>
              OK
            </button>
          </p>
        </ModalContent>
      )}
      {NameMatch && (
        <ModalContent>
          <h2>CHECK YOUR DOG'S NAME</h2>
          <p>
            Your dog has the same name as another already on your account. Are you sure you're adding the correct dog -
            amend if necessary, please.
          </p>
          <p>
            <button className="button button-blue" onClick={() => setNameMatch(false)}>
              OK
            </button>
          </p>
        </ModalContent>
      )}
      {DOBMatch && !ShowConfirmDOB && (
        <ModalContent>
          <h2>CHECK YOUR DOG'S DATE OF BIRTH</h2>
          <p>
            Your dog has the same date of birth as another already on your account. Are you sure you're adding the
            correct dog - amend if necessary, please.
          </p>
          <p>
            <button className="button button-blue" onClick={() => setDOBMatch(false)}>
              OK
            </button>
          </p>
        </ModalContent>
      )}
      <div className="card form-centered">
        <div className="card-header">Add a dog to your account</div>
        <div className="card-body">
          <p className="mb-2x">
            Please complete the details for your dog below and click 'Save Details' to update the database.
          </p>
          <div className="form-group">
            <label className="form-label">
              Pet Name{" "}
              <FontAwesomeIcon icon={faInfoCircle} className="cursor-pointer" onClick={(e) => doHelp("PetName")} />
            </label>
            <input
              type="text"
              className="form-control width-50"
              value={PetName}
              onChange={(e) => setPetName(e.target.value)}
              onBlur={CheckNameMatch}
            />
          </div>
          <div className="form-group">
            <label className="form-label">
              Date of Birth{" "}
              <FontAwesomeIcon icon={faInfoCircle} className="cursor-pointer" onClick={(e) => doHelp("DoB")} />
            </label>
            <DatePicker
              maxDate={new Date()}
              showMonthDropdown
              showYearDropdown
              className="form-control width-50"
              selected={DateOfBirth}
              dateFormat="dd/MM/yyyy"
              value={DateOfBirth}
              onChange={(val) => setDateOfBirth(val)}
            ></DatePicker>
          </div>
          <div className="form-group">
            <label className="form-label">Gender</label>
            <select className="form-control width-25" value={Gender} onChange={(e) => setGender(e.target.value)}>
              <option value="">Please select...</option>
              <option value="M">Male</option>
              <option value="F">Female</option>
            </select>
          </div>
          <div className="form-group">
            <label className="form-label">
              Breed <FontAwesomeIcon icon={faInfoCircle} className="cursor-pointer" onClick={(e) => doHelp("Breed")} />
            </label>
            <CreateSelect
              isClearable
              className="width-50"
              placeholder="Start typing a breed"
              loadOptions={FindBreed}
              onChange={(e) => setBreed(e ? e.value : "")}
            />
          </div>
          <div className="form-group">
            <label className="form-label">
              Has {PetName !== "" ? PetName : " your dog"} competed previously? If so, which level has been achieved?{" "}
              <FontAwesomeIcon icon={faInfoCircle} className="cursor-pointer" onClick={(e) => doHelp("Level")} />
            </label>
            <select className="form-control width-50" onChange={(e) => setLevels(e.target.value)}>
              <option value="Beginners">Not competed</option>
              <option value="Beginners">Yes - KC Grade 1 or 2</option>
              <option value="Novice">Yes - KC Grade 3, 4 or 5</option>
              <option value="Senior">Yes - KC Grade 6 or 7</option>
              <option value="Champion">Yes - KC CC or RC Winner </option>
            </select>
          </div>
          <div className="form-group">
            <label className="form-label">Level</label>
            <p>{PerformanceLevel}</p>
          </div>
          <div className="form-group">
            <label className="form-label">
              Height{" "}
              <FontAwesomeIcon icon={faInfoCircle} className="cursor-pointer" onClick={(e) => doHelp("Height")} />
            </label>
            <select className="form-control width-25" value={HeightId} onChange={(e) => setHeightId(e.target.value)}>
              <option value="">Please select...</option>
              <option value="14">250</option>
              <option value="1">300</option>
              <option value="2">400</option>
              <option value="3">500</option>
              <option value="4">600</option>
            </select>
          </div>
        </div>
        <div className="card-footer">
          <button className="button button-green mr-1x" onClick={SaveDog}>
            Save Dog
          </button>{" "}
          <Link to="/Dogs">Cancel</Link>
        </div>
      </div>

      {ShowHelp ? (
        <ModalContent className="fadeIn">
          {HelpText}
          <p>
            <button className="button button-blue button-small" onClick={(e) => setShowHelp(false)}>
              Close
            </button>
          </p>
        </ModalContent>
      ) : (
        <></>
      )}
    </>
  );
}

export default withRouter(AddDog);
