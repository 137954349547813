import React from "react";
import Menu from "./menu";
import { Switch } from "react-router-dom";
import Search from "./search";
import MemberCreate from "./members/membercreate";
import MemberView from "./memberview";
import WalletReports from "./wallet/reports";
import WalletBalances from "./wallet/balances";
import WalletBalancesPreviousDay from "./wallet/balancespreviousday";
import Wallet from "./wallet/wallet";
import AdminRoute from "../../shared/AdminRoute";
import WalletHandlerRegistrations from "./wallet/handlerregistrations";
import WalletHandlerRenewals from "./wallet/handlerrenewals";
import WalletDogFees from "./wallet/dogfees";
import WalletCredits from "./wallet/credits";
import WalletActivity from "./wallet/activity";
import UnknownBACS from "./wallet/unknownbacs";
import UnknownBACSAssigned from "./wallet/unknownbacsassigned";
import UploadResults from "./shows/uploadresults";
import FeesReport from "./wallet/feesreport";
import Shows from "./shows/shows";
import ShowEdit from "./shows/showedit";
import ClassDefaults from "./shows/classdefaults";
import Classes from "./classes/classes";
import EmailEditor from "./emaileditor/emaileditor";
import Processing from "./shows/processing/processing";
import MoneyOut from "./shows/processing/moneyout";
import EntryList from "./shows/processing/entrylist";
import MoneyReport from "./shows/moneyreport";
import RingEditor from "./shows/processing/ringeditor";
import ExcelProcess from "./shows/processing/excelprocess";
import ExcelLatesProcess from "./shows/processing/excellatesprocess";
import DogMeasuresReport from "./members/dogmeasuresreport";
import News from "./news/news";
import EditNews from "./news/edit";
import SpecialClasses from "./specialclasses/specialclasses";
import SystemParameters from "./parameters/list";
import EntryDuplicates from "./shows/entryduplicates";

function Admin(props) {
  return (
    <>
      <div className="grid">
        <div className="col-3">
          <Menu />
        </div>
        <div className="col-9">
          <Switch>
            <AdminRoute exact path="/admin/member/:Id">
              <MemberView />
            </AdminRoute>
            <AdminRoute exact path="/admin/membercreate">
              <MemberCreate />
            </AdminRoute>
            <AdminRoute exact path="/admin/members/dogmeasuresreport">
              <DogMeasuresReport />
            </AdminRoute>
            <AdminRoute exact path="/admin/wallet/reports">
              <WalletReports />
            </AdminRoute>
            <AdminRoute exact path="/admin/wallet/balances">
              <WalletBalances />
            </AdminRoute>{" "}
            <AdminRoute exact path="/admin/wallet/balances/previousday">
              <WalletBalancesPreviousDay />
            </AdminRoute>
            <AdminRoute exact path="/admin/wallet/handlerregistration">
              <WalletHandlerRegistrations />
            </AdminRoute>
            <AdminRoute exact path="/admin/wallet/handlerrenewal">
              <WalletHandlerRenewals />
            </AdminRoute>
            <AdminRoute exact path="/admin/wallet/dogfees">
              <WalletDogFees />
            </AdminRoute>
            <AdminRoute exact path="/admin/wallet/feesreport">
              <FeesReport />
            </AdminRoute>
            <AdminRoute exact path="/admin/wallet/credits">
              <WalletCredits />
            </AdminRoute>
            <AdminRoute exact path="/admin/wallet/activity">
              <WalletActivity />
            </AdminRoute>
            <AdminRoute exact path="/admin/wallet/unknownbacs">
              <UnknownBACS />
            </AdminRoute>
            <AdminRoute exact path="/admin/wallet/unknownbacsassigned">
              <UnknownBACSAssigned />
            </AdminRoute>
            <AdminRoute exact path="/admin/wallet/:Id">
              <Wallet />
            </AdminRoute>
            <AdminRoute exact path="/admin/shows/list">
              <Shows />
            </AdminRoute>
            <AdminRoute exact path="/admin/shows/classdefaults">
              <ClassDefaults />
            </AdminRoute>
            <AdminRoute exact path="/admin/shows/edit/:Id">
              <ShowEdit />
            </AdminRoute>
            <AdminRoute exact path="/admin/shows/processing/:Id">
              <Processing />
            </AdminRoute>
            <AdminRoute exact path="/admin/shows/processing/entrylist/:Id">
              <EntryList />
            </AdminRoute>
            <AdminRoute exact path="/admin/shows/processing/ringeditor/:Id">
              <RingEditor />
            </AdminRoute>
            <AdminRoute exact path="/admin/shows/processing/moneyout/:Id">
              <MoneyOut />
            </AdminRoute>
            <AdminRoute exact path="/admin/shows/processing/excelprocess/:Id">
              <ExcelProcess />
            </AdminRoute>
            <AdminRoute exact path="/admin/shows/processing/excellatesprocess/:Id">
              <ExcelLatesProcess />
            </AdminRoute>
            <AdminRoute exact path="/admin/shows/uploadresults">
              <UploadResults />
            </AdminRoute>
            <AdminRoute exact path="/admin/shows/moneyreport">
              <MoneyReport />
            </AdminRoute>
            <AdminRoute exact path="/admin/shows/entryduplicates">
              <EntryDuplicates />
            </AdminRoute>
            <AdminRoute exact path="/admin/classes">
              <Classes />
            </AdminRoute>
            <AdminRoute exact path="/admin/emails">
              <EmailEditor />
            </AdminRoute>
            <AdminRoute exact path="/admin/news">
              <News />
            </AdminRoute>
            <AdminRoute exact path="/admin/news/edit/:Id">
              <EditNews />
            </AdminRoute>
            <AdminRoute exact path="/admin/specialclasses">
              <SpecialClasses />
            </AdminRoute>
            <AdminRoute exact path="/admin/systemparameters">
              <SystemParameters />
            </AdminRoute>
            <AdminRoute exact path="/admin">
              <Search />
            </AdminRoute>
          </Switch>
        </div>
      </div>
    </>
  );
}

export default Admin;
