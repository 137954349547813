import React from 'react';
import {Link} from 'react-router-dom';
import ShowList from './showlist';


const Shows = props => {

    return (
        <div className="card">
            <div className="card-header">
                <h4>Show Management</h4>
            </div>
            <div className="card-body">
                <p className="mt-1x mb-2x">
                    <Link to="/admin/shows/edit/0" className="button button-blue button-small">Add a show</Link>
                </p>
                <ShowList />
            </div>
        </div>
    )
}


export default Shows;