import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalLoading from "../../../shared/ModalLoading";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import DogLine from "./dogline";
import Message from "../../../shared/Message";
import { apiGet } from "../../../shared/api";
import { useParams, Link } from "react-router-dom";

const DogList = (props) => {
  const [Loading, setLoading] = useState(true);
  const [Error, setError] = useState(false);
  const [DisplayList, setDisplayList] = useState([]);

  const { Id } = useParams();

  const LoadDogList = useCallback(async () => {
    try {
      const req = await apiGet(`/Dogs/List/${Id}`);
      if (req && req.ok && req.status === 200) {
        const res = await req.json();
        res.sort((a, b) => {
          return a.PetName < b.PetName ? -1 : 1;
        });
        setDisplayList(res);

        setError(false);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  }, [Id]);

  //Load list of dogs
  useEffect(() => {
    LoadDogList();
  }, [LoadDogList]);

  const DogLines = DisplayList.map((itm, idx) => {
    return <DogLine key={itm.Id} Dog={itm} onRefresh={LoadDogList} />;
  });

  if (Loading) return <ModalLoading />;

  if (Error)
    return (
      <Message
        Type="Error"
        Content="Sorry, there was an error loading the list of dogs. Please try again"
      />
    );

  return (
    <div className="card">
      <div className="card-header">
        <h4>Dogs</h4>
      </div>
      <div className="card-body">
        <div className="grid mb-2x">
          <div className="col-2">
            <Link to={`/admin/memberview/adddog/${Id}`}>
              <FontAwesomeIcon icon={faPlus} className="mr-1x" />
              Add a dog
            </Link>
          </div>
        </div>

        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Id</th>
              <th>Status</th>
              <th>DoB</th>
              <th>Gender</th>
              <th>Breed</th>
              <th>Performance</th>
              <th>Steeplechase</th>
              <th>Measure</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{DogLines}</tbody>
        </table>
      </div>
      <div className="card-footer"></div>
    </div>
  );
};

export default DogList;
