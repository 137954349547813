import React, { useState, useEffect, useContext } from "react";
import "./navbar.css";
import UserContext from "../../../context/user/UserContext";
import { apiGet } from "../../../shared/api.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

function Navbar(props) {
  const { User } = useContext(UserContext);
  const [IsAdmin, setIsAdmin] = useState(false);

  const [HasExpired, setHasExpired] = useState(false);

  //Call API to check if admin or not
  useEffect(() => {
    (async () => {
      try {
        setHasExpired(User.Expired);
        const req = await apiGet(`/members/account/${User.Id}`);
        if (req && req.ok && req.status === 200) {
          const res = await req.json();
          setIsAdmin(res.IsAdmin);
        }
      } catch (error) {
        setIsAdmin(false);
      }
    })();
  });

  const doMenu = () => {
    const links = document.getElementsByClassName("nav-item");
    if (links[0].classList.contains("visible")) {
      for (const lnk of links) lnk.classList.remove("visible");
    } else {
      for (const lnk of links) lnk.classList.add("visible");
    }
  };

  return (
    <div className="navbar mb-1x">
      <a href="/" className="nav-item">
        Home
      </a>
      {!HasExpired && (
        <>
          <a href="/account" className="nav-item">
            Account
          </a>
          <a href="/dogs" className="nav-item">
            Dogs
          </a>
        </>
      )}
      <a href="/wallet" className="nav-item">
        Wallet
      </a>
      {!HasExpired && (
        <>
          <a href="/entries" className="nav-item">
            My Entries
          </a>
          <a href="/shows/entrypreferences" className="nav-item">
            Preferences
          </a>
          <a href="/shows" className="nav-item">
            Enter Shows
          </a>
          <a href="/showmanagers" className="nav-item">
            Show Managers
          </a>
          <a href="/members/liveresults" className="nav-item">
            Live Results
          </a>
          <a href="/results" className="nav-item">
            Results
          </a>
          <a href="/league" className="nav-item">
            League
          </a>
          <a href="/emails" className="nav-item">
            Emails
          </a>
          {IsAdmin && (
            <a href="/admin" className="nav-item admin-link">
              Admin
            </a>
          )}
          <span className="responsive-menu-trigger" onClick={doMenu}>
            <FontAwesomeIcon icon={faBars} size="2x" className="mr-1x" />
            Menu
          </span>
        </>
      )}
    </div>
  );
}

export default Navbar;
