import React,{useContext, useState, useEffect, useRef} from 'react';
import RegistrationContext from '../../context/registration/RegistrationContext';
import {ValidatePassword} from '../../shared/functions.js';


function RegStep4(props) {

    const [PasswordValid, setPasswordValid] = useState(false);
    const [ShowPassword, setShowPassword] = useState(false);
    const {Password,UpdateValue} = useContext(RegistrationContext);
    const refPassword = useRef(null);


    //Focus on password box
    useEffect(() => {
        refPassword.current.focus();
    },[]);



    //Show password if needed
    useEffect(() => {
        refPassword.current.type = ShowPassword ? 'text' : 'password';
    },[ShowPassword])


    //Checks for all criteria
    useEffect(() => {
        setPasswordValid(ValidatePassword(Password));
    },[Password]);



    return (
        <>
            <h4>Password</h4>
            <p className="mb-1x">
                Set a password you'll use to secure your account. Requirements are:
            </p>
            <ul>
                <li>At least 8 characters</li>
                <li>Must contain at least one uppercase character, one lower case character and one digit</li>
            </ul>
        

            <div className="form-group">
                <label className="form-label">Password</label>
                <input ref={refPassword} type="password" value={Password} onChange={e => UpdateValue({Password: e.target.value})} className="form-control width-75"></input>
            </div>
            <div className="form-group mb-2x">
                Show password <input type="checkbox" value={ShowPassword} onChange={e => setShowPassword(e.target.checked)} />
            </div>
            <div className="form-group">
                <button className="button button-outline mr-1x" onClick={() => UpdateValue({CurrentStep: 3})}>Previous</button>
                {
                    PasswordValid ? 
                        <button className="button button-blue mr-2x" onClick={() => UpdateValue({CurrentStep: 5})}>Next</button>
                    :
                    <p>Password not yet valid - cannot continue</p>            
                }
            </div>
        </>
    )
}

export default RegStep4;