import React, { useState, useEffect, useCallback, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { apiPost, apiGet, apiDelete } from "../../../shared/api";
import moment from "moment";
import numeral from "numeral";
import Modal from "../../../shared/ModalContent";

const Classes = (props) => {
  const [Days, setDays] = useState([]);
  const [Classes, setClasses] = useState([]);
  const [Levels, setLevels] = useState([]);
  const [Heights, setHeights] = useState([]);
  const [ShowAdd, setShowAdd] = useState(false);

  const [SelectedClassDate, setSelectedClassDate] = useState();
  const [SelectedClassIds, setSelectedClassIds] = useState([]);
  const [SelectedLevels, setSelectedLevels] = useState([]);
  const [SelectedHeights, setSelectedHeights] = useState([]);
  const [RunCost, setRunCost] = useState("");
  const [OnlineLateRunCost, setOnlineLateRunCost] = useState("");
  const [AtShowLateRunCost, setAtShowLateRunCost] = useState("");
  const [ShowModal, setShowModal] = useState(false);
  const [ModalContent, setModalContent] = useState(false);

  const [ClassesAtShow, setClassesAtShow] = useState([]);
  const [Combinations, setCombinations] = useState([]);

  const ClassList = useRef(null);

  const LoadInitialData = useCallback(async () => {
    try {
      let req, res;

      //List of dates for show
      req = await apiGet(`/shows/${props.ShowId}`);
      res = await req.json();
      const DateList = [];
      let CurrentDate = moment(res.StartDate).hours(12);
      const LastDate = moment(res.EndDate).hours(12);
      while (CurrentDate.isSameOrBefore(LastDate)) {
        const CurrVal = moment(CurrentDate);
        DateList.push(CurrVal);
        CurrentDate = CurrentDate.add(1, "days");
      }
      setDays(DateList);

      //List of classes in database
      req = await apiGet(`/admin/shows/classes`);
      res = await req.json();
      setClasses(res.filter((c) => !c.IsHidden));

      //List of levels in database
      req = await apiGet(`/admin/shows/levels`);
      res = await req.json();
      setLevels(res.filter((l) => l.LevelId !== 1));

      //List of levels in database
      req = await apiGet(`/admin/shows/heights`);
      res = await req.json();
      setHeights(res);
    } catch (error) {
      console.log(error.message);
      window.alert("Error loading initial values");
    }
  }, [props.ShowId]);

  //Refresh list of classes at show
  const RefreshClasses = useCallback(async () => {
    try {
      let req, res;
      req = await apiGet(`/admin/shows/classes/list/${props.ShowId}`);
      res = await req.json();
      setClassesAtShow(res);
      req = await apiGet(`/admin/shows/classes/listcombinations/${props.ShowId}`);
      res = await req.json();
      setCombinations(res);
    } catch (error) {
      console.log(error.message);
      window.alert("Error loading list of classes");
    }
  }, [props.ShowId]);

  //Get default run cost when class selected
  useEffect(() => {
    if (SelectedClassIds.length > 0) {
      let found;
      switch (parseInt(SelectedClassIds[0])) {
        case 23:
          setRunCost((parseFloat(props.PreEntryPrice) * 2).toFixed(2));
          setOnlineLateRunCost((parseFloat(props.LateEntryPrice) * 2).toFixed(2));
          setAtShowLateRunCost((parseFloat(props.AtShowPrice) * 2).toFixed(2));
          break;
        case (24, 25):
          setRunCost((parseFloat(props.PreEntryPrice) * 3).toFixed(2));
          setOnlineLateRunCost((parseFloat(props.LateEntryPrice) * 3).toFixed(2));
          setAtShowLateRunCost((parseFloat(props.AtShowPrice) * 3).toFixed(2));
          break;

        case 26:
          found = Classes.find((c) => c.ClassId === 26);
          setRunCost(found.DefaultRunCost || 12.5);
          setOnlineLateRunCost(found.DefaultOnlineLateCost || 15);
          setAtShowLateRunCost(found.DefaultAtShowLateCost || 15);
          break;

        case 27:
          found = Classes.find((c) => c.ClassId === 27);
          setRunCost(found.DefaultRunCost || 6);
          setOnlineLateRunCost(found.DefaultOnlineLateCost || 8);
          setAtShowLateRunCost(found.DefaultAtShowLateCost || 8);
          break;

        case 90:
          found = Classes.find((c) => c.ClassId === 90);
          setRunCost(found.DefaultRunCost || 35);
          setOnlineLateRunCost(found.DefaultOnlineLateCost || 35);
          setAtShowLateRunCost(found.DefaultAtShowLateCost || 35);
          break;

        default:
          setRunCost(props.PreEntryPrice);
          setOnlineLateRunCost(props.LateEntryPrice);
          setAtShowLateRunCost(props.AtShowPrice);
          break;
      }
    }
  }, [SelectedClassIds, Classes]);

  //Manage list of selected levels
  const SetLevelSelections = (LevelId, Selected) => {
    let arr = Array.from(SelectedLevels);
    if (Selected) {
      arr.push(LevelId);
    } else {
      arr = arr.filter((i) => i !== LevelId);
    }
    setSelectedLevels(arr);
  };

  //Manage list of selected heights
  const SetHeightSelections = (HeightId, Selected) => {
    let arr = Array.from(SelectedHeights);
    if (Selected) {
      arr.push(HeightId);
    } else {
      arr = arr.filter((i) => i !== HeightId);
    }
    setSelectedHeights(arr);
  };

  //Selected Class Ids from multi-select
  const SelectClasses = () => {
    const options = ClassList.current.children;
    const idList = [];
    for (const option of options) if (option.selected) idList.push(option.value);

    setSelectedClassIds(idList);
  };

  //Array of checkboxes for level selection
  const LevelsChecks = Levels.map((itm) => {
    return (
      <React.Fragment key={itm.LevelId}>
        <input
          type="checkbox"
          checked={SelectedLevels.includes(itm.LevelId)}
          onChange={(e) => SetLevelSelections(itm.LevelId, e.target.checked)}
        />
        <span className="mr-1x">{itm.LevelLabel}</span>
      </React.Fragment>
    );
  });

  //Array of checkboxes for height selection
  const HeightsChecks = Heights.map((itm) => {
    return (
      <React.Fragment key={itm.HeightId}>
        <input
          type="checkbox"
          checked={SelectedHeights.includes(itm.HeightId)}
          onChange={(e) => SetHeightSelections(itm.HeightId, e.target.checked)}
        />
        <span className="mr-1x">{itm.HeightLabel}</span>
      </React.Fragment>
    );
  });

  //Save class
  const SaveNewClass = async () => {
    let hasErrors = false;

    try {
      for (const id of SelectedClassIds) {
        const found = ClassesAtShow.find(
          (c) =>
            moment(c.ClassDate).format("DD/MM/YYYY").toString() === SelectedClassDate &&
            c.ClassId === parseInt(SelectedClassIds)
        );
        if (found) {
          window.alert("Class already in the show on this date");
          return;
        }

        if (!SelectedClassIds || SelectedClassIds === "") {
          window.alert("Select a class!");
          return;
        }
        if (!SelectedClassDate || SelectedClassDate === "") {
          window.alert("Select a date!");
          return;
        }

        const LevelsInOrder = SelectedLevels.sort((a, b) => {
          return a < b ? -1 : 1;
        });
        const HeightsInOrder = SelectedHeights.sort((a, b) => {
          return a < b ? -1 : 1;
        });

        const obj = {
          ShowId: props.ShowId,
          ClassDate: SelectedClassDate,
          ClassId: id,
          SelectedLevels: LevelsInOrder,
          SelectedHeights: HeightsInOrder,
          RunCost,
          OnlineLateRunCost,
          AtShowLateRunCost,
        };

        const req = await apiPost("/admin/shows/classes/insert", obj);
        if (!req || !req.ok) hasErrors = true;
      }

      if (hasErrors)
        window.alert(
          "There was an error when saving at least one of the selected classes. Please check below to see what is missing"
        );

      //Refresh the list
      RefreshClasses();
      setSelectedClassIds([]);
      setSelectedHeights([]);
      setSelectedLevels([]);
    } catch (error) {
      console.log(error.message);
      window.alert("Error saving class");
    }
  };

  //List of days and classes
  useEffect(() => {
    LoadInitialData();
    RefreshClasses();
  }, [LoadInitialData, RefreshClasses]);

  const DeleteClass = async (LineId) => {
    try {
      const req = await apiDelete(`/admin/shows/classes/${LineId}`);
      if (req && req.ok) {
        RefreshClasses();
      } else {
        window.alert("Error deleting class");
      }
    } catch (error) {
      console.log(error.message);
      window.alert("Error deleting class");
    }
  };

  const SetDefaults = async () => {
    try {
      if (!SelectedClassIds || SelectedClassIds === "") {
        window.alert("Select a class!");
        return;
      }
      if (!SelectedClassDate || SelectedClassDate === "") {
        window.alert("Select a date!");
        return;
      }

      const obj = {
        ShowId: props.ShowId,
        ClassList: SelectedClassIds,
        ClassDate: SelectedClassDate,
        RunCost,
        OnlineLateRunCost,
        AtShowLateRunCost,
      };
      const req = await apiPost("/admin/shows/classes/defaults/assigntoshow", obj);
      if (req && req.ok) {
        const res = await req.json();
        const ClassesNotAdded = res.NotAddedClasses;
        if (ClassesNotAdded.length > 0) {
          const msg = ClassesNotAdded.join(", ");
          window.alert(`The following classes were not added as they were already present:\n\n${msg}`);
        }

        RefreshClasses();
      } else {
        window.alert("Error assigning defaults");
      }
    } catch (error) {
      console.log(error.message);
      window.alert("Error assigning defaults");
    }
  };

  const ShowCombos = (ClassDate, ClassId) => {
    const matchedClasses = Combinations.filter(
      (c) => moment(c.ClassDate).isSame(moment(ClassDate)) && c.ClassId === ClassId
    );
    let Uniques = new Set();
    for (const c of matchedClasses) {
      Uniques.add(c.LevelId);
    }

    const output = [...Uniques].map((itm, idx) => {
      const found = matchedClasses.find((c) => c.LevelId === itm);

      return (
        <tr>
          <td>
            <b>{found.LevelLabel}</b>
          </td>
          <td>
            {matchedClasses
              .filter((c) => c.LevelId === itm)
              .map((itm) => itm.HeightLabel)
              .join(",")}
          </td>
        </tr>
      );
    });

    setModalContent(
      <>
        <table>{output}</table>
        <button className="button" onClick={() => setShowModal(false)}>
          Close
        </button>
      </>
    );
    setShowModal(true);
  };

  const Rows = () => {
    let lastDate = undefined;
    return ClassesAtShow.map((itm, idx) => {
      const ShowTheDate = lastDate === undefined || !moment(lastDate).isSame(itm.ClassDate);
      lastDate = itm.ClassDate;

      return (
        <tr key={idx}>
          <td>{ShowTheDate ? moment(itm.ClassDate).format("ddd Do MMM") : <></>}</td>
          <td className="cursor-pointer" onClick={() => ShowCombos(itm.ClassDate, itm.ClassId)}>
            {itm.ClassLabel}
          </td>
          <td>
            £{numeral(itm.RunCost).format("0.00")} / £{numeral(itm.OnlineLateRunCost).format("0.00")} / £
            {numeral(itm.AtShowLateCost).format("0.00")}
          </td>
          <td>
            <span className="text-red cursor-pointer" onClick={() => DeleteClass(itm.LineId)}>
              <FontAwesomeIcon icon={faTrash} className="mr-1x" />
              Delete
            </span>
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="card">
      <div className="card-header">Classes at this show</div>
      <div className="card-body">
        {ShowModal && <Modal>{ModalContent}</Modal>}

        <p className="mb-1x">
          <button className="button" onClick={() => setShowAdd(true)}>
            <FontAwesomeIcon className="mr-1x" icon={faPlus} /> Add class
          </button>
        </p>

        {ShowAdd ? (
          <div className="mb-3x">
            <p>
              <b>New Class at show</b>
            </p>
            <div className="form-group">
              <label>Day:</label>
              <select
                className="form-control width-25"
                value={SelectedClassDate}
                onChange={(e) => setSelectedClassDate(e.target.value)}
              >
                <option value="">Select...</option>
                {Days.map((itm, idx) => {
                  return (
                    <option key={idx} value={moment(itm).format("DD/MM/YYYY")}>
                      {moment(itm).format("dddd Do MMM")}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group">
              <label>Class:</label>
              <select
                ref={ClassList}
                multiple
                size="8"
                className="form-control width-50"
                value={SelectedClassIds}
                onChange={SelectClasses}
              >
                {Classes.map((itm) => {
                  return (
                    <option key={itm.ClassId} value={itm.ClassId}>
                      {itm.ClassLabel}
                    </option>
                  );
                })}
              </select>
              <br />
              <p>
                Add specifics below, or just{" "}
                <button className="button button-green ml-1x" onClick={SetDefaults}>
                  Add Defaults
                </button>
              </p>
            </div>
            <div className="form-group">
              <label>Levels:</label>
              {LevelsChecks}
            </div>
            <div className="form-group">
              <label>Heights:</label>
              {HeightsChecks}
            </div>
            <div className="form-group">
              <button className="button button-green mr-1x" onClick={SaveNewClass}>
                Save
              </button>
              <button className="button" onClick={() => setShowAdd(false)}>
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}

        <br />

        {ClassesAtShow.length === 0 ? (
          <p>
            <b>No classes yet</b>
          </p>
        ) : (
          <>
            <p>Click a class name for height / level details</p>
            <table className="table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Class</th>
                  <th>Costs</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{Rows()}</tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
};

export default Classes;
