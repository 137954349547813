import React, { useState, useEffect } from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";

const RingView = ({ Loading, Data, RingId, ...props }) => {
  const [Classes, setClasses] = useState([]);

  const history = useHistory();

  useEffect(() => {
    if (!Data || Data.length === 0) {
      history.push("/liveresults/ringoverview");
    } else {
      const foundRing = Data.find((r) => r.RingId === RingId);
      const foundClasses = foundRing.Classes.sort((a, b) =>
        a.LevelSortOrder < b.LevelSortOrder ? -1 : 1
      );
      setClasses(foundClasses);
    }
  }, [Data, RingId]);

  return (
    <>
      {Loading && (
        <div className="mb-3x mt-3x">
          <FontAwesomeIcon icon={faSpinner} spin className="mr-1x" />
          Loading...
        </div>
      )}

      <p className="mb-2x">
        A red highlight indicates a class has moved from the published ring plan.
      </p>
      <h3 className="mb-1x">Viewing Ring {RingId}</h3>
      <table className="table">
        <thead>
          <tr>
            <th>Class</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {Classes.map((c) => {
            let status;

            if (c.Heights && c.Heights.some((h) => h.Status === "Finished"))
              status = "Running";
            if (c.Heights && c.Heights.every((h) => h.Status === "Finished"))
              status = "Finished";
            if (c.Heights && c.Heights.some((h) => h.Status === "Reopened"))
              status = "Reopened";
            if (!status) {
              if (c.Status === "Closed") {
                status = "Closed";
              } else if (!status && c.Status !== "Finished") {
                status = c.Status;
              }
            }
            if (!status) status = "Not started";

            return (
              <tr key={`${c.ClassId}_${c.LevelId}`}>
                <td data-mobilelabel="Class">
                  <span className={c.ClassMoved ? "alert alert-error" : ""}>
                    {c.ClassLabel}
                  </span>
                </td>
                <td data-mobilelabel="Status">{status}</td>
                <td>
                  <button
                    className="button button-blue"
                    onClick={() => props.onClassSelected(c.ClassId, c.LevelId)}
                  >
                    Details
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default RingView;
