import React, { useState, useEffect } from "react";
import { apiGet } from "../../../shared/api";

function ShowFilters({ ShowId, ...props }) {
  const [LevelId, setLevelId] = useState("");
  const [ClassType, setClassType] = useState("");
  const [HeightId, setHeightId] = useState("");
  const [HeightList, setHeightList] = useState([]);
  const [PointsOnly, setPointsOnly] = useState(false);
  const [DocumentList, setDocumentList] = useState([]);
  const [BaseUrl, setBaseUrl] = useState();

  //Notify of filter update when values change
  useEffect(() => {
    props.onFilterChange({ LevelId, ClassType, PointsOnly, HeightId });
    //eslint-disable-next-line
  }, [LevelId, ClassType, PointsOnly, HeightId]);

  //Get results PDFs when we have a ShowId
  useEffect(() => {
    (async () => {
      try {
        if (ShowId) {
          let req, res;
          req = await apiGet(`/admin/shows/managerdocuments/list/${ShowId}`);
          res = await req.json();
          setDocumentList(res.FileList.filter((d) => d.IsHeat));
          setBaseUrl(res.BaseUrl);

          //Heights
          req = await apiGet("/admin/shows/heights");
          res = await req.json();
          setHeightList(res);
        }
      } catch (error) {
        //window.alert("Error loading list of documents");
      }
    })();
  }, [ShowId]);

  const Heights = HeightList.map((itm) => (
    <option key={itm.HeightId} value={itm.HeightId}>
      {itm.HeightLabel}
    </option>
  ));

  return (
    <div className="mb-3x">
      <div className="grid">
        <div className="col-2">
          <p>
            <b>Level</b>
          </p>
          <p>
            <select
              className="form-control"
              value={LevelId}
              onChange={(e) => setLevelId(e.target.value)}
            >
              <option value="">All</option>
              <option value="1">Nursery</option>
              <option value="2">Beginners</option>
              <option value="3">Novice</option>
              <option value="4">Senior</option>
              <option value="5">Champion</option>
              <option value="6">Junior</option>
              <option value="7">Casual</option>
            </select>
          </p>
        </div>
        <div className="col-3">
          <p>
            <b>Class type</b>
          </p>
          <p>
            <select
              className="form-control"
              value={ClassType}
              onChange={(e) => setClassType(e.target.value)}
            >
              <option value="">All</option>
              <option value="1">Agility classes</option>
              <option value="2">Jumping classes</option>
              <option value="3">Games classes</option>
              <option value="4">Steeplechase classes</option>
              <option value="5">Performance classes</option>
              <option value="6">Nursery classes</option>
              <option value="7">Casual classes</option>
            </select>
          </p>
        </div>
        <div className="col-2">
          <p>
            <b>Height</b>
          </p>
          <p>
            <select
              className="form-control"
              value={HeightId}
              onChange={(e) => setHeightId(e.target.value)}
            >
              <option value="">All</option>
              {Heights}
            </select>
          </p>
        </div>
        <div className="col-4">
          <p className="mt-2x">
            <input
              type="checkbox"
              checked={PointsOnly}
              onChange={(e) => setPointsOnly(e.target.checked)}
            ></input>{" "}
            Just runs that scored progression points
          </p>
        </div>
      </div>
      {DocumentList.length > 0 && (
        <div className="mt-2x">
          <b className="mr-1x">Heat Results:</b>
          {DocumentList.map((itm, idx) => {
            const fn = `${BaseUrl}/${itm.Filename}`;
            return (
              <a
                target="_blank"
                rel="noopener noreferrer"
                key={itm.LineId}
                className="mr-1x"
                href={fn}
              >
                {itm.Description}
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default ShowFilters;
