import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { apiGet } from "../../../../shared/api";
import ModalLoading from "../../../../shared/ModalLoading";
import HandlerSheet from "./handlersheet.js";
import { pdf } from "@react-pdf/renderer";
import { DownloadFile } from "../../../../shared/clientsidefiledownload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const EntryList = (props) => {
  const [Data, setData] = useState([]);
  const [Loading, setLoading] = useState(false);

  const { Id } = useParams();

  useEffect(() => {
    const LoadData = async () => {
      try {
        setLoading(true);

        const req = await apiGet(`/admin/shows/processing/entrylist/${Id}`);
        const res = await req.json();
        let output = res.reduce((acc, itm) => {
          const found = acc.find((p) => p.MemberId === itm.MemberId);
          return !found ? acc.concat(itm) : acc;
        }, []);

        setData(output);

        setLoading(false);
      } catch (error) {
        window.alert("Error loading data");
      }
    };

    if (Id) LoadData();
  }, [Id]);

  const GetPdf = async (MemberId) => {
    setLoading(true);
    const doc = await HandlerSheet(Id, MemberId);
    const blob = await pdf(doc).toBlob();
    DownloadFile(blob, "HandlerInfoSheet.pdf");
    setLoading(false);
  };

  const TableRows = Data.map((itm) => {
    return (
      <tr key={itm.MemberId}>
        <td>
          {itm.FirstName} {itm.LastName} ({itm.MemberId})
          {itm.IsPaper ? " (Paper)" : ""}
        </td>
        <td>
          <span className="cursor-pointer" onClick={() => GetPdf(itm.MemberId)}>
            <FontAwesomeIcon
              icon={faFilePdf}
              size="lg"
              className="text-red mr-1x"
            />
            Handler Info Sheet
          </span>
        </td>
      </tr>
    );
  });

  if (Loading) return <ModalLoading />;

  return (
    <div className="card">
      <div className="card-header">Entry List</div>
      <div className="card-body">
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Handler</th>
              <th>Documents</th>
            </tr>
          </thead>
          <tbody>{TableRows}</tbody>
        </table>
      </div>
    </div>
  );
};

export default EntryList;
