import React, {useState, useEffect, useContext} from 'react';
import Transactions from './transactions';
import Balance from './balance';
import Actions from './actions';
import {GetBalance} from '../../../shared/wallet.js'
import UserContext from '../../../context/user/UserContext';


function Wallet (props) {

    const {User} = useContext(UserContext);

    const [WalletDetails, setWalletDetails] = useState(undefined);

    // //Get balance
    useEffect(() => {

        const LoadData = async() => {
            try {
                const details = await GetBalance(User.Id);
                setWalletDetails(details);
            }
            catch (error) {
                console.log(error.message);
            }
        }

        LoadData();

    },[User.Id]);


    return (
        <div className="grid spaced">
            <div className="col-4">
                <Balance Wallet={WalletDetails} />
                <br /><br />
                <Actions Wallet={WalletDetails} />
            </div>
            <div className="col-8 mb-2x">
                <Transactions UserId={User.Id} /><br /><br />
            </div>            
        </div>
    );
}

export default Wallet;