import React, { useState, useEffect, useCallback } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { apiDelete, apiGet, apiPost } from "../../../shared/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

const CampingDates = (props) => {
  const [ShowAdd, setShowAdd] = useState(false);
  const [CampingDate, setCampingDate] = useState();
  const [DateList, setDateList] = useState([]);

  const LoadData = useCallback(async () => {
    try {
      const req = await apiGet(
        `/admin/shows/camping/singlenight/list/${props.ShowId}`
      );
      if (req && req.ok) {
        const res = await req.json();
        setDateList(res);
      } else {
        window.alert("Error loading list of camping dates");
      }
    } catch (error) {
      console.log(error.message);
      window.alert("Error loading list of camping dates");
    }
  }, [props.ShowId]);

  useEffect(() => {
    LoadData();
  }, [LoadData]);

  //Save a new date
  const SaveDate = async () => {
    try {
      //Check we don't have date already
      const dt = moment(CampingDate, "DD/MM/YYYY");
      let exists = false;
      for (const currentDate of DateList) {
        if (moment(currentDate.CampingDate).isSame(dt)) {
          exists = true;
        }
      }

      if (exists) {
        window.alert("Date already assigned to show");
      } else {
        const obj = {
          ShowId: props.ShowId,
          CampingDate: moment(dt).format("YYYYMMDD"),
        };
        const req = await apiPost("/admin/shows/camping/singlenight", obj);
        if (req && req.ok) {
          LoadData();
          setCampingDate("");
        } else {
          window.alert("Error saving camping date");
        }
      }
    } catch (error) {
      console.log(error.message);
      window.alert("Error saving camping date");
    }
  };

  //Delete an existing date
  const DeleteDate = async (Id) => {
    try {
      if (window.confirm("Are you sure?")) {
        const req = await apiDelete(`/admin/shows/camping/singlenight/${Id}`);
        if (req && req.ok) {
          LoadData();
        } else {
          window.alert("Error deleting date");
        }
      }
    } catch (error) {
      console.log(error.message);
      window.alert("Error deleting date");
    }
  };

  //List of dates
  const Rows = DateList.map((itm) => {
    return (
      <tr key={itm.Id}>
        <td>{moment(itm.CampingDate).format("ddd Do MMM")}</td>
        <td>
          <span
            className="text-red cursor-pointer"
            onClick={() => DeleteDate(itm.Id)}
          >
            <FontAwesomeIcon icon={faTrash} className="mr-1x" />
            Delete
          </span>
        </td>
      </tr>
    );
  });

  return (
    <div className="card">
      <div className="card-header">Camping Dates</div>
      <div className="card-body">
        <p className="mb-1x">
          <button className="button" onClick={() => setShowAdd(true)}>
            <FontAwesomeIcon className="mr-1x" icon={faPlus} /> Add date
          </button>
        </p>

        {ShowAdd ? (
          <>
            <div className="form-group">
              <label>Date:</label>
              <DatePicker
                showMonthDropdown
                showYearDropdown
                className="form-control width-50"
                dateFormat="dd/MM/yyyy"
                selected={CampingDate}
                value={CampingDate}
                onChange={(val) => setCampingDate(val)}
              ></DatePicker>
            </div>
            <div className="form-group">
              <button onClick={SaveDate} className="button button-green mr-1x">
                Save
              </button>{" "}
              <button className="button" onClick={() => setShowAdd(false)}>
                Cancel
              </button>
            </div>
          </>
        ) : (
          <></>
        )}

        <table className="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{Rows}</tbody>
        </table>
      </div>
    </div>
  );
};

export default CampingDates;
