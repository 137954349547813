import React from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Rings = ({ Loading, Data, ...props }) => {
  const SelectRing = (RingId) => {
    props.onRingSelected(RingId);
  };

  const localStyles = {
    Ring: {
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "silver",
      width: 300,
      height: 200,
      marginBottom: 10,
      marginRight: 10,
      padding: 5,
      cursor: "pointer",
    },
  };

  return (
    <>
      <div className="card">
        <div className="card-header">Ring Overview</div>
        <div className="card-body">
          {Loading && (
            <div className="mb-3x mt-3x">
              <FontAwesomeIcon icon={faSpinner} spin className="mr-1x" />
              Loading...
            </div>
          )}

          <h4 className="mb-2x mt-2x">Click a ring for further details</h4>
          <div className="flex-row">
            {Data.map((ring) => (
              <div
                style={localStyles.Ring}
                key={ring.RingId}
                onClick={(e) => SelectRing(ring.RingId)}
              >
                <h3>Ring {ring.RingId}</h3>
                <p>
                  <small>Judge: {ring.JudgeName}</small>
                </p>
                <p>
                  <small>Currently running:</small> {ring.Status.CurrentlyRunning}
                </p>
                <p>
                  <small>Total runs in ring:</small> {ring.Status.Progress.Complete}{" "}
                  <small>of </small>
                  {ring.Status.Progress.Total}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Rings;
