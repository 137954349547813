import React, { useState, useEffect, useContext } from "react";
import { apiGet } from "../../../shared/api";
import { useParams } from "react-router-dom";
import ResultsLine from "./resultsline";
import Progression from "./progression";
import Filters from "./dogfilters";
import Message from "../../../shared/Message";
import ModalLoading from "../../../shared/ModalLoading";
import UserContext from "../../../context/user/UserContext";

function Dog(props) {
  const [DogId, setDogId] = useState(undefined);
  const [Loading, setLoading] = useState(true);
  const [Error, setError] = useState(false);
  const [Shows, setShows] = useState([]);
  const [AllResults, setAllResults] = useState([]);
  const [Dog, setDog] = useState({});
  const [ShowId, setShowId] = useState(0);
  const [Results, setResults] = useState([]);
  const [ResultList, setResultList] = useState([]);
  const [FilterCriteria, setFilterCriteria] = useState({
    LevelId: "",
    ClassType: "",
    PointsOnly: false,
  });
  const [IsAdmin, setIsAdmin] = useState(false);

  const { Id } = useParams();
  const { User } = useContext(UserContext);

  useEffect(() => {
    setDogId(Id);
  }, [Id]);

  useEffect(() => {
    let val = sessionStorage.getItem("User");
    if (val) {
      val = JSON.parse(val);
      if (val.IsAdmin) setIsAdmin(true);
    }
  }, []);

  //Loads the results data for this dog
  useEffect(() => {
    const LoadData = async () => {
      try {
        setLoading(true);
        setError(false);

        const req = await apiGet(`/results/dog/${DogId}`);
        if (req && req.ok) {
          const data = await req.json();

          //Data returned by API
          setAllResults(data[1]);
          setShows(data[0]);
          setDog(data[2][0]);

          setLoading(false);
          setError(false);
        } else {
          setLoading(false);
          setError(true);
        }
      } catch (error) {
        setError(true);
        setLoading(false);
        console.log(error);
      }
    };

    if (DogId !== undefined) LoadData();
  }, [DogId]);

  //Updates matching results when a show is selected from the dropdown
  useEffect(() => {
    if (ShowId !== undefined) {
      //Apply filters if needed
      let FilteredResults = Array.from(AllResults);
      if (FilterCriteria.LevelId !== "") {
        const levelId = parseInt(FilterCriteria.LevelId);
        FilteredResults = FilteredResults.filter((r) => r.LevelId === levelId);
      }
      if (FilterCriteria.ClassType !== "") {
        const classType = parseInt(FilterCriteria.ClassType);
        switch (classType) {
          case 7:
            FilteredResults = FilteredResults.filter((r) => r.ClassType === 8);
            break;

          case 6:
            FilteredResults = FilteredResults.filter((r) => r.ClassType === 5);
            break;

          case 5:
            FilteredResults = FilteredResults.filter(
              (r) =>
                (r.ClassType === 1 || r.ClassType === 2 || r.ClassType === 3) &&
                (r.LevelId === 2 || r.LevelId === 3 || r.LevelId === 4)
            );
            break;

          default:
            FilteredResults = FilteredResults.filter((r) => r.ClassType === classType);
            break;
        }
      }
      if (FilterCriteria.PointsOnly) {
        FilteredResults = FilteredResults.filter((r) => r.ProgressionPoints > 0);
      }

      //Get the matching results
      let results = FilteredResults.map((r) => {
        return r.ShowId === parseInt(ShowId) || parseInt(ShowId) === 0 ? r : null;
      });

      //Remove not applicable results
      results = results.filter((r) => r !== null);

      setResults(results);
    }
  }, [ShowId, FilterCriteria, AllResults]);

  //Renders the table of results
  useEffect(() => {
    const results = Results.map((itm, idx) => {
      return (
        <ResultsLine
          View="Dog"
          Data={itm}
          ShowId={ShowId}
          key={itm.LineId}
          onRefresh={() => window.location.reload()}
        />
      );
    });
    setResultList(results);
  }, [Results, ShowId]);

  //Shows to appear in the drop-down
  const ShowList = Shows.map((s) => {
    return (
      <option key={s.ShowId} value={s.ShowId}>
        {s.DisplayName}
      </option>
    );
  });

  const ScrollToResults = () => {
    window.scrollBy(0, 1000);
  };

  if (Loading) return <ModalLoading />;

  if (Error)
    return (
      <Message
        Type="Error"
        Content="Sorry, there was an error loading the data for this dog. Please try again"
      />
    );

  return (
    <>
      {Dog.MemberId === User.Id ? (
        <Progression Dog={Dog} ScrollToResults={() => ScrollToResults()} />
      ) : (
        <></>
      )}

      <div className="card">
        <div className="card-header">Results for {Dog.PetName}</div>
        <div className="card-body">
          {AllResults.length !== 0 ? (
            <>
              <Filters onFilterChange={(e) => setFilterCriteria(e)} />

              <div className="grid mb-2x">
                <div className="col-1">
                  <label>
                    <b>Show:</b>
                  </label>
                </div>
                <div className="col-3">
                  <select
                    value={ShowId}
                    onChange={(e) => setShowId(e.target.value)}
                    className="form-control"
                  >
                    <option value="0">All Shows</option>
                    {ShowList}
                  </select>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {Results.length === 0 ? (
            <p>No results to show</p>
          ) : (
            <table className="table font-90">
              <thead>
                <tr>
                  {IsAdmin && <th></th>}
                  <th>Date</th>
                  {parseInt(ShowId) === 0 ? <th>Show</th> : <></>}
                  <th>Class</th>
                  <th>Level</th>
                  <th>Height</th>
                  <th className="text-center">Faults</th>
                  <th className="text-center">Games Points</th>
                  <th className="text-center">Time</th>
                  <th className="text-center">Award</th>
                  <th className="text-center">Progression Points</th>
                </tr>
              </thead>
              <tbody>{ResultList}</tbody>
            </table>
          )}
        </div>
        <div className="card-footer"></div>
      </div>
    </>
  );
}

export default Dog;
