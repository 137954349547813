import React, { useState, useEffect } from "react";
import { apiGet, apiPost } from "../../shared/api.js";
import Message from "../../shared/Message";
import { useParams } from "react-router-dom";

const ResetPassword = (props) => {
  const [MessageText, setMessageText] = useState("");
  const [PasswordValid, setPasswordValid] = useState("");
  const [Password, setPassword] = useState("");
  const [Id, setId] = useState(undefined);

  const { ResetKey } = useParams();

  //Check key is valid
  useEffect(() => {
    const CheckKey = async () => {
      try {
        const req = await apiGet(
          `/members/validateresetpasswordkey/${ResetKey}`
        );
        if (req && req.ok && req.status === 200) {
          const res = await req.json();
          setId(res.MemberId);
        } else {
          console.log("Error validating key");
          setMessageText(
            "Sorry, there was an error validating your password reset key. Please contact the office on enquiries@agility4all.co.uk"
          );
        }
      } catch (error) {
        console.log(error.message);
        setMessageText(
          "Error validating password reset key. Please contact the office on enquiries@agility4all.co.uk"
        );
      }
    };

    CheckKey();
  }, [ResetKey]);

  //Checks for all criteria
  useEffect(() => {
    let ok = true;

    //Criteria
    const checks = [/[A-Z]/, /[a-z]/, /[0-9]/];
    for (const check of checks)
      if (!Password.toString().match(check)) ok = false;

    //Min length
    if (Password.toString().length < 8) ok = false;

    setPasswordValid(ok);
  }, [Password]);

  const SetPassword = async () => {
    const ERROR_MSG =
      "Error setting password. Please contact the office on enquiries@agility4all.co.uk";

    try {
      const data = {
        UserDetails: {
          Id,
          Password,
          ResetKey,
        },
      };

      const req = await apiPost("/members/resetpassword", data);
      if (req && req.ok && req.status === 204) {
        window.location.href = "/";
      } else {
        setMessageText(ERROR_MSG);
      }
    } catch (error) {
      setMessageText(ERROR_MSG);
    }
  };

  return (
    <div className="container">
      {MessageText !== "" ? (
        <>
          <Message Type="Error" Content={MessageText}></Message>
          <br />
          <br />
        </>
      ) : (
        <></>
      )}
      <div className="card card-narrow">
        <div className="card-header">Reset Password</div>
        <div className="card-body">
          <p>
            Please enter a new password and click Set Password to update the
            database. Requirements are:
          </p>
          <ul>
            <li>At least 8 characters</li>
            <li>
              Must contain at least one uppercase character, one lower case
              character and one digit
            </li>
          </ul>
          <p>
            The Reset Password button will appear once you have entered a new
            password which meets the criteria.
          </p>
          <p>
            <input
              type="password"
              value={Password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control width-50"
            />
          </p>
        </div>
        <div className="card-footer">
          {PasswordValid ? (
            <button className="button button-green mr-2x" onClick={SetPassword}>
              Reset Password
            </button>
          ) : (
            <p>
              <br />
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
