import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { apiGet } from "../../../shared/api";
import moment from "moment";
import { Workbook } from "exceljs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { DownloadFile } from "../../../shared/clientsidefiledownload";

const ManagerAuth = () => {
  const [Type, setType] = useState("");
  const [Data, setData] = useState([]);
  const [AllData, setAllData] = useState([]);
  const { Id } = useParams();

  useEffect(() => {
    (async () => {
      if (Id) {
        try {
          const req = await apiGet(`/admin/shows/authorisations/${Id}`);
          const res = await req.json();
          let output = [];

          output = res.reduce((acc, itm) => {
            const details = JSON.parse(itm.Details);
            return acc.concat(details);
          }, []);

          output.sort((a, b) => {
            const aNameParts = a.MemberName.split(" ");
            const bNameParts = b.MemberName.split(" ");
            if (aNameParts[0] < bNameParts[0]) return -1;
            if (aNameParts[0] > bNameParts[0]) return 1;
            if (aNameParts.length > 1 && bNameParts.length > 1) {
              if (aNameParts[1] < bNameParts[1]) return -1;
              if (aNameParts[1] > bNameParts[1]) return 1;
            }
            if (a.PetName < b.PetName) return -1;
            if (a.PetName > b.PetName) return 1;
            if (
              moment(a.DateTimeStamp).isBefore(
                moment(b.DateTimeStamp),
                "second"
              )
            )
              return -1;
            if (
              moment(a.DateTimeStamp).isAfter(moment(b.DateTimeStamp), "second")
            )
              return -1;
            return 0;
          });

          setAllData(output);
        } catch (error) {
          window.alert("Error loading authorisation data");
        }
      }
    })();
  }, [Id]);

  useEffect(() => {
    setData(Type === "" ? AllData : AllData.filter((t) => t.Type === Type));
  }, [Type, AllData]);

  const Rows = Data.map((itm, idx) => {
    let Details = itm.Details;
    return (
      <tr key={idx}>
        <td>{moment(itm.DateTimeStamp).format("DD/MM/YYYY")}</td>
        <td>{itm.MemberId}</td>
        <td>{itm.MemberName}</td>
        <td>{itm.Id}</td>
        <td>{itm.PetName}</td>
        <td>{Details}</td>
        <td>{itm.Notes}</td>
      </tr>
    );
  });

  const GetExcel = async () => {
    try {
      const wb = new Workbook();
      const ws = wb.addWorksheet("Data");
      ws.addRow([
        "Date",
        "Handler Id",
        "Handler Name",
        "Dog Id",
        "Dog Name",
        "Details",
        "Notes",
      ]);
      for (const itm of Data) {
        let Details = itm.Details;
        ws.addRow([
          moment(itm.DateTimeStamp).format("DD/MM/YYYY"),
          itm.MemberId,
          itm.MemberName,
          itm.Id,
          itm.PetName,
          Details,
          itm.Notes,
        ]);
      }

      const buf = await wb.xlsx.writeBuffer();
      const blob = new Blob([buf], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      DownloadFile(blob, "ManagerAuthorisations.xlsx");
    } catch (error) {
      console.log(error.message);
      window.alert("Error creating Excel");
    }
  };

  return (
    <>
      <h2>Manager Authorisations</h2>
      <p className="mb-3x">
        <a href={`/showmanagers/process/${Id}`}>Back to process</a>
      </p>

      <div className="row mb-3x">
        <div className="form-group mr-2x width-50">
          <label>Type</label>
          <select
            value={Type}
            onChange={(e) => setType(e.target.value)}
            className="form-control"
          >
            <option value="">All</option>
            <option value="DogHeightChange">Dog Height Change</option>
            <option value="DogLevelChange">Dog Level Change</option>
          </select>
        </div>
      </div>

      {Data.length > 0 && (
        <>
          <p className="mt-1x mb-1x">
            <button className="button button-blue" onClick={GetExcel}>
              <FontAwesomeIcon icon={faFileExcel} className="mr-1x" /> Get as
              Excel
            </button>
          </p>
          <table className="table width-70">
            <thead>
              <tr>
                <td>Date</td>
                <td>Handler Id</td>
                <td>Handler Name</td>
                <td>Dog Id</td>
                <td>Dog Name</td>
                <td>Details</td>
                <td>Notes</td>
              </tr>
            </thead>
            <tbody>{Rows}</tbody>
          </table>
        </>
      )}
    </>
  );
};

export default ManagerAuth;
