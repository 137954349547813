import React,{useState, useEffect, useRef} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import SearchResults from './searchresults';


function Search(props) {

    const [Field, setField] = useState('MemberName');
    const [Value, setValue] = useState('');
    const [Criteria, setCriteria] = useState({});

    const valRef = useRef(null);


    const doSearch = () => {
        setCriteria({
            Field,Value
        });
    }



    //Runs a search if the key pressed is Enter
    const SearchIfEnter = e => {
        if(e.key === 'Enter')
            doSearch();
    }


    //Focus on the search string box if a field is selected, 
    //otherwise clear it
    useEffect(() => {
        if(Field !== '')
            valRef.current.focus();
        if(Field === '')
            setValue('');
        
    },[Field]);


    return (
        <>

            <div className="card mb-3x">
                <div className="card-header">
                    <h4>Member / Dog Search</h4>
                </div>
                <div className="card-body">

                    <div className="grid mb-2x">
                        <div className="col-4">
                            <div className="form-group">
                                <label className="form-label">Field to search:</label>
                                <select value={Field} onChange={e => setField(e.target.value)} className="form-control">
                                    <option value="MemberId">Member Id</option>
                                    <option value="MemberName">Member Name</option>
                                    <option value="Email">Email Address</option>
                                    <option value="Phone">Phone Number</option>
                                    <option value="Postcode">Postcode</option>
                                    <option value="DogId">Dog Id</option>
                                    <option value="DogName">Dog Name</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label className="form-label">Search for:</label>
                                <input ref={valRef} value={Value} onChange={e => setValue(e.target.value)} type="text" onKeyPress={SearchIfEnter} className="form-control width-75"></input>
                            </div>                    
                        </div>   
                        {
                            Field !== '' && Value !== '' ?
                            <div className="col-4">
                                <div className="form-group">
                                    <label className="form-label"></label>
                                    <p>
                                        <button className="button button-blue" onClick={() => doSearch()}><FontAwesomeIcon icon={faSearch} className="mr-1x" />Search</button>
                                    </p>
                                </div>                    
                            </div>    
                            :
                            <></>
                        }         
                    </div>


                    <SearchResults Criteria={Criteria} />


                </div>
                <div className="card-footer"></div>
            </div>

        </>
    );
}

export default Search;