import React, { useState, useEffect } from "react";
import logo from "../../../images/a4a-logo.png";

function Logo(props) {
  return (
    <div className="logo-container">
      <a href="https://www.agility4all.co.uk/" target="_new">
        <img
          src={logo}
          alt="The A4A Logo"
          title="A4A Logo"
          style={{ maxHeight: "130px", width: "auto" }}
        />
      </a>
    </div>
  );
}

export default Logo;
