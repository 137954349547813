import React, { useState, useEffect, useRef } from "react";
import { SortClasses, SortHeights } from "../../shared/functions";
import { apiGet, apiPost } from "../../shared/api";
import { useHistory } from "react-router-dom";
import moment from "moment";

const ClassList = (props) => {
  const [ClassId, setClassId] = useState("");
  const [HeightId, setHeightId] = useState("");
  const [AllClasses, setAllClasses] = useState([]);
  const [ClassList, setClassList] = useState([]);
  const [HeightList, setHeightList] = useState([]);
  const [ShowDate, setShowDate] = useState();
  const [ShowId, setShowId] = useState(0);
  const [Documents, setDocuments] = useState([]);
  const BaseUrl = useRef(null);
  const HasMasters = useRef(null);
  const HasSteepShowcase = useRef(null);
  const history = useHistory();

  useEffect(() => {
    let itm = sessionStorage.getItem("ShowId");
    setShowId(itm || 0);

    itm = sessionStorage.getItem("ShowDate");
    const chosenDate = moment(itm, "YYYYMMDD");
    setShowDate(chosenDate);
  }, []);

  //List available heights when we select a class
  useEffect(() => {
    const parts = ClassId.split("_");
    const found = AllClasses.filter((c) => c.ClassId === parseInt(parts[0]) && c.LevelId === parseInt(parts[1]));
    const sorted = SortHeights(found);
    setHeightList(sorted);
  }, [ClassId, ClassList, AllClasses]);

  //List of classes running today
  useEffect(() => {
    (async () => {
      if (ShowId === 0) return;
      try {
        let req, res;
        //Masters results check
        let obj = {
          ShowId,
          ClassDate: moment(ShowDate).format("YYYYMMDD"),
          ClassId: 36,
          LevelId: 5,
        };
        req = await apiPost("/admin/shows/classes/mastersresultscheck", obj);
        res = await req.json();
        const HasMastersAgilityResults = res.filter((r) => r.LeaderboardData !== null).length > 0;

        obj = {
          ShowId,
          ClassDate: moment(ShowDate).format("YYYYMMDD"),
          ClassId: 37,
          LevelId: 5,
        };
        req = await apiPost("/admin/shows/classes/mastersresultscheck", obj);
        res = await req.json();
        const HasMastersJumpingResults = res.filter((r) => r.LeaderboardData !== null).length > 0;

        req = await apiGet(`/admin/shows/classes/listcombinationsforresults/${ShowId}`);
        res = await req.json();

        HasMasters.current = res.find((c) => c.ClassId === 26 && moment(c.ClassDate).isSame(ShowDate, "day"));
        HasSteepShowcase.current = res.find((c) => c.ClassId === 44 && moment(c.ClassDate).isSame(ShowDate, "day"));

        let source = res.filter((c) => {
          return moment(c.ClassDate).isSame(ShowDate, "day") && (c.IsEnterable || c.ClassId === 31);
        });
        const heats = res.filter(
          (c) => moment(c.ClassDate).isSame(ShowDate, "day") && c.ClassId !== 31 && !c.IsEnterable
        );

        const heatClassIds = new Set();
        const heatLevelIds = new Set();
        for (const heat of heats) {
          heatClassIds.add(heat.ClassId);
          heatLevelIds.add(heat.LevelId);
        }

        for (const c of [...heatClassIds]) {
          let foundClass = res.filter((f) => f.ClassId === c && f.LevelId === [...heatLevelIds][0]);
          if (foundClass) {
            foundClass.forEach((e) => {
              e.ClassLabel = e.ClassLabel.replace("Beginners ", "");
              e.ClassLabel = e.ClassLabel.replace("Novice ", "");
              e.ClassLabel = e.ClassLabel.replace("Senior ", "");
              e.ClassLabel = e.ClassLabel.replace("Champion ", "");
            });
            source = source.concat(foundClass);
          }
        }

        if (HasSteepShowcase.current) {
          source.push({
            ClassId: 44,
            ClassLabel: `Steeplechase Select `,
            LevelId: 5,
            LevelLabel: "",
            HeightId: 14,
            HeightLabel: "250",
          });
          source.push({
            ClassId: 44,
            ClassLabel: `Steeplechase Select `,
            LevelId: 5,
            LevelLabel: "",
            HeightId: 1,
            HeightLabel: "300",
          });
          source.push({
            ClassId: 44,
            ClassLabel: `Steeplechase Select `,
            LevelId: 5,
            LevelLabel: "",
            HeightId: 2,
            HeightLabel: "400",
          });
          source.push({
            ClassId: 44,
            ClassLabel: `Steeplechase Select `,
            LevelId: 5,
            LevelLabel: "",
            HeightId: 3,
            HeightLabel: "500",
          });
          source.push({
            ClassId: 44,
            ClassLabel: `Steeplechase Select `,
            LevelId: 5,
            LevelLabel: "",
            HeightId: 4,
            HeightLabel: "600",
          });
        }

        if (HasMasters.current) {
          const itm = source.filter((c) => c.ClassId === 26);
          for (const found of itm) {
            found.ClassLabel = "Performance Agility Masters Overall";
            found.LevelLabel = "";
          }
          source.push({
            ClassId: 36,
            ClassLabel: `Performance Agility Masters Series Agility${
              HasMastersAgilityResults ? "" : " (No results yet)"
            } `,
            LevelId: 5,
            LevelLabel: "",
            HeightId: 14,
            HeightLabel: "250",
          });
          source.push({
            ClassId: 36,
            ClassLabel: `Performance Agility Masters Series Agility${
              HasMastersAgilityResults ? "" : " (No results yet)"
            } `,
            LevelId: 5,
            LevelLabel: "",
            HeightId: 1,
            HeightLabel: "300",
          });
          source.push({
            ClassId: 36,
            ClassLabel: `Performance Agility Masters Series Agility${
              HasMastersAgilityResults ? "" : " (No results yet)"
            } `,
            LevelId: 5,
            LevelLabel: "",
            HeightId: 2,
            HeightLabel: "400",
          });
          source.push({
            ClassId: 36,
            ClassLabel: `Performance Agility Masters Series Agility${
              HasMastersAgilityResults ? "" : " (No results yet)"
            } `,
            LevelId: 5,
            LevelLabel: "",
            HeightId: 3,
            HeightLabel: "500",
          });
          source.push({
            ClassId: 36,
            ClassLabel: `Performance Agility Masters Series Agility${
              HasMastersAgilityResults ? "" : " (No results yet)"
            } `,
            LevelId: 5,
            LevelLabel: "",
            HeightId: 4,
            HeightLabel: "600",
          });

          source.push({
            ClassId: 37,
            ClassLabel: `Performance Agility Masters Series Jumping${
              HasMastersJumpingResults ? "" : " (No results yet)"
            } `,
            LevelId: 5,
            LevelLabel: "",
            HeightId: 14,
            HeightLabel: "250",
          });
          source.push({
            ClassId: 37,
            ClassLabel: `Performance Agility Masters Series Jumping${
              HasMastersJumpingResults ? "" : " (No results yet)"
            } `,
            LevelId: 5,
            LevelLabel: "",
            HeightId: 1,
            HeightLabel: "300",
          });
          source.push({
            ClassId: 37,
            ClassLabel: `Performance Agility Masters Series Jumping${
              HasMastersJumpingResults ? "" : " (No results yet)"
            } `,
            LevelId: 5,
            LevelLabel: "",
            HeightId: 2,
            HeightLabel: "400",
          });
          source.push({
            ClassId: 37,
            ClassLabel: `Performance Agility Masters Series Jumping${
              HasMastersJumpingResults ? "" : " (No results yet)"
            } `,
            LevelId: 5,
            LevelLabel: "",
            HeightId: 3,
            HeightLabel: "500",
          });
          source.push({
            ClassId: 37,
            ClassLabel: `Performance Agility Masters Series Jumping${
              HasMastersJumpingResults ? "" : " (No results yet)"
            } `,
            LevelId: 5,
            LevelLabel: "",
            HeightId: 4,
            HeightLabel: "600",
          });
        }

        setAllClasses(source);

        const classes = SortClasses(source);
        for (const c of classes) {
          const found = source.some(
            (e) => e.ClassId === c.ClassId && e.LevelId === c.LevelId && e.HasLeaderboard === 1
          );
          c.HasResults = found && found.length !== 0;
        }

        setClassList(classes);

        //List of show managers documents
        req = await apiGet(`/admin/shows/managerdocuments/list/${ShowId}`);
        res = await req.json();
        BaseUrl.current = res.BaseUrl;
        setDocuments(
          HasMasters.current ? res.FileList.filter((d) => d.Filename.toLowerCase().indexOf("masters") > 0) : []
        );
      } catch (error) {
        console.log(error.message);
      }
    })();
  }, [ShowId, ShowDate]);

  //Build Url and redirect to leaderboard
  const ViewResults = () => {
    const parts = ClassId.split("_");
    const url = `/liveresults/resultsview/${parts[0]}/${parts[1]}/${HeightId}`;
    history.push(url);
  };

  return (
    <div className="card">
      <div className="card-header">Live Results</div>
      <div className="card-body">
        <h2>Ring Status</h2>
        <p className="mb-3x">
          <a href="/liveresults/ringoverview">Click here</a> to view the current ring status.
        </p>

        {Documents.length > 0 && (
          <>
            <h2>Running Orders</h2>
            <div className="mb-3x">
              {Documents.map((itm, idx) => (
                <p key={idx}>
                  <a target="_blank" rel="noopener noreferrer" href={`${BaseUrl.current}/${itm.Filename}`}>
                    {itm.Description}
                  </a>
                </p>
              ))}
            </div>
          </>
        )}

        <h2>Results</h2>
        <p className="mb-3x">Please select your class / height below and click 'View Now' to view results</p>
        {ClassList.length === 0 && <p>There are currently no classes to show.</p>}
        {ClassList.length > 0 && (
          <div className="row">
            <div className="form-group mr-2x">
              <label>Class</label>
              <select value={ClassId} onChange={(e) => setClassId(e.target.value)} className="form-control width-25">
                <option value="">Please select...</option>
                {ClassList.map((itm) => (
                  <option key={`${itm.ClassId}_${itm.LevelId}`} value={`${itm.ClassId}_${itm.LevelId}`}>
                    {itm.ClassId !== 29 && itm.ClassId !== 30 && itm.ClassId !== 31 && itm.LevelLabel} {itm.ClassLabel}{" "}
                    {!itm.HasResults && itm.ClassId !== 36 && itm.ClassId !== 37 && "(No results yet)"}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Height</label>
              <select className="form-control width-25" value={HeightId} onChange={(e) => setHeightId(e.target.value)}>
                <option value="">Please select...</option>
                <option value="0">All heights</option>
                {HeightList.map((itm) => (
                  <option key={itm.HeightId} value={itm.HeightId}>
                    {itm.HeightLabel}
                    {itm.HasLeaderboard === 0 && " (No results yet)"}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        {ClassId !== "" && HeightId !== "" && (
          <p>
            <button className="button button-green" onClick={ViewResults}>
              View Now
            </button>
          </p>
        )}
      </div>
    </div>
  );
};

export default ClassList;
