import React, { useState, useRef } from "react";
import { useInterval } from "beautiful-react-hooks";

const SaveButton = ({ onSave }) => {
  const [Classes, setClasses] = useState([]);
  const DaysInShow = useRef(0);

  useInterval(() => {
    const output = [];
    let itm = sessionStorage.getItem("Classes");
    if (itm) {
      itm = JSON.parse(itm);
      if (itm.length > 0) {
        DaysInShow.current = itm.filter((i) => i.Classes.length > 0).length;
      }
      for (const dt of itm) {
        for (const cls of dt.Classes) output.push(cls);
      }
    }
    setClasses([...output]);
  }, 2000);

  //if (Classes.length === 0) return <></>;
  const className =
    Classes.length === 0 ? "button mr-1x" : "button button-green mr-1x";

  return (
    <button
      disabled={Classes.length === 0}
      className={className}
      onClick={onSave}
    >
      Enter
    </button>
  );
};

export default SaveButton;
