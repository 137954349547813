import React, { useState, useEffect } from "react";

const MyEntryDeclaration = ({ onAgree, ...props }) => {
  const [Agreed, setAgreed] = useState(false);
  const [IsPaper, setIsPaper] = useState(false);
  const [ExpiresBeforeShow, setExpiresBeforeShow] = useState(false);
  const [ExpiresBeforeClose, setExpiresBeforeClose] = useState(false);

  useEffect(() => {
    onAgree(Agreed);
  }, [onAgree, Agreed]);

  return (
    <>
      {IsPaper && (
        <div className="alert alert-error mt-2x mb-2x">
          THIS IS A PAPER ENTRY
        </div>
      )}
      {ExpiresBeforeClose && (
        <div className="alert alert-error mt-2x mb-2x">
          <b>Warning</b>
          <br />
          Please be aware that your membership expires before the show closes.
          Please ensure you have £6 available in your account to cover this, in
          addition to any show entry fees due. If there are insufficient funds,
          your entry will be deleted.
        </div>
      )}
      {ExpiresBeforeShow && !ExpiresBeforeClose && (
        <div className="alert alert-error mt-2x mb-2x">
          <b>Warning</b>
          <br />
          Please be aware that your membership expires before the date of the
          show. Please ensure you have £6 available in your account to cover
          this, in addition to any show entry fees due. If there are
          insufficient funds, your entry will be deleted.
        </div>
      )}

      <p>
        <b>Declaration</b>
      </p>
      <p className="mb-3x">
        I agree to submit to and be bound by the Agility4All Rules &amp;
        Regulations in their present form or as they may be amended from time to
        time. I also agree to abide by the Regulations of this show and not
        bring to the Show any dog, which has contracted or been knowingly
        exposed to any infectious or contagious disease during the 21 days prior
        to the event. I further declare that I believe to the best of my
        knowledge that the dogs are not liable to disqualification under
        Agility4All Show Regulations. I also accept that I enter at my own risk.
      </p>
      <p className="mb-4x alert alert-error">
        <input
          type="checkbox"
          className="mr-1x"
          value={Agreed}
          onChange={(e) => setAgreed(e.target.checked)}
        />
        I agree
      </p>
    </>
  );
};

export default MyEntryDeclaration;
