import React, { useState } from "react";
import moment from "moment";
import numeral from "numeral";
import Modal from "../../../shared/ModalContent";
import SummaryDog from "../shows/singleclickentries/SummaryDog";
import SummaryCamping from "../shows/entries/SummaryCamping";
import SumaryHelping from "../shows/entries/SummaryHelping";
import SummaryPostage from "../shows/entries/SummaryPostage";

const PendingTransactionItem = (props) => {
  const isOverdue = moment(props.Item.DateTimeStamp).isBefore(moment());
  const ClassName =
    props.Item?.ShowId === 0 || isOverdue ? "text-red text-bold" : "";

  const [ShowModal, setShowModal] = useState(false);

  return (
    <tr>
      <td className={ClassName} data-mobilelabel="Date:">
        {moment(props.Item.DateTimeStamp).format("DD/MM/yyyy")}
      </td>
      <td className={ClassName} data-mobilelabel="Detail:">
        {props.Item.ShowId !== 0 ? (
          <span
            className={["text-link", "cursor-pointer", ...ClassName]}
            onClick={() => setShowModal(true)}
          >
            {props.Item.Detail}
          </span>
        ) : (
          <span>{props.Item.Detail}</span>
        )}
        {ShowModal ? (
          <Modal>
            <SummaryCamping ShowId={props.Item.ShowId} CanEdit={false} />
            {/*<SumaryHelping ShowId={props.Item.ShowId} CanEdit={false} />*/}
            <SummaryPostage ShowId={props.Item.ShowId} CanEdit={false} />
            <SummaryDog
              IsCondensed
              IsWallet
              ShowId={props.Item.ShowId}
              CanEdit={false}
            />
            <p className="mt-3x">
              <button className="button" onClick={() => setShowModal(false)}>
                Close
              </button>
            </p>
          </Modal>
        ) : (
          <></>
        )}
      </td>
      <td className={ClassName} data-mobilelabel="Type">
        {props.Item.TransactionType === "D" ? "-" : ""}£
        {numeral(props.Item.Amount).format("0.00")}
      </td>
    </tr>
  );
};

export default PendingTransactionItem;
