import React from "react";
import moment from "moment";
import { apiGet } from "../../../../shared/api";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

const RingBoard = async (Id) => {
  let Data = [],
    Rings = [];

  try {
    const req = await apiGet(`/admin/shows/rings/ringboarddata/${Id}`);
    const res = await req.json();
    Data = res;

    const _Dates = new Set();
    for (const row of res) _Dates.add(row.ClassDate);

    const _Rings = new Set();
    for (const row of res) _Rings.add(row.RingNumber);

    for (const dt of Array.from(_Dates))
      for (const ring of _Rings) Rings.push({ Date: dt, RingNumber: ring });
  } catch (error) {
    console.log(error.message);
    window.alert("Error loading data");
  }

  const styles = StyleSheet.create({
    page: {
      margin: 10,
    },
    fullWidth: {
      width: "95%",
      display: "flex",
      alignItems: "center",
    },
    ringHeading: {
      fontSize: 24,
      marginTop: 12,
      marginBottom: 8,
    },
    classHeading: {
      fontSize: 16,
      marginBottom: 10,
    },
    row: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      marginBottom: 24,
    },
    combo: {
      flex: 1,
      flexDirection: "column",
      width: "100%",
      justifyContent: "center",
    },
    cell: {
      fontSize: 14,
      textAlign: "center",
    },
  });

  const CreateClasses = (Ring, Data, c) => {
    let Heights = [];
    const IdParts = c.split("_");

    Heights = Data.filter(
      (r) =>
        moment(r.ClassDate).isSame(moment(Ring.Date)) &&
        r.RingNumber === parseInt(Ring.RingNumber) &&
        r.ClassId === parseInt(IdParts[0]) &&
        r.LevelId === parseInt(IdParts[1])
    ).sort((a, b) => {
      return a.HeightSortOrder < b.HeightSortOrder ? -1 : 1;
    });

    const key = `Ring${Ring.RingNumber}_${moment(Ring.Date).format(
      "YYYYMMDD"
    )}_${c}`;

    return (
      <View key={key} wrap={false}>
        <View style={styles.fullWidth}>
          <Text style={styles.classHeading}>
            {Heights.length > 0 && Heights[0].ClassLabel}
          </Text>
        </View>
        <View style={styles.row}>
          {Heights.map((h) => {
            const heightKey = `Ring${Ring.RingNumber}_${moment(
              Ring.Date
            ).format("YYYYMMDD")}_${c}_${h.HeightId}`;
            let RunCount = h.RunCount;
            if (h.ClassId === 23 || h.ClassId === 38 || h.ClassId === 39)
              RunCount = h.DogCount;

            return (
              <View style={styles.combo} key={heightKey}>
                <Text style={styles.cell}>{h.ShortName}</Text>
                <Text style={styles.cell}>{RunCount}</Text>
              </View>
            );
          })}
        </View>
      </View>
    );
  };

  const CreateRing = (Ring, Data) => {
    let Day = "";
    const Title = `Ring ${Ring.RingNumber}`;
    let Classes = [];
    let Judge = "";
    let RingData = Data.filter(
      (r) =>
        moment(r.ClassDate).isSame(moment(Ring.Date)) &&
        r.RingNumber === parseInt(Ring.RingNumber)
    );
    if (RingData.length > 0) {
      Day = moment(RingData[0].ClassDate).format("dddd Do MMMM");
      Judge = RingData[0].JudgeName;
      const _Classes = new Set();
      for (const row of RingData) _Classes.add(`${row.ClassId}_${row.LevelId}`);
      Classes = [..._Classes];
    } else {
      return <></>;
    }

    const key = `Ring${Ring.RingNumber}_${moment(Ring.Date).format(
      "YYYYMMDD"
    )}`;

    return (
      <Page key={key} size="A4" style={styles.page}>
        <View style={styles.fullWidth}>
          <Text>{Day}</Text>
          <Text style={styles.ringHeading}>
            {Title} - {Judge}
          </Text>
        </View>
        {Classes.map((c) => CreateClasses(Ring, Data, c))}
      </Page>
    );
  };

  return <Document>{Rings.map((r) => CreateRing(r, Data))}</Document>;
};

export default RingBoard;
