const ValidatePassword = (value) => {
  let ok = true;

  //Criteria
  const checks = [/[A-Z]/, /[a-z]/, /[0-9]/];
  for (const check of checks) if (!value.toString().match(check)) ok = false;

  //Min length
  if (value.toString().length < 8) ok = false;

  return ok;
};

const CapitaliseName = (name) => {
  //Bail if blank name
  if (name === "") return;

  let lowerName = name.toLowerCase();

  //Scottish names
  if (lowerName.substr(0, 2) === "mc") {
    const prefix = "Mc";
    const firstChar = name.length > 2 ? name.substr(2, 1) : "";
    const restOfString = name.length > 2 ? lowerName.substr(3) : "";
    return `${prefix}${firstChar}${restOfString.toLowerCase()}`;
  } else if (lowerName.substr(0, 3) === "mac") {
    const prefix = "Mac";
    const firstChar = name.length > 3 ? name.substr(3, 1) : "";
    const restOfString = name.length > 3 ? lowerName.substr(4) : "";
    return `${prefix}${firstChar}${restOfString.toLowerCase()}`;
  } else {
    //Capitalise frist letter
    return lowerName.replace(/\b(\w)/g, (s) => s.toUpperCase());
  }
};

const SortClasses = (data) => {
  const ClassIds = new Set();
  const LevelIds = new Set();
  for (const itm of data) {
    ClassIds.add(itm.ClassId);
    LevelIds.add(itm.LevelId);
  }
  let Uniques = [];
  for (const ClassId of [...ClassIds]) {
    for (const LevelId of [...LevelIds]) {
      const found = data.find((i) => i.ClassId === ClassId && i.LevelId === LevelId);
      if (found) Uniques.push(found);
    }
  }
  Uniques = Uniques.sort((a, b) => {
    if (a.ClassType > b.ClassType) return 1;
    if (a.ClassType < b.ClassType) return -1;
    if (a.LevelSortOrder > b.LevelSortOrder) return 1;
    if (a.LevelSortOrder < b.LevelSortOrder) return -1;

    return 0;
  });
  return Uniques;
};

const SortHeights = (data) => {
  data = data.sort((a, b) => {
    return a.HeightSortOrder > b.HeightSortOrder ? 1 : -1;
  });
  return data;
};

const GetHeightFromId = (val) => {
  let output = "Unknown";
  switch (val) {
    case 1:
      output = "300";
      break;
    case 2:
      output = "400";
      break;
    case 3:
      output = "500";
      break;
    case 4:
      output = "600";
      break;
    case 9:
      output = "200";
      break;
    case 14:
      output = "250";
      break;
    default:
      break;
  }
  return output;
};

export { ValidatePassword, CapitaliseName, SortClasses, SortHeights, GetHeightFromId };
