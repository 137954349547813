import React,{useState} from 'react';
import Message from '../../../shared/Message';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import {apiPost} from '../../../shared/api';
import {CapitaliseName} from '../../../shared/functions.js';
import {withRouter} from 'react-router-dom';


function MemberCreate(props) {

    const [Error, setError] = useState(false);
    const [UserDetails, setUserDetails] = useState({
        FirstName: '',
        LastName: '',
        Email: '',
        StreetAddress: '',
        Town: '',
        Postcode: '',
        MobilePhone: '',
        OtherPhone: '',
        DateOfBirth: '',
        Password: '',
        Notes: ''
    });



    const UpdateValue = value => {
        
        const field = Object.keys(value)[0];
        let val = Object.values(value)[0];
        
        if(val !== '') {

            //Do something with a string if we have one
            switch(field) {
                case 'FirstName':
                    val = CapitaliseName(val.toString().toLowerCase()); //val.substring(0,1).toUpperCase() + val.substring(1).toLowerCase();
                    break;


                case 'LastName':
                    val = CapitaliseName(val.toString().toLowerCase());
                    break;
                    

                case 'StreetAddress':
                    let saParts = val.split(' ');
                    for(let i = 0; i < saParts.length; i++)
                        saParts[i] = saParts[i].substring(0,1).toUpperCase() + saParts[i].substring(1).toLowerCase();

                    val =  saParts.join(' ');
                    break;


                case 'Town':
                    let tParts = val.split(' ');
                    for(let i = 0; i < tParts.length; i++)
                    tParts[i] = tParts[i].substring(0,1).toUpperCase() + tParts[i].substring(1).toLowerCase();
                    val = tParts.join(' ');
                    break;


                case 'Postcode':
                    val = val.toString().toUpperCase();
                    break;


                default: break;
                    
            }     
            
        }    

        const finalObj = {[field]:val}

        setUserDetails(prevState => {
            return {...prevState, ...finalObj};
        });
    }


    //API call to save user
    const SaveUser = async() => {

        try {

            //Save via API POST
            const data = {UserDetails};
            UserDetails.IsJunior = UserDetails.DateOfBirth === '' ? 0 : 1;

            if(UserDetails.IsJunior) {
                const minAge = moment().add(-16, "years").add(1, "days");
                const dt = moment(UserDetails.DateOfBirth);
                if(!dt.isAfter(minAge)) {
                    UserDetails.IsJunior = 0;
                    UserDetails.DateOfBirth = '';
                }
            }

            UserDetails.DateOfBirth = UserDetails.DateOfBirth !== '' ? UserDetails.DateOfBirth : '';
            
            const req = await apiPost('/members/create', data);
            if(req && req.ok && req.status === 201) {
                const res = await req.json();
                window.location.href = `/admin/wallet/${res.MemberId}`;
            } else {
                setError(true);
                return;
            }

        }
        catch (error) {
            console.log(error.message);
            setError(true);
        }

    }



    if(Error)
        return <Message Type="Error" Content="Sorry, there was an error with these account details. Please try again" />



    return (
        <div className="form-centered">
            <div className="card mb-4x">
                <div className="card-header">
                    <h4>Account Details</h4>
                </div>
                <div className="card-body">

                    <p className="mb-2x">Enter details below and click 'Save Details' to create an account</p>

                    <div className="form-group">
                        <label className="form-label">First Name:</label>
                        <input type="text" value={UserDetails.FirstName} onChange={e => UpdateValue({FirstName: e.target.value})} className="form-control width-50" />
                    </div>
                    <div className="form-group">
                        <label className="form-label">Last Name:</label>
                        <input type="text" value={UserDetails.LastName} onChange={e => UpdateValue({LastName: e.target.value})} className="form-control width-50" />
                    </div>
                    <div className="form-group mb-2x">
                        <label className="form-label">Email Address:</label>
                        <input type="text" value={UserDetails.Email} onChange={e => UpdateValue({Email: e.target.value})} className="form-control width-50" />
                    </div>    

                    <div className="form-group">
                        <label className="form-label">Street Address:</label>
                        <input type="text" value={UserDetails.StreetAddress} onChange={e => UpdateValue({StreetAddress: e.target.value})} className="form-control width-50" />
                    </div>
                    <div className="form-group">
                        <label className="form-label">Town:</label>
                        <input type="text" value={UserDetails.Town} onChange={e => UpdateValue({Town: e.target.value})} className="form-control width-50" />
                    </div>
                    <div className="form-group mb-2x">
                        <label className="form-label">Postcode:</label>
                        <input type="text" value={UserDetails.Postcode} onChange={e => UpdateValue({Postcode: e.target.value})} className="form-control width-25" />
                    </div>

                    <div className="form-group">
                        <label className="form-label">Mobile Phone:</label>
                        <input type="text" value={UserDetails.MobilePhone} onChange={e => UpdateValue({MobilePhone: e.target.value})} className="form-control width-25" />
                    </div>
                    <div className="form-group">
                        <label className="form-label">Other Phone:</label>
                        <input type="text" value={UserDetails.OtherPhone} onChange={e => UpdateValue({OtherPhone: e.target.value})} className="form-control width-25" />
                    </div>  
                    <div className="form-group">
                        <label className="form-label">Junior handler date of birth:</label>
                        <DatePicker maxDate={new Date()} showMonthDropdown showYearDropdown className="form-control width-50" selected={UserDetails.DateOfBirth} dateFormat="dd/MM/yyyy" value={UserDetails.DateOfBirth} onChange={val => UpdateValue({DateOfBirth:val})}></DatePicker>
                    </div>
                    <div className="form-group">
                        <label className="form-label">Password:</label>
                        <input type="text" value={UserDetails.Password} onChange={e => UpdateValue({Password: e.target.value})} className="form-control width-25" />
                    </div>    
                    <div className="form-group">
                        <label className="form-label">Notes:</label>
                        <textarea rows="6" value={UserDetails.Notes} onChange={e => UpdateValue({Notes: e.target.value})} className="form-control width-75"></textarea>
                    </div>                                       

                </div>
                <div className="card-footer">
                    <button className="button button-green" onClick={SaveUser}>Save Details</button>
                </div>
            </div>

        </div>
    )
}

export default withRouter(MemberCreate);