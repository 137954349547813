import React,{useState, useEffect} from 'react';
import {apiGet} from '../../../../shared/api.js';
import './showsbar.css';
import InlineLoading from '../../../../shared/InlineLoading';


function ShowsBar (props) {

    const [Loading, setLoading] = useState(true);
    const [Error, setError] = useState(false);
    const [ShowList, setShowList] = useState([]);


    useEffect(() => {

        const loadData = async() => {

            setError(false);
            setLoading(true);

            try {

                const req = await apiGet('/shows/closingin/7');
                if(req && req.ok && req.status === 200) {
                    const res = await req.json();
                    setShowList(res);
                    setError(false);
                } else {
                    setError(true);
                }
                setLoading(false);
            }
            catch (error) {
                setError(true);
                setLoading(false);
            }
        }

        loadData();
    },[]);


    const Shows = ShowList.map(itm => {
        return <span className="mr-1x" key={itm.ShowId}>{itm.DisplayName}</span>
    });



    if(ShowList.length === 0 || Error)
        return <></>


    if(Loading)
        return <InlineLoading />

    
    return (
        <div className="showsbar mb-2x">
            <p><b className="mr-1x">Shows closing in the next 7 days:</b> {Shows}</p>
        </div>
    )
}

export default ShowsBar;