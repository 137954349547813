import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { apiGet } from "../../../shared/api";
import DogHeightLine from "./dogheightline";
import DogMeasure from "./dogmeasure";

function DogHeights() {
  const { Id } = useParams();
  const [List, setList] = useState([]);
  const [ShowAddMeasure, setShowAddMeasure] = useState(false);

  const RefreshList = useCallback(async () => {
    try {
      const req = await apiGet(`/dogs/heights/list/${Id}`);
      const res = await req.json();
      setList(res);
    } catch (error) {
      window.alert("Error loading list of heights for this dog");
    }
  }, [Id]);

  useEffect(() => {
    RefreshList();
  }, [RefreshList]);

  const HandleSavedHeight = async () => {
    setShowAddMeasure(false);
    await RefreshList();
  };

  return (
    <div className="card">
      <div className="card-header">Dog Heights</div>
      <div className="card-body">
        <p className="mt-2x mb-2x">
          <button className="button button-blue" onClick={() => setShowAddMeasure(true)}>
            Add Measure
          </button>
        </p>

        {ShowAddMeasure && (
          <div className="mt-2x mb-3x">
            <DogMeasure
              onSave={HandleSavedHeight}
              onCancel={() => setShowAddMeasure(false)}
            />
          </div>
        )}

        <table className="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Height</th>
              <th>Type</th>
              <th>Measurer</th>
              <th>Show</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {List.map((itm, idx) => (
              <DogHeightLine
                key={itm.LineId}
                Data={itm}
                IsMostRecent={idx === 0}
                onRefresh={() => RefreshList()}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DogHeights;
