import React,{useState, useEffect, useContext, useCallback} from 'react';
import UserContext from '../../../context/user/UserContext';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import ModalLoading from '../../../shared/ModalLoading';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';
import DogLine from './dogline';
import Message from '../../../shared/Message';
import {apiGet} from '../../../shared/api';


function Dogs (props) {

    const [Loading, setLoading] = useState(true);
    const [Error, setError] = useState(false);
    const [DogList, setDogList] = useState([]);
    const [DisplayList, setDisplayList] = useState([]);
    const [ShowActive, setShowActive] = useState(true);
    const [ShowNotCompeting, setShowNotCompeting] = useState(false);
    const [ShowRetired, setShowRetired] = useState(false);
    const [ShowRainbow, setShowRainbow] = useState(false);

    const {User} = useContext(UserContext);


    const UpdateList = (field, value) => {

        switch(field) {
            case 'Active':
                setShowActive(prev => {
                    if(!value && !ShowRetired && !ShowNotCompeting && !ShowRainbow)
                        return true;
                    return value;
                });
                break; 

            case 'Retired': 
                setShowRetired(prev => {
                    if(!value && !ShowActive && !ShowNotCompeting && !ShowRainbow)
                        return true;
                    return value;
                });
                break;

            case 'NotCompeting': 
                setShowNotCompeting(prev => {
                    if(!value && !ShowActive && !ShowRetired && !ShowRainbow)
                        return true;
                    return value;
                });            
                break;

            case 'Rainbow':
                setShowRainbow(prev => {
                    if(!value && !ShowActive && !ShowNotCompeting && !ShowRetired)
                        return true;
                    return value;
                });                
                break;
            default: break;
        }

    }


    const LoadDogList = useCallback(async() => {

        try {
            const req = await apiGet(`/Dogs/List/${User.Id}`);
            if(req && req.ok && req.status === 200) {

                const res = await req.json();
                res.sort((a,b) => {
                    return a.PetName < b.PetName ? -1 : 1;
                });
                setDogList(res);

                setError(false);
                setTimeout(() => {
                    setLoading(false);
                }, 1000);

            } else {
                setLoading(false);
                setError(true);
            }
        }
        catch (error) {
            setError(true);
            setLoading(false);
        }

    },[User]);


    //Refresh list whenever checkbox state changes
    useEffect(() => {

        let workingList = [];

        //Add each dog the meets the filter criteria
        for(const dog of DogList) {
            if((dog.Status === 1 && ShowActive) || (dog.Status === 2 && ShowRetired) || (dog.Status === 3 && ShowRainbow) || (dog.Status === 5 && ShowNotCompeting))
                workingList.push(dog);
        }

        //Update list
        setDisplayList(workingList);
    
    }, [DogList, ShowActive, ShowRetired, ShowNotCompeting, ShowRainbow]);



    //Load list of dogs
    useEffect(() => {
        
        //Get list of dogs
        LoadDogList();

    },[LoadDogList]);




    const DogLines = DisplayList.map((itm,idx) => {
        return <DogLine key={itm.Id} Dog={itm} onRefresh={LoadDogList} />
    });


    if(Loading)
        return <ModalLoading />

    if(Error)
        return <Message Type="Error" Content="Sorry, there was an error loading the list of dogs. Please try again" />


    return (
        <div className="card">
            <div className="card-header">
                <h4>My Dogs</h4>
            </div>
            <div className="card-body">
                <div className="grid mb-2x">
                    <div className="col-2">
                        Filter visible dogs
                    </div>
                    <div className="col-9">
                        <span className="mr-1x"><input type="checkbox" checked={ShowActive} onChange={e => UpdateList('Active', e.target.checked)} /> Active</span>
                        <span className="mr-1x"><input type="checkbox" checked={ShowRetired} onChange={e => UpdateList('Retired', e.target.checked)} /> Retired</span>
                        <span className="mr-1x"><input type="checkbox" checked={ShowNotCompeting} onChange={e => UpdateList('NotCompeting', e.target.checked)} /> Not competing</span>
                        <span className="mr-1"><input type="checkbox" checked={ShowRainbow} onChange={e => UpdateList('Rainbow', e.target.checked)} /> Rainbow bridge</span>
                    </div>
                </div>
                <div className="grid mb-2x">
                    <div className="col-12">
                        <p>
                            <Link to="/Dogs/Add" className="button button-blue button-small"><FontAwesomeIcon icon={faPlus} className="mr-1x" />Add a dog</Link>
                        </p>
                        <p>
                            Dog registration costs &pound;6 for a lifetime membership
                        </p>
                    </div>
                </div>
                <p>
                    A dog height, if present, is displayed in mm. If the text is <span className="text-red text-bold">red</span> the height is provisional.
                </p>
                <p className="mb-2x">
                    If your dog was registered as no longer with us prior to 2020 then, due to the transfer of systems, there will unfortunately not be any results shown for them.
                </p>

                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Id</th>
                            <th>DoB</th>
                            <th>Gender</th>
                            <th>Breed</th>
                            <th>Performance</th>
                            <th>Steeplechase</th>
                            <th>Measure</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {DogLines}
                    </tbody>
                </table>
            </div>
            <div className="card-footer"></div>
        </div>
    )
}

export default Dogs;