import React, {useState} from 'react';
import Transactions from '../../members/wallet/transactions';
import {useParams} from 'react-router-dom';
import QuickAddItem from './quickadditem';


function Wallet (props) {

    const [RefreshCount, setRefreshCount] = useState(1);

    const {Id} = useParams();

    return (
        <>
            <QuickAddItem onRefresh={e => setRefreshCount(prevState => prevState + 1)} />
            <Transactions UserId={Id} View="Admin" RefreshCount={RefreshCount} /><br /><br />
        </>
    );
}

export default Wallet;