import React, { useState, useEffect } from "react";
import { apiGet } from "../../../shared/api";
import a4jlogo from "./agilityforjuniors.png";

const JuniorLeague = () => {
  const [Heights, setHeights] = useState([]);
  const [JLHeightId, setJLHeightId] = useState();
  const [Data, setData] = useState([]);
  const [FilteredData, setFilteredData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        let req, res;

        //Height list
        req = await apiGet("/admin/shows/heights");
        res = await req.json();
        setHeights(res);

        //Data
        req = await apiGet("/results/juniorleague");
        res = await req.json();
        setData(res);
      } catch (error) {}
    })();
  }, []);

  useEffect(() => {
    setFilteredData(Data.filter((d) => d.HeightId === parseInt(JLHeightId)));
  }, [JLHeightId]);

  return (
    <>
      <div className="card">
        <div className="card-header">Junior League</div>
        <div className="card-body">
          <p className="mb-3x">
            The Agility4All Junior League is sponsored by
            <br />
            <img
              src={a4jlogo}
              alt="Agility for Juniors"
              style={{ width: 200 }}
            />
            <br />
            <a
              href="https://www.facebook.com/Agility-For-Juniors-144841152247337"
              target="_new"
            >
              https://www.facebook.com/Agility-For-Juniors-144841152247337
            </a>
          </p>

          <div className="form-group">
            <label>Please select the height you'd like to view</label>
            <select
              className="form-control width-20"
              value={JLHeightId}
              onChange={(e) => setJLHeightId(e.target.value)}
            >
              <option value="">Select height...</option>
              {Heights.map((itm) => (
                <option key={itm.HeightId} value={itm.HeightId}>
                  {itm.HeightLabel}
                </option>
              ))}
            </select>
          </div>

          {FilteredData.length === 0 && JLHeightId > "" && (
            <p>No points have been gained in this height</p>
          )}

          {FilteredData.length > 0 && (
            <table className="table width-50 mt-3x">
              <thead>
                <tr>
                  <th>Member</th>
                  <th>Dog</th>
                  <th>Points</th>
                </tr>
              </thead>
              <tbody>
                {FilteredData.map((itm, idx) => (
                  <tr key={idx}>
                    <td>
                      {itm.FirstName} {itm.LastName}
                    </td>
                    <td>{itm.PetName}</td>
                    <td>{itm.Score}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default JuniorLeague;
