import React, { useState, useEffect, useContext } from "react";
import UserContext from "../../../context/user/UserContext";
import moment from "moment";
import { apiGet } from "../../../shared/api";
import { Link } from "react-router-dom";
import InfoSheet from "../shows/Infosheet";

const ShowManagers = (props) => {
  const [Data, setData] = useState([]);
  const [ShowModal, setShowModal] = useState(false);
  const [Show, setShow] = useState();
  const [EntryData, setEntryData] = useState();

  const { User } = useContext(UserContext);

  useEffect(() => {
    (async () => {
      try {
        const req = await apiGet(`/shows/imanage/${User.Id}`);
        const res = await req.json();
        setData(res);
      } catch (error) {
        console.log(error.message);
        window.alert("Error loading show list");
      }
    })();
  }, [User.Id]);

  const ShowInfoModal = async (Show) => {
    try {
      setShow(Show);
      setShowModal(true);
      const req = await apiGet(`/admin/shows/quickstats/${Show.ShowId}`);
      const res = await req.json();
      setEntryData(res);
    } catch (error) {
      window.alert("Error loading entry data");
    }
  };

  const Rows = Data.map((itm) => {
    return (
      <tr key={itm.ShowId}>
        <td data-mobilelabel="Show">{itm.DisplayName}</td>
        <td data-mobilelabel="Opens">
          {moment(itm.EntriesOpen).format("ddd Do MMM")}
        </td>
        <td data-mobilelabel="Closes">
          {moment(itm.EntriesClose).format("ddd Do MMM")}
        </td>
        <td data-mobilelabel="Starts">
          {moment(itm.StartDate).format("ddd Do MMM")}
        </td>
        <td data-mobilelabel="Ends">
          {moment(itm.EndDate).format("ddd Do MMM")}
        </td>
        <td data-mobilelabel="Links" className="flex-cell">
          <span
            className="text-link cursor-pointer mr-1x"
            onClick={() => ShowInfoModal(itm)}
          >
            Show Details
          </span>
          <Link to={`/showmanagers/process/${itm.ShowId}`} className="mr-1x">
            Documents
          </Link>
          <Link to={`/showmanagers/pincodes/${itm.ShowId}`} className="mr-1x">
            PIN Codes
          </Link>
          <Link
            to={`/showmanagers/transactions/${itm.ShowId}`}
            className="mr-1x"
          >
            Transactions
          </Link>
        </td>
      </tr>
    );
  });

  return (
    <div className="card">
      <div className="card-header">Show Management</div>
      <div className="card-body">
        <h3 className="mt-2x mb-2x">Shows for which you are a manager</h3>

        {ShowModal && (
          <InfoSheet
            Show={Show}
            EntryData={EntryData}
            View="Managers"
            onClose={() => setShowModal(false)}
          />
        )}

        {Data.length === 0 ? (
          <>
            <p>You're not currently attached to any shows</p>
            <p className="mt-1x">
              We are actively looking for new Show Managers in all areas. To
              find out more about the benefits of running a A4A show in your
              area, please email the office on{" "}
              <a href="mailto:enquiries@agility4all.co.uk">
                enquiries@agility4all.co.uk
              </a>
            </p>
          </>
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th>Show</th>
                <th>Opens</th>
                <th>Closes</th>
                <th>Starts</th>
                <th>Ends</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{Rows}</tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default ShowManagers;
