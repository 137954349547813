import React from "react";
import { apiGet } from "../../../../shared/api";
import { Page, StyleSheet, Text, View, Document } from "@react-pdf/renderer";

const AddressLabels = async (Id) => {
  const req = await apiGet(`/admin/shows/processing/postage/${Id}`);
  let res = await req.json();

  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      flexWrap: "wrap",
      paddingLeft: "5mm",
      paddingRight: "5mm",
      paddingTop: "15mm",
      paddingBottom: "15mm",
    },
    label: {
      fontSize: 10,
      width: "99mm",
      height: "38mm",
      display: "flex",
      paddingLeft: "20mm",
      paddingRight: "10mm",
      paddingTop: "10mm",
    },
  });

  const Labels = res.map((itm) => {
    const key = `Member_${itm.Id}`;

    return (
      <View key={key} wrap={false} style={styles.label}>
        <Text>
          {itm.FirstName} {itm.LastName}
        </Text>
        <Text>{itm.StreetAddress}</Text>
        <Text>{itm.Town}</Text>
        <Text>{itm.Postcode}</Text>
      </View>
    );
  });

  return (
    <Document>
      <Page size="A4" orientation="portrait" style={styles.page}>
        {Labels}
      </Page>
    </Document>
  );
};

export default AddressLabels;
