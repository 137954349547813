import React,{useEffect,useState,useContext} from 'react';
import UserContext from '../../../context/user/UserContext';
import InlineLoading from '../../../shared/InlineLoading';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaw } from '@fortawesome/free-solid-svg-icons';
import {apiGet} from  '../../../shared/api';


function DogCard(props) {    

    const {User} = useContext(UserContext);

    const [Loading, setLoading] = useState(true);
    const [Error, setError] = useState(false);
    const [DogList, setDogList] = useState([]);
    const [ActiveCount, setActiveCount] = useState('');


    useEffect(() => {

        const loadDogCount = async () => {

            try {

                setError(false);

                const req = await apiGet(`/Dogs/List/${User.Id}`);
                if(req && req.ok && req.status === 200) {
                    const res = await req.json();
                    setDogList(res);
                    setActiveCount(res.filter(d => d.Status === 1).length);
                    setLoading(false);                        
                } else {
                    setError(true);
                    setLoading(false);
                }

            }
            catch (error) {
                setError(true);
                setLoading(false);
            }

        }

        loadDogCount();

    },[User.Id]);

    if(Loading)
        return <InlineLoading />

    return (
        <div className="card borderless">
            <div className="card-header alternate">
                <h5>My Dogs</h5>
            </div>
            <div className="card-body">
            {
                    Error ? <p>Error loading details</p> :
                    <>
                        {
                            DogList.length === 0 ?
                            <p>
                                You don't currently have any dogs
                            </p>
                            :
                            <p>
                                You currently have {DogList.length === 1 ? <><b>1</b> dog</> : <><b>{DogList.length}</b> dogs</>} in our database, of which <b>{ActiveCount}</b> {ActiveCount === 1 ? 'is' : 'are'} active.
                            </p>
                        }
                    </>
                }                
            </div>
            <div className="card-footer">
                {
                    !User.Expired ?
                    <Link className="button button-link" to="/dogs"><FontAwesomeIcon icon={faPaw} className="mr-1x" />Manage Dogs</Link>
                    :
                    <></>
                }
            </div>
        </div>
    )
}

export default DogCard;