import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Workbook } from "exceljs";
import { DownloadFile } from "../../../shared/clientsidefiledownload.js";
import {
  faMapMarkedAlt,
  faInfoCircle,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Modal from "../../../shared/ModalContent";
import { apiGet } from "../../../shared/api";
import { Link } from "react-router-dom";
import UserContext from "../../../context/user/UserContext";
import InfoSheet from "./Infosheet";
import ModalLoading from "../../../shared/ModalLoading";

const ShowLine = (props) => {
  const itm = props.Show;

  const [ShowClassesModal, setShowClassesModal] = useState(false);
  const [ShowDocsModal, setShowDocsModal] = useState(false);
  const [DocumentList, setDocumentList] = useState([]);
  const [BaseUrl, setBaseUrl] = useState("");
  const [UserPostcode, setUserPostcode] = useState(undefined);
  const [AllDaysCapped, setAllDaysCapped] = useState(false);
  const [SomeDaysCapped, setSomeDaysCapped] = useState(false);
  const [LateEntriesOpen, setLateEntriesOpen] = useState();
  const [LateEntriesDate, setLateEntriesDate] = useState();
  const [ShowLoading, setShowLoading] = useState(false);
  const [IsAdminLogin, setIsAdminLogin] = useState(false);
  const [IsOpen, setIsOpen] = useState(false);
  const [IsSC, setIsSC] = useState(false);

  const { User } = useContext(UserContext);

  useEffect(() => {
    //Check for Admin login
    let itm = sessionStorage.getItem("User");
    itm = JSON.parse(itm);
    if (itm.IsAdminLogin) setIsAdminLogin(true);
  }, []);

  useEffect(() => {
    setIsOpen(moment(itm.EntriesOpen).isBefore(moment()) || IsAdminLogin);
  }, [IsAdminLogin, itm]);

  const GetMeasures = async () => {
    try {
      setShowLoading(true);

      const req = await apiGet(`/shows/measuresneeded/${itm.ShowId}`);
      const res = await req.json();

      let wb = new Workbook();
      let ws = wb.addWorksheet("MeasuresNeeded");
      ws.addRow(["Member Id", "Member Name", "Dog Id", "Dog Name", "Measure Needed"]);
      for (const row of res[0]) {
        const foundHeight = res[1].find(
          (r) => r.DogId === row.Id && r.HeightId !== 4 && r.HeightId !== 8
        );
        const age = row.AgeInYears > 1;
        const hasPerm = parseInt(row.Measure) === 0;
        const hasFirst = parseInt(row.Measure) === 1;
        if (foundHeight && ((age && !hasPerm) || (!age && row.Measure === null))) {
          let measureNeeded = "Unknown";
          switch (row.Measure) {
            case null:
              if (foundHeight && !hasFirst) measureNeeded = "First";
              break;

            case false:
              measureNeeded = "";
              break;

            default:
              if (age && !hasPerm) measureNeeded = "Permanent";
              break;
          }
          if (measureNeeded !== "")
            ws.addRow([
              row.MemberId,
              `${row.FirstName} ${row.LastName}`,
              row.Id,
              row.PetName,
              measureNeeded,
            ]);
        }

        const buf = await wb.xlsx.writeBuffer();
        const blob = new Blob([buf], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        DownloadFile(blob, "MeasuresNeeded.xlsx");
        setShowLoading(false);
      }
    } catch (error) {
      setShowLoading(false);
      console.log(error.message);
      window.alert("Error downloading file");
    }
  };

  //Load data for info sheet
  useEffect(() => {
    const LoadData = async () => {
      try {
        const dt = moment();
        setIsSC(props.Show.SingleEntryShow);
        const latesOpen = moment(props.Show.LatesOpenDate);
        const startDate = moment(props.Show.StartDate);
        setLateEntriesDate(latesOpen);
        setLateEntriesOpen(
          latesOpen.isBefore(startDate) &&
            latesOpen.isBefore(dt) &&
            !props.Show.NoOnlineLates
        );
        let req, res;

        req = await apiGet(`/shows/infosheet/${props.Show.ShowId}`);
        res = await req.json();

        const data = res[0];
        //Get distinct dates
        const dtList = [...new Set(data.map((c) => c.ClassDate))];
        const output = [];
        for (const dt of dtList) {
          const firstClass = data.find((c) => moment(c.ClassDate).isSame(moment(dt)));
          output.push({
            ClassDate: dt,
            Capped: firstClass.Capped,
            Classes: data.filter((c) => c.ClassDate === dt),
          });
        }
        setAllDaysCapped(output.filter((d) => d.Capped === false).length === 0);
        setSomeDaysCapped(output.filter((d) => d.Capped === true).length > 0);
      } catch (error) {
        window.alert("Error loading information sheet for show");
      }

      try {
        const req = await apiGet(`/admin/shows/documents/list/${props.Show.ShowId}`);
        const res = await req.json();
        setDocumentList(res.FileList);
        setBaseUrl(res.BaseUrl);
      } catch (error) {
        console.log(error.message);
        //window.alert("Error loading list of documents");
      }
    };

    if (props.Show) LoadData();
  }, [props.Show]);

  useEffect(() => {
    const LoadData = async () => {
      try {
        const req = await apiGet(`/members/account/${User.Id}`);
        const res = await req.json();
        setUserPostcode(res.Postcode);
      } catch (error) {
        console.log(error.message);
        window.alert("Error loading user details");
      }
    };

    LoadData();
  }, [User]);

  const DocumentRows = DocumentList.map((itm, idx) => {
    return (
      <li key={idx}>
        <a href={`${BaseUrl}/${itm}`}>
          {itm.toString().replace(`${props.Show.ShowId}/`, "")}
        </a>
      </li>
    );
  });

  return (
    <tr>
      <td data-mobilelabel="Dates">
        {moment(itm.StartDate).isSame(moment(itm.EndDate)) ? (
          moment(itm.StartDate).format("DD/MM/YY")
        ) : (
          <>
            {moment(itm.StartDate).format("DD/MM/YY")} to{" "}
            {moment(itm.EndDate).format("DD/MM/YY")}
          </>
        )}
      </td>
      <td data-mobilelabel="Show">{itm.DisplayName}</td>
      <td data-mobilelabel="Venue">
        {itm.VenueName}, {itm.VenuePostalCode}
      </td>
      <td data-mobilelabel="Docs">
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {itm.HasDocuments ? (
            <span
              className="cursor-pointer text-link mr-1x"
              onClick={() => setShowDocsModal(true)}
            >
              <FontAwesomeIcon icon={faFileAlt} /> Docs
            </span>
          ) : (
            <></>
          )}

          {ShowLoading && <ModalLoading />}

          {ShowDocsModal ? (
            <Modal>
              <h3>Documents</h3>
              {DocumentList.length === 0 ? (
                <p>No documents available</p>
              ) : (
                <ul>
                  {DocumentRows}
                  {/*<li>
                    <span
                      className="text-link cursor-pointer"
                      onClick={GetMeasures}
                    >
                      Measure Needed
                    </span>
                  </li>*/}
                </ul>
              )}
              <br />
              <button className="button" onClick={() => setShowDocsModal(false)}>
                Close
              </button>
            </Modal>
          ) : (
            <></>
          )}
          {itm.VenuePostalCode ? (
            <span title="Directions" className="mr-1x">
              <a
                target="_new"
                href={`https://www.google.co.uk/maps/dir/${UserPostcode}/${escape(
                  itm.VenuePostalCode
                )}`}
              >
                <FontAwesomeIcon icon={faMapMarkedAlt} /> Map
              </a>
            </span>
          ) : (
            <></>
          )}
          <span
            className="cursor-pointer text-link"
            onClick={() => setShowClassesModal(true)}
          >
            <FontAwesomeIcon icon={faInfoCircle} /> Info
          </span>
          {ShowClassesModal ? (
            <InfoSheet Show={props.Show} onClose={() => setShowClassesModal(false)} />
          ) : (
            <></>
          )}
        </div>
      </td>
      <td data-mobilelabel="Opens">{moment(itm.EntriesOpen).format("DD/MM/YY HH:mm")}</td>
      <td data-mobilelabel="Closes">
        {moment(itm.EntriesClose).format("DD/MM/YY HH:mm")}
      </td>
      <td data-mobilelabel="Entered">
        {itm.EntryId === 0 ? (
          !itm.IsClosed && AllDaysCapped && itm.ClassCount > 0 ? (
            <span>Entries closed{AllDaysCapped ? " (All days capped)" : ""}</span>
          ) : (
            <>
              {(itm.IsClosed === 1 || LateEntriesOpen) && AllDaysCapped && (
                <span>Show closed - no late entries</span>
              )}{" "}
              {((itm.IsClosed === 0 && !LateEntriesOpen && !AllDaysCapped && IsOpen) ||
                IsAdminLogin) && (
                <Link to={`/shows/${IsSC ? "scmyentry" : "myentry"}/${itm.ShowId}`}>
                  Enter Show
                </Link>
              )}{" "}
              {/*SomeDaysCapped ? ' (Some days capped)' : ''*/}
              {itm.IsClosed === 1 &&
                !LateEntriesOpen &&
                !itm.SingleEntryShow &&
                !props.Show.NoOnlineLates && (
                  <>Late entries open {moment(LateEntriesDate).format("DD/MM/YY")}</>
                )}
              {itm.IsClosed === 1 &&
                !itm.SingleEntryShow &&
                LateEntriesOpen &&
                !AllDaysCapped && (
                  <Link to={`/shows/${IsSC ? "scmyentry" : "myentry"}/${itm.ShowId}`}>
                    Enter Show (Late Entry)
                  </Link>
                )}{" "}
              {itm.IsClosed === 1 && itm.SingleEntryShow && !IsAdminLogin && (
                <span className="text-red">Show closed</span>
              )}
              {/*SomeDaysCapped ? ' (Some days capped)' : ''*/}
            </>
          )
        ) : (
          <>
            {(IsOpen || IsAdminLogin) && (
              <Link to={`/shows/${IsSC ? "scentry" : "entry"}/${itm.ShowId}`}>
                My Entry
              </Link>
            )}
            {itm.IsClosed === 1 && !itm.SingleEntryShow && !LateEntriesOpen && (
              <p>Late entries open {moment(LateEntriesDate).format("DD/MM/YY HH:mm")}</p>
            )}
            {itm.IsClosed === 1 && !itm.SingleEntryShow && LateEntriesOpen && (
              <p>
                <Link
                  to={`/shows/${IsSC ? "scmyentry" : "myentry"}/${
                    itm.ShowId
                  }?View=AddRuns`}
                >
                  Add Late Entry
                </Link>
              </p>
            )}
          </>
        )}
      </td>
    </tr>
  );
};

export default ShowLine;
