import React from 'react';
import numeral from 'numeral';


function WarningBar (props) {
return (
    <div className="alert alert-error mb-2x">
        You currently owe £{numeral(Math.abs(props.Balance)).format('0.00')} and should make a payment immediately to bring your account up to date. You will not be able to enter shows or register dogs until you have completed this.<br />
        To save you making multiple transactions, we suggest that you add an additional £6.00 for each dog you wish to register at this time.
    </div>
)
}

export default WarningBar;