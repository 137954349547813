import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import UserContext from "../../../context/user/UserContext";
import { apiGet } from "../../../shared/api.js";
import numeral from "numeral";
import moment from "moment";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Entries = (props) => {
  const [Data, setData] = useState([]);
  const { User } = useContext(UserContext);

  useEffect(() => {
    const LoadData = async () => {
      try {
        const req = await apiGet(`/shows/entries/${User.Id}`);
        const res = await req.json();
        setData(res);
      } catch (error) {
        console.log(error.message);
        window.alert("Error loading list of entries");
      }
    };

    LoadData();
  }, [User.Id]);

  const Rows = Data.map((itm) => {
    const isClosed = itm.IsClosed;
    const isPaper = itm.IsPaper;
    const isSC = itm.SingleEntryShow;
    const hasPaid = itm.EntryCost > 0 && itm.Paid === "found";
    return (
      <tr key={itm.ShowId}>
        <td data-mobilelabel="Show Name">{itm.DisplayName}</td>
        <td data-mobilelabel="Pre-Entry Due Date">
          {isPaper || hasPaid ? (
            <span className="text-green">
              <FontAwesomeIcon icon={faCheckCircle} /> Paid
            </span>
          ) : (
            moment(itm.EntriesClose).format("DD/MM/YYYY")
          )}
        </td>
        <td data-mobilelabel="Pre-Entry Amount">
          {itm.EntryCost === 0 ? "N/A" : `£${numeral(itm.EntryCost).format("0.00")}`}
        </td>
        <td data-mobilelabel="Pre-Entry Status">
          {isClosed === 1 && !itm.EntryCost > 0 && "N/A"}
          {isClosed === 1 &&
            itm.EntryCost > 0 &&
            (itm.Paid === "found" || itm.IsPaper) &&
            "Pre-Entries Paid"}
          {isClosed === 1 &&
            itm.EntryCost > 0 &&
            itm.Paid !== "found" &&
            !itm.IsPaper &&
            "Entry not paid"}
          {isClosed === 0 && !isPaper && hasPaid && "Pre-Entries Paid"}
          {isClosed === 0 && !isPaper && !hasPaid && "Payment Pending"}
          {isClosed === 0 && isPaper && "Paper - Paid"}
        </td>
        <td data-mobilelabel="Late Entry Amount">
          {itm.LateEntryCost === 0
            ? "N/A"
            : `£${numeral(itm.LateEntryCost).format("0.00")}`}
        </td>
        <td data-mobilelabel="Actions">
          {isPaper || isClosed ? (
            <Link
              to={`/shows/${isSC ? "scentry" : "entry"}/${itm.ShowId}`}
              className="mr-1x"
            >
              View Entry
            </Link>
          ) : (
            <Link
              to={`/shows/${isSC ? "scentry" : "entry"}/${itm.ShowId}`}
              className="mr-1x"
            >
              View / Edit Entry
            </Link>
          )}
        </td>
        <td>
          {/*isClosed && <Link to={`/shows/documents/${itm.ShowId}`} className="mr-1x">Documents</Link>*/}
          <Link to={`/shows/documents/${itm.ShowId}`} className="mr-1x">
            Documents
          </Link>
        </td>
      </tr>
    );
  });

  return (
    <div className="card">
      <div className="card-header">
        <h4>Entries</h4>
      </div>
      <div className="card-body">
        {Data.length === 0 ? (
          <p>You aren't currently entered in any shows</p>
        ) : (
          <>
            <p className="alert alert-error mt-1x mb-3x">
              Please ensure you have enough funds in your account on the due date for each
              show for pre-entries, or your entry will be cancelled. If the show is a
              capped show and it caps, payment will be due immediately. We therefore
              advise that you make payment now for capped shows.
            </p>

            <table className="table">
              <thead>
                <tr>
                  <th></th>
                  <th>Pre-Entry</th>
                  <th>Pre-Entry</th>
                  <th>Pre-Entry</th>
                  <th>Late Entry</th>
                  <th colSpan="2"></th>
                </tr>
                <tr>
                  <th>Show</th>
                  <th>Date Due</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Amount</th>
                  <th colSpan="2">Actions</th>
                </tr>
              </thead>
              <tbody>{Rows}</tbody>
            </table>
          </>
        )}
      </div>
      <div className="card-footer"></div>
    </div>
  );
};

export default Entries;
