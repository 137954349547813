import React, { useState, useEffect, useCallback, useContext } from "react";
import SummaryDog from "./SummaryDog";
import SummaryCamping from "./SummaryCamping";
import SummaryHelping from "./SummaryHelping";
import SummaryPostage from "./SummaryPostage";
import { useParams } from "react-router-dom";
import { apiGet, apiPost } from "../../../../shared/api";
import UserContext from "../../../../context/user/UserContext";
import moment from "moment";
import numeral from "numeral";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../../shared/ModalContent";

const Entry = (props) => {
  const [EntryCost, setEntryCost] = useState("");
  const [LateEntryCost, setLateEntryCost] = useState("");
  const [EntriesClose, setEntriesClose] = useState("");
  const [DisplayName, setDisplayName] = useState("");
  const [IsClosed, setIsClosed] = useState(false);
  const [ShowPostage, setShowPostage] = useState(false);
  const [ShowCamping, setShowCamping] = useState(false);
  const [ShowHelping, setShowHelping] = useState(false);
  const [ShowSavedModal, setShowSavedModal] = useState(false);
  const [ShowAreYouSureModal, setShowAreYouSureModal] = useState(false);
  const [HasCamping, setHasCamping] = useState(false);
  const [IsPaper, setIsPaper] = useState(false);
  const [LatesOpen, setLatesOpen] = useState(false);
  const [HasLateEntries, setHasLateEntries] = useState(false);
  const [HasLateTeam, setHasLateTeam] = useState(false);

  const { ShowId } = useParams();
  const { User } = useContext(UserContext);

  const LoadData = useCallback(async () => {
    try {
      let req, res;

      //Does show have camping?
      let _hasCamping = false;
      req = await apiGet(`/shows/singlenight/list/${ShowId}`);
      res = await req.json();
      if (res.length > 0) _hasCamping = true;
      req = await apiGet(`/admin/shows/camping/packs/${ShowId}`);
      res = await req.json();
      if (res.length > 0) _hasCamping = true;
      setHasCamping(_hasCamping);

      req = await apiGet(`/shows/${ShowId}`);
      res = await req.json();
      const latesOpen = moment(res.EntriesClose).add(7202, "minutes");
      if (latesOpen.isSameOrBefore(moment()) && !res.NoOnlineLates)
        setLatesOpen(true);

      //Show and entry details stuff
      req = await apiGet(`/shows/entry/${ShowId}/${User.Id}`);
      res = await req.json();

      setEntryCost(res.EntryCost);
      setLateEntryCost(res.LateEntryCost);
      setEntriesClose(res.EntriesClose);
      setIsClosed(res.IsClosed);
      setDisplayName(res.DisplayName);
      setIsPaper(res.IsPaper);
      setHasLateEntries(res.HasLateEntries);
      setHasLateTeam(res.HasLateTeam);

      //User preferences
      req = await apiGet(`/members/account/${User.Id}`);
      res = await req.json();
      // switch (res.PostRunningOrders) {
      //   case 0:
      //     setShowPostage(true);
      //     break;
      //   case 1:
      //     setShowPostage(true);
      //     break;
      //   case 2:
      //     setShowPostage(true);
      //     break;
      //   default:
      //     break;
      // }
      setShowPostage(true);
      setShowCamping(true);
      setShowHelping(true);
      // setShowCamping(res.ShowCamping);
      // setShowHelping(res.ShowHelping);
    } catch (error) {
      console.log(error.message);
      props.history.push("/shows");
    }
  }, [User.Id, ShowId, props.history]);

  useEffect(() => {
    LoadData();
  }, [LoadData]);

  useEffect(() => {
    if (ShowSavedModal) {
      setTimeout(() => {
        setShowSavedModal(false);
      }, 2000);
    }
  }, [ShowSavedModal]);

  const CancelEntry = async () => {
    try {
      const obj = {
        ShowId,
        MemberId: User.Id,
        LatesOnly: LatesOpen,
      };

      const req = await apiPost("/shows/cancelentireentry", obj);
      if (req && req.ok) {
        props.history.push("/shows");
      } else {
        window.alert("Error deleting entry");
      }
    } catch (error) {
      console.log(error.message);
      window.alert("Error cancelling entry");
    }
  };

  const ShowSaved = () => {
    setShowSavedModal(true);
  };

  return (
    <div className="card">
      <div className="card-header">My Entry for {DisplayName}</div>
      <div className="card-body">
        {ShowSavedModal && (
          <Modal>
            <h2 className="text-green">
              <FontAwesomeIcon icon={faCheckCircle} className="mr-2x" />
              Choices saved
            </h2>
          </Modal>
        )}

        {ShowAreYouSureModal && (
          <Modal>
            <p>
              <b>Are you sure?</b>
            </p>
            {LatesOpen && (
              <p>
                This will remove all of your late-entry runs for the show. You
                may not be able to re-enter if the show has since capped
              </p>
            )}
            {!LatesOpen && (
              <p>
                This will remove your entire entry for the show. You may not be
                able to re-enter if the show has since capped
              </p>
            )}
            <p>
              <button className="button button-red mr-1x" onClick={CancelEntry}>
                Yes
              </button>
              <button
                className="button"
                onClick={() => setShowAreYouSureModal(false)}
              >
                No
              </button>
            </p>
          </Modal>
        )}
        {EntryCost > 0 && !IsPaper && !LatesOpen && !IsClosed && (
          <p className="alert alert-error mb-3x">
            Payment for this show will be due on{" "}
            <b>{moment(EntriesClose).format("MMMM Do")}</b> and will show in
            your wallet as a pending transaction until then. Your total cost is{" "}
            <b>£{numeral(EntryCost).format("0.00")}</b>. If the show is a capped
            show and it caps, payment will be due immediately. We therefore
            advise that you make payment now for capped shows.
          </p>
        )}
        {EntryCost > 0 && (
          <p>
            This page shows your confirmed entries for this show, subject to
            payment being received. If the show has not yet capped or closed you
            can scroll down to add runs or edit your entry.
          </p>
        )}
        {LateEntryCost > 0 && !IsPaper && LatesOpen && (
          <>
            <p className="alert alert-error mb-1x">
              Payment for late runs is due at the show. Your total late runs
              cost is <b>£{numeral(LateEntryCost).format("0.00")}</b>. You
              cannot pay for this entry online prior to attending the show.
            </p>
            <p className="text-red mb-2x">
              Any payments to your A4A account or wallet balances{" "}
              <span className="text-red">
                <b>
                  <u>cannot</u>
                </b>
              </span>{" "}
              be used for late entries at a show. These payments must only be
              made to the show manager at the show. Late entries do not appear
              in your wallet at any time but you can view them by going to My
              Entries
            </p>
          </>
        )}

        <SummaryDog
          LatesOpen={LatesOpen}
          IsClosed={IsClosed}
          ShowId={ShowId}
          CanEdit={!IsPaper}
          IsPaper={IsPaper}
          RefreshCost={LoadData}
          onSave={ShowSaved}
        />
        <div className="grid">
          <div className="col-6">
            {HasCamping && ShowCamping && (
              <SummaryCamping
                IsClosed={IsClosed}
                ShowId={ShowId}
                CanEdit={!IsPaper}
                RefreshCost={LoadData}
                onSave={ShowSaved}
              />
            )}
            {ShowPostage && (
              <SummaryPostage
                IsClosed={IsClosed}
                ShowId={ShowId}
                CanEdit={!IsPaper}
                RefreshCost={LoadData}
                onSave={ShowSaved}
              />
            )}
          </div>
          <div className="col-6">
            {ShowHelping && (
              <SummaryHelping
                IsClosed={IsClosed}
                ShowId={ShowId}
                CanEdit={!IsPaper}
                onSave={ShowSaved}
              />
            )}
          </div>
        </div>
      </div>
      <div className="card-footer">
        {IsClosed === 0 && !IsPaper && (
          <button
            className="button button-red mr-2x"
            onClick={() => setShowAreYouSureModal(true)}
          >
            Cancel my entire entry for this show
          </button>
        )}
        {IsClosed === 1 &&
          LatesOpen &&
          !IsPaper &&
          (HasLateEntries > 0 || HasLateTeam > 0) && (
            <button
              className="button button-red mr-2x"
              onClick={() => setShowAreYouSureModal(true)}
            >
              Delete all my late entry runs
            </button>
          )}
        <button
          className="button button-blue"
          onClick={() => props.history.push("/shows")}
        >
          Exit
        </button>
      </div>
    </div>
  );
};

export default withRouter(Entry);
