import React, { useState, useEffect, useCallback } from 'react';
import ClassLine from './classline';
import {apiGet} from '../../../shared/api.js';


const Classes = props => {

    const [ClassList, setClassList] = useState([]);

    const LoadData = useCallback(async() => {
        try {

            const req = await apiGet('/admin/classes/list');
            const res = await req.json();
            setClassList(res);

        }
        catch (error) {
            console.log(error.message);
            window.alert('Error loading class list');
        }
    },[]);


    useEffect(() => {
        LoadData();
    },[LoadData]);


    return ( 
        <div className="card">
            <div className="card-header">Edit Classes</div>
            <div className="card-body">
                {ClassList.map(itm => <ClassLine Class={itm} key={itm.ClassId} />)}
            </div>
            <div className="card-footer"></div>
        </div>
     );
}
 
export default Classes;