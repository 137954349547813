import React from "react";
import { Link } from "react-router-dom";

function Menu(props) {
  return (
    <div className="col-3 pr-2x">
      <div className="card mb-5x">
        <div className="card-body">
          <h4>Members &amp; Dogs</h4>
          <div className="sub-menu mb-2x">
            <Link to="/admin/">Search</Link>
            <br />
            <Link to="/admin/membercreate">Create Member</Link>
            <br />
            <Link to="/admin/members/dogmeasuresreport">Dog Measures Report</Link>
          </div>

          <h4>Financials</h4>
          <div className="sub-menu mb-2x">
            <Link to="/admin/wallet/reports">Wallet Reports</Link>
            <br />
            <Link to="/admin/shows/moneyreport">Show Money Report</Link>
          </div>

          <h4>Shows</h4>
          <div className="sub-menu mb-2x">
            <Link to="/admin/classes">Manage Classes</Link>
            <br />
            <Link to="/admin/specialclasses">Special Classes</Link>
            <br />
            <Link to="/admin/shows/list">Manage Shows </Link>
            <br />
            <Link to="/admin/shows/uploadresults">Upload Results</Link>
            <br />
            <Link to="/admin/shows/classdefaults">Class Defaults</Link>
            <br />
            <Link to="/admin/shows/entryduplicates">Entry Duplicates</Link>
            <br />
          </div>

          <h4>System</h4>
          <div className="sub-menu mb-2x">
            <Link to="/admin/systemparameters">System Parameters</Link>
          </div>

          <h4>Emails</h4>
          <div className="sub-menu mb-2x">
            <Link to="/admin/emails">Edit Emails</Link>
          </div>

          <h4>News</h4>
          <div className="sub-menu mb-2x">
            <Link to="/admin/news">News articles</Link>
          </div>
        </div>
        <div className="card-footer"></div>
      </div>
    </div>
  );
}

export default Menu;
