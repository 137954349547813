import React,{useState,useEffect,useCallback} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faTrash} from '@fortawesome/free-solid-svg-icons';
import { apiPost, apiGet, apiDelete } from '../../../shared/api';
import numeral from 'numeral';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";


const Camping = props => {

    const [ShowAdd, setShowAdd] = useState(false);
    const [Description, setDescription] = useState('');
    const [Price, setPrice] = useState('');
    const [PackList, setPacklist] = useState([]);
    const [ArrivalDate, setArrivalDate] = useState();
    const [DepartureDate, setDepartureDate] = useState();

    //List of packs
    const LoadData = useCallback(async() => {

        try {

            const req = await apiGet(`/admin/shows/camping/packs/${props.ShowId}`);
            const res = await req.json();
            setPacklist(res);

        }
        catch (error) {
            console.log(error.message);
            window.alert('Error loading list of camping packs');
        }

    },[props.ShowId]);


    //Save pack
    const SaveNewPack = async() => {

        try {

            const obj = {
                PackId: 0, ShowId: props.ShowId, Description,Price,ArrivalDate: moment(ArrivalDate).format('YYYYMMDD'), DepartureDate: moment(DepartureDate).format('YYYYMMDD')
            }

            const req = await apiPost('/admin/shows/camping/pack/update', obj);
            if(req && req.ok)  {
                LoadData();
                setDescription('');
                setPrice('');
                setShowAdd(false);
            } else {
                window.alert('Error saving pack');
            }

        }
        catch (error) {
            console.log(error.message);
            window.alert('Error saving pack');
        }

    }


    //Refresh all packs on first load
    useEffect(() => {
        LoadData();
    },[LoadData]);


    //Delete a pack
    const DeletePack = async(PackId) => {
        try {
            if(window.confirm('Are you sure?')) {
                const req = await apiDelete(`/admin/shows/camping/pack/${PackId}`);
                if(req && req.ok) {
                    LoadData();
                } else {
                    window.alert('Error deleting Pack');
                }
            }
        }
        catch (error) {
            console.log(error.message);
            window.alert('Error deleting pack');
        }
    }



    //List packs
    const Rows = PackList.map(itm => {
        return (
            <tr key={itm.PackId}>
                <td>{itm.Description}</td>
                <td>{numeral(itm.Price).format('0.00')}</td>
                <td><span className="text-red cursor-pointer" onClick={() => DeletePack(itm.PackId)}><FontAwesomeIcon icon={faTrash} className="mr-1x" />Delete</span></td>
                <td>{moment(itm.ArrivalDate).format('ddd Do MMM')}</td>
                <td>{moment(itm.DepartureDate).format('ddd Do MMM')}</td>
            </tr>
        )
    });


    return (
        <div className="card">
            <div className="card-header">Camping Packs</div>
            <div className="card-body">
                
                <p className="mb-1x"><button className="button" onClick={() => setShowAdd(true)}><FontAwesomeIcon className="mr-1x" icon={faPlus} /> Add pack</button></p>

                {
                    ShowAdd ?
                    <div className="mb-3x">
                        <p><b>New Camping Pack</b></p>
                        <div className="form-group">
                            <label>Description:</label>
                            <input type="text" value={Description} onChange={e => setDescription(e.target.value)} className="form-control width-50"></input>
                        </div>                
                        <div className="form-group">
                            <label>Price:</label>
                            <input type="number" value={Price} onChange={e => setPrice(e.target.value)} className="form-control width-10"></input>
                        </div>
                        <div className="form-group">
                            <label>Arrival Date</label>
                            <DatePicker showMonthDropdown showYearDropdown className="form-control width-50" dateFormat="dd/MM/yyyy" selected={ArrivalDate} value={DepartureDate} onChange={val => setArrivalDate(val)}></DatePicker>
                        </div>
                        <div className="form-group">
                            <label>Departure Date</label>
                            <DatePicker showMonthDropdown showYearDropdown className="form-control width-50" dateFormat="dd/MM/yyyy" selected={DepartureDate} value={ArrivalDate} onChange={val => setDepartureDate(val)}></DatePicker>
                        </div>
                        <div className="form-group">
                            <button className="button button-green mr-1x" onClick={SaveNewPack}>Save</button> <button className="button" onClick={() => setShowAdd(false)}>Cancel</button>
                        </div>                        
                    </div>
                    :
                    <></>
                }
                    

                <table className="table">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Price</th>
                            <th>Actions</th>
                            <th>Arrival</th>
                            <th>Departure</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            PackList.length === 0 ?
                            <tr><td colSpan="3">No camping packs created</td></tr>
                            :
                            Rows
                        }
                    </tbody>
                </table>

            </div>
        </div>
    )
}

export default Camping;