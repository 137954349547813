import React, {useState} from 'react';
import RegistrationContext from './RegistrationContext.js';

function RegistrationContextProvider(props) {

    const UpdateValue = val => {
        setState(prevState => {
            return ({...prevState, ...val});
        });
    }


    const defaultState = {
        Email: '',
        FirstName: '',
        LastName: '',
        Junior: 0,
        DateOfBirth: '',
        MobilePhone: '',
        OtherPhone: '',
        StreetAddress: '',
        Town: '',
        Postcode: '',
        Password: '',
        CurrentStep: 1,
        UpdateValue
    }


    const [State, setState] = useState(defaultState);

    
    return (
        <RegistrationContext.Provider value={State}>
            {props.children}
        </RegistrationContext.Provider>
    )
}

export default RegistrationContextProvider;