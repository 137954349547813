import React, { useState, useEffect, useContext, useCallback } from "react";
import moment from "moment";
import { apiGet } from "../../../shared/api";
import UserContext from "../../../context/user/UserContext";
import ShowLine from "./showline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function Shows(props) {
  const [SelectedMonth, setSelectedMonth] = useState("0");
  const [SelectedYear, setSelectedYear] = useState("0");
  const [Shows, setShows] = useState([]);
  const [FilteredShows, setFilteredShows] = useState([]);

  const { User } = useContext(UserContext);

  const MatchShows = useCallback(() => {
    if (Shows.length > 0) {
      const Matches = [];
      for (const show of Shows) {
        const dt = moment(show.StartDate);
        if (
          (SelectedYear === "0" || dt.year() === parseInt(SelectedYear)) &&
          (SelectedMonth === "0" || dt.month() === parseInt(SelectedMonth) - 1)
        )
          Matches.push(show);
      }

      setFilteredShows(Matches);
    }
  }, [SelectedYear, SelectedMonth, Shows]);

  //Update list when month or year changes
  useEffect(() => {
    MatchShows();
  }, [SelectedMonth, SelectedYear, MatchShows, Shows]);

  //Initial list of shows
  useEffect(() => {
    (async () => {
      try {
        const req = await apiGet(
          `/shows/list?MemberId=${User.Id}&FutureOnly=1&PastOnly=0`
        );
        let res = await req.json();
        res = res
          .filter((s) => s.IsLive)
          .sort((a, b) => {
            return moment(a.StartDate).isBefore(moment(b.StartDate)) ? -1 : 1;
          });
        setShows(res);
      } catch (error) {
        console.log(error.message);
        //window.alert("Error loading list of shows");
      }
    })();
  }, [User.Id]);

  const Rows = FilteredShows.map((itm) => {
    return <ShowLine key={itm.ShowId} Show={itm} />;
  });

  return (
    <div className="card">
      <div className="card-header">
        <h4>Shows</h4>
      </div>
      <div className="card-body">
        <div className="alert alert-tint text-center">
          <h3 className="mt-1x text-red">Show Entry Preferences</h3>
          <p className="mb-1x text-red">
            To make entering a show quicker, you can pre-select many of your choices to be
            automatically set each time you enter a show.
          </p>
          <p className="mt-1x mb-2x">
            <Link
              to="/shows/entrypreferences"
              className="button button-small button-blue"
            >
              <FontAwesomeIcon icon={faCog} className="mr-1x" /> View / Manage my show
              entry preferences
            </Link>
          </p>
        </div>
        <br />
        <br />

        <div className="grid mb-4x">
          <div className="col-2">
            <p>
              <b>Year</b>
            </p>
            <p>
              <select
                className="form-control"
                value={SelectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
              >
                <option value="0">All Years</option>
                <option value="2022">2022</option>
              </select>
            </p>
          </div>
          <div className="col-3">
            <p>
              <b>Month</b>
            </p>
            <p>
              <select
                className="form-control"
                value={SelectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
              >
                <option value="0">All months</option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
            </p>
          </div>
        </div>

        <table className="table table-grid table-grid-lines">
          <thead>
            <tr>
              <th className="spaced">Dates</th>
              <th className="spaced">Show</th>
              <th className="spaced">Venue</th>
              <th className="spaced">Info</th>
              <th className="spaced">Opens</th>
              <th className="spaced">Closes</th>
              <th className="spaced">Entered</th>
            </tr>
          </thead>
          <tbody>{Rows}</tbody>
        </table>
      </div>
      <div className="card-footer"></div>
    </div>
  );
}

export default Shows;
