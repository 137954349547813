import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { apiPost } from "../../../shared/api";
import ModalLoading from "../../../shared/ModalLoading";
import { Workbook } from "exceljs";
import { DownloadFile } from "../../../shared/clientsidefiledownload.js";

const DogMeasuresReport = (props) => {
  const [StartDate, setStartDate] = useState();
  const [EndDate, setEndDate] = useState();
  const [DogId, setDogId] = useState("");
  const [Data, setData] = useState([]);
  const [Loading, setLoading] = useState(false);

  const doSearch = async () => {
    try {
      setLoading(true);
      let obj = {
        StartDate:
          StartDate && StartDate !== ""
            ? moment(StartDate).format("YYYYMMDD")
            : null,
        EndDate:
          EndDate && EndDate !== "" ? moment(EndDate).format("YYYYMMDD") : null,
        DogId,
      };
      const req = await apiPost("/admin/dogs/heightsreport", obj);
      if (req && req.ok) {
        const res = await req.json();
        setData(res);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      window.alert("Error loading data");
    }
  };

  const Rows = Data.map((itm, idx) => {
    return (
      <tr key={`${itm.Id}_${idx}`}>
        <td>{moment(itm.DateStamp).format("DD/MM/YY")}</td>
        <td>{itm.ShowName}</td>
        <td>{itm.Id}</td>
        <td>{itm.PetName}</td>
        <td>{itm.Height}</td>
        <td>{itm.MemberId}</td>
        <td>
          {itm.FirstName} {itm.LastName}
        </td>
        <td>
          {!itm.IsProvisional && !itm.IsDisputed && !itm.IsChallenge && "P"}
          {itm.IsProvisional && "F"}
          {itm.IsChallenge && "C"}
          {itm.IsDisputed && "D"}
        </td>
        <td>
          {itm.MeasurerFirstName} {itm.MeasurerLastName}
        </td>
      </tr>
    );
  });

  const doExcel = async () => {
    try {
      let wb = new Workbook();
      let ws = wb.addWorksheet("Dog Measures");

      ws.addRow([
        "Date",
        "Show",
        "Dog Id",
        "Pet Name",
        "Height",
        "Member Id",
        "Member",
        "Type",
        "Measured By",
      ]);

      for (const itm of Data) {
        let type = "";

        if (!itm.IsProvisional && !itm.IsDisputed && !itm.IsChallenge)
          type = "P";
        if (itm.IsProvisional) type = "F";
        if (itm.IsChallenge) type = "C";
        if (itm.IsDisputed) type = "D";

        ws.addRow([
          moment(itm.DateStamp).format("DD/MM/YY"),
          itm.ShowName,
          itm.Id,
          itm.PetName,
          itm.Height,
          itm.MemberId,
          `${itm.FirstName} ${itm.LastName}`,
          type,
          `${itm.MeasurerFirstName} ${itm.MeasurerLastName}`,
        ]);
      }

      const buf = await wb.xlsx.writeBuffer();
      const blob = new Blob([buf], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      DownloadFile(blob, "Dog Measures.xlsx");
    } catch (error) {
      console.log(error.message);
      window.alert("Error producting Excel");
    }
  };

  return (
    <div className="card">
      {Loading && <ModalLoading />}
      <div className="card-header">Dog Measures Report</div>
      <div className="card-body">
        <div className="grid">
          <div className="col-3">
            <p>Start Date</p>
            <DatePicker
              maxDate={new Date()}
              showMonthDropdown
              showYearDropdown
              className="form-control width-50 mr-2x"
              dateFormat="dd/MM/yyyy"
              value={StartDate}
              selected={StartDate}
              onChange={(val) => setStartDate(val)}
            ></DatePicker>
          </div>
          <div className="col-3">
            <p>End Date</p>
            <DatePicker
              maxDate={new Date()}
              showMonthDropdown
              showYearDropdown
              className="form-control width-50 mr-2x"
              dateFormat="dd/MM/yyyy"
              value={EndDate}
              selected={EndDate}
              onChange={(val) => setEndDate(val)}
            ></DatePicker>
          </div>
        </div>
        <div className="grid">
          <div className="col-3">
            <p>Dog Id</p>
            <input
              type="number"
              className="form-control"
              vlaue={DogId}
              onChange={(e) => setDogId(e.target.value)}
            />
          </div>
        </div>
        <p>
          <br />
          <button className="button mr-1x" onClick={doSearch}>
            Search
          </button>
          {Data && Data.length > 0 && (
            <button className="button" onClick={doExcel}>
              <FontAwesomeIcon icon={faFileExcel} className="mr-1x" /> Get Excel
            </button>
          )}
        </p>
        <br />
        <br />

        {Data && Data.length > 0 && (
          <table className="table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Show</th>
                <th>Dog Id</th>
                <th>Pet Name</th>
                <th>Height</th>
                <th>Member Id</th>
                <th>Member</th>
                <th>Type</th>
                <th>Measured By</th>
              </tr>
            </thead>
            <tbody>{Rows}</tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default DogMeasuresReport;
