import React, { useState, useEffect } from "react";
import { apiGet } from "../../../shared/api";
import moment from "moment";

const EntryDuplicates = () => {
  const [List, setList] = useState([]);
  const [Loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const req = await apiGet("/admin/shows/entryduplicates");
        const res = await req.json();
        setList(res);
      } catch (error) {
        console.log(error.message);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    })();
  }, []);

  return (
    <div className="card">
      <div className="card-header">Entry Duplicates</div>
      <div className="card-body">
        {Loading && <p>Loading data...</p>}
        {!Loading && List.length === 0 && <p>No data</p>}
        {!Loading && List.length > 0 && (
          <table class="table">
            <thead>
              <tr>
                <th>Show</th>
                <th>Date</th>
                <th>Member</th>
                <th>Dog</th>
                <th>Class</th>
                <th>Level</th>
              </tr>
            </thead>
            <tbody>
              {List.map((itm, idx) => (
                <tr key={idx}>
                  <td>{itm.DisplayName}</td>
                  <td>{moment(itm.ClassDate).format("DD/MM/YYYY")}</td>
                  <td>
                    {itm.FirstName} {itm.LastName}
                  </td>
                  <td>{itm.PetName}</td>
                  <td>{itm.ClassLabel}</td>
                  <td>{itm.LevelLabel}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default EntryDuplicates;
