import React,{useState,useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import {apiGet} from '../../../shared/api.js';
import InlineLoading from '../../../shared/InlineLoading';
import numeral from 'numeral';
import {Workbook} from 'exceljs';
import {DownloadFile} from '../../../shared/clientsidefiledownload.js';


function Balances (props) {

    const [Data, setData] = useState([]);
    const [Loading, setLoading] = useState(true);
    const [TotalBalance, setTotalBalance] = useState(0);


    //Create an Excel sheet and download
    const DownloadExcel = async() => {

        try {

            let wb = new Workbook();
            let ws = wb.addWorksheet('Balances');
            
            ws.addRow(['Id','Member Name', 'Balance']);

            for (const itm of Data)
                ws.addRow([itm.Id,`${itm.FirstName} ${itm.LastName}`, itm.Balance]);

            //Total row
            ws.addRow(['','',TotalBalance]);

            const buf = await wb.xlsx.writeBuffer();
            const blob =  new Blob([buf], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            DownloadFile(blob,'Balances.xlsx');

        }
        catch (error) {
            console.log(error.message);
        }

    }



    //Get data on first load
    useEffect(() => {

        const LoadData = async() => {

            try {

                const req = await apiGet('/admin/wallet/reports/balances');
                if(req && req.ok && req.status === 200) {

                    const res = await req.json();
                    setData(res);

                    //Set total balance
                    let output = res.reduce((total,itm) => total + parseFloat(itm.Balance),0);
                    setTotalBalance(output);

                    setLoading(false);

                } else {
                    window.alert('Error loading data');
                }

            }
            catch(error) {
                console.log(error);
            }
            finally {
                setLoading(false);
            }

        }


        LoadData();

    },[]);


    const TableRows = Data.map((itm,idx) => {
        return (
            <tr key={idx}>
                <td>{itm.Id}</td>
                <td>{itm.FirstName} {itm.LastName}</td>
                <td>£{numeral(itm.Balance).format('0.00')}</td>
            </tr>
        )
    });


    return (
        <div className="card">
            <div className="card-header">
                <h4>Wallet Balances</h4>
            </div>
            <div className="card-body">

                {
                    Loading ? <InlineLoading /> :
                    <>
                        <p className="mb-2x">
                            <button className="button button-outline" onClick={DownloadExcel}>
                                <FontAwesomeIcon icon={faFileExcel} className="mr-1x" />Download as Excel
                            </button>
                        </p>

                        <table className="table width-50">
                            <thead>
                                <tr>
                                    <td colSpan="2" align="right">Total:</td>
                                    <td>£{numeral(TotalBalance).format('0,0.00')}</td>                  
                                </tr>
                                <tr>
                                    <th>Id</th>
                                    <th>Member</th>
                                    <th>Balance</th>
                                </tr>
                            </thead>
                            <tbody>{TableRows}</tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="2" align="right">Total:</td>
                                    <td>£{numeral(TotalBalance).format('0,0.00')}</td>
                                </tr>
                            </tfoot>
                        </table>

                    </>
                }


            </div>
            <div className="card-footer"></div>
        </div>
    );

}


export default Balances;