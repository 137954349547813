import React, {useState} from 'react';
import {apiPost} from '../../../shared/api.js';
import {GetPaymentMethod} from '../../../shared/wallet.js';
import numeral from 'numeral';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import {Workbook} from 'exceljs';
import {DownloadFile} from '../../../shared/clientsidefiledownload.js';


function Credits(props) {

    const [Data, setData] = useState([]);
    const [Transactions, setTransactions] = useState([]);
    const [StartDate, setStartDate] = useState(new Date());
    const [Total, setTotal] = useState(0);


    const LoadData = async() => {

        const ERROR_MSG = 'Error loading data from server. Micky support time';

        try {

            const data = {
                StartDate: moment(StartDate).format('DD/MM/YYYY')
            }

            const req = await apiPost('/admin/wallet/reports/credits', data);
            if(req && req.ok && req.status === 200) {
                const res = await req.json();
                setData(res[0]);
                setTransactions(res[1]);

                //Total up
                let total = 0;
                if(res[0].length > 0) {
                    total = res[0].reduce(
                        (acc,curr) => {
                            return acc + curr.Amount;
                        },0
                    );
                    setTotal(total);
                }

                return {res,total};

            } else {

            }
        }
        catch (error) {
            console.log(error.message);
            window.alert(ERROR_MSG);
        }
    }



    //Excel output
    const GetExcel = async() => {

        try {

            const data = await LoadData();

            let wb = new Workbook();
            let ws = wb.addWorksheet('Credits');

            ws.addRow(['Method', 'Amount']);
            for (const itm of data.res[0])
                ws.addRow([`${GetPaymentMethod(itm)}`, itm.Amount]);
            //Total row
            ws.addRow(['Total', data.total]);

            ws = wb.addWorksheet('Transactions');
            ws.addRow(['Date','Amount','Method','Admin Notes']);
            for (const itm of data.res[1])
                ws.addRow([`${moment(itm.DateTimeStamp).format('DD/MM/YYYY')}`,itm.Amount,`${GetPaymentMethod(itm)}`, itm.Notes]);


            const buf = await wb.xlsx.writeBuffer();
            const blob =  new Blob([buf], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            DownloadFile(blob,'Credits.xlsx');

        }
        catch (error) {
            console.log(error.message);
            window.alert('Error producing Excel sheet');
        }

    }





    //Summary
    const LineItems = Data.map((itm,idx) => {

        return (
            <tr>
                <td>{GetPaymentMethod(itm)}</td>
                <td>£{numeral(itm.Amount).format('0.00')}</td>
            </tr>
        );
    });


    //Transaction lines
    const TransactionLines = Transactions.map(itm => {
        return (
            <tr>
                <td>{moment(itm.DateTimeStamp).format('DD/MM/YYYY')}</td>
                <td>£{numeral(itm.Amount).format('0,0.00')}</td>
                <td>{GetPaymentMethod(itm)}</td>
            </tr>
        )
    });



    return (
        <div className="card">
            <div className="card-header">Wallet Credits</div>
            <div className="card-body">

                <div className="mb-1x"><span className="mr-2x">Start Date: </span><DatePicker maxDate={new Date()} showMonthDropdown showYearDropdown className="form-control width-50 mr-2x" dateFormat="dd/MM/yyyy" value={StartDate} selected={StartDate} onChange={val => setStartDate(val)}></DatePicker></div>
                <div className="mb-2x"><button className="button mr-2x" onClick={LoadData}>View Data</button><button className="button mr-2x" onClick={GetExcel}><FontAwesomeIcon icon={faFileExcel} /> Excel</button></div>

                {
                    Data.length > 0 ?
                    <>
                        <table className="table mb-2x">
                            <thead>
                                <tr>
                                    <th>Method</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {LineItems}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Total</td>
                                    <td>£{numeral(Total).format('0.00')}</td>
                                </tr>
                            </tfoot>
                        </table>

                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Amount</th>
                                    <th>Method</th>
                                </tr>
                            </thead>
                            <tbody>
                                {TransactionLines}
                            </tbody>
                        </table>
                    </>
                    :
                    <></>
                }                        

            </div>
            <div className="card-footer"></div>
        </div>
    )
}

export default Credits;