import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { apiGet, apiPostFile, apiDelete } from "../../../shared/api";
import moment from "moment";

const SponsorImages = (props) => {
  const [Days, setDays] = useState([]);
  const [ImageList, setImageList] = useState([]);
  const [File, setFile] = useState();
  const [SelectedDate, setSelectedDate] = useState("");
  const [AllClasses, setAllClasses] = useState([]);
  const [Classes, setClasses] = useState([]);
  const [ClassId, setClassId] = useState("");

  const LoadData = useCallback(async () => {
    try {
      let req, res;

      //Images
      req = await apiGet(
        `/admin/shows/sponsorimages/list/${props.ShowId}/${SelectedDate}`
      );
      res = await req.json();
      setImageList(res);

      //Classes
      req = await apiGet(`/admin/shows/classes/list/${props.ShowId}`);
      res = await req.json();
      setAllClasses(res);
    } catch (error) {
      console.log(error.message);
      window.alert("Error loading image list");
    }
  }, [props.ShowId, SelectedDate]);

  useEffect(() => {
    const LoadShowDates = async () => {
      //List of dates for show
      let req = await apiGet(`/shows/${props.ShowId}`);
      let res = await req.json();
      const DateList = [];
      let CurrentDate = moment(res.StartDate).hours(12);
      const LastDate = moment(res.EndDate).hours(12);
      while (CurrentDate.isSameOrBefore(LastDate)) {
        const CurrVal = moment(CurrentDate);
        DateList.push(CurrVal);
        CurrentDate = CurrentDate.add(1, "days");
      }
      setDays(DateList);
      setClasses(
        AllClasses.filter((c) =>
          moment(c.ClassDate).isSame(moment.utc(SelectedDate, "YYYYMMDD"))
        )
      );
      setClassId("");
    };

    if (props.ShowId) LoadShowDates();
  }, [props.ShowId, AllClasses, SelectedDate]);

  useEffect(() => {
    if (SelectedDate) {
      LoadData();
    }
  }, [SelectedDate, LoadData]);

  const UploadFile = async () => {
    try {
      const data = new FormData();
      data.append("file", File);
      data.append("ShowId", props.ShowId);
      data.append("ShowDate", SelectedDate);
      data.append("ClassId", ClassId);

      const req = await apiPostFile("/admin/shows/sponsorimages/", data);
      if (req && req.ok) {
        LoadData();
      } else {
        window.alert("Error uploading file");
      }
    } catch (error) {
      console.log(error.message);
      window.alert("Error uploading file");
    }
  };

  const doDelete = async (Id) => {
    try {
      if (window.confirm("Are you sure?")) {
        const req = await apiDelete(`/admin/shows/sponsorimages/${Id}`);
        if (req && req.ok) {
          LoadData();
        } else {
          window.alert("Error deleting item");
        }
      }

      LoadData();
    } catch (error) {
      console.log(error.message);
      window.alert("Error deleting item");
    }
  };

  return (
    <div className="card">
      <div className="card-header">Sponsor Images</div>
      <div className="card-body">
        <div className="form-group mb-3x">
          <label>Day:</label>
          <select
            className="form-control width-25"
            value={SelectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
          >
            <option value="">Select...</option>
            {Days.map((itm, idx) => {
              return (
                <option key={idx} value={moment(itm).format("YYYYMMDD")}>
                  {moment(itm).format("dddd Do MMM")}
                </option>
              );
            })}
          </select>
        </div>

        {SelectedDate && (
          <>
            <p>Upload a file</p>
            <div className="grid mb-3x">
              <div className="col-3">
                <p>
                  <input
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                  />{" "}
                  <button className="button button-green" onClick={UploadFile}>
                    <FontAwesomeIcon icon={faFileUpload} className="mr-1x" />
                    Upload file
                  </button>
                </p>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Class</label>
                  <select
                    value={ClassId}
                    onChange={(e) => setClassId(e.target.value)}
                    className="form-control"
                  >
                    <option value="">Please select...</option>
                    {Classes.map((itm) => (
                      <option key={itm.ClassId} value={itm.ClassId}>
                        {itm.ClassLabel}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="sponsor-image-row">
              {ImageList.map((itm) => {
                return (
                  <div key={itm.Id} className="sponsor-image-container">
                    <img
                      alt="Sponsor Logo"
                      className="sponsor-image"
                      src={`data:image;base64, ${itm.SponsorImage}`}
                    />
                    <div
                      className="sponsor-image-delete-link"
                      onClick={() => doDelete(itm.Id)}
                    >
                      <FontAwesomeIcon icon={faTrash} className="mr-1x" />
                      Delete
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SponsorImages;
