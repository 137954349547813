import React, { useState, useEffect, useContext, useCallback } from "react";
import moment from "moment";
import { apiGet, apiPost } from "../../../../shared/api.js";
import numeral from "numeral";
import UserContext from "../../../../context/user/UserContext";
import InlineLoading from "../../../../shared/InlineLoading";

const SummaryCamping = (props) => {
  const [ShowAlter, setShowAlter] = useState(false);
  const [PackList, setPackList] = useState([]);
  const [Days, setDays] = useState([]);
  const [SingleNightRate, setSingleNightRate] = useState();
  const [CampingPackId, setCampingPackId] = useState("");
  const [SingleNightChoices, setSingleNightChoices] = useState([]);
  const [CampingString, setCampingString] = useState("");
  const [Loading, setLoading] = useState(false);
  const [CampingClosed, setCampingClosed] = useState(false);
  const [CampingCapped, setCampingCapped] = useState(false);

  const { User } = useContext(UserContext);

  const CanEdit = props.CanEdit;

  const LoadData = useCallback(async () => {
    try {
      setLoading(true);

      let req, res;

      //Camping nights
      req = await apiGet(`/shows/singlenight/list/${props.ShowId}`);
      res = await req.json();
      const dates = res.map((itm) => itm.CampingDate);
      setDays(dates);

      //Show details
      req = await apiGet(`/shows/${props.ShowId}`);
      res = await req.json();
      const singleNightRate = res.SingleNightCampingPrice;
      setSingleNightRate(singleNightRate);
      setCampingClosed(res.CampingClosed);
      if (res.CampingCap > 0 && res.Campers >= res.CampingCap) setCampingCapped(true);

      //List of camping packs
      req = await apiGet(`/admin/shows/camping/packs/${props.ShowId}`);
      res = await req.json();
      setPackList(res);

      //Show entry details
      req = await apiGet(`/shows/campingchoices/${props.ShowId}/${User.Id}`);
      res = await req.json();
      const row = res[0][0];
      const PackId = row.CampingPackId;
      if (PackId !== 0 && PackId !== null) {
        setCampingPackId(PackId);
        setCampingString(
          `You've chosen the '${row.Description}' pack at a price of £${numeral(row.Price).format("0.00")}`
        );
        setSingleNightChoices([]);
      } else {
        setCampingString(
          res[1].length === 0
            ? "No camping selected"
            : `You've selected ${res[1].length} night(s) camping at a cost of £${numeral(
                res[1].length * singleNightRate
              ).format("0.00")}`
        );
      }

      if (!PackId || PackId === 0) {
        const singleNights = [];
        for (const dt of res[1]) singleNights.push(dt.ShowDate);
        setSingleNightChoices(singleNights);
      }

      setLoading(false);
    } catch (error) {
      console.log(error.message);
      window.alert("Error loading show data");
      setLoading(false);
    }
  }, [props.ShowId, User.Id]);

  useEffect(() => {
    LoadData();
  }, [LoadData]);

  const SaveChoices = async () => {
    try {
      //Single night choices
      let obj = {
        ShowId: props.ShowId,
        MemberId: User.Id,
        Choices: CampingPackId !== "" ? [] : SingleNightChoices,
      };
      await apiPost("/shows/campingnightchoices", obj);

      //Camping details
      obj = {
        ShowId: props.ShowId,
        MemberId: User.Id,
        CampingType: CampingPackId !== "" ? 1 : 0,
        CampingPackId: CampingPackId !== "" ? CampingPackId : 0,
      };
      await apiPost("/shows/campingchoices", obj);

      LoadData();
      setShowAlter(false);

      props.RefreshCost();
      props.onSave();
    } catch (error) {}
  };

  const SelectSingleNight = (dt, selected) => {
    let choices = Array.from(SingleNightChoices);
    if (selected) {
      choices.push(dt);
    } else {
      choices = choices.filter((c) => !moment(c).isSame(dt));
    }
    setSingleNightChoices(choices);
  };

  const CampingPackRows = PackList.map((itm) => (
    <option key={itm.PackId} value={itm.PackId}>
      {itm.Description}, £{numeral(itm.Price).format("0.00")}
    </option>
  ));

  const CampingNightChoices = Days.map((itm, idx) => {
    const found = SingleNightChoices.find((c) => moment(c).isSame(itm));
    const checked = found ? true : false;
    return (
      <span key={idx} className="mr-1x">
        <input type="checkbox" checked={checked} onChange={(e) => SelectSingleNight(itm, e.target.checked)} />{" "}
        {moment.utc(itm).format("ddd Do MMM")}
      </span>
    );
  });

  return (
    <div className="mb-3x">
      <h3>Camping choices</h3>

      {Loading ? <InlineLoading /> : <></>}

      <p>{CampingString}</p>

      {CampingClosed && <p>Camping is closed for this show</p>}
      {CampingCapped && <p>Camping has capped for this show</p>}

      {!ShowAlter && !CampingCapped && !CampingClosed && CanEdit && !props.IsClosed && (
        <button className="button" onClick={() => setShowAlter(true)}>
          Alter my choices
        </button>
      )}

      {ShowAlter && (
        <>
          <div className="mt-3x">
            {PackList.length !== 0 && (
              <>
                <h4>Camping Packs</h4>
                <div className="form-group">
                  <label>Select a camping pack</label>
                  <select
                    className="form-control width-25"
                    value={CampingPackId}
                    onChange={(e) => setCampingPackId(e.target.value)}
                  >
                    <option value="">No camping pack</option>
                    {CampingPackRows}
                  </select>
                </div>
              </>
            )}
            {SingleNightRate > 0 && (
              <>
                <h4>Per-night camping</h4>
                <p>Camping is charged at £{numeral(SingleNightRate).format("0.00")} / night</p>
                <p>{CampingNightChoices}</p>
              </>
            )}
            {CanEdit && (
              <p className="mt-3x">
                <button className="button button-green" onClick={SaveChoices}>
                  Save choices
                </button>
              </p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SummaryCamping;
