import React, { useContext, useRef, useEffect } from "react";
import RegistrationContext from "../../context/registration/RegistrationContext";
import { apiPost } from "../../shared/api.js";

function RegStep1(props) {
  const { UpdateValue, Email } = useContext(RegistrationContext);
  const refEmail = useRef(null);

  //Focus on email box
  useEffect(() => {
    refEmail.current.focus();
  }, []);

  const CheckEmail = async () => {
    const ERROR_MSG =
      "Sorry, there was an error checking the supplied email address";

    try {
      //Check for blanks
      if (Email.toString().trim() === "") {
        window.alert("Invalid email address");
        return;
      }

      //Valid email address format
      const rx = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (!Email.toString().trim().match(rx)) {
        window.alert("Invalid email address format");
        return;
      }

      //Check the database
      const req = await apiPost("/registration/checkemail", {
        EmailAddress: Email,
      });
      if (req && req.ok && req.status === 200) {
        const res = await req.json();
        res.Outcome === 0
          ? UpdateValue({ CurrentStep: 2 })
          : window.alert("Sorry, that email address is already in use");
      } else {
        window.alert(ERROR_MSG);
      }
    } catch (error) {
      console.log(error);
      window.alert(ERROR_MSG);
    }
  };

  return (
    <>
      <h2 className="text-red">
        This is the registration process for Agility4All in the United Kingdom
      </h2>
      <p className="text-red">
        This is not the site for UK Agility International. If you continue with
        registration we will be unable to refund your money.{" "}
        <a href="https://entries.ukagilityinternational.com/">
          Click here to go to the North American site (UKI)
        </a>
      </p>
      <br />
      <h4>Step 1 - Email Address</h4>
      <p className="mb-1x">
        Please provide an email address, which must be unique to our system.
      </p>
      <div className="form-group mb-3x">
        <label className="form-label"></label>
        <input
          ref={refEmail}
          type="text"
          className="form-control"
          value={Email}
          onChange={(e) =>
            UpdateValue({ Email: e.target.value.toString().toLowerCase() })
          }
        ></input>
      </div>
      <div className="form-group">
        <button className="button button-blue" onClick={CheckEmail}>
          Next
        </button>
      </div>
    </>
  );
}

export default RegStep1;
