import React, { useState, useContext, useEffect, useRef } from "react";
import "./login.css";
import Message from "../../shared/Message";
import UserContext from "../../context/user/UserContext";
import { apiPost } from "../../shared/api";
import ModalLoading from "../../shared/ModalLoading";
import ForgottenPassword from "./forgottenpassword";
import Signup from "./signup";
import moment from "moment";

function Login(props) {
  const [Waiting, setWaiting] = useState(false);
  const [HasMessage, setHasMessage] = useState(false);
  const [MessageContent, setMessageContent] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [Remember, setRemember] = useState(false);
  const [ShowForgottenPassword, setShowForgottenPassword] = useState(false);
  const [ShowSignup, setShowSignup] = useState(false);
  const [ShowPassword, setShowPassword] = useState(false);

  const PasswordControl = useRef(null);

  const { SetUser } = useContext(UserContext);

  useEffect(() => {
    try {
      //Get querystring - we might be trying to link direct to the reg form
      const qs = window.location.search;
      if (qs.includes("?view=reg")) setShowSignup(true);

      //Get saved email address if there is one
      const email = localStorage.getItem("EmailAddress");
      if (email && email !== "") {
        setEmail(email);
        setRemember(true);
        PasswordControl.current.focus();
      }
    } catch (error) {
      console.log("Error checking localStorage");
    }
  }, []);

  //Show/hide password text
  useEffect(() => {
    PasswordControl.current.type = ShowPassword ? "text" : "password";
  }, [ShowPassword]);

  const doLogin = async () => {
    const ERROR_MSG =
      "Error: There was an error processing your login request. Please try again.";
    const CONTACT_EMAIL = "enquiries@agility4all.co.uk";

    try {
      if (!Waiting) {
        //Show spinner
        setWaiting(false);
        setHasMessage(false);

        //API to authenticate
        const body = {
          Email,
          Password,
        };

        const req = await apiPost("/members/authenticate", body);
        if (req.ok) {
          const res = await req.json();

          setWaiting(false);

          switch (res.Result) {
            case "Duplicate":
              setHasMessage(true);
              setMessageContent(
                <>
                  {
                    "The supplied email address is attached to more than one account. Please contact the office to update your records on "
                  }
                  <a className="text-white" href={`mailto:${CONTACT_EMAIL}`}>
                    {CONTACT_EMAIL}
                  </a>
                </>
              );
              break;

            case "Error":
              setHasMessage(true);
              setMessageContent(
                "There was an error on the server while processing your login. Please try again."
              );
              break;

            // case 'Expired':
            //     setHasMessage(true);
            //     setMessageContent(<>{'Error: Your account has expired. Please contact the office on '}<a className="text-white" href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a></>);
            //     break;

            case "Invalid Password":
              setHasMessage(true);
              setMessageContent(
                'Error: Your password does not match your stored password. Please try again. If you cannot remember your stored password please reset it using the "I have forgotten my details" button below'
              );
              break;

            case "Not Found":
              setHasMessage(true);
              setMessageContent(
                <>
                  {
                    "Error: No active accounts were found with this email address in our system. Please contact the office on "
                  }
                  <a className="text-white" href={`mailto:${CONTACT_EMAIL}`}>
                    {CONTACT_EMAIL}
                  </a>
                </>
              );
              break;

            case "Success":
              setHasMessage(false);

              //if (res.IsAdmin) {
              //Store details received from the server in browser session storage
              sessionStorage.setItem("User", JSON.stringify(res));

              if (Remember) {
                localStorage.setItem("EmailAddress", Email);
              } else {
                localStorage.removeItem("EmailAddress");
              }

              //Set the user context also
              SetUser({
                User: {
                  Id: res.Id,
                  FirstName: res.FirstName,
                  LastName: res.LastName,
                  Email: res.Email,
                  Expired: moment().isAfter(
                    moment(res.DateExpires).add(1, "day")
                  ),
                },
              });
              sessionStorage.removeItem("ShowDate");

              break;
            // } else {
            //   window.alert(
            //     "The login system is currently closed for maintenance"
            //   );
            //   break;
            // }

            default:
              setHasMessage(true);
              setMessageContent(
                "An unknown server response was received. Please try again"
              );
              break;
          }
        } else {
          setHasMessage(true);
          setMessageContent(ERROR_MSG);
          setWaiting(false);
        }
      }
    } catch (error) {
      setHasMessage(true);
      setMessageContent(ERROR_MSG);
      setWaiting(false);
    }
  };

  if (ShowForgottenPassword)
    return (
      <ForgottenPassword onCancel={(e) => setShowForgottenPassword(false)} />
    );

  if (ShowSignup) return <Signup onCancel={(e) => setShowSignup(false)} />;

  return (
    <>
      {HasMessage ? (
        <>
          <Message Type="Error" Content={MessageContent} /> <br />
          <br />
        </>
      ) : (
        <></>
      )}

      {Waiting ? <ModalLoading /> : <></>}
      <div className="card card-narrow">
        <div className="card-header">
          <h3>Member Login</h3>
        </div>
        <div className="card-body">
          <div className="grid">
            <div className="col-7 mt-2x mr-2x mb-2x">
              <div className="form-group">
                <label className="form-label">Email Address:</label>
                <input
                  className="form-control login-text"
                  type="text"
                  value={Email}
                  onChange={(e) => setEmail(e.target.value)}
                ></input>
              </div>
              <div className="form-group">
                <label className="form-label">Password:</label>
                <input
                  ref={PasswordControl}
                  className="form-control login-text"
                  type="password"
                  value={Password}
                  onChange={(e) => setPassword(e.target.value)}
                ></input>
              </div>
              <div className="form-group mt-2x mb-2x">
                <label className="mr-1x inline">Show password?</label>
                <input
                  type="checkbox"
                  checked={ShowPassword}
                  onChange={(e) => setShowPassword(e.target.checked)}
                />
              </div>

              <div className="form-group">
                <label className="mr-1x inline">
                  Remember my email address
                </label>
                <input
                  type="checkbox"
                  checked={Remember}
                  onChange={(e) => setRemember(e.target.checked)}
                />
              </div>

              <div className="form-group mt-3x">
                <button
                  type="button"
                  className="button button-blue mr-2x"
                  onClick={doLogin}
                >
                  Login Now
                </button>{" "}
                <button
                  type="button"
                  className="button button-link"
                  onClick={() => setShowForgottenPassword(true)}
                >
                  I've forgotten my details
                </button>
              </div>
            </div>

            <div className="col-5 border-left">
              <div className="pl-2x pr-1x mt-4x">
                <b>Not got an account?</b>
                <br />
                <h3
                  onClick={(e) => setShowSignup(true)}
                  className="text-red cursor-pointer mb-3x"
                >
                  Register now
                </h3>

                <p className="quote mb-2x">
                  "Sign up in less than five minutes and join the agility
                  organisation run by competitors, for competitors"
                </p>

                <h4>Live Results</h4>
                <p>
                  <a href="/liveresults">
                    Click here to view live results for a currently running
                    show.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer"></div>
      </div>
    </>
  );
}

export default Login;
