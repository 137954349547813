import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { apiGet } from "../../../../shared/api.js";
import numeral from "numeral";
import { useParams } from "react-router-dom";

const MyEntryCamping = (props) => {
  const [PackList, setPackList] = useState([]);
  const [Days, setDays] = useState([]);
  const [SingleNightRate, setSingleNightRate] = useState();
  const [CampingPackId, setCampingPackId] = useState(props.Camping.CampingPackId || 0);
  const [SingleNightChoices, setSingleNightChoices] = useState(props.Camping.Choices || []);
  const [CampingCapped, setCampingCapped] = useState(false);
  const [CampingClosed, setCampingClosed] = useState(false);

  const { ShowId } = useParams();

  useEffect(() => {
    props.SetCanContinue(true);
  }, [props]);

  const LoadData = useCallback(async () => {
    try {
      //Show details
      let req, res;
      req = await apiGet(`/shows/singlenight/list/${ShowId}`);
      res = await req.json();
      const dates = res.map((itm) => itm.CampingDate);
      setDays(dates);

      req = await apiGet(`/shows/${ShowId}`);
      res = await req.json();
      setSingleNightRate(res.SingleNightCampingPrice);
      setCampingClosed(res.CampingClosed);
      if (res.CampingCap > 0 && res.Campers >= res.CampingCap) setCampingCapped(true);

      //List of camping packs
      req = await apiGet(`/admin/shows/camping/packs/${ShowId}`);
      res = await req.json();
      setPackList(res);
    } catch (error) {
      console.log(error.message);
      window.alert("Error loading show data");
    }
  }, [ShowId]);

  useEffect(() => {
    window.scrollTo(0, 0);
    LoadData();
  }, [LoadData]);

  useEffect(() => {
    let obj = {
      CampingType: CampingPackId !== "" ? 1 : 0,
      CampingPackId: CampingPackId !== "" ? CampingPackId : 0,
      Choices: CampingPackId !== "" ? [] : SingleNightChoices,
    };

    props.SetCamping(obj);
    //eslint-disable-next-line
  }, [CampingPackId, SingleNightChoices]);

  const SetCampingPackId = (val) => {
    if (val !== "") setSingleNightChoices([]);

    setCampingPackId(val);
  };

  const SelectSingleNight = (dt, selected) => {
    let choices = Array.from(SingleNightChoices);
    if (selected) {
      choices.push(dt);
    } else {
      choices = choices.filter((c) => !moment(c).isSame(dt));
    }
    setCampingPackId("");
    setSingleNightChoices(choices);
  };

  const CampingPackRows = PackList.map((itm) => (
    <option key={itm.PackId} value={itm.PackId}>
      {itm.Description}, £{numeral(itm.Price).format("0.00")}
    </option>
  ));

  const CampingNightChoices = Days.map((itm, idx) => {
    const found = SingleNightChoices.find((c) => moment(c).isSame(itm));
    const checked = found ? true : false;
    return (
      <React.Fragment key={idx}>
        <div className="mb-1x">
          <label className="switch">
            <input type="checkbox" checked={checked} onChange={(e) => SelectSingleNight(itm, e.target.checked)} />
            <span className="slider round"></span>
          </label>
          <span className="ml-1x">{moment.utc(itm).format("ddd Do MMM")}</span>
        </div>
      </React.Fragment>
    );
  });

  return (
    <>
      <h3>Camping choices</h3>
      {PackList.length === 0 && SingleNightRate === 0 ? (
        <p>There is no camping available at this show.</p>
      ) : (
        <div className="mt-3x">
          {PackList.length !== 0 ? (
            <>
              <h4>Camping Packs</h4>
              {CampingClosed && <p>Camping is closed for this show.</p>}
              {CampingCapped && <p>Camping is already capped for this show.</p>}
              {!CampingCapped && !CampingClosed && (
                <div className="form-group">
                  <label>Select a camping pack</label>
                  <select
                    className="form-control width-25"
                    value={CampingPackId}
                    onChange={(e) => SetCampingPackId(e.target.value)}
                  >
                    <option value="">No camping pack</option>
                    {CampingPackRows}
                  </select>
                </div>
              )}
              <br />
              <br />
            </>
          ) : (
            <></>
          )}
          {!CampingClosed && !CampingCapped && SingleNightRate > 0 ? (
            <>
              <h4>Per-night camping</h4>
              <p>Camping is charged at £{numeral(SingleNightRate).format("0.00")} / night</p>
              <div>{CampingNightChoices}</div>
            </>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};

export default MyEntryCamping;
