import React, { useState, useEffect, useCallback, useContext, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { apiGet, apiPost } from "../../../../shared/api";
import moment from "moment";
import Ring from "./ringeditorring";
import InlineLoading from "../../../../shared/InlineLoading";
import ModalLoading from "../../../../shared/ModalLoading";
import ModalContent from "../../../../shared/ModalContent";
import UserContext from "../../../../context/user/UserContext";

const RingEditor = (props) => {
  const { Id } = useParams();
  const { User } = useContext(UserContext);

  const [Data, setData] = useState([]);
  const [Day, setDay] = useState();
  const [List, setList] = useState([]);
  const [ShowLoading, setShowLoading] = useState(false);
  const [ClassId, setClassId] = useState();
  const [LevelId, setLevelId] = useState();
  const [ClassName, setClassName] = useState("");
  const [ShowModal, setShowModal] = useState(false);
  const [IsAdmin, setIsAdmin] = useState(false);
  const [Selected, setSelected] = useState([]);
  const [AssignedRing, setAssignedRing] = useState("1");

  const RefreshCount = useRef(1);

  useEffect(() => {
    const CheckForAdmin = async () => {
      const req = await apiGet(`/members/account/${User.Id}`);
      if (req && req.ok && req.status === 200) {
        const res = await req.json();
        setIsAdmin(res.IsAdmin);
      }
    };

    CheckForAdmin();
  }, [User]);

  const AssignSelectedClasses = async () => {
    try {
      let obj = {
        ShowId: Id,
        ClassDate: Day,
      };
      let rowCount = 1;
      for (const row of Selected) {
        const idParts = row.split("_");
        obj.ClassId = idParts[0];
        obj.LevelId = idParts[1];
        obj.SortOrder = rowCount;
        obj.RingNumber = AssignedRing;
        await apiPost(`/admin/shows/classes/setlevelorder`, obj);
        rowCount += 1;
      }
      setSelected([]);
      LoadDataForDay();
      RefreshCount.current += 1;
    } catch (error) {
      console.log(error.message);
    }
  };

  //Show data
  const LoadData = useCallback(async () => {
    try {
      let req, res;

      setShowLoading(true);

      //Create ring board data if needed
      req = await apiGet(`/admin/shows/rings/createringboarddata/${Id}`);

      //Show dates
      req = await apiGet(`/shows/${Id}`);
      if (req && req.ok) {
        res = await req.json();

        const dateList = [];
        let currentDate = moment(res.StartDate);
        let lastDate = moment(res.EndDate);
        while (currentDate.isSameOrBefore(lastDate)) {
          const dt = moment(currentDate);
          dateList.push(dt);
          currentDate.add(1, "days");
        }
        setData(dateList);
        setShowLoading(false);
      } else {
        window.alert("Error loading show data");
        setShowLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      window.alert("Error loading show data");
      setShowLoading(false);
    }
  }, [Id]);

  useEffect(() => {
    if (Id) LoadData();
  }, [Id, LoadData]);

  const LoadDataForDay = useCallback(async () => {
    try {
      setShowLoading(true);

      let req, res;

      //Combinations
      req = await apiGet(`/admin/shows/classes/listcombinations_ring/${Id}`);
      if (req && req.ok) {
        res = await req.json();
        res = res.filter(
          (r) => moment(r.ClassDate).format("YYYYMMDD") === Day && r.IsEnterable === true
        );

        const _filtered = [];
        for (const row of res)
          if (
            !_filtered.find((r) => r.ClassId === row.ClassId && r.LevelId === row.LevelId)
          )
            _filtered.push(row);

        const list = [];
        for (const row of _filtered
          .filter((r) => r.RingNumber === 0)
          .sort((a, b) => (a.LevelSortOrder < b.LevelSortOrder ? -1 : 1)))
          list.push({
            id: `${row.ClassId}_${row.LevelId}`,
            name: row.ClassLabel,
          });

        setList(list);
      } else {
        window.alert("Error loading combination data");
      }
      setShowLoading(false);
    } catch (error) {
      console.log(error.message);
      setShowLoading(false);
    }
  }, [Id, Day]);

  useEffect(() => {
    if (Day) LoadDataForDay();
  }, [Day, LoadDataForDay]);

  const SetItem = async (Id) => {
    const idParts = Id.split("_");
    setClassId(idParts[0]);
    setLevelId(idParts[1]);

    const foundClass = List.find((i) => i.id === Id);
    setClassName(foundClass.name);
    setShowModal(true);
  };

  const SaveClass = async () => {
    try {
      const obj = {
        ShowId: Id,
        ClassId,
        LevelId,
        ClassDate: Day,
        ClassLabel: ClassName,
      };
      await apiPost(`/admin/shows/rings/classdetails`, obj);
      setClassName();
      setLevelId();
      setClassId();
      setShowModal(false);
      LoadDataForDay();
    } catch (error) {
      console.log(error.message);
      window.alert("Error saving class");
    }
  };

  const CaptureCheck = (id, checked) => {
    let selected = [...Selected];
    if (checked) selected.push(id);

    if (!checked) selected = selected.filter((i) => i !== id);

    setSelected(selected);
  };

  const IsChecked = (Id) => {
    return Selected.some((i) => i === Id) ? "checked" : "";
  };

  return (
    <div className="card">
      <div className="card-header">Ring Editor</div>
      <div className="card-body">
        {ShowLoading && <ModalLoading />}

        {ShowModal && (
          <ModalContent>
            <p>
              Class Name:{" "}
              <input
                type="text"
                className="form-control"
                value={ClassName}
                onChange={(e) => setClassName(e.target.value)}
                onBlur={SaveClass}
              />
            </p>
            <p>
              <button className="button button-green mr-1x" onClick={SaveClass}>
                Save
              </button>
              <button className="button" onClick={() => setShowModal(false)}>
                Cancel
              </button>
            </p>
          </ModalContent>
        )}

        {IsAdmin && (
          <p className="mb-2x">
            <Link to={`/admin/shows/processing/${Id}`}>Back to menu</Link>
          </p>
        )}
        {!IsAdmin && (
          <p className="mb-2x">
            <Link to={`/showmanagers/process/${Id}`}>Back to menu</Link>
          </p>
        )}
        <div className="form-group mb-3x">
          <label>Selected Day:</label>
          <select
            className="form-control width-25"
            value={Day}
            onChange={(e) => setDay(e.target.value)}
          >
            <option value="">Select...</option>
            {Data.map((itm, idx) => (
              <option key={idx} value={moment(itm).format("YYYYMMDD")}>
                {moment(itm).format("ddd Do MMM")}
              </option>
            ))}
          </select>
        </div>
        {Day && (
          <>
            <div className="form-group mb-3x">
              <p className="text-red">
                Firstly, select the correct classes and assign them to the correct ring.
                Then drag each class into the correct order. Once you have all classes in
                the desired order, please click on the first class in each ring to set the
                height order. You can then either set each class individually or click on
                the 'Reverse Heights' button.
                <br />
                <b>Do not forget to enter a judge's name for each ring.</b>
              </p>
            </div>
            <div className="grid grid-33-66">
              <div>
                <p className="mb-2x">
                  <b>Assign to:</b>
                  <br />
                  <select
                    className="form-control width-50 mr-1x"
                    value={AssignedRing}
                    onChange={(e) => setAssignedRing(e.target.value)}
                  >
                    <option value="1">Ring 1</option>
                    <option value="2">Ring 2</option>
                    <option value="3">Ring 3</option>
                    <option value="4">Ring 4</option>
                    <option value="5">Ring 5</option>
                    <option value="6">Ring 6</option>
                  </select>
                  {Selected.length > 0 && (
                    <button
                      className="button button-green"
                      onClick={AssignSelectedClasses}
                    >
                      Assign
                    </button>
                  )}
                </p>
                <p>
                  <b>Unplaced</b>
                </p>
                {ShowLoading && <InlineLoading />}
                {List.map((itm) => (
                  <p className="cursor-pointer" key={itm.id}>
                    <input
                      type="checkbox"
                      checked={IsChecked(itm.id)}
                      onChange={(e) => CaptureCheck(itm.id, e.target.checked)}
                    />{" "}
                    {itm.name}{" "}
                    {IsAdmin && (
                      <span onClick={() => SetItem(itm.id)} className="text-link">
                        (Edit)
                      </span>
                    )}
                  </p>
                ))}
              </div>
              <div>
                <Ring RefreshCount={RefreshCount.current} RingNumber="1" Date={Day} />
                <Ring RefreshCount={RefreshCount.current} RingNumber="2" Date={Day} />
                <Ring RefreshCount={RefreshCount.current} RingNumber="3" Date={Day} />
                <Ring RefreshCount={RefreshCount.current} RingNumber="4" Date={Day} />
                <Ring RefreshCount={RefreshCount.current} RingNumber="5" Date={Day} />
                <Ring RefreshCount={RefreshCount.current} RingNumber="6" Date={Day} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RingEditor;
