import React,{useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';
import '../wallet/balance.css';
import numeral from 'numeral';
import InlineLoading from '../../../shared/InlineLoading';


function WalletCard (props) {

    const [ClassNames, setClassNames] = useState('card-body');
    const [Loading, setLoading] = useState(true);


    useEffect(() => {
        if(props.Balance<0)
            setClassNames('card-body action-needed');

        if(props.Balance !== undefined && props.Balance !== null)
            setLoading(false);

    },[props]);

    if(Loading)
        return <InlineLoading />


    return (
        <div className="card">
            <div className="card-header">
                <h5>My wallet</h5>
            </div>
            <div className={ClassNames}>
                {
                    <p>
                        {
                            props.Balance < 0 ?
                            <>
                                You currently owe £{numeral(Math.abs(props.Balance)).format('0.00')}
                            </>
                            :
                            props.Balance > 0 ? 
                            <>
                                You are currently in credit by £{numeral(props.Balance).format('0.00')}
                            </>
                            :
                            <>
                                Your wallet is currently up to date.
                            </>
                        }
                    </p>
                }
                
            </div>
            <div className="card-footer">
                <Link to="/wallet"><FontAwesomeIcon icon={faCreditCard} className="mr-1x"></FontAwesomeIcon>View transactions</Link>
            </div>
        </div>
    )
}

export default WalletCard;