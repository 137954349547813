import React, { useEffect, useState } from "react";
import moment from "moment";
import ModalContent from "../../../shared/ModalContent";
import { apiPost, apiGet } from "../../../shared/api";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

function DogLine(props) {
  const [ShowModal, setShowModal] = useState(false);
  const [NewStatus, setNewStatus] = useState(props.Dog.Status);
  const [CanComplete, setCanComplete] = useState(false);
  const [MeasureHistory, setMeasureHistory] = useState([]);
  const [ShowMeasureHistory, setShowMeasureHistory] = useState(false);

  useEffect(() => {
    (async () => {
      if (
        props.Dog.ShowRegistration &&
        moment(props.Dog.DateCreated).isAfter(moment().add(-4, "days"))
      )
        setCanComplete(true);

      if (props.Dog) {
        const req = await apiGet(`/dogs/heights/list/${props.Dog.Id}`);
        const res = await req.json();
        setMeasureHistory(res);
      }
    })();
  }, [props.Dog]);

  //Save 'Status' modal
  const SaveStatus = async () => {
    try {
      const data = {
        Id: props.Dog.Id,
        Status: NewStatus,
      };
      const req = await apiPost("/Dogs/UpdateStatus", data);
      if (req && req.ok && req.status === 204) {
        props.onRefresh();
        setShowModal(false);
      } else {
        window.alert("Error updating dog details");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <tr>
      <td data-mobilelabel="Name:">
        {props.Dog.PetName}
        {ShowMeasureHistory && (
          <ModalContent>
            <p>Key:</p>
            <ul className="mb-2x">
              <li>F: First</li>
              <li>D: Dispute</li>
              <li>C: Challenge</li>
              <li>P: Permanent</li>
            </ul>
            <table className="mb-2x table no-hover">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Height</th>
                  <th>Show</th>
                  <th>Measurer</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                {MeasureHistory.sort((a, b) =>
                  moment(a.DateStamp).isBefore(b.DateStamp) ? 1 : -1
                ).map((itm) => {
                  return (
                    <tr key={itm.LineId}>
                      <td>{moment(itm.DateStamp).format("DD/MM/YYYY")}</td>
                      <td>{itm.Height}</td>
                      <td>{itm.ShowName}</td>
                      <td>{itm.MeasurerName}</td>
                      <td>
                        {itm.IsProvisional && "F"}
                        {itm.IsDisputed && "D"}
                        {itm.IsChallenge && "C"}
                        {!itm.IsChallenge &&
                          !itm.IsProvisional &&
                          !itm.IsDisputed &&
                          "P"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <p className="mb-3x">
              If your dog has measured into 600 on its first measure, you are
              not required to have it measured again if you intend to continue
              running at 600 or 500(s). You will need to have it measured again
              if you think you should be in a lower height category.
            </p>
            <p>
              <button
                className="button"
                onClick={() => setShowMeasureHistory(false)}
              >
                Ok
              </button>
            </p>
          </ModalContent>
        )}
      </td>
      <td data-mobilelabel="Dog Id:">{props.Dog.Id}</td>
      <td data-mobilelabel="DoB:">
        {moment(props.Dog.DateOfBirth).format("DD/MM/YYYY")}
      </td>
      <td data-mobilelabel="Gender:">{props.Dog.Gender}</td>
      <td data-mobilelabel="Breed:">{props.Dog.Breed}</td>
      <td data-mobilelabel="Performance:">{props.Dog.PerformanceLevel}</td>
      <td data-mobilelabel="Steeplechase:">{props.Dog.SteeplechaseLevel}</td>
      <td
        data-mobilelabel="Height:"
        className={props.Dog.IsProvisional ? "text-red text-bold" : ""}
      >
        {props.Dog.Height}
        {MeasureHistory.length > 0 && (
          <span
            title="Measurement history"
            className="ml-1x cursor-pointer"
            onClick={() => setShowMeasureHistory(true)}
          >
            <FontAwesomeIcon icon={faInfoCircle} />
          </span>
        )}
      </td>
      <td data-mobilelabel="Actions">
        {ShowModal ? (
          <ModalContent View="Narrow">
            <p>
              Please select the current status for this dog. If any other
              details are incorrect, please email the office:{" "}
              <a
                className="text-blue"
                href="mailto:enquiries@agility4all.co.uk"
              >
                enquiries@agility4all.co.uk
              </a>
            </p>
            <div className="form-group">
              <label className="form-label">Status:</label>
              <select
                className="form-control"
                value={NewStatus}
                onChange={(e) => setNewStatus(e.target.value)}
              >
                <option value="1">Active</option>
                <option value="5">Not competing</option>
                <option value="2">Retired</option>
                <option value="3">Rainbow Bridge</option>
              </select>
            </div>
            <div className="form-group">
              <button
                className="button button-green mr-1x"
                onClick={(e) => SaveStatus()}
              >
                Update Status
              </button>{" "}
              <button
                className="button button-link mr-1x"
                onClick={(e) => setShowModal(false)}
              >
                Cancel
              </button>
            </div>
          </ModalContent>
        ) : (
          <></>
        )}
        <span className="cursor-pointer" onClick={(e) => setShowModal(true)}>
          Edit
        </span>{" "}
        | <Link to={`/results/dog/${props.Dog.Id}`}>Results</Link>{" "}
        {CanComplete && (
          <>
            | <Link to={`/dogs/edit/${props.Dog.Id}`}>Complete Details</Link>
          </>
        )}
      </td>
    </tr>
  );
}

export default DogLine;
