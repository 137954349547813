import React from "react";
import { apiGet } from "../../../../shared/api";
import moment from "moment";
import { Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";
import { Font } from "@react-pdf/renderer";

const RingPlan = async (Id) => {
  let Data = [],
    ShowName = "",
    Days = [],
    Rings = [];
  Font.registerHyphenationCallback((word) => [word]);

  const styles = StyleSheet.create({
    fullWidth: {
      display: "flex",
      alignItems: "center",
    },
    page: {
      padding: "2mm",
      marginLeft: "5mm",
      marginBottom: "10mm",
    },
    paddedContainer: {
      padding: 10,
      paddingBottom: 4,
    },
    dayHeading: {
      fontSize: 14,
      marginTop: 8,
    },
    ringHeading: {
      fontSize: 11,
      paddingLeft: 5,
      marginBottom: 8,
    },
    ringContainer: {
      border: 2,
      borderColor: "black",
      padding: 5,
      marginRight: 10,
      marginBottom: 10,
    },
    ringContainerHalves: {
      width: "48%",
    },
    row: {
      marginBottom: 5,
      paddingLeft: 3,
      paddingRight: 3,
      paddingBottom: 0,
      flexDirection: "row",
      flexWrap: "wrap",
    },
    rowRing: {
      borderBottomColor: "silver",
      borderBottomWidth: 1,
    },
    classLabel: {
      flex: 0.33,
      fontSize: 10,
      paddingLeft: 2,
      paddingRight: 2,
    },
    classList: {
      display: "flex",
      flexDirection: "row",
      flex: 0.66,
    },
    cell: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingLeft: 2,
      paddingRight: 2,
    },
    smallText: {
      fontSize: 6,
    },
    footerText: {
      fontSize: 9,
      marginBottom: 6,
    },
    sponsorImage: {
      width: "26mm",
      height: "auto",
      maxHeight: "26mm",
      margin: "0.3rem",
    },
  });

  try {
    const req = await apiGet(`/admin/shows/rings/ringboarddata/${Id}`);
    const res = await req.json();
    Data = res;
    ShowName = res.length > 0 && res[0].DisplayName;

    const _Dates = new Set();
    for (const row of res) _Dates.add(row.ClassDate);
    Days = [..._Dates];

    const _Rings = new Set();
    for (const row of res) _Rings.add(row.RingNumber);

    for (const dt of [..._Dates]) for (const ring of _Rings) Rings.push({ Date: dt, RingNumber: ring });
  } catch (error) {
    console.log(error.message);
    window.alert("Error loading data");
  }

  const CreateClasses = (Data, Ring, c) => {
    const IdParts = c.split("_");
    const Heights = Data.filter(
      (r) =>
        moment(r.ClassDate).isSame(moment(Ring.Date)) &&
        r.RingNumber === parseInt(Ring.RingNumber) &&
        r.ClassId === parseInt(IdParts[0]) &&
        r.LevelId === parseInt(IdParts[1])
    )
      .filter(
        (c) => (c.ClassId !== 36 && c.ClassId !== 37) || ((c.ClassId === 36 || c.ClassId === 37) && c.LevelId === 5)
      )
      .sort((a, b) => {
        return a.HeightSortOrder < b.HeightSortOrder ? -1 : 1;
      });

    const key = `Ring${Ring.RingNumber}_${moment(Ring.Date).format("YYYYMMDD")}_${c}`;

    return (
      <View key={key} style={[styles.row, styles.rowRing]}>
        <View style={styles.classLabel}>
          <Text>{Heights.length > 0 && Heights[0].ClassLabel}</Text>
        </View>
        <View style={styles.classList}>
          {Heights.map((h) => {
            const heightKey = `Ring${Ring.RingNumber}_${moment(Ring.Date).format("YYYYMMDD")}_${c}_${h.HeightId}`;
            let RunCount = h.RunCount;
            if (h.ClassId === 23 || h.ClassId === 38 || h.ClassId === 39) RunCount = h.DogCount;

            return (
              <View style={styles.cell} key={heightKey}>
                <Text style={styles.smallText}>{h.ShortName}</Text>
                <Text style={styles.smallText}>{RunCount}</Text>
              </View>
            );
          })}
        </View>
      </View>
    );
  };

  const CreateRing = (Ring, Data, NumberOfRings) => {
    const Title = `Ring ${Ring.RingNumber}`;
    let Judge = "";
    let Classes = [];

    const RingData = Data.filter(
      (r) => moment(r.ClassDate).isSame(moment(Ring.Date)) && r.RingNumber === parseInt(Ring.RingNumber)
    );
    if (RingData && RingData.length > 0) {
      Judge = RingData[0].JudgeName;
      const _Classes = new Set();
      for (const row of RingData) _Classes.add(`${row.ClassId}_${row.LevelId}`);
      Classes = [..._Classes];
    } else {
      return <></>;
    }

    const key = `Ring${Ring.RingNumber}_${moment(Ring.Date).format("YYYYMMDD")}`;

    return (
      <View key={key} style={[styles.ringContainer, styles.ringContainerHalves]}>
        <Text style={styles.ringHeading}>
          {Title} - {Judge}
        </Text>
        {Classes.map((c) => CreateClasses(Data, Ring, c))}
      </View>
    );
  };

  const CreateDay = async (day) => {
    const key = moment(day).format("YYYYMMDD");

    //List of sponsor images
    const req = await apiGet(`/admin/shows/sponsorimages/list/${Id}/${key}`);
    const res = await req.json();

    return (
      <Page key={key} style={styles.page} size="A4" orientation="landscape">
        <View style={styles.fullWidth}>
          <Text style={styles.dayHeading}>
            {ShowName} - {moment(day).format("dddd Do MMMM")}
          </Text>
        </View>
        <View style={[styles.paddedContainer, styles.row]}>
          {Rings.map((Ring) => moment(Ring.Date).isSame(moment(day)) && CreateRing(Ring, Data, Rings.length))}
        </View>
        <View>
          <Text style={styles.footerText}>An (s) indicates Select height.</Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            justifyContent: "center",
          }}
        >
          {res.map((itm) => (
            <Image style={styles.sponsorImage} source={`data:image;base64, ${itm.SponsorImage}`} />
          ))}
        </View>
      </Page>
    );
  };

  const DayList = [];
  for (const day of Days) {
    const output = await CreateDay(day);
    DayList.push(output);
  }

  return <Document>{DayList}</Document>;
};

export default RingPlan;
