import React, {useState} from 'react';
import {apiPost} from '../../../shared/api.js';
import {GetPaymentMethod} from '../../../shared/wallet.js';
import numeral from 'numeral';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import {Workbook} from 'exceljs';
import {DownloadFile} from '../../../shared/clientsidefiledownload.js';


function Activity(props) {

    const [StartDate, setStartDate] = useState(new Date());
    const [Data, setData] = useState(undefined);
    const [FinalTransactions, setFinalTransactions] = useState([]);


    const LoadData = async() => {

        const ERROR_MSG = 'Error loading data from server. Micky support time';

        try {

            const data = {
                StartDate: moment(StartDate).format('DD/MM/YYYY')
            }

            const req = await apiPost('/admin/wallet/reports/activity', data);
            if(req && req.ok && req.status === 200) {
                const res = await req.json();
                setData(res);

                //Totals for extra lines
                const ids = new Set();
                let transactionList = res.Activity.Transactions;
                for(const row of transactionList)
                    if(row.TransactionId)
                        ids.add(row.TransactionId);

                for(const id of Array.from(ids)) {
                    const lines = Array.from(transactionList.filter(r => r.TransactionId === id));
                    if(lines.length > 1) {
                        const credit = lines.find(t => t.TransactionType === 'C');
                        const debit = lines.find(t => t.TransactionType === 'D' && t.Detail === 'Transaction charges');
                        const otherdebits = lines.find(t => t.TransactionType === 'D' && t.Detail !== 'Transaction charges');
                        if(credit && debit && credit.Amount !== debit.Amount) {
                            transactionList = transactionList.filter(r => r.TransactionId !== id);
                            transactionList.push({
                                DateTimeStamp: credit.DateTimeStamp,
                                TransactionType: 'C',
                                Id: credit.Id,
                                Amount: credit.Amount ,
                                FirstName: credit.FirstName,
                                LastName: credit.LastName,
                                Detail: 'Total payment',
                                Method: 2
                            });    
                            transactionList.push({
                                DateTimeStamp: credit.DateTimeStamp,
                                TransactionType: 'C',
                                Id: credit.Id,
                                Amount: credit.Amount - debit.Amount,
                                FirstName: credit.FirstName,
                                LastName: credit.LastName,
                                Detail: 'Account Top-up',
                                Method: 2
                            });                            
                            transactionList.push(debit);   
                            if(otherdebits)
                                transactionList.push(otherdebits);
                        }                                             
                    }
                }

                setFinalTransactions(transactionList.sort((a,b) => {return a.DateTimeStamp <= b.DateTimeStamp ? -1 : 1}));

                return {Data: res, TransactionList: transactionList};
                
            } else {
                window.alert(ERROR_MSG);
            }
        }
        catch (error) {
            console.log(error.message);
            window.alert(ERROR_MSG);
        }
    }


    const GetExcel = async() => {

        try {
            
            const {Data,TransactionList} = await LoadData();

            let wb = new Workbook();
            let ws = wb.addWorksheet('Activity');

            ws.addRow(['Income', 'Paid Out', 'Current wallet balances']);
            ws.addRow([Data.Activity.Credits, Data.Activity.Debits,Data.TotalBalance]);

            ws = wb.addWorksheet('Transactions');
            ws.addRow(['Date','Type','Member','Amount','Notes','Method']);
            for(const itm of TransactionList)
                ws.addRow([`${moment(itm.DateTimeStamp).format('DD/MM/YYYY')}`,itm.TransactionType,`${itm.Id} ${itm.FirstName} ${itm.LastName}`, itm.Amount,`${itm.Detail}. ${itm.Notes ?? ''}`, `${GetPaymentMethod(itm)}`]);

            const buf = await wb.xlsx.writeBuffer();
            const blob =  new Blob([buf], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            DownloadFile(blob,'Activity.xlsx');

        }
        catch (error) {
            console.log(error.message);
            window.alert('Error producing Excel sheet');
        }

    }
   


    return (
        <div className="card">
            <div className="card-header">Wallet Activity</div>
            <div className="card-body">

                <div className="mb-1x"><span className="mr-2x">Start Date: </span><DatePicker maxDate={new Date()} showMonthDropdown showYearDropdown className="form-control width-50 mr-2x" dateFormat="dd/MM/yyyy" value={StartDate} selected={StartDate} onChange={val => setStartDate(val)}></DatePicker></div>
                <div className="mb-2x"><button className="button mr-2x" onClick={LoadData}>View Data</button><button className="button" onClick={GetExcel}><FontAwesomeIcon icon={faFileExcel} className="mr-1x" />Get Excel</button></div>

                {
                    Data ?
                    <>
                        <table className="table mb-2x">
                            <thead>
                                <tr>
                                    <th>Income</th>
                                    <th>Paid out</th>
                                    <th>Current wallet balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>£{numeral(Data.Activity.Credits).format('0.00')}</td>
                                    <td>£{numeral(Data.Activity.Debits).format('0.00')}</td>
                                    <td>£{numeral(Data.TotalBalance).format('0.00')}</td>
                                </tr>
                            </tbody>
                        </table>

                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Type</th>
                                    <th>Member</th>
                                    <th>Amount</th>
                                    <th>Notes</th>
                                    <th>Method</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    FinalTransactions.map(itm => {
                                        return (
                                            <tr>
                                                <td>{moment(itm.DateTimeStamp).format('DD/MM/YYYY HH:mm:SS')}</td>
                                                <td>{itm.TransactionType}</td>
                                                <td>{itm.Id} {itm.FirstName} {itm.LastName}</td>
                                                <td>£{numeral(itm.Amount).format('0,0.00')}</td>
                                                <td>
                                                    {itm.Detail}
                                                    {
                                                        itm.Notes > '' ?
                                                        <><br /><i>{itm.Notes}</i></>
                                                        :
                                                        <></>
                                                    }
                                                </td>
                                                <td>
                                                    {GetPaymentMethod(itm)}
                                                </td>
                                            </tr>
                                        );
                                    })                              
                                }
                            </tbody>
                        </table>
                    </>
                    :
                    <></>

                }

            </div>
            <div className="card-footer"></div>
        </div>
    )
}

export default Activity;