import React, { useState, useEffect } from "react";
import Modal from "../../../shared/ModalContent";
import { apiGet, apiPost } from "../../../shared/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const EditDialog = ({ Data, ShowId, ...props }) => {
  const [RunDate, setRunDate] = useState();
  const [Handler, setHandler] = useState();
  const [Dog, setDog] = useState();
  const [LevelId, setLevelId] = useState([]);
  const [ClassId, setClassId] = useState([]);
  const [HeightId, setHeightId] = useState([]);
  const [Faults, setFaults] = useState();
  const [RunTime, setRunTime] = useState();
  const [ProgressionPoints, setProgressionPoints] = useState();
  const [Points, setPoints] = useState();
  const [Eliminated, setEliminated] = useState(false);
  const [NFC, setNFC] = useState(false);
  const [RunProgression, setRunProgression] = useState(true);
  const [Levels, setLevels] = useState([]);
  const [Classes, setClasses] = useState([]);
  const [Heights, setHeights] = useState([]);
  const [Award, setAward] = useState();

  const GetHandlerSuggestions = async (val) => {
    try {
      const req = await apiGet(`/members/match/${val}`);
      if (req && req.ok && req.status === 200) {
        let res = await req.json();
        return res.map((itm) => {
          const displayString = `${itm.Id} - ${itm.FirstName} ${itm.LastName}`;
          return { value: itm.Id, label: displayString };
        });
      } else {
        return [];
      }
    } catch (error) {
      console.log(error.message);
      return [];
    }
  };

  //Gets suggested dog names from the server
  const GetDogSuggestions = async (val) => {
    try {
      const req = await apiGet(`/dogs/match/${val}`);
      if (req && req.ok && req.status === 200) {
        const res = await req.json();
        return res.map((itm) => {
          let displayString = `${itm.Id} ${itm.PetName}`;

          if (itm.FirstName !== "Unknown")
            displayString += ` - ${itm.FirstName} ${itm.LastName}`;

          return { value: itm.Id, label: displayString };
        });
      } else {
        return [];
      }
    } catch (error) {
      console.log(error.message);
      return [];
    }
  };

  const doSave = async () => {
    const LineId = Data?.LineId || 0;

    const obj = {
      LineId,
      ShowId,
      RunDate: moment.utc(RunDate, "DD/MM/YYYY").toDate(),
      HandlerId: Handler.value,
      DogId: Dog.value,
      LevelId,
      ClassId,
      HeightId,
      Faults: Faults > "" ? Faults : 0,
      RunTime: RunTime > "" ? RunTime : null,
      ProgressionPoints: ProgressionPoints > "" ? ProgressionPoints : null,
      Points: Points > "" ? Points : 0,
      Eliminated,
      NFC,
      RunProgression,
      Award: Award > "" ? Award : null,
    };

    const req = await apiPost(`/results/update/${LineId}`, obj);
    if (req && req.ok) {
      props.CloseModal();
    } else {
      window.alert("Error saving record");
    }
  };

  const doCancel = () => {
    props.CloseModal();
  };

  useEffect(() => {
    (async () => {
      try {
        let req, res;
        req = await apiGet("/admin/shows/levels");
        res = await req.json();
        setLevels(res);

        req = await apiGet("/admin/shows/heights");
        res = await req.json();
        setHeights(res);

        req = await apiGet("/admin/shows/classes");
        res = await req.json();
        setClasses(res);

        if (Data) {
          console.log(Data);
          setRunDate(moment(Data.RunDate).toDate());
          setHandler({
            value: Data.HandlerId,
            label: `${Data.FirstName} ${Data.LastName}`,
          });
          setDog({ value: Data.DogId, label: Data.PetName });
          setLevelId(Data.LevelId);
          setClassId(Data.ClassId);
          setHeightId(Data.HeightId);
          setFaults(Data.Faults);
          setProgressionPoints(Data.ProgressionPoints);
          setPoints(Data.Points);
          setRunTime(Data.Time);
          setAward(Data.Rank);
          setEliminated(Data.Eliminated);
          setNFC(Data.NFC || 0);
        }
      } catch (error) {}
    })();
  }, [Data]);

  return (
    <Modal>
      <h4>Edit Run Data</h4>
      <table className="table">
        <thead>
          <tr>
            <td></td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Run Date:</td>
            <td>
              <DatePicker
                showMonthDropdown
                showYearDropdown
                className="form-control width-50"
                dateFormat="dd/MM/yyyy"
                selected={RunDate}
                value={RunDate}
                onChange={(val) => setRunDate(val)}
              />
            </td>
          </tr>
          <tr>
            <td>Handler:</td>
            <td>
              <AsyncSelect
                value={Handler}
                placeholder="Start typing..."
                loadOptions={GetHandlerSuggestions}
                onChange={(e) => setHandler(e)}
              />
            </td>
          </tr>
          <tr>
            <td>Dog:</td>
            <td>
              <AsyncSelect
                value={Dog}
                placeholder="Start typing..."
                loadOptions={GetDogSuggestions}
                onChange={(e) => setDog(e)}
              />
            </td>
          </tr>
          <tr>
            <td>Event:</td>
            <td>
              <p>
                <select
                  className="form-control mr-1x"
                  value={LevelId}
                  onChange={(e) => setLevelId(e.target.value)}
                >
                  <option value="">Select...</option>
                  {Levels.map((itm) => (
                    <option key={itm.LevelId} value={itm.LevelId}>
                      {itm.LevelLabel}
                    </option>
                  ))}
                </select>
              </p>
              <p>
                <select
                  className="form-control mr-1x"
                  value={ClassId}
                  onChange={(e) => setClassId(e.target.value)}
                >
                  <option value="">Select...</option>
                  {Classes.map((itm) => (
                    <option key={itm.ClassId} value={itm.ClassId}>
                      {itm.ClassLabel}
                    </option>
                  ))}
                </select>
              </p>
              <p>
                <select
                  className="form-control mr-1x"
                  value={HeightId}
                  onChange={(e) => setHeightId(e.target.value)}
                >
                  <option value="">Select...</option>
                  {Heights.map((itm) => (
                    <option key={itm.HeightId} value={itm.HeightId}>
                      {itm.HeightLabel}
                    </option>
                  ))}
                </select>
              </p>
            </td>
          </tr>
          <tr>
            <td>Total Faults:</td>
            <td>
              <input
                type="text"
                className="form-control width-20"
                value={Faults}
                onChange={(e) => setFaults(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Games Points:</td>
            <td>
              <input
                type="text"
                className="form-control width-20"
                value={Points}
                onChange={(e) => setPoints(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Progression Points:</td>
            <td>
              <input
                type="text"
                className="form-control width-50"
                value={ProgressionPoints}
                onChange={(e) => setProgressionPoints(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Run Time:</td>
            <td>
              <input
                type="text"
                className="form-control width-50"
                value={RunTime}
                onChange={(e) => setRunTime(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Award:</td>
            <td>
              <input
                type="text"
                className="form-control width-20"
                value={Award}
                onChange={(e) => setAward(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Eliminated?</td>
            <td>
              <input
                type="checkbox"
                checked={Eliminated && "checked"}
                onChange={(e) => setEliminated(e.target.checked)}
              />
            </td>
          </tr>
          <tr>
            <td>NFC?</td>
            <td>
              <input
                type="checkbox"
                checked={NFC && "checked"}
                onChange={(e) => setNFC(e.target.checked)}
              />
            </td>
          </tr>
          <tr>
            <td>Run progression?</td>
            <td>
              <input
                type="checkbox"
                checked={RunProgression && "checked"}
                onChange={(e) => setRunProgression(e.target.checked)}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <p className="mt-2x">
        <button className="button button-green mr-1x" onClick={doSave}>
          <FontAwesomeIcon icon={faSave} className="mr-1x" />
          Save
        </button>
        <button className="button button-outline" onClick={doCancel}>
          Cancel
        </button>
      </p>
    </Modal>
  );
};

export default EditDialog;
