import React,{useState,useEffect} from 'react';
import {apiPost} from '../../../shared/api.js';
import InlineLoading from '../../../shared/InlineLoading';
import PendingTransactionItem from './pendingtransactionitem.jsx';


const Upcoming = props => {

    const [Loading, setLoading] = useState(true);
    const [TransactionList, setTransactionList] = useState([]);

    const Id = props.UserId;
    const IsAdmin = props.View === 'Admin';


    //Loads the list of transactions
    useEffect(() => {

        const LoadData = async() => {

            const ERROR_MSG = 'Error loading pending transaction list';

            try {
                const data = {
                    Data: {
                        UserId: Id
                    }
                }

                const req = await apiPost('/wallet/pendingtransactions', data);
                if(req && req.ok && req.status === 200) {
                    const res = await req.json();
                    setTransactionList(res);                    
                } else {
                    window.alert(ERROR_MSG);
                }
            }
            catch (error) {
                console.log(error.message);
                window.alert(ERROR_MSG);
            }
            finally {
                setLoading(false);
            }
        }

        LoadData();

    },[Id]);


    const TransactionRows = TransactionList.map(itm => {
        return <PendingTransactionItem key={itm.Id} Item={itm} />
    });


    

    return (
        <>
            <h4>Pending transactions</h4>
            <p className="mb-1x">
                These transactions will be deducted from your wallet on the date shown. Transactions in <b className="text-red">red</b> are due now. Click a show name to view your entry
            </p>
            <table className="table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Detail</th>
                        <th>Amount Due</th>
                        {IsAdmin ? <th>Actions</th> : <></>}
                    </tr>
                </thead>
                <tbody>      
                    {
                        Loading ? 
                        <tr><td colSpan="3"><InlineLoading /></td></tr>
                        :
                        <>{TransactionRows}</>
                    }       
                </tbody>
            </table>
        </>
    )
}


export default Upcoming;