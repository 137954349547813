import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { apiGet } from "../../../shared/api.js";
import moment from "moment";
import ModalLoading from "../../../shared/ModalLoading";
import UserContext from "../../../context/user/UserContext.js";
import { useParams } from "react-router-dom";

const Emails = () => {
  const PageSize = 15;

  const [Data, setData] = useState([]);
  const [Page, setPage] = useState(1);
  const [PageCount, setPageCount] = useState(1);
  const [PageData, setPageData] = useState([]);
  const [MessageId, setMessageId] = useState("");
  const [Loading, setLoading] = useState(false);
  const [UserId, setUserId] = useState();
  const iFrame = useRef(null);

  const { User } = useContext(UserContext);
  const { Id } = useParams();

  useEffect(() => {
    if (Id && Id !== "") {
      setUserId(Id);
    } else {
      setUserId(User.Id);
    }
  }, [User.Id, Id]);

  useEffect(() => {
    var el = document.createElement("html");
    if (MessageId === "") {
      el.innerHTML = "<body />";
    } else {
      const itm = Data.find((m) => m.MessageId === parseInt(MessageId));
      el.innerHTML = itm ? itm.Body : "<body />";
    }
    const root = el.getElementsByTagName("body");
    if (iFrame.current) iFrame.current.innerHTML = root[0].innerHTML;
    window.scrollTo(0, 900);
  }, [MessageId, Data]);

  const LoadData = useCallback(async () => {
    try {
      if (!UserId) return;
      setLoading(true);
      const req = await apiGet(`/members/emails/${UserId}`);
      if (req && req.ok) {
        const res = await req.json();
        setData(res);
        setPageCount(Math.ceil(res.length / PageSize));
      } else {
        window.alert("Error loading email data");
      }
      setLoading(false);
    } catch (error) {
      window.alert("Error loading email data");
      setLoading(true);
    }
  }, [UserId]);

  //Get email list
  useEffect(() => {
    LoadData();
  }, [LoadData]);

  useEffect(() => {
    setPage(1);
  }, [Data]);

  useEffect(() => {
    const slice = Data.slice(
      [(parseInt(Page) - 1) * PageSize],
      PageSize * parseInt(Page)
    );
    setPageData(slice);
  }, [Page, Data]);

  const Rows = PageData.map((itm) => (
    <option value={itm.MessageId} key={itm.MessageId}>
      {moment(itm.DateTimeCreated).format("DD/MM/YYYY HH:mm")}{" "}
      {itm.Subject.toString().replace("UK Agility - ", "")}
    </option>
  ));

  const Pages = () => {
    const output = [];
    for (let i = 1; i <= PageCount; i++)
      output.push(
        <option key={i} value={i}>
          {i}
        </option>
      );

    return output;
  };

  if (Loading) return <ModalLoading />;

  return (
    <div className="card width-75">
      <div className="card-header">Emails you have been sent</div>
      <div className="card-body">
        <div className="mb-2x">
          <p>
            <select
              className="form-control"
              value={MessageId}
              onChange={(e) => setMessageId(e.target.value)}
            >
              <option value="">Please select...</option>
              {Rows}
            </select>
          </p>
          <div>
            <br />
            <p>
              This is page {Page} of {PageCount}.
            </p>
            <p>
              Jump to page:
              <select
                className="form-control width-10"
                value={Page}
                onChange={(e) => setPage(e.target.value)}
              >
                {Pages()}
              </select>
            </p>
          </div>
        </div>
        <div ref={iFrame} width="90%" height="450"></div>
      </div>
      <div className="card-footer"></div>
    </div>
  );
};

export default Emails;
