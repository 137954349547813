import React,{useState,useEffect} from 'react';
import {apiGet} from '../../../shared/api.js';
import moment from 'moment';
import {apiPost} from '../../../shared/api.js';


function Owner (props) {

    const [MemberName, setMemberName] = useState('');
    const [HasExpired, setHasExpired] = useState(false);


    const doRenew = async() => {

        const ERROR_MSG = 'Error renewing membership';

        try {

            const req = await apiPost('/members/renew', {Id: props.UserId});
            if(req && req.ok && req.status === 200) {
                setHasExpired(false);
                window.alert('Renewal successful');
            } else {
                window.alert(ERROR_MSG);
            }

        }
        catch (error) {
            console.log(error.message);
            window.alert(ERROR_MSG);
        }
    }



    useEffect(() => {

        const LoadData = async() => {

            const ERROR_MSG = 'Error retrieving member details';

            try {

                const req = await apiGet(`/members/account/${props.UserId}`);
                if(req && req.ok && req.status === 200) {
                    const res = await req.json();
                    setMemberName(`${res.FirstName} ${res.LastName}`);
                    const dt = res.DateExpires;
                    if(moment(dt) < moment())
                        setHasExpired(true);
                } else {
                    window.alert(ERROR_MSG);
                }
            }
            catch (error) {
                console.log(error.message);
                window.alert(ERROR_MSG);
            }
            
        }

        LoadData();

    },[props.UserId]);

    return (
        <h4 className="mb-1x">
            You're viewing <b>{MemberName}</b> ({props.UserId})
            {
            HasExpired ? 
                <>
                    <span className="ml-1x mr-1x text-red text-bold">Expired</span>
                    <button className="button button-small" onClick={doRenew}>Renew</button>
                </>
                :
                <></>
            }
        </h4>
    )
}

export default Owner;