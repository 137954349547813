import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { withRouter } from "react-router-dom";
import UserContext from "../../../context/user/UserContext";
import { apiGet, apiPost } from "../../../shared/api.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../shared/ModalContent";
import AsyncSelect from "react-select/async";
import LoadingIndicator from "../../../shared/LoadingIndication";

const EntryPreferences = (props) => {
  const [RunningOrders, setRunningOrders] = useState(false);
  const [Camping, setCamping] = useState(true);
  const [Helping, setHelping] = useState(true);
  const [DogId, setDogId] = useState("");
  const [DogList, setDogList] = useState([]);
  const [RegularPerformance, setRegularPerformance] = useState("");
  const [RegularSteeplechase, setRegularSteeplechase] = useState("");
  const [RegularCasual, setRegularCasual] = useState("");
  const [RegularNursery, setRegularNursery] = useState("");
  const [SelectPerformance, setSelectPerformance] = useState("");
  const [SelectSteeplechase, setSelectSteeplechase] = useState("");
  const [PreselectProgression, setPreselectProgression] = useState(false);
  const [PreselectCasual, setPreselectCasual] = useState(false);
  const [PreselectNursery, setPreselectNursery] = useState(false);
  const [RegularHeightsList, setRegularHeightsList] = useState([]);
  const [SelectHeightsList, setSelectHeightsList] = useState([]);
  const [HeightList, setHeightList] = useState([]);
  const [ShowSavedModal, setShowSavedModal] = useState(false);
  const [DefaultHandler, setDefaultHandler] = useState();

  const { User } = useContext(UserContext);
  const BackToHere = useRef(null);

  useEffect(() => {
    const LoadData = async () => {
      try {
        let req, res;

        req = await apiGet(`/dogs/list/${User.Id}`);
        res = await req.json();
        setDogList(res);

        req = await apiGet("/admin/shows/heights");
        res = await req.json();
        setHeightList(res);

        req = await apiGet(`/members/account/${User.Id}`);
        res = await req.json();
        setRunningOrders(res.PostRunningOrders);
        setCamping(res.ShowCamping ? "1" : "0");
        setHelping(res.ShowHelping ? "1" : "0");
      } catch (error) {
        console.log(error.message);
        window.alert("Error loading initial data");
      }
    };

    LoadData();
  }, [User]);

  const LoadDataForDog = useCallback(async () => {
    try {
      const req = await apiGet(`/dogs/${DogId}`);
      const res = await req.json();
      setRegularPerformance(res.RegularPerformanceHeight ?? "");
      setRegularSteeplechase(res.RegularSteeplechaseHeight ?? "");
      setRegularCasual(res.RegularCasualHeight ?? "");
      setRegularNursery(res.RegularNurseryHeight ?? "");
      setSelectPerformance(res.SelectPerformanceHeight ?? "");
      setSelectSteeplechase(res.SelectSteeplechaseHeight ?? "");
      setPreselectProgression(res.PreselectProgression);
      setPreselectCasual(res.PreselectCasual);
      setPreselectNursery(res.PreselectNursery);
      setDefaultHandler({
        value: res.DefaultHandlerId,
        label: `${res.DefaultHandlerId} - ${res.FirstName} ${res.LastName}`,
      });

      //Available heights for this dog
      setRegularHeightsList(
        HeightList.filter(
          (h) => h.IsRegular && h.SortOrder >= res.LowestRegularHeight
        )
      );
      setSelectHeightsList(
        HeightList.filter(
          (h) => h.IsSelect && h.SortOrder === res.LowestSelectHeight
        )
      );
    } catch (error) {
      console.log(error.message);
      window.alert("Error loading dog preferences");
    }
  }, [HeightList, DogId]);

  //Get dog details
  useEffect(() => {
    if (DogId !== "") LoadDataForDog();
  }, [DogId, LoadDataForDog]);

  useEffect(() => {
    if (ShowSavedModal) {
      setTimeout(() => {
        setShowSavedModal(false);
      }, 2000);
    }
  }, [ShowSavedModal]);

  //Gets suggested handler names from the server
  const GetHandlerSuggestions = async (val) => {
    try {
      const req = await apiGet(`/members/match/${val}`);
      if (req && req.ok && req.status === 200) {
        const res = await req.json();
        return res
          .filter((r) => r.Status === 1)
          .map((itm) => {
            const displayString = `${itm.Id} - ${itm.FirstName} ${itm.LastName}`;
            return { value: itm.Id, label: displayString };
          });
      } else {
        return [];
      }
    } catch (error) {
      console.log(error.message);
      return [];
    }
  };

  //Save preferences for selected member
  const SaveMember = async () => {
    try {
      const obj = {
        Id: User.Id,
        PostRunningOrders: RunningOrders,
        ShowCamping: Camping,
        ShowHelping: Helping,
      };
      const req = await apiPost("/members/setshowpreferences", obj);
      if (req && req.ok) {
        setShowSavedModal(true);
      } else {
        window.alert("Error saving preferences");
      }
    } catch (error) {
      console.log(error.message);
      window.alert("Error saving preferences");
    }
  };

  //Save preferences for selected dog
  const SaveDog = async () => {
    try {
      const defaultHandlerId = DefaultHandler ? DefaultHandler.value : User.Id;

      const obj = {
        Id: DogId,
        RegularPerformance,
        RegularSteeplechase,
        RegularCasual,
        RegularNursery,
        SelectPerformance,
        SelectSteeplechase,
        PreselectProgression,
        PreselectCasual,
        PreselectNursery,
        DefaultHandlerId: defaultHandlerId,
      };
      const req = await apiPost("/dogs/setshowpreferences", obj);
      if (req && req.ok) {
        setShowSavedModal(true);
        setDogId("");
        const posY = BackToHere.current.offsetTop;
        const posX = BackToHere.current.offsetLeft;
        window.scrollTo(posX, posY);
      } else {
        window.alert("Error saving preferences");
      }
    } catch (error) {
      console.log(error.message);
      window.alert("Error saving preferences");
    }
  };

  useEffect(() => {
    if (PreselectProgression) {
      setPreselectCasual(false);
      setPreselectNursery(false);
    }
  }, [PreselectProgression]);

  useEffect(() => {
    if (PreselectCasual) {
      setPreselectProgression(false);
      setPreselectNursery(false);
    }
  }, [PreselectCasual]);

  useEffect(() => {
    if (PreselectNursery) {
      setPreselectProgression(false);
      setPreselectCasual(false);
    }
  }, [PreselectNursery]);

  const RegularHeights = RegularHeightsList.map((itm) => (
    <option key={itm.HeightId} value={itm.HeightId}>
      {itm.HeightLabel}
    </option>
  ));
  const SelectHeights = SelectHeightsList.map((itm) => (
    <option key={itm.HeightId} value={itm.HeightId}>
      {itm.HeightLabel}
    </option>
  ));

  return (
    <>
      <div className="card mb-5x">
        <div className="card-header">My entry preferences</div>
        <div className="card-body">
          {ShowSavedModal ? (
            <Modal>
              <h2 className="text-green">
                <FontAwesomeIcon icon={faCheckCircle} className="mr-2x" />
                Choices saved
              </h2>
            </Modal>
          ) : (
            <></>
          )}

          <p className="mt-2x mb-2x text-red">
            <b>
              Changes you make on this screen will not affect entries you have
              already made.
            </b>
          </p>

          <h2 className="mt-2x mb-2x">Preferences for me</h2>
          <p>
            Please select one option in the drop down menu for your postage,
            camping and helping options. Then click Save my preferences.
          </p>
          <div className="grid mb-1x">
            <div className="col-4">
              <div className="form-group">
                <label>Always post my running orders</label>
                <select
                  value={RunningOrders}
                  onChange={(e) => setRunningOrders(e.target.value)}
                  className="form-control width-75"
                >
                  <option value="1">Yes</option>
                  <option value="2">No</option>
                  <option value="0">Offer me the option at each show</option>
                </select>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>Show me camping options at each show</label>
                <select
                  value={Camping}
                  onChange={(e) => setCamping(e.target.value)}
                  className="form-control width-50"
                >
                  <option value="1">Always</option>
                  <option value="0">Never</option>
                </select>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>Show me helping options at each show</label>
                <select
                  value={Helping}
                  onChange={(e) => setHelping(e.target.value)}
                  className="form-control width-50"
                >
                  <option value="1">Always</option>
                  <option value="0">Never</option>
                </select>
              </div>
            </div>
          </div>
          <p className="mt-2x mb-5x">
            <button className="button button-green" onClick={SaveMember}>
              Save my preferences
            </button>
          </p>

          <h2 className="mt-2x mb-2x" ref={BackToHere}>
            Preferences for my dogs
          </h2>
          <p>
            Please select a dog in the drop down box. In the heights section,
            please select the height that you wish to run that dog in each type
            of class. If you do not wish to run the dog in a certain type of
            class then please select the "I won't be running in" option. In the
            preselect classes section you can choose whether to preselect all
            classes of a certain type for each show. (You can remove classes
            when you enter a show if you do not wish to do them). You can only
            select one of these options per dog. Once you have completed this
            page for your dog click save dog preferences. You can either then
            return to the top of the page to select another dog or click return
            to shows.
          </p>
          <div className="form-group">
            <label>Select a dog</label>
            <select
              value={DogId}
              onChange={(e) => setDogId(e.target.value)}
              className="form-control width-25"
            >
              <option value="">Select...</option>
              {DogList.map((itm) => (
                <option key={itm.Id} value={itm.Id}>
                  {itm.PetName}
                </option>
              ))}
            </select>
          </div>

          {DogId === "" ? (
            <p>Waiting for dog selection...</p>
          ) : (
            <>
              <table className="table mb-5x">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Regular heights</th>
                    <th>Select heights</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>Performance</b>
                    </td>
                    <td>
                      <select
                        className="form-control width-50"
                        value={RegularPerformance}
                        onChange={(e) => setRegularPerformance(e.target.value)}
                      >
                        <option value="">Select...</option>
                        <option value="0">
                          I won't be running regular heights
                        </option>
                        {RegularHeights}
                      </select>
                    </td>
                    <td>
                      <select
                        className="form-control width-50"
                        value={SelectPerformance}
                        onChange={(e) => setSelectPerformance(e.target.value)}
                      >
                        <option value="">Select...</option>
                        <option value="0">
                          I won't be running select heights
                        </option>
                        {SelectHeights}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Steeplechase</b>
                    </td>
                    <td>
                      <select
                        className="form-control width-50"
                        value={RegularSteeplechase}
                        onChange={(e) => setRegularSteeplechase(e.target.value)}
                      >
                        <option value="">Select...</option>
                        <option value="0">
                          I won't be running regular heights
                        </option>
                        {RegularHeights}
                      </select>
                    </td>
                    <td>
                      <select
                        className="form-control width-50"
                        value={SelectSteeplechase}
                        onChange={(e) => setSelectSteeplechase(e.target.value)}
                      >
                        <option value="">Select...</option>
                        <option value="0">
                          I won't be running select heights
                        </option>
                        {SelectHeights}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Nursery</b>
                    </td>
                    <td>
                      <select
                        className="form-control width-50"
                        value={RegularNursery}
                        onChange={(e) => setRegularNursery(e.target.value)}
                      >
                        <option value="">Select...</option>
                        <option value="0">I won't be running in Nursery</option>
                        {HeightList.filter(
                          (h) => h.IsRegular && h.HeightId !== 4
                        ).map((h) => (
                          <option key={h.HeightId} value={h.HeightId}>
                            {h.HeightLabel}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <b>Casual</b>
                    </td>
                    <td>
                      <select
                        className="form-control width-50"
                        value={RegularCasual}
                        onChange={(e) => setRegularCasual(e.target.value)}
                      >
                        <option value="">Select...</option>
                        <option value="0">I won't be running in Casual</option>
                        <option value="9">200</option>
                        <option value="1">300</option>
                      </select>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>

              <h3>Default handler</h3>
              <p>Start typing a name below to select a new handler.</p>
              <div className="mb-3x width-50">
                <AsyncSelect
                  components={{ LoadingIndicator }}
                  isClearable
                  className="width-75"
                  placeholder="Start typing"
                  value={DefaultHandler}
                  onChange={(e) => setDefaultHandler(e)}
                  loadOptions={GetHandlerSuggestions}
                />
              </div>

              <div className="grid mb-3x">
                <div className="col-4">
                  <p>
                    <b>Preselect Progression classes</b>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={PreselectProgression}
                      onChange={(e) =>
                        setPreselectProgression(e.target.checked)
                      }
                    />
                    <span className="slider round"></span>
                  </label>
                  <p>(Agility, Jumping, Games and Steeplechase)</p>
                </div>
                <div className="col-4">
                  <p>
                    <b>Preselect Casual classes</b>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={PreselectCasual}
                      onChange={(e) => setPreselectCasual(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="col-4">
                  <p>
                    <b>Preselect Nursery classes</b>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={PreselectNursery}
                      onChange={(e) => setPreselectNursery(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                  <p>(Nursery and Steeplechase)</p>
                </div>
              </div>
              <p className="mt-2x mb-2x">
                <button className="button button-green" onClick={SaveDog}>
                  Save dog preferences
                </button>
              </p>
            </>
          )}
        </div>
        <div className="card-footer">
          <button
            className="button button-outline"
            onClick={() => props.history.push("/shows")}
          >
            Return to shows
          </button>
        </div>
      </div>
      <div className="wtf"></div>
    </>
  );
};

export default withRouter(EntryPreferences);
