let baseUrl = "",
  site = "live";

const url = window.location.href;
if (url.indexOf("localhost") !== -1) baseUrl = "http://localhost:4000";

if (url.indexOf("192.168.1.64") !== -1) baseUrl = "http://192.168.1.64:4000";

if (url.indexOf("compete.agility4all.co.uk") !== -1) baseUrl = "https://api.agility4all.co.uk";

const getSite = () => site;

const apiGet = async (url) => {
  try {
    const token = GetAuthToken();
    const req = await fetch(`${baseUrl}${url}`, {
      method: "GET",
      headers: {
        Authorization: `Basic ${token}`,
      },
    });
    if (req.status === 403) {
      sessionStorage.removeItem("User");
      window.history.go("/");
    } else {
      return req;
    }
  } catch (error) {
    return null;
  }
};

const apiPost = async (url, bodyString) => {
  try {
    const token = GetAuthToken();
    return await fetch(`${baseUrl}${url}`, {
      method: "POST",
      headers: {
        Authorization: `Basic ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyString),
    });
  } catch (error) {
    return null;
  }
};

const apiDelete = async (url) => {
  try {
    const token = GetAuthToken();
    return await fetch(`${baseUrl}${url}`, {
      method: "DELETE",
      headers: {
        Authorization: `Basic ${token}`,
      },
    });
  } catch (error) {
    return null;
  }
};

const apiPostFile = async (url, data) => {
  try {
    const token = GetAuthToken();
    return await fetch(`${baseUrl}${url}`, {
      method: "POST",
      headers: {
        Authorization: `Basic ${token}`,
      },
      body: data,
    });
  } catch (error) {
    return null;
  }
};

const GetAuthToken = () => {
  try {
    const UserItem = sessionStorage.getItem("User");
    if (!UserItem) return "";

    const User = JSON.parse(UserItem);
    return User && User.AuthToken ? User.AuthToken : "";
  } catch (error) {
    return "";
  }
};

export { apiGet, apiPost, apiPostFile, apiDelete, GetAuthToken, getSite };
