import React from "react";
import "../styles/Modal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function ModalLoading(props) {
  const Message = props.Message ?? "Loading data, please wait a moment...";

  return (
    <div className="modal" style={{ zIndex: 1000 }}>
      <div className="modal-content">
        <h5>
          <FontAwesomeIcon icon={faSpinner} spin className="mr-2x" />
          {Message}
        </h5>
      </div>
    </div>
  );
}

export default ModalLoading;
