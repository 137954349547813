import React, {useState, useEffect} from 'react';
import '../styles/Modal.css';


function ModalContent (props) {

    const [ClassList, setClassList] = useState([]);
    let className = 'modal-content';
    if(props.View && props.View === 'Narrow')
        className = 'modal-content modal-content-narrow';
    if(props.View && props.View === 'Wide')
        className = 'modal-content modal-content-wide';

    useEffect(() => {
        const cl = ['modal'];
        if(props.className)
            for(const c of props.className.split(' '))
                cl.push(c);
        
        setClassList(cl);

    },[props]);

    return (
        <div className={ClassList.join(' ')}>
            <div className={className}>
                {props.children}
            </div>
        </div>
    );
}

export default ModalContent;