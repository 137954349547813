import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import UserContext from "../../../../context/user/UserContext";
import { apiGet } from "../../../../shared/api";
import { withRouter } from "react-router-dom";
import DayView from "./MyEntryDogsAndClasses_DayView";
import moment from "moment";
import ModalLoading from "../../../../shared/ModalLoading";
import Modal from "../../../../shared/ModalContent";
import numeral from "numeral";

const MyEntry_DogsAndClasses = (props) => {
  const [DogList, setDogList] = useState([]);
  const [HeightList, setHeightList] = useState([]);
  const [LevelList, setLevelList] = useState([]);
  const [DogId, setDogId] = useState("");
  const [DayList, setDayList] = useState([]);
  const [AllClassCombinations, setAllClassCombinations] = useState([]);
  const [LateEntry, setLateEntry] = useState(false);
  const [HasPreEntries, setHasPreEntries] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [RunData, setRunData] = useState([]);
  const [ShowPreEntries, setShowPreEntries] = useState(false);

  const { ShowId } = useParams();
  const { User } = useContext(UserContext);

  useEffect(() => {
    setDogId("");
  }, [props.ResetDogCount]);

  useEffect(() => {
    const LoadData = async () => {
      try {
        let req, res;

        setLoading(true);
        req = await apiGet(`/shows/${ShowId}`);
        res = await req.json();
        if (moment(res.EntriesClose).isBefore(moment())) setLateEntry(true);

        req = await apiGet(`/shows/classes/entries/${ShowId}/${User.Id}`);
        res = await req.json();
        setRunData(res);

        req = await apiGet(`/dogs/list/${User.Id}/${ShowId}`);
        res = await req.json();

        setDogList(
          res.filter((d) => d.Status === 1 && d.AgeInMonthsAtShow >= 16)
        );

        req = await apiGet("/admin/shows/heights");
        res = await req.json();
        setHeightList(res);

        req = await apiGet("/admin/shows/levels");
        res = await req.json();
        setLevelList(res.filter((l) => l.LevelId !== 1));

        req = await apiGet(`/admin/shows/classes/listcombinations/${ShowId}`);
        res = await req.json();
        setAllClassCombinations(res.filter((c) => c.IsEnterable));

        let dtList = new Set();
        for (const itm of res.filter((c) => c.IsEnterable)) {
          dtList.add(itm.ClassDate);
        }

        req = await apiGet("/helpers/currentdatetime");
        res = await req.json();
        const now = moment(res.CurrentDateTime);

        dtList = [...dtList].reduce((acc, itm) => {
          let today = moment(itm);
          today.hour(7);
          today.minute(15);
          return today.isAfter(now) ? acc.concat(itm) : acc;
        }, []);
        setDayList(dtList);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error.message);
        window.alert("Error loading list of dogs");
      }
    };

    window.scrollTo(0, 0);

    LoadData();
    //eslint-disable-next-line
  }, [ShowId, User.Id]);

  //When a dog is selected, see if it has pre-entry runs
  useEffect(() => {
    try {
      if (RunData.length > 0 && DogId) {
        const found = RunData.filter(
          (r) => r.DogId === parseInt(DogId) && r.IsPreEntry
        );
        setHasPreEntries(found.length > 0);
      }
    } catch (error) {
      console.log(error.message);
      setHasPreEntries(false);
    }
  }, [DogId, RunData]);

  //List of options for dogs
  const DogRows = DogList.map((itm) => (
    <option key={itm.Id} value={itm.Id}>
      {itm.Id} {itm.PetName}
    </option>
  ));

  //Day control for each day of the show
  const DayRows = DayList.map((itm, idx) => {
    return (
      <DayView
        key={`${itm.DogId}_${idx}`}
        SetDogs={(val) => props.SetDogs(val)}
        ClassDate={itm}
        DogId={DogId}
        LateEntry={LateEntry}
        Data={{
          FirstTime: props.FirstTime,
          Dogs: props.Dogs,
          UsePreferences: props.UsePreferences,
          DogList,
          HeightList,
          LevelList,
          AllClassCombinations,
        }}
      />
    );
  });

  //Table row for each run
  const PreEntryRuns = RunData.filter(
    (r) => r.IsPreEntry && r.DogId === parseInt(DogId)
  ).map((itm, idx) => {
    return (
      <tr key={idx}>
        <td>
          {itm.FirstName} {itm.LastName}
        </td>
        <td>{moment(itm.ClassDate).format("ddd Do")}</td>
        <td>
          {itm.ClassLabel}
          {itm.Sponsor ? (
            <>
              <br />
              {itm.Sponsor}
            </>
          ) : (
            <></>
          )}
        </td>
        <td>{itm.HeightLabel}</td>
        <td>{itm.LevelLabel}</td>
        <td>£{numeral(itm.RunCost).format("0.00")}</td>
      </tr>
    );
  });

  return (
    <>
      {Loading && <ModalLoading />}

      {ShowPreEntries && (
        <Modal>
          <table className="table mb-2x">
            <thead>
              <tr>
                <th>Handler</th>
                <th>Date</th>
                <th>Class</th>
                <th>Height</th>
                <th>Level</th>
                <th>Cost</th>
              </tr>
            </thead>
            <tbody>{PreEntryRuns}</tbody>
          </table>

          <p>
            <button className="button" onClick={() => setShowPreEntries(false)}>
              Close
            </button>
          </p>
        </Modal>
      )}

      <h3 className="mb-2x">Entry details</h3>

      <p className="mb-1x">
        <b>Instructions</b>
        <br />
        Select the first dog that you wish to enter from the drop-down below.
        Set your heights and select classes if different from your
        preselections.
        <br />
        Then repeat the process for each dog you wish to enter classes with.
        <br />
        If you wish to change handler, please type the first few letters of the
        handlers name in the box and then select the correct name.
      </p>
      <p className="text-red mb-3x">
        You must navigate using the Next / Previous buttons at the bottom of the
        screen and click the green 'Finish' button to complete an entry.
        <br />
        Your entry is not saved and sent until you have done this.
      </p>

      <div className="grid mb-3x">
        <div className="col-3">
          <div className="form-group">
            <label>Which dog do you wish to run?</label>
            <select
              id="SelectedDog"
              className="form-control"
              value={DogId}
              onChange={(e) => setDogId(e.target.value)}
            >
              <option value="0">Please select...</option>
              {DogRows}
            </select>
          </div>
        </div>
        <div className="col-1"></div>

        {LateEntry && HasPreEntries && (
          <div className="col-2">
            <br />
            <button className="button" onClick={() => setShowPreEntries(true)}>
              View Pre-entries
            </button>
          </div>
        )}
      </div>

      {DogId !== "" ? (
        <table>
          <tbody>{DayRows}</tbody>
        </table>
      ) : (
        <></>
      )}
    </>
  );
};

export default withRouter(MyEntry_DogsAndClasses);
