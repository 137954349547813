import React, { useState, useEffect, useCallback } from "react";
import { apiGet } from "../../shared/api";
import { useInterval } from "beautiful-react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faTrophy } from "@fortawesome/free-solid-svg-icons";
import { LeaderboardAsPdf } from "./leaderboardaspdf";
import { DownloadFile } from "../../shared/clientsidefiledownload";
import { Document, pdf } from "@react-pdf/renderer";

const ClassResult = ({
  ClassId,
  LevelId,
  HeightId,
  ShowPdfLink,
  ShowLastThree,
}) => {
  const [All, setAll] = useState([]);
  const [Runs, setRuns] = useState([]);
  const [NFC, setNFC] = useState([]);
  const [StillToRun, setStillToRun] = useState([]);
  const [LastThree, setLastThree] = useState([]);
  const [Eliminated, setEliminated] = useState([]);
  const [ClassName, setClassName] = useState("");
  const [IsHeat, setIsHeat] = useState(false);
  const [Loading, setLoading] = useState(true);
  const [FirstLoad, setFirstLoad] = useState(true);
  const [IsMasters, setIsMasters] = useState(false);
  const [IsMastersOverall, setIsMastersOverall] = useState(false);

  const RefreshData = useCallback(async () => {
    try {
      setLoading(true);

      let req, res;
      let itm = sessionStorage.getItem("ShowDate");
      const ShowId = sessionStorage.getItem("ShowId");

      if (ClassId && LevelId && HeightId) {
        req = await apiGet(
          `/admin/shows/classes/leaderboard/${ShowId}/${ClassId}/${LevelId}/${HeightId}/${itm}`
        );

        res = await req.json();

        let className = `${res.ClassLabel} ${res.HeightLabel}`;
        if (res.IsEnterable) className = `${res.LevelLabel} ${className}`;
        setClassName(className);

        //Get leaderboard
        let lb = res.LeaderboardData;
        lb = JSON.parse(lb);
        setAll(lb);
        setRuns(lb.Runs);
        setNFC(lb.Others?.filter((r) => r.NFC) ?? []);
        setEliminated(lb.Others?.filter((r) => r.Eliminated) ?? []);
        setStillToRun(lb.StillToRun ?? []);
        setLastThree(lb.LastThree ?? []);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
      setFirstLoad(false);
    }
  }, [ClassId, LevelId, HeightId]);

  useEffect(() => {
    RefreshData();
  }, [RefreshData]);

  useInterval(() => {
    RefreshData();
  }, 20000);

  useEffect(() => {
    const ClassIds = [29, 30, 31];
    setIsHeat(ClassIds.some((i) => i === parseInt(ClassId)));

    setIsMasters(parseInt(ClassId) === 36 || parseInt(ClassId) === 37);
    setIsMastersOverall(parseInt(ClassId) === 26);
  }, [ClassId]);

  const LastThreeRuns = LastThree.map((itm, idx) => {
    return (
      <tr key={itm.RunId}>
        <td>
          {itm.HandlerFirst} {itm.HandlerLast} &amp; {itm.PetName}{" "}
          {itm.Eliminated && <span className="text-red">Eliminated</span>}
          {itm.NFC && <span className="text-red">NFC Run</span>}
        </td>

        {All?.ClassDetails?.HasFaults && (
          //Show faults if the class has them and there are any
          <td data-mobilelabel="Faults">
            {!itm.Eliminated && !itm.NFC && (
              <>
                {itm.FaultList.length === 0 && "0"}
                {itm.FaultList.join(", ")}
                {itm.TimeFaults > 0 && (
                  <span style={{ marginLeft: "1rem" }}>T{itm.TimeFaults}</span>
                )}
              </>
            )}
          </td>
        )}

        {parseInt(ClassId) === 11 && (
          //Snakes and Ladders
          <td data-mobilelabel="Scores">
            {!itm.Eliminated && !itm.NFC && (
              <>
                {itm.SnakesAndLadders.Scores.map((itm) => `${itm.Id}`).join(
                  ", "
                )}{" "}
                {itm.DeductionsList.length > 0 && (
                  <span className="text-red">
                    D{itm.DeductionsList.length * -5}
                  </span>
                )}
              </>
            )}
          </td>
        )}

        {(parseInt(ClassId) === 4 ||
          parseInt(ClassId) === 5 ||
          parseInt(ClassId) === 6) && (
          //Gamblers
          <>
            <td data-mobilelabel="Obstacles">
              {!itm.Eliminated &&
                !itm.NFC &&
                itm.Gamblers.Pushes.map((itm) => itm + 1).join(", ")}
            </td>
            <td data-mobilelabel="Opening">
              {!itm.Eliminated && !itm.NFC && itm.Gamblers.OpeningPoints}
            </td>
            <td data-mobilelabel="Score/Faults">
              {!itm.Eliminated && !itm.NFC && itm.Gamblers.GamblePoints}{" "}
              {!itm.Eliminated && !itm.NFC && itm.TimeFaults !== 0 && "T"}
            </td>
          </>
        )}

        {parseInt(ClassId) === 12 && (
          //Snooker
          <>
            <td data-mobilelabel="Points">
              {!itm.Eliminated && !itm.NFC && itm.Snooker.Points.join(", ")}{" "}
              {!itm.Eliminated && !itm.NFC && itm.DeductionsList.length > 0 && (
                <span className="text-red">
                  D{itm.DeductionsList.length * -5}
                </span>
              )}
            </td>
            <td data-mobilelabel="Score">
              {!itm.Eliminated && !itm.NFC && itm.Snooker.TotalPoints}
            </td>
          </>
        )}
        {
          //Snakes and ladders
          parseInt(ClassId) === 11 && (
            <td data-mobilelabel="Score">
              {!itm.Eliminated && !itm.NFC && itm.SnakesAndLadders.TotalScore}
            </td>
          )
        }
        {
          //Gamblers
          (parseInt(ClassId) === 4 ||
            parseInt(ClassId) === 5 ||
            parseInt(ClassId) === 6) && (
            <td data-mobilelabel="Points">
              {!itm.Eliminated && !itm.NFC && itm.Gamblers.TotalPoints}{" "}
              {!itm.Eliminated && !itm.NFC && itm.DeductionsList.length > 0 && (
                <span className="text-red">
                  D{itm.DeductionsList.length * -5}
                </span>
              )}
            </td>
          )
        }
        {
          //TFO
          parseInt(ClassId) === 22 && (
            <td data-mobilelabel="Obstacles">
              {!itm.Eliminated && !itm.NFC && itm.ObstaclesCompleted}{" "}
              {!itm.Eliminated && !itm.NFC && itm.DeductionsList.length > 0 && (
                <span className="text-red">
                  D{itm.DeductionsList.length * -5}
                </span>
              )}
            </td>
          )
        }
        {!IsMasters && (
          <td data-mobilelabel="Time">
            {!itm.Eliminated &&
              !itm.NFC &&
              parseFloat(itm.TotalTime).toFixed(3)}
          </td>
        )}
      </tr>
    );
  });

  const Rows = Runs.map((itm, idx) => {
    return (
      <tr key={itm.RunId}>
        {!IsMasters && !IsMastersOverall && (
          <td
            data-mobilelabel={
              All?.Data?.Status === "Closed" ? "Award" : "Position"
            }
          >
            {All?.Data?.Status === "Closed" && !IsHeat
              ? itm.Award
              : (!IsHeat &&
                  parseInt(ClassId) !== 32 &&
                  parseInt(LevelId) !== 99 &&
                  itm.Position) ||
                (IsHeat && itm.Position < 3 && itm.Position)}
            {All?.Data?.Status === "Closed" && itm.Trophy && (
              <FontAwesomeIcon icon={faTrophy} className="ml-1x" />
            )}
          </td>
        )}
        {(IsMasters || IsMastersOverall) && (
          <td data-mobilelabel="Position">{idx < 2 ? idx + 1 : <></>}</td>
        )}
        <td data-mobilelabel="Handler">
          {itm.HandlerFirst} {itm.HandlerLast} &amp; {itm.PetName}{" "}
        </td>

        {!IsMastersOverall && All?.ClassDetails?.HasFaults && (
          //Show faults if the class has them and there are any
          <td data-mobilelabel="Faults">
            {parseInt(ClassId) !== 30 && (
              <>
                {itm.FaultList.length === 0 && "0"}
                {itm.FaultList.join(", ")}
                {itm.TimeFaults > 0 && (
                  <span style={{ marginLeft: "1rem" }}>T{itm.TimeFaults}</span>
                )}
              </>
            )}
            {parseInt(ClassId) === 30 && itm.Faults}
          </td>
        )}

        {parseInt(ClassId) === 11 && (
          //Snakes and Laddres
          <td data-mobilelabel="Scores">
            {itm.SnakesAndLadders.Scores.map((itm) => `${itm.Id}`).join(", ")}{" "}
            {itm.DeductionsList.length > 0 && (
              <span className="text-red">
                D{itm.DeductionsList.length * -5}
              </span>
            )}
          </td>
        )}

        {(parseInt(ClassId) === 4 ||
          parseInt(ClassId) === 5 ||
          parseInt(ClassId) === 6) && (
          //Gamblers
          <>
            <td data-mobilelabel="Obstacles">
              {itm.Gamblers.Pushes.map((itm) => itm + 1).join(", ")}
            </td>
            <td data-mobilelabel="Opening">{itm.Gamblers.OpeningPoints}</td>
            <td data-mobilelabel="Gamble / Faults">
              {itm.Gamblers.GamblePoints} {itm.TimeFaults !== 0 && "T"}
            </td>
          </>
        )}

        {parseInt(ClassId) === 12 && (
          //Snooker
          <>
            <td data-mobilelabel="Points">
              {itm.Snooker.Points.join(", ")}{" "}
              {itm.DeductionsList.length > 0 && (
                <span className="text-red">
                  D{itm.DeductionsList.length * -5}
                </span>
              )}
            </td>
            <td data-mobilelabel="Score">{itm.Snooker.TotalPoints}</td>
          </>
        )}
        {
          //Snakes and ladders
          parseInt(ClassId) === 11 && <td>{itm.SnakesAndLadders.TotalScore}</td>
        }
        {
          //Gamblers
          (parseInt(ClassId) === 4 ||
            parseInt(ClassId) === 5 ||
            parseInt(ClassId) === 6) && (
            <td data-mobilelabel="Points">
              {itm.Gamblers.TotalPoints}{" "}
              {itm.DeductionsList.length > 0 && (
                <span className="text-red">
                  D{itm.DeductionsList.length * -5}
                </span>
              )}
            </td>
          )
        }
        {
          //TFO
          parseInt(ClassId) === 22 && (
            <td data-mobilelabel="Obstacles">
              {itm.ObstaclesCompleted}{" "}
              {itm.DeductionsList.length > 0 && (
                <span className="text-red">
                  D{itm.DeductionsList.length * -5}
                </span>
              )}
            </td>
          )
        }
        {!IsMasters && !IsMastersOverall && (
          <td data-mobilelabel="Time">
            {parseFloat(itm.TotalTime).toFixed(3)}
          </td>
        )}
        {IsMasters && (
          <>
            <td data-mobilelabel="Run Time">
              {itm.RunTime && parseFloat(itm.RunTime).toFixed(3)}
            </td>
            <td data-mobilelabel="Total Time">
              {itm.TotalTime && parseFloat(itm.TotalTime).toFixed(3)}
            </td>
          </>
        )}
        {IsMastersOverall && (
          <>
            <td data-mobilelabel="Jumping Faults">
              {itm.JumpingRun.FaultList.join(", ")}
              {itm.JumpingRun.FaultList.length === 0 &&
                !itm.JumpingRun.Eliminated &&
                !itm.JumpingRun.NoShow &&
                "0"}
              {itm.JumpingRun.FaultList.length === 0 &&
                itm.JumpingRun.Eliminated &&
                "50"}
              {itm.JumpingRun.FaultList.length === 0 &&
                itm.JumpingRun.NoShow &&
                "1000"}
              {itm.JumpingRun.TimeFaults
                ? ` T${parseFloat(itm.JumpingRun.TimeFaults).toFixed(3)}`
                : ""}
            </td>
            <td data-mobilelabel="Jumping Time">
              {!itm.JumpingRun.Eliminated &&
                !itm.JumpingRun.NoShow &&
                parseFloat(itm.JumpingRun.RunTime).toFixed(3)}
              {(itm.JumpingRun.Eliminated || itm.JumpingRun.NoShow) &&
                itm.JumpingRun.RunTime}
            </td>
            <td data-mobilelabel="Jumping Score">
              {!itm.JumpingRun.Eliminated &&
                !itm.JumpingRun.NoShow &&
                parseFloat(itm.JumpingRun.TotalTime).toFixed(3)}
              {(itm.JumpingRun.Eliminated || itm.JumpingRun.NoShow) &&
                itm.JumpingRun.TotalTime}
            </td>
            <td data-mobilelabel="Agility Faults">
              {itm.AgilityRun.FaultList.join(", ")}
              {itm.AgilityRun.FaultList.length === 0 &&
                !itm.AgilityRun.Eliminated &&
                !itm.AgilityRun.NoShow &&
                "0"}
              {itm.AgilityRun.FaultList.length === 0 &&
                itm.AgilityRun.Eliminated &&
                "50"}
              {itm.AgilityRun.FaultList.length === 0 &&
                itm.AgilityRun.NoShow &&
                "1000"}
              {itm.AgilityRun.TimeFaults
                ? ` T${parseFloat(itm.AgilityRun.TimeFaults).toFixed(3)}`
                : ""}
            </td>
            <td data-mobilelabel="Agility Time">
              {!itm.AgilityRun.Eliminated &&
                !itm.AgilityRun.NoShow &&
                parseFloat(itm.AgilityRun.RunTime).toFixed(3)}
              {(itm.AgilityRun.Eliminated || itm.AgilityRun.NoShow) &&
                itm.AgilityRun.RunTime}
            </td>
            <td data-mobilelabel="Agility Score">
              {!itm.AgilityRun.Eliminated &&
                !itm.AgilityRun.NoShow &&
                parseFloat(itm.AgilityRun.TotalTime).toFixed(3)}
              {(itm.AgilityRun.Eliminated || itm.AgilityRun.NoShow) &&
                itm.AgilityRun.TotalTime}
            </td>
            <td data-mobilelabel="Total Score">
              {parseFloat(itm.TotalTime).toFixed(3)}
            </td>
          </>
        )}
        {All?.Data?.Status === "Closed" && !IsHeat && (
          <td data-mobilelabel="Prog Pts">{itm.ProgressionPoints}</td>
        )}
      </tr>
    );
  });

  const doPdf = async () => {
    try {
      const result = await LeaderboardAsPdf(All);
      const doc = <Document>{result.Page}</Document>;
      const blob = await pdf(doc).toBlob();
      DownloadFile(blob, result.Filename);
    } catch (error) {
      window.alert(error.message);
    }
  };

  return (
    <div className="mt-3x">
      {Loading && FirstLoad ? (
        <h3>Checking for results</h3>
      ) : (
        <>
          <h3>{ClassName}</h3>

          {Runs.length === 0 && NFC.length === 0 && Eliminated.length === 0 ? (
            <>
              <p className="mb-2x">
                {All?.Data?.Status === "Closed"
                  ? "There are currently no results available to display for this class"
                  : "No dogs ran in this class"}
              </p>
            </>
          ) : (
            <>
              {All?.Data?.CourseLength !== "" && (
                <h4>
                  Course Details: Length:{" "}
                  {`${All?.Data?.CourseLength}m, ${All?.Data?.CourseTime}s`},
                  Judge: {All?.Data?.JudgeName}
                </h4>
              )}
              {ShowPdfLink && (
                <p className="text-link cursor-pointer" onClick={doPdf}>
                  <FontAwesomeIcon icon={faFilePdf} className="mr-1x" />
                  Get Pdf
                </p>
              )}

              {!IsHeat &&
                ShowLastThree &&
                LastThree.length > 0 &&
                All?.Data?.Status !== "Closed" && (
                  <>
                    <h3 className="mt-1x" style={{ marginBottom: 0 }}>
                      Last three runs
                    </h3>
                    <table className="table mb-2x">
                      <thead>
                        <tr>
                          <th>Handler</th>
                          {All?.ClassDetails?.HasFaults && <th>Faults</th>}
                          {
                            //TFO
                            parseInt(ClassId) === 11 && <th>Obstacles</th>
                          }
                          {
                            //Gamblers
                            (parseInt(ClassId) === 4 ||
                              parseInt(ClassId) === 5 ||
                              parseInt(ClassId) === 6) && (
                              <>
                                <th>Obstacles</th>
                                <th>Opening Points</th>
                                <th>Gamble Points</th>
                              </>
                            )
                          }
                          {
                            //Snooker
                            parseInt(ClassId) === 12 && (
                              <>
                                <th>Points</th>
                                <th>Score</th>
                              </>
                            )
                          }
                          {
                            //Snakes and Ladders
                            parseInt(ClassId) === 11 && <th>Score</th>
                          }
                          {
                            //Gamblers
                            (parseInt(ClassId) === 4 ||
                              parseInt(ClassId) === 5 ||
                              parseInt(ClassId) === 6) && <th>Total Points</th>
                          }
                          {
                            //TFO
                            parseInt(ClassId) === 22 && <th>Obstacles</th>
                          }
                          <th>Time</th>
                        </tr>
                      </thead>
                      <tbody>{LastThreeRuns}</tbody>
                    </table>
                  </>
                )}

              <h4 className="text-red">Class status: {All?.Data?.Status}</h4>

              {Runs.length > 0 && (
                <>
                  <h3 className="mt-1x" style={{ marginBottom: 0 }}>
                    Leaderboard
                  </h3>
                  <table className="table mb-2x">
                    <thead>
                      <tr>
                        {!IsMasters &&
                          !IsMastersOverall &&
                          All?.Data?.Status === "Closed" && <th>Award</th>}
                        {!IsMasters &&
                          !IsMastersOverall &&
                          All?.Data?.Status !== "Closed" && <th>Position</th>}
                        {(IsMasters || IsMastersOverall) && <th>Position</th>}
                        <th>Handler</th>
                        {!IsMasters &&
                          !IsMastersOverall &&
                          All?.ClassDetails?.HasFaults && <th>Faults</th>}
                        {
                          //TFO
                          parseInt(ClassId) === 11 && <th>Obstacles</th>
                        }
                        {
                          //Gamblers
                          (parseInt(ClassId) === 4 ||
                            parseInt(ClassId) === 5 ||
                            parseInt(ClassId) === 6) && (
                            <>
                              <th>Obstacles</th>
                              <th>Opening Points</th>
                              <th>Gamble Points</th>
                            </>
                          )
                        }
                        {
                          //Snooker
                          parseInt(ClassId) === 12 && (
                            <>
                              <th>Points</th>
                              <th>Score</th>
                            </>
                          )
                        }
                        {
                          //Snakes and Ladders
                          parseInt(ClassId) === 11 && <th>Score</th>
                        }
                        {
                          //Gamblers
                          (parseInt(ClassId) === 4 ||
                            parseInt(ClassId) === 5 ||
                            parseInt(ClassId) === 6) && <th>Total Points</th>
                        }
                        {
                          //TFO
                          parseInt(ClassId) === 22 && <th>Obstacles</th>
                        }

                        {!IsMasters && !IsMastersOverall && <th>Time</th>}
                        {IsMasters && !IsMastersOverall && (
                          <>
                            <th>Faults</th>
                            <th>Time</th>
                            <th>Total Time</th>
                          </>
                        )}
                        {All?.Data?.Status === "Closed" &&
                          !IsHeat &&
                          !IsMastersOverall &&
                          !IsMasters && <th>Prog Pts</th>}
                        {IsMastersOverall && (
                          <>
                            <th>
                              Jumping
                              <br />
                              Faults
                            </th>
                            <th>
                              Jumping
                              <br />
                              Time
                            </th>
                            <th>
                              Jumping
                              <br />
                              Score
                            </th>
                            <th>
                              Agility
                              <br />
                              Faults
                            </th>
                            <th>
                              Agillity
                              <br />
                              Time
                            </th>
                            <th>
                              Agility
                              <br />
                              Score
                            </th>
                            <th>
                              Total
                              <br />
                              Score
                            </th>
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody>{Rows}</tbody>
                  </table>
                </>
              )}
              {Eliminated.length > 0 && (
                <div className="mb-2x">
                  <p>
                    <b className="mr-1x">Eliminated:</b>
                    {Eliminated.map((itm, idx) => {
                      return (
                        <>
                          <span>
                            {itm.HandlerFirst} {itm.HandlerLast} &amp;{" "}
                            {itm.PetName}{" "}
                            {itm.FaultList.length > 0 && (
                              <span> {itm.FaultList.join(", ")}</span>
                            )}
                            {idx < Eliminated.length - 1 && ", "}
                          </span>
                        </>
                      );
                    })}
                  </p>
                </div>
              )}
              {NFC.length > 0 && (
                <div className="mb-2x">
                  <p>
                    <b className="mr-1x">NFC:</b>
                    {NFC.map(
                      (itm) =>
                        `${itm.HandlerFirst} ${itm.HandlerLast} & ${itm.PetName}`
                    ).join(", ")}
                  </p>
                </div>
              )}
              {All?.Data?.Status === "Closed" &&
                StillToRun &&
                StillToRun.length > 0 && (
                  <div className="mb-2x">
                    <p>
                      <b className="mr-1x">Did not run:</b>
                      {StillToRun.map(
                        (itm) =>
                          `${itm.HandlerFirst} ${itm.HandlerLast} & ${itm.PetName}`
                      ).join(", ")}
                    </p>
                  </div>
                )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ClassResult;
