import React, { useState, useEffect } from "react";
import moment from "moment";
import { apiDelete, apiPost } from "../../../shared/api";
import Modal from "../../../shared/ModalContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const DogHeightLine = ({ Data, IsMostRecent, onRefresh, ...props }) => {
  const [Height, setHeight] = useState(Data.Height);
  const [Type, setType] = useState("");
  const [Notes, setNotes] = useState(Data.Notes);
  const [ShowSavedModal, setShowSavedModal] = useState(false);

  useEffect(() => {
    if (Data.IsChallenge) {
      setType("C");
    } else if (Data.IsDisputed) {
      setType("D");
    } else if (Data.IsProvisional) {
      setType("P");
    } else {
      setType("F");
    }
  }, [Data]);

  useEffect(() => {
    if (ShowSavedModal)
      setTimeout(() => {
        setShowSavedModal(false);
      }, 2000);
  }, [ShowSavedModal]);

  const doRefresh = () => onRefresh();

  const DoSave = async () => {
    try {
      const obj = {
        LineId: Data.LineId,
        MeasuredHeight: Height,
        Type,
        IsMostRecent,
        Notes,
      };

      const req = await apiPost("/dogs/heights/update", obj);
      if (req && req.ok) {
        setShowSavedModal(true);
      } else {
        window.alert("Error updating measure");
      }
    } catch (error) {
      window.alert("Error udpating measure");
    }
  };

  const DoDelete = async () => {
    try {
      const req = await apiDelete(
        `/dogs/height/${Data.LineId}/${IsMostRecent}`
      );
      if (req && req.ok) {
        doRefresh();
      } else {
        window.alert("Error removing height");
      }
    } catch (error) {
      window.alert("Error removing height");
    }
  };

  return (
    <>
      <tr key={Data.LineId}>
        <td>{moment(Data.DateStamp).format("DD/MM/YYYY")} </td>
        <td>
          <input
            type="text"
            value={Height}
            onChange={(e) => setHeight(e.target.value)}
            className="form-control width-50"
          />
        </td>
        <td>
          <select
            value={Type}
            onChange={(e) => setType(e.target.value)}
            className="form-control"
          >
            <option value="C">Challenge</option>
            <option value="P">First</option>
            <option value="D">Dispute</option>
            <option value="F">Final</option>
          </select>
        </td>
        <td>{Data.MeasurerName}</td>
        <td>{Data.ShowName}</td>
        <td>
          <button className="button mr-1x" onClick={DoSave}>
            Save
          </button>
          <button className="button button-red" onClick={DoDelete}>
            Delete
          </button>

          {ShowSavedModal && (
            <Modal>
              <h3>
                <FontAwesomeIcon icon={faCheck} className="text-green mr-1x" />{" "}
                Measure Saved
              </h3>
            </Modal>
          )}
        </td>
      </tr>
      <tr>
        <td colSpan="6">
          <p>Notes</p>
          <textarea
            value={Notes}
            onChange={(e) => setNotes(e.target.value)}
            className="form-control"
            rows="4"
          />
        </td>
      </tr>
    </>
  );
};

export default DogHeightLine;
