import React,{ useContext, useRef, useEffect } from 'react';
import RegistrationContext from '../../context/registration/RegistrationContext';
import {CapitaliseName} from '../../shared/functions.js';


function RegStep2(props) {

    const {FirstName,LastName,StreetAddress,Town,Postcode,MobilePhone,OtherPhone,UpdateValue} = useContext(RegistrationContext);
    const refFirstName = useRef(null);


    //Focus on firstname box
    useEffect(() => {
        refFirstName.current.focus();
    },[]);


    const doNext = () => {

        let ok = true;
        let errors = [];

        const required = [
            {Name: 'First Name', Value: FirstName},
            {Name: 'Last Name', Value: LastName},
            {Name: 'Street Address', Value: StreetAddress},
            {Name: 'Town', Value: Town},
            {Name: 'Postcode', Value: Postcode}
        ]

        for(const field of required) {
            if(field.Value.toString().trim() === '') {
                ok = false;
                errors.push(field.Name);
            }
        }

        if(MobilePhone.toString().trim() === '' && OtherPhone.toString().trim() === '') {
            ok = false;
            errors.push('Mobile phone and/or Other Phone');
        }
        
        ok ? UpdateValue({CurrentStep: 3}) : window.alert(`Please complete the following fields:\n\n${errors.join('\n')}`);

    }



    //Sets fields to have upper case first characters etc
    const setField = (field,val) => {

        if(val !== '') {

            //Do something with a string if we have one
            switch(field) {
                case 'FirstName':
                    val = CapitaliseName(val.toString()); //val.substring(0,1).toUpperCase() + val.substring(1).toLowerCase();

                    UpdateValue({FirstName: val});
                    break;


                case 'LastName':
                    val = CapitaliseName(val.toString());

                    UpdateValue({LastName: val});
                    break;
                    

                case 'StreetAddress':
                    let saParts = val.split(' ');
                    for(let i = 0; i < saParts.length; i++)
                        saParts[i] = saParts[i].substring(0,1).toUpperCase() + saParts[i].substring(1).toLowerCase();

                    UpdateValue({StreetAddress: saParts.join(' ')});
                    break;


                case 'Town':
                    let tParts = val.split(' ');
                    for(let i = 0; i < tParts.length; i++)
                    tParts[i] = tParts[i].substring(0,1).toUpperCase() + tParts[i].substring(1).toLowerCase();

                    UpdateValue({Town: tParts.join(' ')});
                    break;


                case 'Postcode':
                    UpdateValue({Postcode: val.toString().toUpperCase()});
                    break;


                default: break;
                    
            }        
        } else {
            UpdateValue({[field]: val});
        }

    }



    return (
        <>
            <h4>Contact Details</h4>
            <p className="mb-1x">
                Please provide some basic contact details so that we may keep in touch with you.
            </p>

            <div className="form-group">
                <label className="form-label">First Name:</label>
                <input ref={refFirstName} type="text" value={FirstName} onChange={e => setField('FirstName', e.target.value)} className="form-control width-50" />
            </div>
            <div className="form-group mb-2x">
                <label className="form-label">Last Name:</label>
                <input type="text" value={LastName} onChange={e => setField('LastName', e.target.value)} className="form-control width-50" />
            </div> 

            <div className="form-group">
                <label className="form-label">Street Address:</label>
                <input type="text" value={StreetAddress} onChange={e => setField('StreetAddress', e.target.value)} className="form-control width-50" />
            </div>
            <div className="form-group">
                <label className="form-label">Town:</label>
                <input type="text" value={Town} onChange={e => setField('Town', e.target.value)} className="form-control width-50" />
            </div>
            <div className="form-group mb-2x">
                <label className="form-label">Postcode:</label>
                <input type="text" maxLength="8" value={Postcode} onChange={e => setField('Postcode', e.target.value)} className="form-control width-25" />
            </div>

            <div className="form-group">
                <label className="form-label">Mobile Phone:</label>
                <input type="text" maxLength="12" value={MobilePhone} onChange={e => UpdateValue({MobilePhone: e.target.value})} className="form-control width-25" />
            </div>
            <div className="form-group mb-3x">
                <label className="form-label">Other Phone:</label>
                <input type="text" maxLength="12" value={OtherPhone} onChange={e => UpdateValue({OtherPhone: e.target.value})} className="form-control width-25" />
            </div>       

            <div className="form-group">
                <button className="button button-outline" onClick={() => UpdateValue({CurrentStep: 1})}>Previous</button> <button className="button button-blue mr-2x" onClick={() => doNext()}>Next</button>
            </div>   
        </>
    )
}

export default RegStep2;