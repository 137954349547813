import React, { useEffect, useCallback, useState } from "react";
import { Workbook } from "exceljs";
import { DownloadFile } from "../../../../shared/clientsidefiledownload.js";
import moment from "moment";
import { apiGet } from "../../../../shared/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import InlineLoading from "../../../../shared/InlineLoading";

const ExcelLatesProcess = (props) => {
  const { Id } = useParams();

  const [ShowLoading, setShowLoading] = useState(false);

  const LoadData = useCallback(async () => {
    //try {

    setShowLoading(true);

    const req = await apiGet(`/admin/shows/processing/excellatesoutput/${Id}`);
    const res = await req.json();

    let lastMemberId, currentLine;

    let data = res[0][0];
    let wb = new Workbook();
    let ws = wb.addWorksheet("Overview");
    ws.getCell("A1").value = "Basic Info";
    ws.getCell("A3").value = "Show";
    ws.getCell("B3").value = "Prepared";
    ws.getCell("A4").value = data.DisplayName;
    ws.getCell("B4").value = moment().format("DD/MM/YYYY HH:mm:ss");

    //Class data
    data = res[1];
    ws = wb.addWorksheet("ClassData");
    ws.addRow([
      "Date",
      "Class",
      "Level",
      "Also Class",
      "Height",
      "R/O",
      "HandlerId",
      "Handler",
      "OwnerId",
      "OwnerName",
      "DogId",
      "DogName",
    ]);
    for (const row of data)
      ws.addRow([
        moment(row.ClassDate).format("DD/MM/YYYY"),
        !row.ShowClassLabel
          ? row.ClassLabel
          : `${row.LevelLabel} ${row.ClassLabel}`,
        row.ClassLabel,
        row.LevelLabel,
        row.HeightLabel,
        row.RunningOrder === 0 ? "Entered" : row.RunningOrder,
        row.HandlerId,
        `${row.HandlerFirst} ${row.HandlerLast}`,
        `${row.HandlerId !== row.Id ? row.Id : ""}`,
        `${row.HandlerId !== row.Id ? `${row.FirstName} ${row.LastName}` : ""}`,
        row.DogId,
        row.PetName,
      ]);

    let runTotal = 0;

    //Online entries
    data = res[8];
    ws = wb.addWorksheet("Online Entries");
    ws.addRow([
      "Entry made",
      "Owner",
      "OwnerId",
      "Handler",
      "HandlerId",
      "DogId",
      "Dog",
      "Date",
      "Class",
      "Height",
      "Fee",
      "RunsTotal",
    ]);

    currentLine = 0;
    runTotal = 0;
    if (data.length > 0) {
      lastMemberId = data[0].Id;
      runTotal += data[0].LateEntryCost;
    }
    for (const row of data) {
      if (row.Id !== lastMemberId) {
        ws.addRow([
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          data[currentLine - 1].LateEntryCost,
        ]);
        lastMemberId = row.Id;
        runTotal += row.LateEntryCost;
      }
      currentLine += 1;

      ws.addRow([
        moment(row.LastDate).format("DD/MM/YY HH:mm"),
        `${row.FirstName} ${row.LastName}`,
        row.Id,
        `${
          row.Id !== row.HandlerId
            ? `${row.HandlerFirst} ${row.HandlerLast}`
            : ""
        }`,
        `${row.HandlerId !== row.Id ? `${row.Id}` : ""}`,
        row.DogId,
        row.PetName,
        moment(row.ClassDate).format("DD/MM/YYYY"),
        !row.ShowClassLabel
          ? row.ClassLabel
          : `${row.LevelLabel} ${row.ClassLabel}`,
        row.HeightLabel,
        row.SingleRunCost,
      ]);
    }
    if (data.length > 0) {
      ws.addRow([
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        data[currentLine - 1].LateEntryCost,
      ]);
      ws.addRow();
      ws.addRow(["", "", "", "", "", "", "", "", "", "Totals:", "", runTotal]);
    }

    ws = wb.addWorksheet("Deleted Entries");
    ws.addRow(["There is no data for this sheet"]);

    //Fees
    data = res[2].filter((r) => r.LateEntryCost > 0);
    ws = wb.addWorksheet("Online Fees");
    ws.addRow(["A4A Number", "Name", "Entries"]);
    for (const row of data)
      ws.addRow([
        row.Id,
        `${row.FirstName} ${row.LastName}`,
        row.LateEntryCost,
      ]);
    ws.addRow([
      "",
      "Total",
      data.reduce((acc, itm) => (acc += itm.LateEntryCost), 0),
    ]);

    //Class fees
    data = res[3];
    ws = wb.addWorksheet("Class Fees");
    ws.addRow(["Date", "Class", "Fee", "Entries", "Total"]);
    for (const row of data)
      ws.addRow([
        moment(row.ClassDate).format("DD/MM/YYYY"),
        !row.ShowClassLabel
          ? row.ClassLabel
          : `${row.LevelLabel} ${row.ClassLabel}`,
        row.RunCost,
        row.Runs,
        row.Runs * row.RunCost,
      ]);
    ws.addRow([
      "",
      "Totals",
      "",
      data.reduce((acc, itm) => (acc += itm.Runs), 0),
      data.reduce((acc, itm) => (acc += itm.RunCost * itm.Runs), 0),
    ]);

    //Measure needed
    ws = wb.addWorksheet("Measure");
    data = res[6];

    ws.addRow([
      "Member Id",
      "Member Name",
      "Dog Id",
      "Dog Name",
      "Measure Needed",
    ]);
    for (const row of data) {
      const foundHeight = res[7].find(
        (r) => r.DogId === row.Id && r.HeightId !== 4 && r.HeightId !== 8
      );
      const age = row.AgeInYears > 1;
      const hasPerm = parseInt(row.Measure) === 0;
      const hasFirst = parseInt(row.Measure) === 1;
      if (
        foundHeight &&
        ((age && !hasPerm) || (!age && row.Measure === null))
      ) {
        let measureNeeded = "Unknown";
        switch (row.Measure) {
          case null:
            if (foundHeight && !hasFirst) measureNeeded = "First";
            if (foundHeight && age) measureNeeded = "Permanent";
            break;

          case false:
            measureNeeded = "";
            break;

          default:
            if (age && !hasPerm) measureNeeded = "Permanent";
            break;
        }
        if (measureNeeded !== "")
          ws.addRow([
            row.MemberId,
            `${row.FirstName} ${row.LastName}`,
            row.Id,
            row.PetName,
            measureNeeded,
          ]);
      }
    }

    //Dogs
    data = res[4];
    ws = wb.addWorksheet("Dogs");
    ws.addRow([
      "DogName",
      "DogId",
      "Breed",
      "Gender",
      "Date Of Birth",
      "Owner Name",
      "Owner Email",
      "Owner Phone Number",
      "Owner Phone Number",
      "Handlers",
    ]);
    for (const row of data)
      ws.addRow([
        row.PetName,
        row.Id,
        row.Breed,
        row.Gender,
        moment(row.DateOfBirth).format("DD/MM/YYYY"),
        `${row.FirstName} ${row.LastName}`,
        row.Email,
        row.MobilePhone,
        row.OtherPhone,
        row.Handlers,
      ]);

    //Competitors
    data = res[5];
    ws = wb.addWorksheet("Competitors");
    ws.addRow([
      "A4A Number",
      "Name",
      "Mobile",
      "Phone",
      "Email",
      "Address",
      "Town",
      "Postcode",
      "Owner / Handler",
    ]);
    for (const row of data)
      ws.addRow([
        row.Id,
        `${row.FirstName} ${row.LastName}`,
        row.MobilePhone,
        row.OtherPhone,
        row.Email,
        row.StreetAddress,
        row.Town,
        row.Postcode,
        row.Status,
      ]);

    //Set column widths
    wb.eachSheet((ws) => {
      ws.columns &&
        ws.columns.forEach((col) => {
          let dataMax = 0;
          col.eachCell({ includeEmpty: true }, (cell) => {
            const len = cell.value ? cell.value.toString().length : 0;
            if (dataMax < len) dataMax = len;
          });
          col.width = dataMax < 10 ? 10 : dataMax;
        });
    });

    const buf = await wb.xlsx.writeBuffer();
    const blob = new Blob([buf], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    DownloadFile(blob, "LateEntries_ShowProcess.xlsx");

    setShowLoading(false);

    // }
    // catch (error) {
    //     setShowLoading(false);
    //     console.log(error.message);
    //     window.alert('Error loading data');
    // }
  }, [Id]);

  useEffect(() => {
    LoadData();
  }, [LoadData]);

  return (
    <div className="card">
      <div className="card-header">
        Show Process - Excel Late Entries Output
      </div>
      <div className="card-body">
        <p className="mb-2x">
          <span className="text-link cursor-pointer" onClick={LoadData}>
            <FontAwesomeIcon icon={faFileExcel} size="2x" className="mr-1x" />
            Click here if your file does not automatically download
          </span>
        </p>
        {ShowLoading ? <InlineLoading /> : <></>}
      </div>
    </div>
  );
};

export default ExcelLatesProcess;
