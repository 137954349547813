import React,{useState, useEffect, useContext} from 'react';
import UserCard from './usercard';
import DogCard from './dogcard';
import WalletCard from './walletcard';
import DiaryCard from './diarycard';
import WarningBar from './warningbar';
import ExpiredAccount from './expiredaccount';
import ShowsBar from './showsbar/showsbar';
import UserContext from '../../../context/user/UserContext';
import {GetBalance} from '../../../shared/wallet.js';


function Home (props) {

    const [Balance, setBalance] = useState(0);
    const {User} = useContext(UserContext);


    useEffect(() => {

        const LoadData = async() => {

            try {

                //Request current balance from the API
                const wallet = await GetBalance(User.Id);
                const balance = wallet.Balance - wallet.TotalDueNow;
                setBalance(balance);
            }
            catch (error) {
                console.log(error.message);
            }

        }

        if(User.Id)
            LoadData();

    },[User.Id]);    


    return (
        <>
            {
                User.Expired ?
                <ExpiredAccount />
                :
                <></>
            }
            {
                Balance < 0 && !User.Expired ?
                <WarningBar Balance={Balance} />
                :
                <></>
            }
            <ShowsBar />
            <div className="grid spaced">
                <div className="col-3 mb-1x">
                    <UserCard />
                </div>
                <div className="col-3 mb-1x">
                    <DogCard />
                </div>
                <div className="col-3 mb-1x">
                    <WalletCard Balance={Balance} />
                </div>
                <div className="col-3 mb-1x">
                    <DiaryCard />
                </div>                                              
            </div>   
        </>
    )
}

export default Home;