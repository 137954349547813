import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useContext } from 'react';
import RegistrationContext from '../../context/registration/RegistrationContext';


function RegProgress(props) {

    const {CurrentStep} = useContext(RegistrationContext);

    return (

        <>
            <p className="text-bold">Registration progress:</p>
            <ul className="reg-progress-list">
                <li>
                    {
                        CurrentStep > 1 ? <FontAwesomeIcon icon={faCheck} className="text-green mr-1x"></FontAwesomeIcon> : <></>
                    }
                    Email address
                </li>
                <li>
                    {
                        CurrentStep > 2 ? <FontAwesomeIcon icon={faCheck} className="text-green mr-1x"></FontAwesomeIcon> : <></>
                    }                
                    Contact details
                </li>
                <li>
                    {
                        CurrentStep > 3 ? <FontAwesomeIcon icon={faCheck} className="text-green mr-1x"></FontAwesomeIcon> : <></>
                    }                
                    Junior handler
                </li>
                <li>
                    {
                        CurrentStep > 4 ? <FontAwesomeIcon icon={faCheck} className="text-green mr-1x"></FontAwesomeIcon> : <></>
                    }
                    Password
                </li>
                <li>
                    Details confirmation
                </li>
            </ul>
        </>
    )
}

export default RegProgress;