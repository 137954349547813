import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import UserContext from "../../../../context/user/UserContext";
import { apiGet } from "../../../../shared/api";
import numeral from "numeral";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const MyEntryDeclaration = (props) => {
  const [Agreed, setAgreed] = useState(false);
  const [DogList, setDogList] = useState([]);
  const [HeightList, setHeightList] = useState([]);
  const [LevelList, setLevelList] = useState([]);
  const [AllClassCombinations, setAllClassCombinations] = useState([]);
  const [PackList, setPackList] = useState([]);
  const [SingleNightRate, setSingleNightRate] = useState();
  const [IsPaper, setIsPaper] = useState(false);
  const [ExpiresBeforeShow, setExpiresBeforeShow] = useState(false);
  const [ExpiresBeforeClose, setExpiresBeforeClose] = useState(false);

  const { ShowId } = useParams();
  const { User } = useContext(UserContext);

  useEffect(() => {
    props.SetCanContinue(Agreed);
    //eslint-disable-next-line
  }, [Agreed]);

  useEffect(() => {
    const LoadData = async () => {
      try {
        let req, res;

        req = await apiGet(`/dogs/list/${User.Id}`);
        res = await req.json();
        setDogList(res.filter((d) => d.Status === 1));

        req = await apiGet("/admin/shows/heights");
        res = await req.json();
        setHeightList(res);

        req = await apiGet("/admin/shows/levels");
        res = await req.json();
        setLevelList(res);

        req = await apiGet(`/admin/shows/classes/listcombinations/${ShowId}`);
        res = await req.json();
        setAllClassCombinations(res);

        //Camping stuff
        req = await apiGet(`/shows/${ShowId}`);
        res = await req.json();
        setSingleNightRate(res.SingleNightCampingPrice);
        let _EntriesClose = moment(res.EntriesClose);
        let _StartDate = moment(res.StartDate);

        //Check to see if we expire before closing date, or before show starts
        let itm = sessionStorage.getItem("User");
        itm = JSON.parse(itm);
        if (moment(itm.DateExpires).isBefore(_EntriesClose))
          setExpiresBeforeClose(true);
        if (moment(itm.DateExpires).isBefore(_StartDate))
          setExpiresBeforeShow(true);

        //List of camping packs
        req = await apiGet(`/admin/shows/camping/packs/${ShowId}`);
        res = await req.json();
        setPackList(res);
      } catch (error) {}
    };

    window.scrollTo(0, 0);

    LoadData();

    //Flag as a paper entry
    let itm = sessionStorage.getItem("IsPaper");
    setIsPaper(itm && itm === "true" ? true : false);

    if (!props.FirstTime) props.SetCanContinue(true);
    //eslint-disable-next-line
  }, [User.Id, ShowId]);

  const HelpingRows = props.Helping.map((itm, idx) => {
    return (
      <tr key={idx}>
        <td>{moment.utc(itm.Date).format("ddd Do")}</td>
        <td>
          {itm.Availability.AM ? (
            <FontAwesomeIcon className="text-green" icon={faCheck} />
          ) : (
            <></>
          )}
        </td>
        <td>
          {itm.Availability.PM ? (
            <FontAwesomeIcon className="text-green" icon={faCheck} />
          ) : (
            <></>
          )}
        </td>
      </tr>
    );
  });

  const RunsRows = props.Dogs.map((itm, idx) => {
    if (props.LatesOpen) {
      itm.Choices = itm.Choices.filter((i) => i.IsLateEntry);
    }

    itm.Choices = itm.Choices.sort((a, b) => {
      return moment(a.ClassDate).isBefore(moment(b.ClassDate)) ? -1 : 1;
    });

    return itm.Choices.map((itm, idx) => {
      const Dog = DogList.find((d) => d.Id === itm.DogId);
      const Class = AllClassCombinations.find(
        (c) =>
          c.ClassId === itm.ClassId &&
          c.HeightId === itm.HeightId &&
          c.LevelId === itm.LevelId
      );

      const Level = LevelList.find((l) => l.LevelId === itm.LevelId);
      const Height = HeightList.find((h) => h.HeightId === itm.HeightId);

      return (
        <tr key={idx}>
          <td>{Dog?.PetName}</td>
          <td>
            {itm.Member === itm.HandlerId
              ? `${User.FirstName} ${User.LastName}`
              : `${itm.FirstName} ${itm.LastName}`}
          </td>
          <td>{moment.utc(itm.ClassDate).format("ddd Do")}</td>
          <td>
            {Class?.ClassLabel}
            {Class?.Sponsor ? (
              <>
                <br />
                {Class.Sponsor}
              </>
            ) : (
              <></>
            )}
            {(itm.Team || itm.Pair) && HeightList.length > 0 ? (
              <>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Dog</th>
                      <th>Handler</th>
                      <th>Height</th>
                      {itm.Pair ? <th>Course</th> : <></>}
                    </tr>
                  </thead>
                  <tbody>
                    {itm.Team && itm.Team.SecondDog ? (
                      <tr>
                        <td>{itm.Team.SecondDog.PetName}</td>
                        <td>{itm.Team.SecondDog.Handler.Name}</td>
                        <td>
                          {
                            HeightList.find(
                              (h) => h.HeightId === itm.Team.SecondDog.HeightId
                            ).HeightLabel
                          }
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                    {itm.Team && itm.Team.ThirdDog ? (
                      <tr>
                        <td>{itm.Team.ThirdDog.PetName}</td>
                        <td>{itm.Team.ThirdDog.Handler.Name}</td>
                        <td>
                          {
                            HeightList.find(
                              (h) => h.HeightId === itm.Team.ThirdDog.HeightId
                            ).HeightLabel
                          }
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                    {itm.Pair && itm.Pair.SecondDog ? (
                      <tr>
                        <td>{itm.Pair.SecondDog.PetName}</td>
                        <td>{itm.Pair.SecondDog.Handler.Name}</td>
                        <td>
                          {
                            HeightList.find(
                              (h) => h.HeightId === itm.Pair.SecondDog.HeightId
                            ).HeightLabel
                          }
                        </td>
                        <td>Course: {itm.Pair.SecondDog.Sector}</td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
                {itm.Pair ? (
                  <p>
                    {Dog?.PetName} is running{" "}
                    {itm.Pair.SecondDog.Sector === "Agility"
                      ? "Jumping"
                      : "Agility"}
                  </p>
                ) : (
                  <></>
                )}
                {itm.Team ? (
                  <p>
                    <b>Team Name:</b> {itm.Team.TeamName}
                  </p>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </td>
          <td>{Level?.LevelLabel}</td>
          <td>{Height?.HeightLabel}</td>
          <td>{Class ? `£${numeral(Class.RunCost).format("0.00")}` : ""}</td>
        </tr>
      );
    });
  });

  const CampingString = () => {
    if (props.Camping.CampingPackId && props.Camping.CampingPackId !== 0) {
      const Pack = PackList.find(
        (p) => p.PackId === parseInt(props.Camping.CampingPackId)
      );
      return Pack
        ? `You've chosen the '${
            Pack.Description
          }' pack, at a cost of £${numeral(Pack.Price).format("0.00")}`
        : "Unknown pack selected";
    } else if (props.Camping.Choices && props.Camping.Choices.length > 0) {
      const dateList = [];
      for (const dt of props.Camping.Choices)
        dateList.push(moment.utc(dt).format("ddd Do MMMM"));

      const cost = dateList.length * SingleNightRate;

      return `Single nights chosen: ${dateList.join(
        ", "
      )}, at a total cost of £${numeral(cost).format("0.00")}`;
    } else {
      return "No camping chosen";
    }
  };

  return (
    <>
      <h3>Confirmation of choices</h3>

      {IsPaper && (
        <div className="alert alert-error mt-2x mb-2x">
          THIS IS A PAPER ENTRY
        </div>
      )}
      {ExpiresBeforeClose && (
        <div className="alert alert-error mt-2x mb-2x">
          <b>Warning</b>
          <br />
          Please be aware that your membership expires before the show closes.
          Please ensure you have £6 available in your account to cover this, in
          addition to any show entry fees due. If there are insufficient funds,
          your entry will be deleted.
        </div>
      )}
      {ExpiresBeforeShow && !ExpiresBeforeClose && (
        <div className="alert alert-error mt-2x mb-2x">
          <b>Warning</b>
          <br />
          Please be aware that your membership expires before the date of the
          show. Please ensure you have £6 available in your account to cover
          this, in addition to any show entry fees due. If there are
          insufficient funds, your entry will be deleted.
        </div>
      )}
      {props.FirstTime && (
        <>
          <p className="mb-2x">
            Scroll down to confirm details of your entry. Please tick "I agree"
            for the declaration and click finish at the bottom to finalise your
            entry.
          </p>
          <p>
            <b>Declaration</b>
          </p>
          <p className="mb-3x">
            I agree to submit to and be bound by the Agility4All Rules &amp;
            Regulations in their present form or as they may be amended from
            time to time. I also agree to abide by the Regulations of this show
            and not bring to the Show any dog, which has contracted or been
            knowingly exposed to any infectious or contagious disease during the
            21 days prior to the event. I further declare that I believe to the
            best of my knowledge that the dogs are not liable to
            disqualification under Agility4All Show Regulations. I also accept
            that I enter at my own risk.
          </p>
          <p className="mb-4x alert alert-error">
            <input
              type="checkbox"
              value={Agreed}
              onChange={(e) => setAgreed(e.target.checked)}
            />{" "}
            I agree
          </p>
        </>
      )}
      {props.LatesOpen && (
        <p className="text-red mb-2x">
          Any payments to your A4A account or wallet balances{" "}
          <span className="text-red">
            <b>
              <u>cannot</u>
            </b>
          </span>{" "}
          be used for late entries at a show. These payments must only be made
          to the show manager at the show.
        </p>
      )}
      <table className="table">
        <thead>
          <tr>
            <th>Dog</th>
            <th>Handler</th>
            <th>Date</th>
            <th>Class</th>
            <th>Level</th>
            <th>Height</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>{RunsRows}</tbody>
      </table>
      {props.FirstTime && props.HasCamping && !props.LatesOpen && (
        <>
          <h3>Camping</h3>
          <p className="mb-3x">{CampingString()}</p>

          <h3>Helping</h3>
          <div className="mb-3x">
            {props.Helping.length === 0 ? (
              <p>You're not able to help at this show</p>
            ) : (
              <>
                <table className="table width-25">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>AM</th>
                      <th>PM</th>
                    </tr>
                  </thead>
                  <tbody>{HelpingRows}</tbody>
                </table>
                {props.Notes !== "" ? (
                  <p>
                    <b>Notes:</b>
                    <br />
                    {props.Notes}
                  </p>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>

          <h3>Postage</h3>
          {props.Postage ? (
            <p>You have opted to have running orders posted to you (£1.50)</p>
          ) : (
            <p>You have opted not to have running orders posted to you</p>
          )}
        </>
      )}
    </>
  );
};

export default MyEntryDeclaration;
