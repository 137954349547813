import React from "react";
import moment from "moment";
import numeral from "numeral";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { GetPaymentMethod } from "../../../shared/wallet";
import { apiDelete } from "../../../shared/api";

function TransactionItem(props) {
  const doDelete = async (Id) => {
    if (window.confirm("Are you sure?")) {
      const ERROR_MSG = "Error deleting item from wallet";

      try {
        const req = await apiDelete(`/admin/wallet/${Id}`);
        if (req && req.ok && req.status === 204) {
          props.onRefresh();
        } else {
          window.alert(ERROR_MSG);
        }
      } catch (error) {
        console.log(error.message);
        window.alert(ERROR_MSG);
      }
    }
  };

  return (
    <tr>
      <td data-mobilelabel="Date:">
        {moment(props.Item.DateTimeStamp).format("DD/MM/yyyy")}
      </td>
      <td data-mobilelabel="Detail:" className="spaced">
        {props.Item.Detail}
        {props.View === "Admin" && props.Item.Notes !== "" ? (
          <>
            <br />
            <i>{props.Item.Notes}</i>
          </>
        ) : (
          <></>
        )}
      </td>
      <td className="spaced">
        {props.Item.IsPaper ? "Paper" : GetPaymentMethod(props.Item)}
      </td>
      <td data-mobilelabel="Type">
        {props.Item.IsPaper ? "Paid" : ""}
        {props.Item.IsPaper
          ? ""
          : props.Item.TransactionType === "D"
          ? "-"
          : ""}
        {props.Item.IsPaper
          ? ""
          : `£${numeral(props.Item.Amount).format("0.00")}`}
      </td>
      {props.Type === "All" ? (
        <td data-mobilelabel="Balance:" className="spaced">
          £{numeral(props.RunningBalance).format("0.00")}
        </td>
      ) : (
        <></>
      )}
      {props.View === "Admin" && props.Item.IsAdmin ? (
        <td>
          <span
            className="text-red text-bold cursor-pointer"
            onClick={(e) => doDelete(props.Item.Id)}
          >
            <FontAwesomeIcon icon={faTrashAlt} className="mr-1x" />
            Delete
          </span>
        </td>
      ) : (
        <></>
      )}
    </tr>
  );
}

export default TransactionItem;
