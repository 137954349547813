import React from 'react';
import './pageheader.css';
import Logo from './logo/logo';

function PageHeader (props) {

    return (
        <>
            <Logo />
        </>
    )
}

export default PageHeader;