import React from "react";
import { Switch, Route } from "react-router-dom";
import WhichDay from "./whichday";
import SelectShow from "./selectshow";
import ClassList from "./classlist";
import ResultsView from "./resultsview";
import RingOverview from "./ringoverview/ringoverview";

const LiveResults = () => {
  return (
    <div className="container">
      <Switch>
        <Route
          exact
          path="/members/liveresults/resultsview/:ClassId/:LevelId/:HeightId"
        >
          <ResultsView />
        </Route>
        <Route exact path="/members/liveresults/classlist">
          <ClassList />
        </Route>
        <Route exact path="/members/liveresults/selectshow">
          <SelectShow />
        </Route>
        <Route path="/members/liveresults/ringoverview">
          <RingOverview />
        </Route>
        <Route exact path="/members/liveresults/whichday">
          <WhichDay />
        </Route>
        <Route exact path="/members/liveresults">
          <SelectShow />
        </Route>
      </Switch>
    </div>
  );
};

export default LiveResults;
