import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import ClassView from "./MyEntryDogsAndClasses_ClassView.jsx";
import UserContext from "../../../../context/user/UserContext.js";
import { useParams } from "react-router-dom";

const DayView = (props) => {
  const [PerformanceHeightId, setPerformanceHeightId] = useState();
  const [SteeplechaseHeightId, setSteeplechaseHeightId] = useState();
  const [NurseryHeightId, setNurseryHeightId] = useState();
  const [CasualHeightId, setCasualHeightId] = useState();
  const [PerformanceLevelId, setPerformanceLevelId] = useState();
  const [SteeplechaseLevelId, setSteeplechaseLevelId] = useState();
  const [AdminLogin, setAdminLogin] = useState(false);

  const [Dog, setDog] = useState();
  const { User } = useContext(UserContext);

  const { ShowId } = useParams();

  useEffect(() => {
    try {
      const itm = JSON.parse(sessionStorage.getItem("User"));
      setAdminLogin(itm.IsAdminLogin);
    } catch (error) {}
  }, []);

  //Find the right dog
  useEffect(() => {
    if (props.DogId !== "") {
      setDog(props.Data.DogList.find((d) => d.Id === parseInt(props.DogId)));
    } else {
      setDog();
    }
  }, [props.DogId, props.Data.DogList]);

  //Get heights and levels for the dog
  useEffect(() => {
    if (Dog) {
      //Choices for this dog
      const FoundDog = props.Data.Dogs?.find((d) => d.Id === parseInt(props.DogId));
      let _Choices = [];
      if (FoundDog)
        _Choices = FoundDog.Choices.filter((c) =>
          moment(c.ClassDate).isSame(moment(props.ClassDate))
        );

      //Heights for this dog
      let _PerformanceHeightId, _SteeplechaseHeightId, _CasualHeightId, _NurseryHeightId;
      _PerformanceHeightId = props.Data.HeightList.find(
        (h) => h.SortOrder === Dog.LowestRegularHeight
      ).HeightId;
      _SteeplechaseHeightId = props.Data.HeightList.find(
        (h) => h.SortOrder === Dog.LowestRegularHeight
      ).HeightId;

      if (props.Data.UsePreferences) {
        if (Dog.RegularPerformanceHeight)
          _PerformanceHeightId = props.Data.HeightList.find(
            (h) => h.HeightId === Dog.RegularPerformanceHeight
          ).HeightId;

        if (Dog.RegularSteeplechaseHeight)
          _SteeplechaseHeightId = props.Data.HeightList.find(
            (h) => h.HeightId === Dog.RegularSteeplechaseHeight
          ).HeightId;

        if (Dog.RegularCasualHeight)
          _CasualHeightId = props.Data.HeightList.find(
            (h) => h.HeightId === Dog.RegularCasualHeight
          ).HeightId;

        if (Dog.RegularNurseryHeight)
          _NurseryHeightId = props.Data.HeightList.find(
            (h) => h.HeightId === Dog.RegularNurseryHeight
          ).HeightId;
      }

      if (_Choices) {
        let FoundClass;

        FoundClass = _Choices.find((c) => c.SelectedClassType < 4);
        if (FoundClass) _PerformanceHeightId = FoundClass.HeightId;

        FoundClass = _Choices.find((c) => c.SelectedClassType === 4);
        if (FoundClass) _SteeplechaseHeightId = FoundClass.HeightId;

        FoundClass = _Choices.find((c) => c.SelectedClassType === 5);
        if (FoundClass) _NurseryHeightId = FoundClass.HeightId;

        FoundClass = _Choices.find((c) => c.SelectedClassType === 8);
        if (FoundClass) _CasualHeightId = FoundClass.HeightId;
      }

      setPerformanceHeightId(_PerformanceHeightId);
      setSteeplechaseHeightId(_SteeplechaseHeightId);
      setNurseryHeightId(_NurseryHeightId);
      setCasualHeightId(_CasualHeightId);

      //Levels also
      let _PerformanceLevelId, _SteeplechaseLevelId, Level;
      Level = props.Data.LevelList.find((l) => l.LevelLabel === Dog.PerformanceLevel);
      _PerformanceLevelId = Level.LevelId;
      Level = props.Data.LevelList.find((l) => l.LevelLabel === Dog.SteeplechaseLevel);
      _SteeplechaseLevelId = Level.LevelId;
      setPerformanceLevelId(_PerformanceLevelId);
      setSteeplechaseLevelId(_SteeplechaseLevelId);

      if (props.Data.FirstTime && props.Data.UsePreferences) {
        //Pre-select if needed and dog has no choices already
        let FoundDog = props.Data.Dogs?.find((d) => d.Id === Dog.Id);
        const HasChoices = FoundDog && FoundDog.Choices.length !== 0 ? true : false;

        if (!HasChoices) {
          const NewChoices = [];
          const NotCapped = props.Data.AllClassCombinations.filter(
            (c) => c.Capped !== true
          );

          for (const option of NotCapped) {
            const OnlyCasual = NewChoices.filter((c) => c.SelectedClassType !== 8);
            //const OnlyNursery = NewChoices.filter(c => c.SelectedClassType !== 4 && c.SelectedClassType !== 5);

            if (
              Dog.PreselectProgression &&
              ((option.ClassType > 0 &&
                option.ClassType < 4 &&
                option.HeightId === _PerformanceHeightId &&
                option.LevelId === _PerformanceLevelId) ||
                (option.ClassType === 4 &&
                  option.HeightId === _SteeplechaseHeightId &&
                  option.LevelId === _SteeplechaseLevelId))
            )
              if (option.ClassType < 4) {
                NewChoices.push({
                  SelectedClassType: option.ClassType,
                  ShowId: parseInt(ShowId),
                  MemberId: User.Id,
                  HandlerId: Dog.DefaultHandlerId ?? User.Id,
                  DogId: Dog.Id,
                  ClassDate: option.ClassDate,
                  ClassId: option.ClassId,
                  HeightId: _PerformanceHeightId,
                  LevelId: _PerformanceLevelId,
                  FirstName: Dog.FirstName,
                  LastName: Dog.LastName,
                  IsPreEntry: !props.LateEntry,
                  IsLateEntry: props.LateEntry,
                });
              } else {
                NewChoices.push({
                  SelectedClassType: option.ClassType,
                  ShowId: parseInt(ShowId),
                  MemberId: User.Id,
                  HandlerId: Dog.DefaultHandlerId ?? User.Id,
                  DogId: Dog.Id,
                  ClassDate: option.ClassDate,
                  ClassId: option.ClassId,
                  HeightId: _SteeplechaseHeightId,
                  LevelId: _SteeplechaseLevelId,
                  FirstName: Dog.FirstName,
                  LastName: Dog.LastName,
                  IsPreEntry: !props.LateEntry,
                  IsLateEntry: props.LateEntry,
                });
              }

            if (
              Dog.PreselectCasual &&
              OnlyCasual.length === 0 &&
              (option.ClassType === 8 || option.LevelId === 7) &&
              option.HeightId === _CasualHeightId
            )
              NewChoices.push({
                SelectedClassType: option.ClassType,
                ShowId: parseInt(ShowId),
                MemberId: User.Id,
                HandlerId: Dog.DefaultHandlerId ?? User.Id,
                DogId: Dog.Id,
                ClassDate: option.ClassDate,
                ClassId: option.ClassId,
                HeightId: _CasualHeightId,
                LevelId: option.LevelId,
                FirstName: Dog.FirstName,
                LastName: Dog.LastName,
                IsPreEntry: !props.LateEntry,
                IsLateEntry: props.LateEntry,
              });

            if (
              Dog.PreselectNursery &&
              option.HeightId === _NurseryHeightId &&
              option.ClassType === 5 &&
              option.LevelId === _PerformanceLevelId
            )
              NewChoices.push({
                SelectedClassType: option.ClassType,
                ShowId: parseInt(ShowId),
                MemberId: User.Id,
                HandlerId: Dog.DefaultHandlerId ?? User.Id,
                DogId: Dog.Id,
                ClassDate: option.ClassDate,
                ClassId: option.ClassId,
                HeightId: _NurseryHeightId,
                LevelId: _PerformanceLevelId,
                FirstName: Dog.FirstName,
                LastName: Dog.LastName,
                IsPreEntry: !props.LateEntry,
                IsLateEntry: props.LateEntry,
              });

            if (
              Dog.PreselectNursery &&
              option.HeightId === _SteeplechaseHeightId &&
              option.ClassType === 4 &&
              option.LevelId === _PerformanceLevelId
            )
              NewChoices.push({
                SelectedClassType: option.ClassType,
                ShowId: parseInt(ShowId),
                MemberId: User.Id,
                HandlerId: Dog.DefaultHandlerId ?? User.Id,
                DogId: Dog.Id,
                ClassDate: option.ClassDate,
                ClassId: option.ClassId,
                HeightId: _SteeplechaseHeightId,
                LevelId: _PerformanceLevelId,
                FirstName: Dog.FirstName,
                LastName: Dog.LastName,
                IsPreEntry: !props.LateEntry,
                IsLateEntry: props.LateEntry,
              });
          }

          if (NewChoices.length > 0) {
            if (!FoundDog) {
              FoundDog = {
                Id: parseInt(Dog.Id),
                Choices: NewChoices,
              };
            } else {
              FoundDog.Choices = NewChoices;
            }
            let NotThisDog =
              props.Data.Dogs?.filter((d) => d.Id !== parseInt(Dog.Id)) || [];
            const Final = [...NotThisDog, FoundDog];
            props.SetDogs(Final);
          }
        }
      }
    }
    //eslint-disable-next-line
  }, [Dog, ShowId, User]);

  //If a height is changed we have to remove and re-add selections into the new height
  useEffect(() => {
    if (PerformanceHeightId) {
      //Selections for this dog
      const FoundDog = props.Data.Dogs?.find((d) => d.Id === Dog.Id);
      if (FoundDog) {
        const ClassesEntered = FoundDog.Choices.filter(
          (c) => c.SelectedClassType < 4 && moment(c.ClassDate).isSame(props.ClassDate)
        );
        const NewChoices = [];
        for (const entry of ClassesEntered) {
          const match = props.Data.AllClassCombinations.find(
            (c) =>
              c.ClassId === entry.ClassId &&
              moment(c.ClassDate).isSame(entry.ClassDate) &&
              c.HeightId === parseInt(PerformanceHeightId)
          );
          if (match) {
            entry.HeightId = parseInt(PerformanceHeightId);
            NewChoices.push(entry);
          }
        }
        const OtherClasses = FoundDog.Choices.filter(
          (c) =>
            (c.SelectedClassType >= 4 && moment(c.ClassDate).isSame(props.ClassDate)) ||
            !moment(c.ClassDate).isSame(props.ClassDate)
        );
        FoundDog.Choices = [...NewChoices, ...OtherClasses];
        const NotThisDog = props.Data.Dogs.filter((d) => d.Id !== Dog.Id);
        const NewDogs = [...NotThisDog, FoundDog];
        props.SetDogs(NewDogs);
      }
    }
  }, [Dog, PerformanceHeightId]); //eslint-disable-line

  useEffect(() => {
    if (SteeplechaseHeightId) {
      //Selections for this dog
      const FoundDog = props.Data.Dogs?.find((d) => d.Id === Dog.Id);
      if (FoundDog) {
        const ClassesEntered = FoundDog.Choices.filter(
          (c) => c.SelectedClassType === 4 && moment(c.ClassDate).isSame(props.ClassDate)
        );
        const NewChoices = [];
        for (const entry of ClassesEntered) {
          const match = props.Data.AllClassCombinations.find(
            (c) =>
              c.ClassId === entry.ClassId &&
              moment(c.ClassDate).isSame(entry.ClassDate) &&
              c.HeightId === parseInt(SteeplechaseHeightId)
          );
          if (match) {
            entry.HeightId = parseInt(SteeplechaseHeightId);
            NewChoices.push(entry);
          }
        }
        const OtherClasses = FoundDog.Choices.filter(
          (c) =>
            (c.SelectedClassType !== 4 && moment(c.ClassDate).isSame(props.ClassDate)) ||
            !moment(c.ClassDate).isSame(props.ClassDate)
        );
        FoundDog.Choices = [...NewChoices, ...OtherClasses];
        const NotThisDog = props.Data.Dogs.filter((d) => d.Id !== Dog.Id);
        const NewDogs = [...NotThisDog, FoundDog];
        props.SetDogs(NewDogs);
      }
    }
  }, [Dog, SteeplechaseHeightId]); //eslint-disable-line

  useEffect(() => {
    if (NurseryHeightId) {
      //Selections for this dog
      const FoundDog = props.Data.Dogs?.find((d) => d.Id === Dog.Id);
      if (FoundDog) {
        const ClassesEntered = FoundDog.Choices.filter(
          (c) => c.SelectedClassType === 5 && moment(c.ClassDate).isSame(props.ClassDate)
        );
        const NewChoices = [];
        for (const entry of ClassesEntered) {
          const match = props.Data.AllClassCombinations.find(
            (c) =>
              c.ClassId === entry.ClassId &&
              moment(c.ClassDate).isSame(entry.ClassDate) &&
              c.HeightId === parseInt(NurseryHeightId)
          );
          if (match) {
            entry.HeightId = parseInt(NurseryHeightId);
            NewChoices.push(entry);
          }
        }
        const OtherClasses = FoundDog.Choices.filter(
          (c) =>
            (c.SelectedClassType !== 5 && moment(c.ClassDate).isSame(props.ClassDate)) ||
            !moment(c.ClassDate).isSame(props.ClassDate)
        );
        FoundDog.Choices = [...NewChoices, ...OtherClasses];
        const NotThisDog = props.Data.Dogs.filter((d) => d.Id !== Dog.Id);
        const NewDogs = [...NotThisDog, FoundDog];
        props.SetDogs(NewDogs);
      }
    }
  }, [Dog, NurseryHeightId]); //eslint-disable-line

  useEffect(() => {
    if (CasualHeightId) {
      //Selections for this dog
      const FoundDog = props.Data.Dogs?.find((d) => d.Id === Dog.Id);
      if (FoundDog) {
        const ClassesEntered = FoundDog.Choices.filter(
          (c) => c.SelectedClassType === 8 && moment(c.ClassDate).isSame(props.ClassDate)
        );
        const NewChoices = [];
        for (const entry of ClassesEntered) {
          const match = props.Data.AllClassCombinations.find(
            (c) =>
              c.ClassId === entry.ClassId &&
              moment(c.ClassDate).isSame(entry.ClassDate) &&
              c.HeightId === parseInt(CasualHeightId)
          );
          if (match) {
            entry.HeightId = parseInt(CasualHeightId);
            NewChoices.push(entry);
          }
        }
        const OtherClasses = FoundDog.Choices.filter(
          (c) =>
            (c.SelectedClassType !== 8 && moment(c.ClassDate).isSame(props.ClassDate)) ||
            !moment(c.ClassDate).isSame(props.ClassDate)
        );
        FoundDog.Choices = [...NewChoices, ...OtherClasses];
        const NotThisDog = props.Data.Dogs.filter((d) => d.Id !== Dog.Id);
        const NewDogs = [...NotThisDog, FoundDog];
        props.SetDogs(NewDogs);
      }
    }
  }, [Dog, CasualHeightId]); //eslint-disable-line

  //Get classes this dog can compete in
  const ClassRows = () => {
    const AllCombos = props.Data.AllClassCombinations.filter(
      (c) =>
        (!props.LateEntry || (props.LateEntry && c.ClassId !== 90)) &&
        moment(c.ClassDate).isSame(moment(props.ClassDate)) &&
        (((c.ClassType === 1 || c.ClassType === 2 || c.ClassType === 3) &&
          Dog?.AgeInMonthsAtShow >= 18 &&
          (c.LevelId === PerformanceLevelId || c.LevelId === 99) &&
          c.HeightId === parseInt(PerformanceHeightId)) ||
          (c.ClassType === 4 &&
            (c.LevelId === SteeplechaseLevelId || c.LevelId === 99) &&
            Dog?.AgeInMonthsAtShow >= 16 &&
            c.HeightId === parseInt(SteeplechaseHeightId)) ||
          ((c.ClassType === 8 || c.LevelId === 7) &&
            c.HeightId === parseInt(CasualHeightId) &&
            Dog?.AgeInMonthsAtShow >= 18) ||
          (c.ClassType > 5 &&
            c.ClassType < 8 &&
            c.ClassId !== 90 &&
            Dog?.AgeInMonthsAtShow >= 18 &&
            c.HeightId === parseInt(PerformanceHeightId) &&
            (c.LevelId === PerformanceLevelId || c.LevelId === 99)) ||
          (c.ClassId === 90 &&
            Dog?.AgeInMonthsAtShow >= 18 &&
            (!props.LateEntry || AdminLogin) &&
            c.HeightId === parseInt(PerformanceHeightId) &&
            (c.LevelId === PerformanceLevelId || c.LevelId === 99)) ||
          (c.ClassType === 9 &&
            Dog?.AgeInMonthsAtShow >= 18 &&
            c.HeightId === parseInt(PerformanceHeightId) &&
            (c.LevelId === PerformanceLevelId || c.LevelId === 99)) ||
          (c.ClassType === 5 &&
            (c.LevelId === PerformanceLevelId || c.LevelId === 99) &&
            c.HeightId === parseInt(NurseryHeightId) &&
            Dog?.AgeInMonthsAtShow >= 16))
    );

    let Classes = [];
    if (props.LateEntry && Dog) {
      const FoundDog = props.Data.Dogs?.find((d) => d.Id === Dog.Id);
      for (const c of AllCombos) {
        const found = FoundDog?.Choices?.find(
          (x) =>
            moment(c.ClassDate).isSame(moment(x.ClassDate)) &&
            c.ClassId === x.ClassId &&
            x.IsPreEntry
        );
        if (!found) Classes.push(c);
      }
    } else {
      Classes = AllCombos;
    }

    Classes = Classes.sort((a, b) => {
      if (a.LevelId < b.LevelId) return -1;
      if (a.LevelId > b.LevelId) return 1;
      if (a.ClassId < b.ClassId) return -1;
      if (a.ClassId > b.ClassId) return 1;
      if (a.HeightId < b.HeightId) return -1;
      if (a.HeightId > b.HeightId) return 1;
      return 0;
    });

    return Classes.length === 0 ? (
      <tr>
        <td colSpan="7">
          <p className="text-bold font-2">
            There are no classes that this dog can compete in on this date
          </p>
        </td>
      </tr>
    ) : (
      Classes.map((itm) => (
        <ClassView
          key={itm.UniqueId}
          SetDogs={(val) => props.SetDogs(val)}
          Class={itm}
          DogId={props.DogId}
          LateEntry={props.LateEntry}
          Data={props.Data}
        />
      ))
    );
  };

  //List of dropdown options for height selections
  const HeightRows = (ClassType) => {
    let availableHeights = [];

    if (Dog) {
      for (const Height of props.Data.HeightList) {
        switch (ClassType) {
          case 1: //Regular
            if (
              Dog.AgeInMonthsAtShow >= 18 &&
              ((Height.IsRegular && Height.SortOrder >= Dog.LowestRegularHeight) ||
                (Height.IsSelect && Height.SortOrder >= Dog.LowestSelectHeight))
            )
              availableHeights.push(Height);
            break;

          case 2: //Steeplechase
            if (
              Dog.AgeInMonthsAtShow >= 16 &&
              ((Height.IsRegular && Height.SortOrder >= Dog.LowestRegularHeight) ||
                (Height.IsSelect && Height.SortOrder >= Dog.LowestSelectHeight))
            )
              availableHeights.push(Height);
            break;

          case 3: //Nursery
            if (Dog.AgeInMonthsAtShow >= 16 && Height.IsRegular && Height.IsNursery)
              availableHeights.push(Height);
            break;

          case 4: //Casual
            if (Dog.AgeInMonthsAtShow >= 18 && Height.IsRegular && Height.IsCasual)
              availableHeights.push(Height);
            break;

          default:
            availableHeights = [];
        }
      }
    }

    return availableHeights.map((itm) => (
      <option key={itm.HeightId} value={itm.HeightId}>
        {itm.HeightLabel}
      </option>
    ));
  };

  return (
    <>
      <tr>
        <td className="width-20">
          <h2>{moment.utc(props.ClassDate).format("ddd Do MMM")}</h2>
        </td>
        <td colSpan={7}>
          <div className="grid mb-1x">
            <div className="col-3">
              <div className="form-group">
                <label>Performance height</label>
                <select
                  className="form-control"
                  value={PerformanceHeightId}
                  onChange={(e) => setPerformanceHeightId(e.target.value)}
                >
                  <option value="0">Not running Performance classes</option>
                  {HeightRows(1)}
                </select>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Steeplechase height</label>
                <select
                  className="form-control"
                  value={SteeplechaseHeightId}
                  onChange={(e) => setSteeplechaseHeightId(e.target.value)}
                >
                  <option value="0">Not running Steeplechase classes</option>
                  {HeightRows(2)}
                </select>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Nursery height</label>
                <select
                  className="form-control"
                  value={NurseryHeightId}
                  onChange={(e) => setNurseryHeightId(e.target.value)}
                >
                  <option value="0">Not running Nursery classes</option>
                  {HeightRows(3)}
                </select>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Casual height</label>
                <select
                  className="form-control"
                  value={CasualHeightId}
                  onChange={(e) => setCasualHeightId(e.target.value)}
                >
                  <option value="0">Not running Casual classes</option>
                  {HeightRows(4)}
                </select>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <th>Class</th>
        <th>Level</th>
        <th>Height</th>
        <th>Select</th>
        <th>Price</th>
        <th>Handler</th>
        <th></th>
      </tr>
      {ClassRows()}
      <tr>
        <td colSpan="5">
          <br />
          <br />
        </td>
      </tr>
    </>
  );
};

export default DayView;
