import React from "react";
import moment from "moment";
import { Link, useParams } from "react-router-dom";

function DogLine(props) {
  const { Id } = useParams();

  const GetStatus = (status) => {
    let output = "Unknown";

    switch (status) {
      case 1:
        output = "Active";
        break;
      case 2:
        output = "Retired";
        break;
      case 3:
        output = "Rainbow Bridge";
        break;
      case 4:
        output = "Blocked";
        break;
      case 5:
        output = "Not Competing";
        break;
      default:
        output = "Unknown";
        break;
    }
    return output;
  };

  return (
    <tr className="hover">
      <td data-mobilelabel="Name:">{props.Dog.PetName}</td>
      <td data-mobilelabel="Dog Id:">{props.Dog.Id}</td>
      <td data-mobilelabel="Status:">{GetStatus(props.Dog.Status)}</td>
      <td data-mobilelabel="DoB:">
        {moment(props.Dog.DateOfBirth).format("MMM DD, YYYY")}
      </td>
      <td data-mobilelabel="Gender:">{props.Dog.Gender}</td>
      <td data-mobilelabel="Breed:">{props.Dog.Breed}</td>
      <td data-mobilelabel="Performance:">{props.Dog.PerformanceLevel}</td>
      <td data-mobilelabel="Steeplechase:">{props.Dog.SteeplechaseLevel}</td>
      <td
        data-mobilelabel="Height:"
        className={props.Dog.IsProvisional ? "text-red text-bold" : ""}
      >
        {props.Dog.Height}
      </td>
      <td data-mobilelabel="Actions">
        <Link
          className="mr-1x"
          to={`/admin/memberview/editdog/${props.Dog.Id}/${Id}`}
        >
          Edit
        </Link>
        <Link
          className="mr-1x"
          to={`/admin/memberview/editdogheights/${props.Dog.Id}/${Id}`}
        >
          Heights
        </Link>
        <Link
          className="mr-1x"
          to={`/admin/memberview/transfer/${props.Dog.Id}/${Id}`}
        >
          Transfer
        </Link>
      </td>
    </tr>
  );
}

export default DogLine;
