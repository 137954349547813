import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import { apiGet, apiPost } from "../../../shared/api.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Classes from "./classes";
import Camping from "./camping";
import CampingDates from "./campingdates";
import Documents from "./documents";
import EntrantDocuments from "./entrantdocuments";
import Managers from "./managers";
import SponsorImages from "./sponsorimages";

const ShowEdit = (props) => {
  const { Id } = useParams();
  const [DisplayName, setDisplayName] = useState("");
  const [VenueName, setVenueName] = useState("");
  const [VenueAddress, setVenueAddress] = useState("");
  const [VenuePostalCode, setVenuePostalCode] = useState("");
  const [StartDate, setStartDate] = useState();
  const [EndDate, setEndDate] = useState();
  const [EntriesOpen, setEntriesOpen] = useState();
  const [EntriesClose, setEntriesClose] = useState();
  const [MaxRuns, setMaxRuns] = useState("9999");
  const [SingleNightCampingPrice, setSingleNightCampingPrice] = useState("0");
  const [SaveDisabled, setSaveDisabled] = useState(true);
  const [ErrorMsg, setErrorMsg] = useState("Required fields missing");
  const [PreEntryPrice, setPreEntryPrice] = useState("");
  const [LateEntryPrice, setLateEntryPrice] = useState("");
  const [AtShowPrice, setAtShowPrice] = useState("");
  const [IsLive, setIsLive] = useState(0);
  const [ClassesLive, setClassesLive] = useState(0);
  const [AppShowInfo, setAppShowInfo] = useState("");
  const [IsSingleEntry, setIsSingleEntry] = useState(0);
  const [DisplayNotes, setDisplayNotes] = useState("");
  const [CampingCap, setCampingCap] = useState(0);
  const [CampingClosed, setCampingClosed] = useState(false);
  const [ROAvailable, setROAvailable] = useState(false);
  const [NoOnlineLates, setNoOnlineLates] = useState(false);
  const [DontRunDebtors, setDontRunDebtors] = useState(false);
  const [MastersPrePrice, setMastersPrePrice] = useState("");
  const [MastersLatePrice, setMastersLatePrice] = useState("");
  const [MastersAtShowPrice, setMastersAtShowPrice] = useState("");

  //Load show if not new
  useEffect(() => {
    const LoadData = async () => {
      const req = await apiGet(`/shows/${Id}`);
      if (req && req.ok) {
        const res = await req.json();
        setDisplayName(res.DisplayName);
        setVenueName(res.VenueName);
        setVenueAddress(res.VenueAddress);
        setVenuePostalCode(res.VenuePostalCode);
        setStartDate(moment(res.StartDate).toDate());
        setEndDate(moment(res.EndDate).toDate());
        setEntriesOpen(moment(res.EntriesOpen).toDate());
        setEntriesClose(moment(res.EntriesClose).toDate());
        setMaxRuns(res.MaxRuns);
        setSingleNightCampingPrice(res.SingleNightCampingPrice);
        setPreEntryPrice(res.PreEntryPrice);
        setLateEntryPrice(res.LateEntryPrice);
        setAtShowPrice(res.AtShowPrice);
        setIsLive(res.IsLive);
        setClassesLive(res.ClassesLive);
        setAppShowInfo(res.AppShowInfo);
        setIsSingleEntry(res.SingleEntryShow);
        setDisplayNotes(res.DisplayNotes);
        setCampingCap(res.CampingCap);
        setCampingClosed(res.CampingClosed);
        setROAvailable(res.ROAvailable);
        setNoOnlineLates(res.NoOnlineLates);
        setDontRunDebtors(res.DontRunDebtors);
        setMastersPrePrice(res.MastersPrePrice);
        setMastersLatePrice(res.MastersLatePrice);
        setMastersAtShowPrice(res.MastersAtShowPrice);
      }
    };

    if (Id !== "0") LoadData();
  }, [Id]);

  //Validation
  useEffect(() => {
    let ok = true;

    //Fields are present
    const FieldList = [
      DisplayName,
      VenueName,
      StartDate,
      EndDate,
      EntriesOpen,
      EntriesClose,
      PreEntryPrice,
      LateEntryPrice,
      AtShowPrice,
    ];
    for (const field of FieldList)
      if (!field || field === "") {
        ok = false;
        setErrorMsg("Required fields missing");
      }

    //Date validation
    if (moment(StartDate, "DD/MM/YYYY").isAfter(moment(EndDate, "DD/MM/YYYY"))) {
      ok = false;
      setErrorMsg("Start date after end date");
    }
    if (moment(EntriesOpen, "DD/MM/YYYY").isAfter(moment(EntriesClose, "DD/MM/YYYY"))) {
      ok = false;
      setErrorMsg("Entries open after entries close");
    }

    setSaveDisabled(!ok);
  }, [
    DisplayName,
    VenueName,
    StartDate,
    EndDate,
    EntriesOpen,
    EntriesClose,
    PreEntryPrice,
    LateEntryPrice,
    AtShowPrice,
  ]);

  //Return to list of shows
  const DoCancel = () => props.history.push("/admin/shows/list");

  //Save/update show
  const DoSave = async () => {
    try {
      let StartDt = moment(StartDate, "DD/MM/YYYY");
      let EndDt = moment(EndDate, "DD/MM/YYYY");
      let Opens = moment(EntriesOpen);
      let Closes = moment(EntriesClose);
      StartDt.hours(12);
      EndDt.hours(12);

      const obj = {
        Show: {
          ShowId: Id,
          DisplayName,
          VenueName,
          VenueAddress,
          VenuePostalCode,
          StartDate: StartDt,
          EndDate: EndDt,
          EntriesOpen: Opens,
          EntriesClose: Closes,
          SingleNightCampingPrice,
          MaxRuns,
          IsLive,
          ClassesLive,
          PreEntryPrice,
          LateEntryPrice,
          AtShowPrice,
          AppShowInfo,
          IsSingleEntry,
          DisplayNotes,
          CampingCap,
          CampingClosed,
          ROAvailable,
          NoOnlineLates,
          DontRunDebtors,
          MastersPrePrice,
          MastersLatePrice,
          MastersAtShowPrice,
        },
      };
      const req = await apiPost("/admin/shows/update", obj);
      if (req && req.ok) {
        if (Id === "0") {
          const res = await req.json();
          window.alert(
            "Show saved - scroll down to manage classes and camping.\n\nOr scroll up to assign show managers."
          );
          props.history.push(`/admin/shows/edit/${res.NewShowId}`);
        } else {
          props.history.push("/admin/shows/list");
        }
      } else {
        window.alert("Error saving show");
      }
    } catch (error) {
      console.log(error.message);
      window.alert("Error saving show");
    }
  };

  return (
    <>
      {Id && Id !== "0" && (
        <>
          <Managers ShowId={Id} />
          <br />
          <br />
        </>
      )}
      <div className="card mb-3x">
        <div className="card-header">Edit Show</div>
        <div className="card-body">
          <p className="mb-1x">
            Enter details of the show below and click 'Save Details' to update the
            database.
          </p>

          <div className="form-group">
            <label>Display Name</label>
            <input
              type="text"
              className="form-control width-75"
              value={DisplayName}
              onChange={(e) => setDisplayName(e.target.value)}
            ></input>
          </div>
          <div className="form-group">
            <label>Venue Name</label>
            <input
              type="text"
              className="form-control width-75"
              value={VenueName}
              onChange={(e) => setVenueName(e.target.value)}
            ></input>
          </div>
          <div className="form-group">
            <label>Venue Address</label>
            <input
              type="text"
              className="form-control width-75"
              value={VenueAddress}
              onChange={(e) => setVenueAddress(e.target.value)}
            ></input>
          </div>
          <div className="form-group">
            <label>Venue Postcode</label>
            <input
              type="text"
              className="form-control width-50"
              value={VenuePostalCode}
              onChange={(e) => setVenuePostalCode(e.target.value)}
            />
          </div>
          <div className="grid">
            <div className="col-3">
              <div className="form-group">
                <label>Start Date</label>
                <DatePicker
                  showMonthDropdown
                  showYearDropdown
                  className="form-control width-50"
                  dateFormat="dd/MM/yyyy"
                  selected={StartDate}
                  value={StartDate}
                  onChange={(val) => setStartDate(val)}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>End Date</label>
                <DatePicker
                  showMonthDropdown
                  showYearDropdown
                  className="form-control width-50"
                  dateFormat="dd/MM/yyyy"
                  selected={EndDate}
                  value={EndDate}
                  onChange={(val) => setEndDate(val)}
                ></DatePicker>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col-3">
              <div className="form-group">
                <label>Entries Open</label>
                <DatePicker
                  showMonthDropdown
                  showYearDropdown
                  showTimeInput
                  timeInputLabel="Time:"
                  className="form-control width-70"
                  dateFormat="dd/MM/yyyy HH:mm"
                  selected={EntriesOpen}
                  value={EntriesOpen}
                  onChange={(val) => setEntriesOpen(val)}
                ></DatePicker>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Entries Close</label>
                <DatePicker
                  showMonthDropdown
                  showYearDropdown
                  showTimeInput
                  timeInputLabel="Time:"
                  className="form-control width-70"
                  dateFormat="dd/MM/yyyy HH:mm"
                  selected={EntriesClose}
                  value={EntriesClose}
                  onChange={(val) => setEntriesClose(val)}
                ></DatePicker>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col-3">
              <div className="form-group">
                <label>Pre Entry Price</label>
                <input
                  type="number"
                  step="0.01"
                  min="0"
                  max="25"
                  className="form-control width-50"
                  value={PreEntryPrice}
                  onChange={(e) => setPreEntryPrice(e.target.value)}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Late Entry Price</label>
                <input
                  type="number"
                  step="0.01"
                  min="0"
                  max="25"
                  className="form-control width-50"
                  value={LateEntryPrice}
                  onChange={(e) => setLateEntryPrice(e.target.value)}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>At-Show Entry Price</label>
                <input
                  type="number"
                  step="0.01"
                  min="0"
                  max="25"
                  className="form-control width-50"
                  value={AtShowPrice}
                  onChange={(e) => setAtShowPrice(e.target.value)}
                />
              </div>
            </div>
          </div>
          <h4>Masters Prices</h4>
          <div className="grid">
            <div className="col-3">
              <div className="form-group">
                <label>Pre Entry Price</label>
                <input
                  type="number"
                  step="0.01"
                  min="0"
                  max="25"
                  className="form-control width-50"
                  value={MastersPrePrice}
                  onChange={(e) => setMastersPrePrice(e.target.value)}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Late Entry Price</label>
                <input
                  type="number"
                  step="0.01"
                  min="0"
                  max="25"
                  className="form-control width-50"
                  value={MastersLatePrice}
                  onChange={(e) => setMastersLatePrice(e.target.value)}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>At-Show Entry Price</label>
                <input
                  type="number"
                  step="0.01"
                  min="0"
                  max="25"
                  className="form-control width-50"
                  value={MastersAtShowPrice}
                  onChange={(e) => setMastersAtShowPrice(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col-3">
              <div className="form-group">
                <label>Max runs per day</label>
                <input
                  type="number"
                  className="form-control width-20"
                  value={MaxRuns}
                  onChange={(e) => setMaxRuns(e.target.value)}
                ></input>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group mb-2x">
                <label>Single night camping price</label>
                <input
                  type="number"
                  className="form-control width-20"
                  value={SingleNightCampingPrice}
                  onChange={(e) => setSingleNightCampingPrice(e.target.value)}
                ></input>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group mb-2x">
                <label>Camping cap</label>
                <input
                  type="number"
                  className="form-control width-20"
                  value={CampingCap}
                  onChange={(e) => setCampingCap(e.target.value)}
                ></input>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col-3">
              <div className="form-group">
                <label>Camping closed</label>
                <input
                  type="checkbox"
                  checked={CampingClosed}
                  onChange={(e) => setCampingClosed(e.target.checked)}
                ></input>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>App Show Info</label>
            <textarea
              type="text"
              className="form-control"
              value={AppShowInfo}
              onChange={(e) => setAppShowInfo(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Display Notes</label>
            <textarea
              type="text"
              className="form-control"
              value={DisplayNotes}
              onChange={(e) => setDisplayNotes(e.target.value)}
            />
          </div>
          <div className="grid">
            <div className="col-3">
              <div className="form-group">
                <label>Show is live (visible)</label>
                <input
                  type="checkbox"
                  checked={IsLive}
                  onChange={(e) => setIsLive(e.target.checked)}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Classes are live</label>
                <input
                  type="checkbox"
                  checked={ClassesLive}
                  onChange={(e) => setClassesLive(e.target.checked)}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Single-entry show</label>
                <input
                  type="checkbox"
                  checked={IsSingleEntry}
                  onChange={(e) => setIsSingleEntry(e.target.checked)}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>RO Available</label>
                <input
                  type="checkbox"
                  checked={ROAvailable}
                  onChange={(e) => setROAvailable(e.target.checked)}
                />
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col-3">
              <div className="form-group">
                <label>No online lates</label>
                <input
                  type="checkbox"
                  checked={NoOnlineLates}
                  onChange={(e) => setNoOnlineLates(e.target.checked)}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Don't run debtors</label>
                <input
                  type="checkbox"
                  checked={DontRunDebtors}
                  onChange={(e) => setDontRunDebtors(e.target.checked)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer">
          {SaveDisabled ? (
            `${ErrorMsg}`
          ) : (
            <button className="button button-green mr-1x" onClick={DoSave}>
              Save Show
            </button>
          )}
          <button className="button" onClick={DoCancel}>
            Cancel
          </button>
        </div>
      </div>

      {Id && Id !== "0" && (
        <Classes
          ShowId={Id}
          PreEntryPrice={PreEntryPrice}
          LateEntryPrice={LateEntryPrice}
          AtShowPrice={AtShowPrice}
        />
      )}

      <br />
      <br />

      {Id && Id !== "0" && (
        <>
          <Camping ShowId={Id} />
          <br />
          <br />
          <CampingDates ShowId={Id} />
          <br />
          <br />
          <Documents ShowId={Id} />
          <br />
          <br />
          <EntrantDocuments ShowId={Id} />
          <br />
          <br />
          <SponsorImages ShowId={Id} />
        </>
      )}
    </>
  );
};

export default withRouter(ShowEdit);
