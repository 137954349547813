import React from "react";
import Item from "./item";

const List = () => {
  const Items = [
    { Name: "Junior League Start Date", ParamName: "JuniorLeagueStartDate" },
    { Name: "Junior League End Date", ParamName: "JuniorLeagueEndDate" },
    { Name: "Earliest Junior DOB", ParamName: "JuniorEarliestDOB" },
  ];

  return (
    <div className="card">
      <div className="card-header">System Parameters</div>
      <div className="card-body">
        <p className="text-red mb-3x">Please enter dates in YYYYMMDD format</p>

        {Items.map((itm, idx) => (
          <Item Details={itm} Key={idx} />
        ))}
      </div>
    </div>
  );
};

export default List;
