import React, { useEffect, useCallback, useState } from "react";
import { Workbook } from "exceljs";
import { DownloadFile } from "../../../../shared/clientsidefiledownload.js";
import moment from "moment";
import { apiGet } from "../../../../shared/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import InlineLoading from "../../../../shared/InlineLoading";

const ExcelProcess = (props) => {
  const { Id } = useParams();

  const [ShowLoading, setShowLoading] = useState(false);

  const LoadData = useCallback(async () => {
    try {
      setShowLoading(true);

      const req = await apiGet(`/admin/shows/processing/exceloutput/${Id}`);
      const res = await req.json();

      let lastMemberId, rowCount, currentLine, snCampingPrice, additionalsTotal, singleEntryShow;

      let data = res[0][0];
      let wb = new Workbook();
      let ws = wb.addWorksheet("Overview");
      ws.getCell("A1").value = "Basic Info";
      ws.getCell("A3").value = "Show";
      ws.getCell("B3").value = "Prepared";
      ws.getCell("A4").value = data.DisplayName;
      ws.getCell("B4").value = moment().format("DD/MM/YYYY HH:mm:ss");
      ws.getCell("A5").value = "CODE";
      ws.getCell("B5").value = Id.toString();
      ws.getCell("A6").value = "Day";
      ws.getCell("B6").value = "Total runs";
      let DayRow = 7;
      let showDay = moment(res[7][0].StartDate);
      while (showDay.isSameOrBefore(moment(res[7][0].EndDate))) {
        ws.getCell(`A${DayRow}`).value = showDay.format("DD/MM/YYYY");
        ws.getCell(`B${DayRow}`).value = res[1].filter(
          (r) =>
            r.ClassId !== 26 &&
            r.ClassId !== 23 &&
            r.ClassId !== 24 &&
            r.ClassId !== 25 &&
            moment(r.ClassDate).isSame(showDay, "day")
        ).length;
        DayRow += 1;
        showDay = showDay.add(1, "day");
      }
      ws.getCell(`A${DayRow}`).value = "Masters";
      ws.getCell(`B${DayRow}`).value = res[1].filter((r) => r.ClassId === 26).length;
      ws.getCell(`A${DayRow + 1}`).value = "Pairs";
      ws.getCell(`B${DayRow + 1}`).value = res[1].filter((r) => r.ClassId === 23).length;
      ws.getCell(`A${DayRow + 2}`).value = "Teams";
      ws.getCell(`B${DayRow + 2}`).value = res[1].filter((r) => r.ClassId === 24 || r.ClassId === 25).length;

      snCampingPrice = data.SingleNightCampingPrice;
      singleEntryShow = data.SingleEntryShow;

      //Class data
      data = res[1];
      ws = wb.addWorksheet("ClassData");
      ws.addRow([
        "Date",
        "Class",
        "Level",
        "Also Class",
        "Height",
        "R/O",
        "HandlerId",
        "Handler",
        "OwnerId",
        "OwnerName",
        "DogId",
        "DogName",
        "Paper",
      ]);
      for (const row of data)
        ws.addRow([
          moment(row.ClassDate).format("DD/MM/YYYY"),
          !row.ShowClassLabel ? row.ClassLabel : `${row.LevelLabel} ${row.ClassLabel}`,
          row.LevelLabel,
          row.ClassLabel,
          row.HeightLabel,
          row.RunningOrder === 0 ? "Entered" : row.RunningOrder,
          row.HandlerId,
          `${row.HandlerFirst} ${row.HandlerLast}`,
          `${row.HandlerId !== row.Id ? row.Id : ""}`,
          `${row.HandlerId !== row.Id ? `${row.FirstName} ${row.LastName}` : ""}`,
          row.DogId,
          row.PetName,
          `${row.IsPaper ? "Y" : ""}`,
        ]);

      let runTotal = 0,
        campingTotal = 0,
        postageTotal = 0,
        overallTotal = 0;

      //Online entries
      data = res[12].filter((r) => !r.IsPaper);
      ws = wb.addWorksheet("Online Entries");
      ws.addRow([
        "Entry made",
        "Owner",
        "OwnerId",
        "Handler",
        "HandlerId",
        "DogId",
        "Dog",
        "Date",
        "Class",
        "Height",
        "Camping",
        "Postage",
        "Additionals",
        "Fee",
        "RunsTotal",
        "Camping Total",
        "Postage Total",
        "Additionals Total",
        "Total",
      ]);

      rowCount = 0;
      currentLine = 0;
      runTotal = 0;
      if (data.length > 0) {
        lastMemberId = data[0].Id;
        campingTotal += data[0].CampingCost;
        postageTotal += data[0].PostageCost;
        runTotal += data[0].RunCost;
        overallTotal += data[0].EntryCost;
      }
      for (const row of data) {
        //Total if member has changed
        if (row.Id !== lastMemberId) {
          ws.addRow([
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            data[currentLine - 1].CampingDetails === "0 night(s)" ? "No camping" : data[currentLine - 1].CampingDetails,
            "",
            "",
            data[currentLine - 1].CampingCost,
            "",
            data[currentLine - 1].CampingCost,
            "",
            "",
            "",
          ]);
          ws.getRow(rowCount + 1).findCell(15).value = data[currentLine - 1].RunCost;
          ws.addRow([
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            data[currentLine - 1].PostageCost !== 0 ? "Yes" : "No",
            "",
            data[currentLine - 1].PostageCost !== 0 ? data[currentLine - 1].PostageCost : 0,
            "",
            "",
            data[currentLine - 1].PostageCost !== 0 ? data[currentLine - 1].PostageCost : 0,
            "",
            "",
          ]);
          ws.addRow([
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            !singleEntryShow ? data[currentLine - 1].EntryCost : "",
            "",
            "",
            "",
            "",
            data[currentLine - 1].EntryCost,
          ]);
          lastMemberId = row.Id;
          rowCount += 3;
          campingTotal += row.CampingCost;
          postageTotal += row.PostageCost;
          runTotal += row.RunCost;
          overallTotal += row.EntryCost;
        }

        rowCount += 1;
        currentLine += 1;

        ws.addRow([
          moment(row.LastDate).format("DD/MM/YY HH:mm"),
          `${row.FirstName} ${row.LastName}`,
          row.Id,
          `${row.HandlerId && row.Id !== row.HandlerId ? `${row.HandlerFirst} ${row.HandlerLast}` : ""}`,
          `${row.HandlerId && row.HandlerId !== row.Id ? `${row.Id}` : ""}`,
          row.DogId,
          row.PetName,
          row.ClassDate && moment(row.ClassDate).format("DD/MM/YYYY"),
          !row.ShowClassLabel ? row.ClassLabel : `${row.LevelLabel} ${row.ClassLabel}`,
          row.HeightLabel,
          "",
          "",
          "",
          !singleEntryShow ? row.SingleRunCost : "",
          "",
          "",
          "",
          "",
          "",
        ]);
      }
      if (data.length > 0) {
        //Camping cost
        ws.addRow([
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          data[currentLine - 1].CampingDetails === "0 night(s)" ? "No camping" : data[currentLine - 1].CampingDetails,
          "",
          "",
          data[currentLine - 1].CampingCost,
          "",
          data[currentLine - 1].CampingCost,
          "",
          "",
          "",
        ]);
        ws.getRow(rowCount + 1).findCell(15).value = data[currentLine - 1].RunCost;

        //Postage cost
        ws.addRow([
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          data[currentLine - 1].PostageCost !== 0 ? "Yes" : "No",
          "",
          data[currentLine - 1].PostageCost !== 0 ? data[currentLine - 1].PostageCost : 0,
          "",
          "",
          data[currentLine - 1].PostageCost !== 0 ? data[currentLine - 1].PostageCost : 0,
          "",
          "",
        ]);

        //Total for this member
        ws.addRow([
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          data[currentLine - 1].EntryCost,
        ]);
        additionalsTotal = 0;
        ws.addRow();
        ws.addRow([
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "Totals:",
          "",
          runTotal,
          campingTotal,
          postageTotal,
          additionalsTotal,
          overallTotal,
        ]);
      }

      //Paper entries
      runTotal = 0;
      campingTotal = 0;
      postageTotal = 0;
      overallTotal = 0;
      ws = wb.addWorksheet("Paper Entries");
      data = res[12].filter((r) => r.IsPaper);
      ws.addRow([
        "Entry made",
        "Owner",
        "OwnerId",
        "Handler",
        "HandlerId",
        "DogId",
        "Dog",
        "Date",
        "Class",
        "Height",
        "Camping",
        "Postage",
        "Additionals",
        "Fee",
        "RunsTotal",
        "Camping Total",
        "Postage Total",
        "Additionals Total",
        "Total",
      ]);

      rowCount = 0;
      currentLine = 0;
      runTotal = 0;
      if (data.length > 0) {
        lastMemberId = data[0].Id;
        campingTotal += data[0].CampingCost;
        postageTotal += data[0].PostageCost;
        runTotal += data[0].RunCost;
        overallTotal += data[0].EntryCost;
      }
      for (const row of data) {
        //Total if member has changed
        if (row.Id !== lastMemberId) {
          ws.addRow([
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            data[currentLine - 1].CampingDetails === "0 night(s)" ? "No camping" : data[currentLine - 1].CampingDetails,
            "",
            "",
            data[currentLine - 1].CampingCost,
            "",
            data[currentLine - 1].CampingCost,
            "",
            "",
            "",
          ]);
          ws.getRow(rowCount + 1).findCell(15).value = data[currentLine - 1].RunCost;
          ws.addRow([
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            data[currentLine - 1].PostageCost !== 0 ? "Yes" : "No",
            "",
            data[currentLine - 1].PostageCost !== 0 ? data[currentLine - 1].PostageCost : 0,
            "",
            "",
            data[currentLine - 1].PostageCost !== 0 ? data[currentLine - 1].PostageCost : 0,
            "",
            "",
          ]);
          ws.addRow([
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            !singleEntryShow ? data[currentLine - 1].EntryCost : "",
            "",
            "",
            "",
            "",
            data[currentLine - 1].EntryCost,
          ]);
          lastMemberId = row.Id;
          rowCount += 3;
          campingTotal += row.CampingCost;
          postageTotal += row.PostageCost;
          runTotal += row.RunCost;
          overallTotal += row.EntryCost;
        }

        rowCount += 1;
        currentLine += 1;

        ws.addRow([
          moment(row.LastDate).format("DD/MM/YY HH:mm"),
          `${row.FirstName} ${row.LastName}`,
          row.Id,
          `${row.HandlerId && row.Id !== row.HandlerId ? `${row.HandlerFirst} ${row.HandlerLast}` : ""}`,
          `${row.HandlerId && row.HandlerId !== row.Id ? `${row.Id}` : ""}`,
          row.DogId,
          row.PetName,
          row.ClassDate && moment(row.ClassDate).format("DD/MM/YYYY"),
          !row.ShowClassLabel ? row.ClassLabel : `${row.LevelLabel} ${row.ClassLabel}`,
          row.HeightLabel,
          "",
          "",
          "",
          !singleEntryShow ? row.SingleRunCost : "",
          "",
          "",
          "",
          "",
          "",
        ]);
      }
      if (data.length > 0) {
        //Camping cost
        ws.addRow([
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          data[currentLine - 1].CampingDetails === "0 night(s)" ? "No camping" : data[currentLine - 1].CampingDetails,
          "",
          "",
          data[currentLine - 1].CampingCost,
          "",
          data[currentLine - 1].CampingCost,
          "",
          "",
          "",
        ]);
        ws.getRow(rowCount + 1).findCell(15).value = data[currentLine - 1].RunCost;

        //Postage cost
        ws.addRow([
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          data[currentLine - 1].PostageCost !== 0 ? "Yes" : "No",
          "",
          data[currentLine - 1].PostageCost !== 0 ? data[currentLine - 1].PostageCost : 0,
          "",
          "",
          data[currentLine - 1].PostageCost !== 0 ? data[currentLine - 1].PostageCost : 0,
          "",
          "",
        ]);

        //Total for this member
        ws.addRow([
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          data[currentLine - 1].EntryCost,
        ]);
        additionalsTotal = 0;
        ws.addRow();
        ws.addRow([
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "Totals:",
          "",
          runTotal,
          campingTotal,
          postageTotal,
          additionalsTotal,
          overallTotal,
        ]);
      }

      //Deleted entries
      ws = wb.addWorksheet("Deleted Entries");
      data = res[19];
      ws.addRow([
        "Date",
        "Class",
        "Level",
        "Also Class",
        "Height",
        "R/O",
        "HandlerId",
        "Handler",
        "OwnerId",
        "OwnerName",
        "DogId",
        "DogName",
        "Paper",
      ]);
      for (const row of data)
        ws.addRow([
          moment(row.ClassDate).format("DD/MM/YYYY"),
          !row.ShowClassLabel ? row.ClassLabel : `${row.LevelLabel} ${row.ClassLabel}`,
          row.LevelLabel,
          row.ClassLabel,
          row.HeightLabel,
          row.RunningOrder === 0 ? "Entered" : row.RunningOrder,
          row.HandlerId,
          `${row.HandlerFirst} ${row.HandlerLast}`,
          `${row.HandlerId !== row.Id ? row.Id : ""}`,
          `${row.HandlerId !== row.Id ? `${row.FirstName} ${row.LastName}` : ""}`,
          row.DogId,
          row.PetName,
          `${row.IsPaper ? "Y" : ""}`,
        ]);

      runTotal = 0;
      campingTotal = 0;
      postageTotal = 0;
      overallTotal = 0;

      //Camping
      let dateRow;
      let snCampingPeriods = [];
      ws = wb.addWorksheet("Camping");
      data = res[10];
      for (const row of data)
        snCampingPeriods.push({
          Id: row.Id,
          FirstName: row.FirstName,
          LastName: row.LastName,
          Paid: row.CampingCost,
          Pack: row.Description,
          Arrives: moment(row.ArrivalDate),
          Departs: moment(row.DepartureDate),
        });

      data = res[11];
      let currentDate, dates, currRow, currentRow, IDList;
      currentDate = moment(res[15][0].FirstNight);
      dates = [];
      while (currentDate.isSameOrBefore(moment(res[15][0].LastNight))) {
        let dt = moment(currentDate);
        dates.push(dt);
        currentDate = currentDate.add(1, "days");
      }
      let snRow = ["Account", "Camper", "Pack", "Paid"];
      for (const dt of dates) snRow.push(moment(dt).format("ddd Do"));
      snRow = [...snRow, "Arrives", "Departs"];
      ws.addRow(snRow);

      currRow = [];
      IDList = new Set();
      for (const row of data) IDList.add(row.Id);

      for (const id of IDList) {
        const row = data.find((r) => r.Id === id);
        let dtArrives;
        for (const dt of dates) {
          const found = data.find((r) => moment(r.ShowDate).isSame(moment(dt)) && r.Id === id);
          if (found && !dtArrives) dtArrives = moment(dt);
          if (!found && dtArrives) {
            const dtDeparts = moment(dt);
            const nights = dtDeparts.diff(dtArrives, "days");
            snCampingPeriods.push({
              Id: row.Id,
              FirstName: row.FirstName,
              LastName: row.LastName,
              Paid: nights * snCampingPrice,
              Arrives: dtArrives,
              Departs: dtDeparts,
            });
            dtArrives = undefined;
          }
        }
        if (dtArrives) {
          const dtDeparts = moment(dates[dates.length - 1]).add(1, "days");
          const nights = dtDeparts.diff(dtArrives, "days");
          snCampingPeriods.push({
            Id: row.Id,
            FirstName: row.FirstName,
            LastName: row.LastName,
            Paid: nights * snCampingPrice,
            Arrives: dtArrives,
            Departs: dtDeparts,
          });
        }
      }

      snCampingPeriods = snCampingPeriods.sort((a, b) => {
        if (a.FirstName.toLowerCase() < b.FirstName.toLowerCase()) return -1;
        if (a.FirstName.toLowerCase() > b.FirstName.toLowerCase()) return 1;
        if (a.LastName.toLowerCase() < b.LastName.toLowerCase()) return -1;
        if (a.LastName.toLowerCase() > b.LastName.toLowerCase()) return 1;
        return 0;
      });

      currentRow = [];
      for (const period of snCampingPeriods) {
        currentRow = [period.Id, `${period.FirstName} ${period.LastName}`, period.Pack, period.Paid];
        for (const dt of dates)
          currentRow.push(moment(dt).isBetween(period.Arrives, period.Departs, undefined, "[)") ? "✔" : "");
        currentRow = [...currentRow, period.Arrives.format("ddd Do"), period.Departs.format("ddd Do")];
        ws.addRow(currentRow);
      }

      //Fees
      data = res[2].filter((r) => !r.IsPaper && r.EntryCost > 0);
      ws = wb.addWorksheet("Online Fees");
      ws.addRow(["A4A Number", "Name", "Entries", "Camping", "Postage", "Additionals", "Total", "Paid", "Owing"]);
      for (const row of data)
        ws.addRow([
          row.Id,
          `${row.FirstName} ${row.LastName}`,
          row.RunCost,
          row.CampingCost,
          row.PostageCost,
          "",
          row.EntryCost,
          row.PaidStatus === "Paid" ? row.AmountTaken : 0,
          row.PaidStatus === "Owing" ? row.AmountOwing : 0,
        ]);
      ws.addRow([
        "",
        "Total",
        data.reduce((acc, itm) => (acc += itm.RunCost), 0),
        data.reduce((acc, itm) => (acc += itm.CampingCost), 0),
        data.reduce((acc, itm) => (acc += itm.PostageCost), 0),
        "",
        data.reduce((acc, itm) => (acc += itm.EntryCost), 0),
        data.reduce((acc, itm) => (acc += itm.PaidStatus === "Paid" ? itm.AmountTaken : 0), 0),
        data.reduce((acc, itm) => (acc += itm.PaidStatus === "Owing" ? itm.AmountOwing : 0), 0),
      ]);

      data = res[2].filter((r) => r.IsPaper && r.EntryCost > 0);
      ws = wb.addWorksheet("Paper Fees");
      ws.addRow(["A4A Number", "Name", "Entries", "Camping", "Postage", "Additionals", "Total"]);
      for (const row of data)
        ws.addRow([
          row.Id,
          `${row.FirstName} ${row.LastName}`,
          row.RunCost,
          row.CampingCost,
          row.PostageCost,
          "",
          row.EntryCost,
        ]);
      ws.addRow([
        "",
        "Total",
        data.reduce((acc, itm) => (acc += itm.RunCost), 0),
        data.reduce((acc, itm) => (acc += itm.CampingCost), 0),
        data.reduce((acc, itm) => (acc += itm.PostageCost), 0),
        "",
        data.reduce((acc, itm) => (acc += itm.EntryCost), 0),
      ]);

      //Class fess
      data = res[3];
      ws = wb.addWorksheet(`Class Fees${singleEntryShow ? " NOT APPLICABLE" : ""}`);
      ws.addRow(["Date", "Class", "Fee", "Entries", "Total"]);
      for (const row of data)
        ws.addRow([
          moment(row.ClassDate).format("DD/MM/YYYY"),
          !row.ShowClassLabel ? row.ClassLabel : `${row.LevelLabel} ${row.ClassLabel}`,
          row.RunCost,
          row.Runs,
          row.Runs * row.RunCost,
        ]);
      ws.addRow([
        "",
        "Totals",
        "",
        data.reduce((acc, itm) => (acc += itm.Runs), 0),
        data.reduce((acc, itm) => (acc += itm.RunCost * itm.Runs), 0),
      ]);

      //Postage
      ws = wb.addWorksheet("Post");
      data = res[6].filter((r) => r.PostageCost > 0);
      ws.addRow(["Post required"]);
      ws.addRow(["A4A Number", "Name", "Address", "Town", "Postcode", "Dogs in show"]);
      for (const row of data)
        ws.addRow([
          row.Id,
          `${row.FirstName} ${row.LastName}`,
          row.StreetAddress,
          row.Town,
          row.Postcode,
          row.DogsInShow,
        ]);
      ws.addRow();
      ws.addRow(["No postage"]);

      data = res[6].filter((r) => r.PostageCost === 0);
      ws.addRow(["A4A Number", "Name", "Address", "Postcode", "Dogs in show"]);
      for (const row of data)
        ws.addRow([row.Id, `${row.FirstName} ${row.LastName}`, row.StreetAddress, row.Postcode, row.DogsInShow]);

      if (!singleEntryShow) {
        //Helpers
        ws = wb.addWorksheet("Helpers");
        currentDate = moment(res[7][0].StartDate);
        dates = [];
        while (currentDate.isSameOrBefore(moment(res[7][0].EndDate))) {
          let dt = moment(currentDate);
          dates.push(dt);
          currentDate = currentDate.add(1, "days");
        }
        dateRow = ["", ""];
        for (const dt of dates) {
          dateRow.push(`${moment(dt).format("ddd Do")} AM`);
          dateRow.push(`${moment(dt).format("ddd Do")} PM`);
        }
        ws.addRow(dateRow);

        data = res[8];
        let entrants = res[1];
        entrants = entrants.sort((a, b) => {
          if (a.FirstName < b.FirstName) return -1;
          if (a.FirstName > b.FirstName) return 1;
          if (a.LastName < b.LastName) return -1;
          if (a.LastName > b.LastName) return 1;
          return 0;
        });
        currRow = [];
        IDList = new Set();
        for (const row of entrants) IDList.add(row.Id);

        for (const id of Array.from(IDList)) {
          const willHelp = data.find((r) => r.Id === id && (r.AvailableAM || r.AvailablePM));
          //if (willHelp) {
          const row = entrants.find((r) => r.Id === id);
          currRow.push(`${row.FirstName} ${row.LastName}`);
          currRow.push(row.Email);
          for (const dt of dates) {
            const foundAM = data.find((r) => moment(r.ShowDate).isSame(moment(dt)) && r.Id === id && r.AvailableAM);
            currRow.push(foundAM ? "✓" : "");
            const foundPM = data.find((r) => moment(r.ShowDate).isSame(moment(dt)) && r.Id === id && r.AvailablePM);
            currRow.push(foundPM ? "✓" : "");
            currRow.push(row.HelpingNotes);
          }
          ws.addRow(currRow);
          if (!willHelp) {
            ws.getCell(`A${ws.rowCount}`).font = {
              color: { argb: "CCCCCC00" },
            };
          }
          //}

          currRow = [];
        }

        // ws.addRow([]);
        // ws.addRow([]);
        // ws.addRow(["Non-helpers"]);

        // rowCount = ws.rowCount;
        // const nonHelpers = [];
        // for (const dt of dates) {
        //   const day = { Date: moment(dt), People: [] };
        //   const atShow = res[8].filter(
        //     (r) =>
        //       moment(r.ShowDate).isSame(moment(dt)) &&
        //       !r.AvailableAM &&
        //       !r.AvailablePM &&
        //       r.AtShow
        //   );
        //   for (const row of atShow) day.People.push(`${row.FirstName} ${row.LastName}`);
        //   nonHelpers.push(day);
        // }

        // currentRow = rowCount + 2;
        // let currentCol = 1;
        // for (const obj of nonHelpers) {
        //   ws.getRow(rowCount + 1).getCell(currentCol).value = obj.Date.format("ddd Do");
        //   for (const person of obj.People) {
        //     ws.getRow(currentRow).getCell(currentCol).value = person;
        //     currentRow += 1;
        //   }
        //   currentCol += 1;
        //   currentRow = rowCount + 2;
        // }
      }

      //Measure needed
      ws = wb.addWorksheet("Measure");
      data = res[9];

      ws.addRow(["Member Id", "Member Name", "Dog Id", "Dog Name", "Measure Needed"]);
      for (const row of data) {
        const foundHeight = res[14].find((r) => r.DogId === row.Id && r.HeightId !== 4 && r.HeightId !== 8);
        const age = row.AgeInYears > 1;
        const hasPerm = parseInt(row.Measure) === 0;
        const hasFirst = parseInt(row.Measure) === 1;
        if (foundHeight && ((age && !hasPerm) || (!age && row.Measure === null))) {
          let measureNeeded = "Unknown";
          switch (row.Measure) {
            case null:
              if (foundHeight && !hasFirst) measureNeeded = "First";
              if (foundHeight && age) measureNeeded = "Permanent";
              break;

            case false:
              measureNeeded = "";
              break;

            default:
              if (age && !hasPerm) measureNeeded = "Permanent";
              break;
          }
          if (measureNeeded !== "")
            ws.addRow([row.MemberId, `${row.FirstName} ${row.LastName}`, row.Id, row.PetName, measureNeeded]);
        }
      }

      //Dogs
      data = res[4];
      ws = wb.addWorksheet("Dogs");
      ws.addRow([
        "DogName",
        "DogId",
        "Breed",
        "Gender",
        "Date Of Birth",
        "Owner Name",
        "Owner Email",
        "Owner Phone Number",
        "Owner Phone Number",
        "Handlers",
      ]);
      for (const row of data)
        ws.addRow([
          row.PetName,
          row.Id,
          row.Breed,
          row.Gender,
          moment(row.DateOfBirth).format("DD/MM/YYYY"),
          `${row.FirstName} ${row.LastName}`,
          row.Email,
          row.MobilePhone,
          row.OtherPhone,
          row.Handlers,
        ]);

      //Competitors
      data = res[5];
      ws = wb.addWorksheet("Competitors");
      ws.addRow(["A4A Number", "Name", "Mobile", "Phone", "Email", "Address", "Town", "Postcode", "Owner / Handler"]);
      for (const row of data)
        ws.addRow([
          row.Id,
          `${row.FirstName} ${row.LastName}`,
          row.MobilePhone,
          row.OtherPhone,
          row.Email,
          row.StreetAddress,
          row.Town,
          row.Postcode,
          row.Status,
        ]);

      //Awards
      ws = wb.addWorksheet("Awards");
      data = res[13];
      ws.addRow([
        "Date",
        "Class",
        "200(s)",
        "250",
        "250(s)",
        "300",
        "300(s)",
        "400",
        "400(s)",
        "500",
        "500(s)",
        "600",
        "T1",
        "NQ1",
        "NQ2",
        "NQ3",
        "NQ4",
      ]);
      let TotalQ = 0;
      let TotalNQ1 = 0,
        TotalNQ2 = 0,
        TotalNQ3 = 0,
        TotalNQ4 = 0;
      let TotalT1 = 0,
        TotalT2 = 0,
        TotalT3 = 0;
      let P5 = 0,
        P6 = 0,
        P7 = 0,
        P8 = 0,
        P9 = 0,
        P10 = 0;
      const heights = ["200 (s)", "250", "250 (s)", "300", "300 (s)", "400", "400 (s)", "500", "500 (s)", "600"];
      for (const row of data) {
        let T1 = 0,
          NQ1 = 0,
          NQ2 = 0,
          NQ3 = 0,
          NQ4 = 0;
        TotalQ += 10;
        NQ1 += 10;
        TotalNQ1 += 10;
        for (const height of heights) {
          if (row[height] && row[height] > 5) {
            NQ2 += 1;
            TotalNQ2 += 1;
            T1 += 1;
            TotalT1 += 1;
          }
          if (row[height] && row[height] > 10) {
            NQ3 += 1;
            TotalNQ3 += 1;
          }
          if (row[height] && row[height] > 15) {
            NQ4 += 1;
            TotalNQ4 += 1;
          }
          if (row[height] && row[height] > 49) TotalT2 += 1;
          if (row[height] && row[height] > 100) TotalT3 += 1;

          if (row[height] && row[height] > 40) P5 += 1;
          if (row[height] && row[height] > 50) P6 += 1;
          if (row[height] && row[height] > 60) P7 += 1;
          if (row[height] && row[height] > 70) P8 += 1;
          if (row[height] && row[height] > 80) P9 += 1;
          if (row[height] && row[height] > 90) P10 += 1;
        }
        ws.addRow([
          moment(row.ClassDate).format("ddd Do"),
          !row.ShowClassLabel ? row.ClassLabel : `${row.LevelLabel} ${row.ClassLabel}`,
          row["200 (s)"] ?? 0,
          row["250"] ?? 0,
          row["250 (s)"] ?? 0,
          row["300"] ?? 0,
          row["300 (s)"] ?? 0,
          row["400"] ?? 0,
          row["400 (s)"] ?? 0,
          row["500"] ?? 0,
          row["500 (s)"] ?? 0,
          row["600"] ?? 0,
          T1,
          NQ1,
          NQ2,
          NQ3,
          NQ4,
        ]);
      }

      ws.addRow();
      ws.addRow(["", "", "Q1", "Q2", "Q3", "Q4", "T1", "T2", "T3"]);
      ws.addRow(["", "", TotalQ, TotalQ, TotalQ, TotalQ, TotalT1, TotalT2, TotalT3]);
      ws.addRow();
      ws.addRow(["", "", "NQ1", "NQ2", "NQ3", "NQ4"]);
      ws.addRow(["", "", TotalNQ1, TotalNQ2, TotalNQ3, TotalNQ4]);
      ws.addRow();
      ws.addRow(["", "", "P5", "P6", "P7", "P8", "P9", "P10"]);
      ws.addRow(["", "", P5, P6, P7, P8, P9, P10]);

      for (let iRow = 1; iRow < ws.rowCount; iRow++) {
        for (let iCol = 3; iCol < 16; iCol++) {
          ws.getCell(iRow, iCol).alignment = { horizontal: "right" };
        }
      }

      ws = wb.addWorksheet("Juniors");
      ws.addRow(["Date", "First Name", "Last Name", "Date of birth", "Pet Name"]);
      for (const row of res[18]) {
        ws.addRow([
          moment(row.ClassDate).format("DD/MM/YYYY"),
          row.FirstName,
          row.LastName,
          row.DateOfBirth ? moment(row.DateOfBirth).format("DD/MM/YYYY") : "",
          row.PetName,
        ]);
      }

      //Team members
      let lead = res[22];
      let members = res[23];
      ws = wb.addWorksheet("Team Data");
      ws.addRow([
        "Team Name",
        "Heights",
        "Handler",
        "Dog",
        "Height",
        "Handler2",
        "Dog2",
        "Height2",
        "Handler3",
        "Dog3",
        "Height3",
      ]);
      for (const row of lead) {
        const memberData = members.filter((r) => r.LeadDogId === row.DogId);
        let firstDog = row,
          secondDog = memberData[0],
          thirdDog = memberData[1];
        if (firstDog.DogHeight === secondDog.DogHeight && firstDog.DogHeight !== thirdDog.DogHeight) {
          secondDog = memberData[1];
          thirdDog = memberData[0];
        } else if (firstDog.DogHeight !== secondDog.DogHeight && firstDog.DogHeight !== thirdDog.DogHeight) {
          firstDog = memberData[0];
          secondDog = row;
          thirdDog = memberData[1];
        }
        const heightString = `${firstDog.DogHeight}/${secondDog.DogHeight}/${thirdDog.DogHeight}`;

        ws.addRow([
          memberData[0].TeamName,
          heightString,
          firstDog.HandlerFirst + " " + firstDog.HandlerLast,
          firstDog.PetName,
          firstDog.HeightLabel,
          secondDog.HandlerFirst + " " + secondDog.HandlerLast,
          secondDog.PetName,
          secondDog.HeightLabel,
          thirdDog.HandlerFirst + " " + thirdDog.HandlerLast,
          thirdDog.PetName,
          thirdDog.HeightLabel,
        ]);
      }

      //Pairs
      lead = res[24];
      members = res[25];
      ws = wb.addWorksheet("Pair Data");
      ws.addRow(["Heights", "Handler", "Dog", "Handler2", "Dog2"]);
      for (const row of lead) {
        const memberData = members.filter((r) => r.LeadDogId === row.DogId);
        if (memberData) {
          let firstDog, secondDog;
          if (memberData[0]?.Sector === "Agility") {
            firstDog = memberData[0];
            secondDog = row;
          } else {
            firstDog = row;
            secondDog = memberData[0];
          }
          const heightString = `${firstDog?.DogHeight}/${secondDog?.DogHeight}`;
          ws.addRow([
            heightString,
            firstDog?.HandlerFirst + " " + firstDog?.HandlerLast,
            firstDog?.PetName,
            secondDog?.HandlerFirst + " " + secondDog?.HandlerLast,
            secondDog?.PetName,
          ]);
        }
      }

      //Knockout
      data = res[26];
      ws = wb.addWorksheet("Knockout");
      ws.addRow(["Height", "Handler", "Dog"]);
      for (const row of data) {
        ws.addRow([row.HeightLabel, row.Handler, row.PetName]);
      }

      //Set column widths
      wb.eachSheet((ws) => {
        ws.columns &&
          ws.columns.forEach((col) => {
            let dataMax = 0;
            col.eachCell({ includeEmpty: true }, (cell) => {
              const len = cell.value ? cell.value.toString().length : 0;
              if (dataMax < len) dataMax = len;
            });
            col.width = dataMax < 10 ? 10 : dataMax;
          });
      });

      const buf = await wb.xlsx.writeBuffer();
      const blob = new Blob([buf], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      DownloadFile(blob, "ShowProcess.xlsx");

      setShowLoading(false);
    } catch (error) {
      setShowLoading(false);
      console.log(error.message);
      window.alert("Error loading data");
    }
  }, [Id]);

  useEffect(() => {
    LoadData();
  }, [LoadData]);

  return (
    <div className="card">
      <div className="card-header">Show Process - Excel Output</div>
      <div className="card-body">
        <p className="mb-2x">
          <span className="text-link cursor-pointer" onClick={LoadData}>
            <FontAwesomeIcon icon={faFileExcel} size="2x" className="mr-1x" />
            Click here if your file does not automatically download
          </span>
        </p>
        {ShowLoading ? <InlineLoading /> : <></>}
      </div>
    </div>
  );
};

export default ExcelProcess;
