import React,{useEffect,useState,useContext} from 'react';
import {Route} from 'react-router-dom';
import {apiGet} from './api.js';
import UserContext from '../context/user/UserContext.js';
import Message from './Message.jsx';



function AdminRoute ({children,...rest}) {

    const {User} = useContext(UserContext);
    const [IsAdmin, setIsAdmin] = useState(false);
    const [IsValidating, setIsValidating] = useState(true);

    //Call API to check if admin or not
    useEffect(() => {

        const LoadDetails = async() => {
            try {
                
                const req = await apiGet(`/members/account/${User.Id}`);
                if(req && req.ok && req.status === 200) {
                    const res = await req.json();
                    setIsAdmin(res.IsAdmin);
                }
                setIsValidating(false);

            }
            catch (error) {
                setIsAdmin(false);
                setIsValidating(false);
            }

        }


        LoadDetails();
    });

    if(IsValidating)
        return <Message Type="Info" Content="Checking permissions..." />

    if(!IsAdmin)
        return <Message Type="Error" Content="Sorry, your account is not marked as an administrator. You cannot access this page." />

    return (
        <Route {...rest}>{children}</Route>
    );

}

export default AdminRoute;