import React from "react";
import Owner from "./members/owner";
import { useParams, Route } from "react-router-dom";
import MemberEdit from "./members/memberedit";
import DogList from "./members/doglist";
import Wallet from "./wallet/wallet";
import { BrowserRouter, Link } from "react-router-dom";
import DogAdd from "./members/dogadd";
import DogEdit from "./members/dogedit";
import DogEditHeights from "./members/dogheights";
import DogTransfer from "./members/dogtransfer";
import Emails from "../members/emails/emails";

function MemberView(props) {
  const { Id } = useParams();

  return (
    <>
      <BrowserRouter>
        <div className="card mb-2x">
          <div className="card-header" />
          <div className="card-body">
            <Owner UserId={Id} />
            <p>
              <Link to={`/admin/memberview/account/${Id}`} className="mr-1x">
                Edit Account
              </Link>
              <Link to={`/admin/memberview/doglist/${Id}`} className="mr-1x">
                Dogs
              </Link>
              <Link to={`/admin/memberview/wallet/${Id}`} className="mr-1x">
                Wallet
              </Link>
              <Link to={`/admin/memberview/emails/${Id}`} className="mr-1x">
                Emails
              </Link>
              <span className="cursor-pointer mr-1x">Results</span>
            </p>
          </div>
          <div className="card-footer" />
        </div>
        <Route exact path="/admin/memberview/emails/:Id">
          <Emails />
        </Route>
        <Route exact path="/admin/memberview/account/:Id">
          <MemberEdit />
        </Route>
        <Route exact path="/admin/memberview/doglist/:Id">
          <DogList />
        </Route>
        <Route exact path="/admin/memberview/adddog/:Id">
          <DogAdd />
        </Route>
        <Route exact path="/admin/memberview/editdog/:Id/:MemberId">
          <DogEdit />
        </Route>
        <Route exact path="/admin/memberview/editdogheights/:Id/:MemberId">
          <DogEditHeights />
        </Route>
        <Route exact path="/admin/memberview/transfer/:Id/:MemberId">
          <DogTransfer />
        </Route>
        <Route exact path="/admin/memberview/wallet/:Id">
          <Wallet />
        </Route>
      </BrowserRouter>
    </>
  );
}

export default MemberView;
