import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';


function InlineLoading (props) {
    return (
        <p>
            <FontAwesomeIcon icon={faSpinner} spin className="mr-1x" />
            {
                props.Message || <>Loading...</>
            }
        </p>
    )
}

export default InlineLoading;