import React, { useState, useEffect, useCallback, useContext } from "react";
import SummaryDog from "./SummaryDog";
import SummaryCamping from "./SummaryCamping";
import SummaryPostage from "./SummaryPostage";
import { useParams } from "react-router-dom";
import { apiGet, apiPost } from "../../../../shared/api";
import UserContext from "../../../../context/user/UserContext";
import numeral from "numeral";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../../shared/ModalContent";

const Entry = (props) => {
  const [EntryCost, setEntryCost] = useState("");
  const [EntriesClose, setEntriesClose] = useState("");
  const [DisplayName, setDisplayName] = useState("");
  const [IsClosed, setIsClosed] = useState(false);
  const [ShowSavedModal, setShowSavedModal] = useState(false);
  const [ShowAreYouSureModal, setShowAreYouSureModal] = useState(false);
  const [CanEdit, setCanEdit] = useState(false);
  const [AnyDaysCapped, setAnyDaysCapped] = useState(true);
  const [IsPaper, setIsPaper] = useState(true);
  const [IsAdminLogin, setIsAdminLogin] = useState(false);

  const { ShowId } = useParams();
  const { User } = useContext(UserContext);

  const LoadData = useCallback(async () => {
    try {
      let req, res;

      //Show and entry details stuff
      req = await apiGet(`/shows/entry/${ShowId}/${User.Id}`);
      res = await req.json();
      setEntryCost(res.EntryCost);
      setEntriesClose(res.EntriesClose);
      setIsClosed(res.IsClosed);
      setDisplayName(res.DisplayName);
      setIsPaper(res.IsPaper);

      req = await apiGet(`/admin/shows/classes/listcombinations/${ShowId}`);
      res = await req.json();
      const cappedClasses = res.filter((c) => c.Capped);
      setAnyDaysCapped(cappedClasses.length !== 0);

      //Check for Admin login
      let itm = sessionStorage.getItem("User");
      itm = JSON.parse(itm);
      if (itm.IsAdminLogin) setIsAdminLogin(true);
    } catch (error) {
      console.log(error.message);
      props.history.push("/shows");
    }
  }, [User.Id, ShowId, props.history]);

  //Set the CanEdit flag if show has not closed
  useEffect(() => {
    if (EntriesClose) {
      setCanEdit(
        (moment(EntriesClose).isAfter(moment()) && !IsPaper) || IsAdminLogin
      );
    }
  }, [EntriesClose, IsPaper, IsAdminLogin]);

  useEffect(() => {
    LoadData();
  }, [LoadData]);

  useEffect(() => {
    if (ShowSavedModal) {
      setTimeout(() => {
        setShowSavedModal(false);
      }, 2000);
    }
  }, [ShowSavedModal]);

  const CancelEntry = async () => {
    try {
      const obj = {
        ShowId,
        MemberId: User.Id,
        LatesOnly: false,
      };

      const req = await apiPost("/shows/cancelentireentry", obj);
      if (req && req.ok) {
        props.history.push("/shows");
      } else {
        window.alert("Error deleting entry");
      }
    } catch (error) {
      console.log(error.message);
      window.alert("Error cancelling entry");
    }
  };

  const ShowSaved = () => {
    setShowSavedModal(true);
  };

  const CampingSaved = async () => {
    ShowSaved();
    await LoadData();
  };

  return (
    <div className="card">
      <div className="card-header">My Entry for {DisplayName}</div>
      <div className="card-body">
        {ShowSavedModal ? (
          <Modal>
            <h2 className="text-green">
              <FontAwesomeIcon icon={faCheckCircle} className="mr-2x" />
              Choices saved
            </h2>
          </Modal>
        ) : (
          <></>
        )}

        {ShowAreYouSureModal ? (
          <Modal>
            <p>
              <b>Are you sure?</b>
            </p>
            <p>
              This will remove your entire entry for the show. You may not be
              able to re-enter if the show has since capped
            </p>
            <p>
              <button className="button button-red mr-1x" onClick={CancelEntry}>
                Yes
              </button>
              <button
                className="button"
                onClick={() => setShowAreYouSureModal(false)}
              >
                No
              </button>
            </p>
          </Modal>
        ) : (
          <></>
        )}
        {EntryCost > 0 && !IsClosed && (
          <>
            {!IsPaper && (
              <p className="alert alert-error mb-3x">
                Please ensure you have enough funds in your account within 24
                hours of making your entry otherwise it may be cancelled. Your
                total cost is currently{" "}
                <b>£{numeral(EntryCost).format("0.00")}</b>
              </p>
            )}
            {IsPaper && (
              <p className="alert alert-success mb-3x">
                You have made a paper entry for this show. Payment has been
                received and no transactions will show in your wallet.
              </p>
            )}
            <p>
              This page shows your confirmed entries for this show, subject to
              payment being received. If the show has not yet capped or closed
              you can scroll down to add runs or edit your entry.
            </p>
          </>
        )}

        <SummaryDog
          IsClosed={IsClosed}
          ShowId={ShowId}
          RefreshCost={LoadData}
          onSave={ShowSaved}
          CanEdit={CanEdit}
          AnyDaysCapped={AnyDaysCapped}
        />
        <div className="grid">
          <div className="col-6">
            <SummaryCamping
              IsClosed={IsClosed}
              ShowId={ShowId}
              CanEdit={CanEdit && !AnyDaysCapped}
              onSave={CampingSaved}
            />
          </div>
          <div className="col-6">
            <SummaryPostage
              IsClosed={IsClosed}
              ShowId={ShowId}
              RefreshCost={LoadData}
              CanEdit={CanEdit && !AnyDaysCapped}
              onSave={ShowSaved}
            />
          </div>
        </div>
      </div>
      <div className="card-footer">
        {IsClosed === 0 && !AnyDaysCapped && CanEdit && (
          <button
            className="button button-red mr-2x mb-2x"
            onClick={() => setShowAreYouSureModal(true)}
          >
            Cancel my entire entry for this show
          </button>
        )}
        <button
          className="button button-blue"
          onClick={() => props.history.push("/shows")}
        >
          Exit
        </button>
      </div>
    </div>
  );
};

export default withRouter(Entry);
