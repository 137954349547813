import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { apiGet } from "../../../shared/api";

const Documents = () => {
  const [DocumentList, setDocumentList] = useState([]);
  const [BaseUrl, setBaseUrl] = useState();
  const { Id } = useParams();

  useEffect(() => {
    (async () => {
      try {
        if (Id) {
          const req = await apiGet(`/admin/shows/managerdocuments/list/${Id}`);
          const res = await req.json();
          setDocumentList(res.FileList);
          setBaseUrl(res.BaseUrl);
        }
      } catch (error) {
        //window.alert("Error loading list of documents");
      }
    })();
  }, [Id]);

  return (
    <>
      <h3>Show Documents</h3>
      <p className="mb-3x">
        <a href={`/showmanagers/process/${Id}`}>Back to process menu</a>
      </p>

      {DocumentList.length === 0 && (
        <p>There are no documents currently available</p>
      )}

      {DocumentList.length > 0 &&
        DocumentList.map((itm, idx) => (
          <p key={idx}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${BaseUrl}/${itm.Filename}`}
            >
              {itm.Description}
            </a>
          </p>
        ))}
    </>
  );
};

export default Documents;
