import React, { useContext, useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { apiGet } from "../../../shared/api.js";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import UserContext from "../../../context/user/UserContext";

function Results(props) {
  const { User } = useContext(UserContext);

  const [MyDogs, setMyDogs] = useState([]);

  //Load My dogs
  useEffect(() => {
    (async () => {
      try {
        let req, res;

        //Dogs for this member
        req = await apiGet(`/dogs/list/${User.Id}`);
        res = await req.json();
        const DogList = [];
        for (const itm of res.filter((d) => d.Status === 1))
          DogList.push({ value: itm.Id, label: `${itm.Id} - ${itm.PetName}` });
        setMyDogs(DogList);
      } catch (error) {
        console.log(error.message);
        window.alert("Error loading list of your dogs");
      }
    })();
  }, [User.Id]);

  //Gets suggested show names from the server
  const GetShowSuggestions = async (val) => {
    if (val.length > 1) {
      try {
        const req = await apiGet(`/shows/match/${val}`);
        if (req && req.ok && req.status === 200) {
          const res = await req.json();
          return res
            .filter((s) => moment(s.StartDate).isSameOrBefore(moment()))
            .map((itm) => {
              let displayString = `${itm.DisplayName}`;

              if (itm.StartDate !== undefined) {
                if (itm.StartDate === itm.EndDate)
                  displayString += `: ${moment(itm.EndDate).format(
                    "Do MMM yyyy"
                  )}`;
                if (itm.StartDate !== itm.EndDate)
                  displayString += `: ${moment(itm.StartDate).format(
                    "Do MMM"
                  )} - ${moment(itm.EndDate).format("Do MMM yyyy")}`;
              }

              return { value: itm.ShowId, label: displayString };
            });
        } else {
          return [];
        }
      } catch (error) {
        console.log(error.message);
        return [];
      }
    }
  };

  //Gets suggested dog names from the server
  const GetDogSuggestions = async (val) => {
    try {
      const req = await apiGet(`/dogs/match/${val}`);
      if (req && req.ok && req.status === 200) {
        const res = await req.json();
        return res.map((itm) => {
          let displayString = `${itm.Id} ${itm.PetName}`;

          if (itm.FirstName !== "Unknown")
            displayString += ` - ${itm.FirstName} ${itm.LastName}`;

          return { value: itm.Id, label: displayString };
        });
      } else {
        return [];
      }
    } catch (error) {
      console.log(error.message);
      return [];
    }
  };

  //Redirect to show results pages
  const SelectShow = (e) => {
    if (e.value !== undefined) props.history.push(`/results/show/${e.value}`);
  };

  //Redirect to dog results page
  const SelectDog = (e) => {
    if (e.value !== undefined) props.history.push(`/results/dog/${e.value}`);
  };

  //Redirect to Junior League
  const ShowJuniorLeague = () => {
    props.history.push(`/results/juniorleague`);
  };

  return (
    <div className="card card-narrow">
      <div className="card-header">Results</div>
      <div className="card-body">
        <h4 className="mb-2x">What do you want to see?</h4>

        <p className="width-75">
          If your dog was registered as no longer with us prior to 2020 then,
          due to the transfer of systems, there will unfortunately not be any
          results shown for them.
        </p>

        <p className="mt-3x">
          <b>I'd like results for one of my dogs</b>
        </p>
        <Select
          placeholder="Start typing a dog name"
          onChange={(e) => SelectDog(e)}
          className="width-75"
          options={MyDogs}
        />

        <p className="mt-3x">
          <b>I'd like results for someone else's dog</b>
        </p>
        <AsyncSelect
          placeholder="Start typing a dog name"
          onChange={(e) => SelectDog(e)}
          className="width-75"
          loadOptions={GetDogSuggestions}
        />

        <p className="mt-3x">
          <b>I'd like to see the results for a show</b>
        </p>
        <AsyncSelect
          placeholder="Enter at least the first two characters of a show name"
          onChange={(e) => SelectShow(e)}
          className="width-75"
          loadOptions={GetShowSuggestions}
        />

        <p className="mt-3x">
          <b>I'd like to see all the results where I was the handler</b>{" "}
          <Link to="/results/handler" className="button button-blue ml-1x">
            View
          </Link>
        </p>

        <p className="mt-3x">
          <b>I'd like to see the Junior League</b>
        </p>

        <button className="button button-blue" onClick={ShowJuniorLeague}>
          View
        </button>

        <p className="mt-3x width-75">
          If a dog name shows as 'Historical Record', this result is from a
          previous system where the dog's details have not transferred to us.
        </p>
      </div>
    </div>
  );
}

export default withRouter(Results);
