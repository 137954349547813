import React, { useEffect } from "react";
import "./App.css";
import UserContextProvider from "./context/user/UserContextProvider";
import PageHeader from "./components/pageheader/pageheader";
import PageFooter from "./components/pagefooter/pagefooter";
import Content from "./components/content/content";
import { getSite } from "./shared/api";

function App() {
  if (window.location.href.indexOf("compete.ukagility.com") !== -1) {
    let url = window.location.href.replace(
      "ukagility.com",
      "agility4all.co.uk"
    );
    window.location.href = url;
  }

  global.LateEntryDays = 2;

  useEffect(() => {
    const site = getSite();
    const body = document.getElementsByTagName("body")[0];
    switch (site) {
      case "dev":
        body.style.backgroundColor = "#f1d7f5";
        break;

      case "stg":
        body.style.backgroundColor = "#caf28a";
        break;

      default:
        break;
    }
  }, []);

  return (
    <UserContextProvider>
      <PageHeader />
      <Content />
      <PageFooter />
    </UserContextProvider>
  );
}

export default App;
