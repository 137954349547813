import React, { useContext } from "react";
import { useInterval } from "beautiful-react-hooks";
import { apiGet } from "../../../shared/api";
import UserContext from "../../../context/user/UserContext";

function TimerBar(props) {
  const ONE_MINUTE = 60000;
  const { User } = useContext(UserContext);

  useInterval(async () => {
    try {
      await apiGet(`/dogs/list/${User.Id}`);
    } catch (error) {}
  }, ONE_MINUTE * 10);

  return <></>;
}

export default TimerBar;
