import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const LoadingIndicator = () => (
  <span className="mr-1x">
    <FontAwesomeIcon className="mr-1x" spin icon={faSpinner} />
    Loading...
  </span>
);

export default LoadingIndicator;
