import React, { useState, useContext, useEffect } from "react";
import UserContext from "../../../context/user/UserContext";
import { DownloadFile } from "../../../shared/clientsidefiledownload";
import { apiGet } from "../../../shared/api";
import { useParams } from "react-router-dom";
import ModalLoading from "../../../shared/ModalLoading";
import { pdf } from "@react-pdf/renderer";
import HandlerSheet from "../../admin/shows/processing/handlersheet.js";

const Documents = (props) => {
  const [Show, setShow] = useState();
  const [Processing, setProcessing] = useState(false);
  const [DocumentList, setDocumentList] = useState([]);
  const [BaseUrl, setBaseUrl] = useState("");
  const [ShowHandlerSheet, setShowHandlerSheet] = useState(false);

  const { User } = useContext(UserContext);
  const { ShowId } = useParams();

  const DocumentRows = DocumentList.map((itm, idx) => {
    return (
      <li key={idx}>
        <a href={`${BaseUrl}/${itm}`}>
          {itm
            .toString()
            .replace(`${ShowId}/`, "")
            .replace(".pdf", "")
            .replace(".xlsx", "")}
        </a>
      </li>
    );
  });

  useEffect(() => {
    const LoadData = async () => {
      try {
        let req, res;
        try {
          req = await apiGet(`/shows/${ShowId}`);
          res = await req.json();
          setShow(res);
        } catch (error) {
          console.log(error.message);
          window.alert("Error loading show details");
        }

        try {
          req = await apiGet(`/shows/entries/${User.Id}`);
          res = await req.json();
          const thisShow = res.find((s) => s.ShowId === parseInt(ShowId));
          if (thisShow && thisShow.EntryCost > 0) setShowHandlerSheet(true);
        } catch (error) {
          console.log(error.message);
        }

        try {
          const req = await apiGet(
            `/admin/shows/entrantdocuments/list/${ShowId}`
          );
          const res = await req.json();
          setDocumentList(res.FileList);
          setBaseUrl(res.BaseUrl);
        } catch (error) {
          console.log(error.message);
          window.alert("Error loading list of documents");
        }
      } catch (error) {
        console.log(error.message);
      }
    };

    if (ShowId) LoadData();
  }, [ShowId]);

  const HandlerEntrySheet = async () => {
    setProcessing(true);
    const doc = await HandlerSheet(ShowId, User.Id);
    const blob = await pdf(doc).toBlob();
    DownloadFile(blob, "HandlerInfoSheet.pdf");
    setProcessing(false);
  };

  if (Processing) return <ModalLoading />;

  return (
    <div className="card">
      <div className="card-header">My Entry for {Show?.DisplayName}</div>
      <div className="card-body">
        {DocumentList.length === 0 && !Show?.ROAvailable && (
          <p>There are no documents yet for this show.</p>
        )}

        <ul>
          {DocumentRows}

          {Show?.ROAvailable && ShowHandlerSheet && (
            <li>
              <div
                onClick={HandlerEntrySheet}
                className="text-link cursor-pointer"
              >
                Handler Entry Sheet
              </div>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Documents;
