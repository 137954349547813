import React, { useState, useEffect, useCallback } from "react";
import { apiGet, apiPost } from "../../../shared/api.js";

const ClassDefaults = (props) => {
  const [ClassList, setClassList] = useState([]);
  const [LevelList, setLevelList] = useState([]);
  const [HeightList, setHeightList] = useState([]);
  const [CanSave, setCanSave] = useState(false);
  const [SelectedClassId, setSelectedClassId] = useState("");
  const [SelectedLevelIds, setSelectedLevelIds] = useState([]);
  const [SelectedHeights, setSelectedHeights] = useState([]);

  const LoadData = useCallback(async () => {
    try {
      let req, res;

      //List of classes in database
      req = await apiGet(`/admin/shows/classes`);
      res = await req.json();
      setClassList(res);

      //List of levels in database
      req = await apiGet(`/admin/shows/levels`);
      res = await req.json();
      setLevelList(res);

      //List of levels in database
      req = await apiGet(`/admin/shows/heights`);
      res = await req.json();
      setHeightList(res);
    } catch (error) {
      console.log(error.message);
      window.alert("Error loading initial data");
    }
  }, []);

  useEffect(() => {
    LoadData();
  }, [LoadData]);

  const SaveChoices = async () => {
    try {
      for (const height of HeightList) {
        for (const level of LevelList) {
          const obj = {
            ClassId: SelectedClassId,
            LevelId: level.LevelId,
            HeightId: height.HeightId,
            Selected:
              SelectedHeights.includes(height.HeightId) &&
              SelectedLevelIds.includes(level.LevelId),
          };

          await apiPost(`/admin/shows/classes/defaults/set`, obj);
        }
      }

      window.alert("Choices saved");
      LoadData();
    } catch (error) {
      console.log(error.message);
      window.alert("Error saving default choices");
    }
  };

  useEffect(() => {
    const LoadDefaults = async () => {
      try {
        //Get existing defaults for this class
        const req = await apiGet(
          `/admin/shows/classes/defaults/${SelectedClassId}`
        );
        const res = await req.json();

        const UniqueLevelIds = new Set();
        const UniqueHeightIds = new Set();
        for (const row of res) {
          UniqueLevelIds.add(row.LevelId);
          UniqueHeightIds.add(row.HeightId);
        }

        setSelectedLevelIds(Array.from(UniqueLevelIds));
        setSelectedHeights(Array.from(UniqueHeightIds));
      } catch (error) {
        console.log(error.message);
        window.alert("Error loading defaults for class");
      }
    };

    if (SelectedClassId !== "") {
      LoadDefaults();
    } else {
      setSelectedHeights([]);
    }
  }, [SelectedClassId]);

  useEffect(() => {
    //Show 'Can Save' button - or not
    setCanSave(SelectedClassId !== "" && SelectedLevelIds !== "");
  }, [SelectedClassId, SelectedLevelIds]);

  const SetSelectedLevelIds = (evt) => {
    setSelectedLevelIds(
      Array.from(evt.target.selectedOptions, (item) => parseInt(item.value))
    );
  };

  const ClassRows = ClassList.map((itm) => (
    <option key={itm.ClassId} value={itm.ClassId}>
      {itm.HasDefaults ? "* " : ""}
      {itm.ClassLabel}
    </option>
  ));
  const LevelRows = LevelList.map((itm) => (
    <option key={itm.LevelId} value={itm.LevelId}>
      {itm.LevelLabel}
    </option>
  ));

  //Array of checkboxes for height selection
  const HeightsChecks = HeightList.map((itm) => {
    return (
      <React.Fragment key={itm.HeightId}>
        <input
          type="checkbox"
          checked={SelectedHeights.includes(itm.HeightId)}
          onChange={(e) => SetHeightSelections(itm.HeightId, e.target.checked)}
        />
        <span className="mr-1x">{itm.HeightLabel}</span>
      </React.Fragment>
    );
  });

  //Manage list of selected heights
  const SetHeightSelections = (HeightId, Selected) => {
    let arr = Array.from(SelectedHeights);
    if (Selected) {
      arr.push(HeightId);
    } else {
      arr = arr.filter((i) => i !== HeightId);
    }
    setSelectedHeights(arr);
  };

  return (
    <div className="card">
      <div className="card-header">Class Defaults</div>
      <div className="card-body">
        <div className="grid mb-2x">
          <div className="col-3">
            <div className="form-group">
              <label>Class</label>
              <select
                className="form-control"
                value={SelectedClassId}
                onChange={(e) => setSelectedClassId(e.target.value)}
              >
                <option value="">Select...</option>
                {ClassRows}
              </select>
            </div>
          </div>
          <div className="col-3">
            <label>Level</label>
            <select
              multiple
              height={12}
              className="form-control"
              value={SelectedLevelIds}
              onChange={(e) => SetSelectedLevelIds(e)}
            >
              {LevelRows}
            </select>
          </div>
        </div>

        {SelectedClassId !== "" && SelectedLevelIds !== "" ? (
          <div className="mb-2x">{HeightsChecks}</div>
        ) : (
          <></>
        )}
      </div>
      <div className="card-footer">
        {CanSave ? (
          <button className="button button-green" onClick={SaveChoices}>
            Save
          </button>
        ) : (
          <p>Waiting for selections...</p>
        )}
      </div>
    </div>
  );
};

export default ClassDefaults;
