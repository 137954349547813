import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import UserContext from "../../../../context/user/UserContext";
import { apiPost } from "../../../../shared/api";
import MyEntryDogsAndClasses from "./MyEntryDogsAndClasses";
import MyEntryDeclaration from "./MyEntryDeclaration";
import MyEntryCamping from "./MyEntryCamping";
import MyEntryPostage from "./MyEntryPostage";
import { withRouter } from "react-router-dom";
import { apiGet } from "../../../../shared/api";
import Modal from "../../../../shared/ModalContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import ModalLoading from "../../../../shared/ModalLoading";
import moment from "moment";
import TotalCost from "./totalcost";
import SaveButton from "./savebutton";

const MyEntry = (props) => {
  const FirstTime = !window.location.search.includes("?View=AddRuns");

  const [CanContinue, setCanContinue] = useState(true);
  const [Dog, setDog] = useState();
  const [Height, setHeight] = useState();
  const [Handler, setHandler] = useState();
  const [Postage, setPostage] = useState(false);
  const [Camping, setCamping] = useState();
  const [ShowName, setShowName] = useState("");
  const [ShowModal, setShowModal] = useState(false);
  const [ModalText, setModalText] = useState("");
  const [ShowSaving, setShowSaving] = useState(false);
  const [LastDay, setLastDay] = useState();
  const [IsAdminLogin, setIsAdminLogin] = useState(false);
  const [IsPaper, setIsPaper] = useState(false);

  const { User } = useContext(UserContext);
  const { ShowId } = useParams();

  useEffect(() => {
    (async () => {
      try {
        //Details of the show itself
        const req = await apiGet(`/shows/${ShowId}`);
        const res = await req.json();
        setShowName(res.DisplayName);
        setLastDay(moment(res.EndDate));

        //Check for Admin login
        let itm = sessionStorage.getItem("User");
        itm = JSON.parse(itm);
        if (itm.IsAdminLogin) setIsAdminLogin(true);
      } catch (error) {
        console.log(error.message);
        setModalText(
          <>
            <p>
              <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1x" />
              Error loading initial data
            </p>
            <p className="mt-2x">
              <button
                className="button button-blue"
                onClick={() => setShowModal(false)}
              >
                OK
              </button>
            </p>
          </>
        );
        setShowModal(true);
      }
    })();
  }, [ShowId]);

  useEffect(() => {
    //Remove anything currently in session storage
    sessionStorage.removeItem("Classes");
    sessionStorage.removeItem("CampingCost");
  }, [Dog]);

  const SaveChoices = async () => {
    if (CanContinue) {
      setShowSaving(true);

      let itm = sessionStorage.getItem("Classes") ?? [];

      let obj = {
        ShowId,
        MemberId: User.Id,
        HandlerId: Handler.value,
        Dog,
        Postage,
        Camping,
        Classes: JSON.parse(itm),
        IsPaper,
      };

      const req = await apiPost("/shows/classes/singleclickentries", obj);
      if (req && req.ok) {
        const res = await req.json();
        const campingOutcome = res.Camping;
        const failedDays = res.Days.filter((r) => parseInt(r.Result) === 99);
        const successDays = res.Days.filter((r) => parseInt(r.Result) === 1);
        if (
          failedDays.length === 0 &&
          (!campingOutcome || campingOutcome === "Success")
        ) {
          props.history.push(`/shows/scentry/${ShowId}`);
        } else {
          setShowSaving(false);
          setModalText(
            <>
              <h3>
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="mr-1x"
                />
                Error saving entry
              </h3>
              <div className="mt-2x mb-2x">
                {failedDays.length === 0 && campingOutcome === "Capped" && (
                  <p>
                    Since you started your entry, camping has capped for this
                    show and so you do not have camping. However, your entry for
                    your dog has been successful.
                  </p>
                )}

                {successDays.length === 0 && !campingOutcome && (
                  <p>
                    Since you started your entry, all the days you have chosen
                    to enter have capped for this show and so your dogs entry
                    has not been successful.
                  </p>
                )}

                {successDays.length === 0 && campingOutcome === "Success" && (
                  <p>
                    Since you started your entry, all the days you have chosen
                    to enter have capped for this show and so your dogs entry
                    has not been successful. However, your request for camping
                    has been successful.
                  </p>
                )}

                {successDays.length !== 0 &&
                  failedDays.length !== 0 &&
                  campingOutcome === "Success" && (
                    <>
                      <p>
                        Since you started your entry, some days you have chosen
                        to enter have capped.
                      </p>
                      <ul>
                        {failedDays.map((itm, idx) => (
                          <li key={idx}>
                            {moment(itm.ClassDate).format("ddd Do MMM")}
                          </li>
                        ))}
                      </ul>
                      <p>
                        However, your request for camping and your dogs entry
                        for days not listed above have been successful.
                      </p>
                    </>
                  )}

                {successDays.length !== 0 &&
                  failedDays.length !== 0 &&
                  campingOutcome === "Capped" && (
                    <>
                      <p>
                        Since you started your entry, camping and some days of
                        the show have capped - you have not been successful with
                        your camping request or with your dogs entries for these
                        days:
                      </p>
                      <ul>
                        {failedDays.map((itm, idx) => (
                          <li key={idx}>
                            {moment(itm.ClassDate).format("ddd Do MMM")}
                          </li>
                        ))}
                      </ul>
                      <p>
                        However, your dogs entry for days not listed above has
                        been successful.
                      </p>
                    </>
                  )}

                {successDays.length === 0 && campingOutcome === "Capped" && (
                  <p>
                    Since you started your entry, camping and each day of the
                    show has capped. Therefore you are not able to book camping
                    or any entries for your dogs.
                  </p>
                )}

                {/*
                {successDays.length > 0 && failedDays.length > 0 && (
                  <>
                    <p className="mt-2x">
                      Unfortunately, since you started your entry, the following
                      days have reached their capping level.
                    </p>
                    <ul>
                      {failedDays.map((itm, idx) => (
                        <li key={idx}>
                          {moment(itm.ClassDate).format("ddd Do MMM")}
                        </li>
                      ))}
                    </ul>
                    <p className="mb-2x">
                      Your entries for other days have been successful.
                    </p>
                    <p className="mt-1x mb-1x">
                      <button
                        className="button button-blue"
                        onClick={() =>
                          props.history.push(`/shows/scentry/${ShowId}`)
                        }
                      >
                        OK
                      </button>
                    </p>
                  </>
                )}
                {successDays.length === 0 && (
                  <>
                    <p className="mt-2x">
                      Unfortunately, since you started your entry, all show days
                      have reached their capping level. Therefore we are unable
                      to accept your entry for this dog.
                      <br />
                      Any entries you have saved for other dogs are unaffected.
                    </p>
                    <p className="mt-1x mb-1x">
                      <button
                        className="button button-blue"
                        onClick={() => props.history.push("/shows")}
                      >
                        OK
                      </button>
                    </p>
                  </>
                )}
                */}
                <p className="mt-1x mb-1x">
                  <button
                    className="button button-blue"
                    onClick={() => props.history.push("/shows")}
                  >
                    OK
                  </button>
                </p>
              </div>
            </>
          );
          setShowModal(true);
        }
      } else {
        setModalText(
          <>
            <p>
              <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1x" />
              Error saving entry
            </p>
            <p className="mt-2x mb-2x">
              <button
                className="button button-blue"
                onClick={() => setShowModal(false)}
              >
                OK
              </button>
            </p>
          </>
        );
        setShowModal(true);
      }
    } else {
      setModalText(
        <>
          <p>
            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1x" />
            You must agree to the declaration in order to save this entry
          </p>
          <p className="mt-2x mb-2x">
            <button
              className="button button-blue"
              onClick={() => setShowModal(false)}
            >
              OK
            </button>
          </p>
        </>
      );
      setShowModal(true);
    }
  };

  if (ShowSaving)
    return (
      <ModalLoading Message="Saving your entry, please wait a moment..." />
    );

  return (
    <div className="card">
      <div className="card-header">
        {FirstTime
          ? `Make an entry for ${ShowName}`
          : `Add runs for my entry into ${ShowName}`}
      </div>
      <div className="card-body">
        {IsAdminLogin && (
          <div className="form-group mt-1x mb-1x">
            <label>I am an admin making a paper entry</label>
            <label className="switch">
              <input
                type="checkbox"
                checked={IsPaper}
                onChange={(e) => setIsPaper(e.target.checked)}
              />
              <span className="slider round"></span>
            </label>
          </div>
        )}

        {ShowModal && <Modal>{ModalText}</Modal>}
        <div className="pt-2x pl-2x pb-2x">
          <MyEntryDogsAndClasses
            Dog={Dog}
            onDog={(dog) => setDog(dog)}
            onHeight={(val) => setHeight(val)}
            onHandler={(val) => setHandler(val)}
            LastDay={LastDay}
          />
          {Handler && Dog && Height && FirstTime && (
            <>
              <MyEntryCamping
                onCamping={(val) => {
                  setCamping(val);
                }}
              />
              <MyEntryPostage onPostage={(val) => setPostage(val)} />
            </>
          )}

          {Handler && Height && <TotalCost Postage={Postage} />}

          {Handler && Dog && Height && FirstTime && (
            <MyEntryDeclaration onAgree={(val) => setCanContinue(val)} />
          )}
        </div>
      </div>

      <div className="card-footer">
        <p className="mt-1x mb-1x">Click 'Enter' to confirm your entry.</p>
        <SaveButton onSave={SaveChoices} />
        <button className="button" onClick={() => props.history.go(-1)}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default withRouter(MyEntry);
