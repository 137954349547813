import React, {useState, useEffect} from 'react';
import './balance.css';
import numeral from 'numeral';
import InlineLoading from '../../../shared/InlineLoading';


function Balance (props) {

    const [ClassNames, setClassNames] = useState('card-body');
    const [AvailableBalance, setAvailableBalance] = useState(undefined);


    
    //Update available balance
    useEffect(() => {

        if(props.Wallet) {
            const amnt = props.Wallet.Balance - props.Wallet.TotalDueNow - props.Wallet.TotalDueSoon;
            setAvailableBalance(amnt);

            if(amnt < 0 || props.Wallet.TotalDueNow > amnt)
                setClassNames('card-body action-needed');
        }

    },[props.Wallet]);



    if(!props.Wallet)
        return <InlineLoading />


    return (
        <div className="card">
            <div className="card-header">
                <h4>Balance</h4>
            </div>
            <div className={ClassNames}>
                <p className="mb-2x">
                    <b>Current balance:</b> £{numeral(props.Wallet.Balance).format('0.00')}
                </p>                
                <p>
                    <b>Due now:</b> £{numeral(props.Wallet.TotalDueNow).format('0.00')}
                </p>                
                <p>
                    <b>Committed to entries:</b> £{numeral(props.Wallet.TotalDueSoon).format('0.00')}
                </p>
                <p>
                    <b>Non-committed funds:</b> £{numeral(AvailableBalance).format('0.00')}
                </p>
            </div>
            <div className="card-footer"></div>
        </div>
    );
}

export default Balance;