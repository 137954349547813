import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import UserContext from "../../../../context/user/UserContext";
import { apiPost } from "../../../../shared/api";
import MyEntryPreferences from "./MyEntryPreferences";
import MyEntryDogsAndClasses from "./MyEntryDogsAndClasses";
import MyEntryDeclaration from "./MyEntryDeclaration";
import MyEntryCamping from "./MyEntryCamping";
import MyEntryHelping from "./MyEntryHelping";
import MyEntryPostage from "./MyEntryPostage";
import { withRouter } from "react-router-dom";
import { apiGet } from "../../../../shared/api";
import Modal from "../../../../shared/ModalContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import ModalLoading from "../../../../shared/ModalLoading";

const MyEntry = (props) => {
  const FirstTime = !window.location.search.includes("?View=AddRuns");

  const [CurrentStep, setCurrentStep] = useState(FirstTime ? 1 : 2);
  const [CanContinue, setCanContinue] = useState(true);
  const [Dogs, setDogs] = useState([]);
  const [UsePreferences, setUsePreferences] = useState(true);
  const [Postage, setPostage] = useState(false);
  const [Camping, setCamping] = useState({});
  const [Helping, setHelping] = useState([]);
  const [Notes, setNotes] = useState("");
  const [ShowName, setShowName] = useState("");
  const [ShowPostage, setShowPostage] = useState(true);
  const [ShowCamping, setShowCamping] = useState(true);
  const [ShowHelping, setShowHelping] = useState(true);
  const [ShowModal, setShowModal] = useState(false);
  const [ModalText, setModalText] = useState("");
  const [ShowMessageModal, setShowMessageModal] = useState(false);
  const [MessageModalContent, setMessageModalContent] = useState();
  const [ShowConfirmSaveDraft, setShowConfirmSaveDraft] = useState(false);
  const [ShowSaving, setShowSaving] = useState(false);
  const [ResetDogCount, setResetDogCount] = useState(0);
  const [DraftData, setDraftData] = useState();
  const [HasCamping, setHasCamping] = useState(false);
  const [ShowDraftModal, setShowDraftModal] = useState(false);
  const [ShowDraftCheck, setShowDraftCheck] = useState(false);
  const [LatesOpen, setLatesOpen] = useState(false);

  const { User } = useContext(UserContext);
  const { ShowId } = useParams();

  let TotalSteps = useRef(6);

  useEffect(() => {
    const LoadData = async () => {
      try {
        setShowDraftCheck(true);

        let req, res;

        //Does show have camping?
        let _hasCamping = false;
        req = await apiGet(`/shows/singlenight/list/${ShowId}`);
        res = await req.json();
        if (res.length > 0) _hasCamping = true;
        req = await apiGet(`/admin/shows/camping/packs/${ShowId}`);
        res = await req.json();
        if (res.length > 0) _hasCamping = true;
        setHasCamping(_hasCamping);

        //Details of the show itself
        req = await apiGet(`/shows/${ShowId}`);
        res = await req.json();
        setShowName(res.DisplayName);

        const latesOpen = moment(res.EntriesClose).add(5762, "minutes");
        const latesAreOpen = latesOpen.isSameOrBefore(moment());
        if (latesAreOpen) setLatesOpen(true);

        if (!FirstTime) {
          //Entries I already have
          req = await apiGet(`/shows/classes/entries/${ShowId}/${User.Id}`);
          res = await req.json();

          const UniqueDogIds = new Set();
          for (const row of res) UniqueDogIds.add(row.DogId);

          const output = [];
          for (const Id of [...UniqueDogIds]) {
            const Dog = {
              Id,
              Choices: [],
            };

            const rows = res.filter((r) => r.DogId === Id);
            for (const row of rows) {
              const {
                ShowId,
                MemberId,
                HandlerId,
                DogId,
                ClassDate,
                ClassId,
                HeightId,
                LevelId,
                FirstName,
                LastName,
                IsPreEntry,
                IsLateEntry,
              } = row;

              const obj = {
                SelectedClassType: row.ClassType,
                ShowId,
                MemberId,
                HandlerId,
                DogId,
                ClassDate,
                ClassId,
                HeightId,
                LevelId,
                FirstName,
                LastName,
                IsPreEntry,
                IsLateEntry,
              };

              if (row.ClassType === 6) {
                const data = {
                  ShowId,
                  LeadDogId: DogId,
                  ClassId,
                  ClassDate,
                };
                let tpReq = await apiPost("/shows/teampairentries", data);
                let tpRes = await tpReq.json();
                let firstRow = tpRes[0];
                obj.Team = {};
                obj.Team.TeamName = firstRow.TeamName;
                const secondDog = {
                  Id: firstRow.Id,
                  PetName: firstRow.PetName,
                  HeightId: firstRow.HeightId,
                  Sector: firstRow.Sector,
                  Handler: {
                    Id: firstRow.HandlerId,
                    Name: `${firstRow.FirstName} ${firstRow.LastName}`,
                  },
                };
                obj.Team.SecondDog = secondDog;

                firstRow = tpRes[1];
                const thirdDog = {
                  Id: firstRow.Id,
                  PetName: firstRow.PetName,
                  HeightId: firstRow.HeightId,
                  Handler: {
                    Id: firstRow.HandlerId,
                    Name: `${firstRow.FirstName} ${firstRow.LastName}`,
                  },
                };
                obj.Team.ThirdDog = thirdDog;
              }
              if (row.ClassType === 9) {
                const data = {
                  ShowId,
                  LeadDogId: DogId,
                  ClassId,
                  ClassDate,
                };
                let tpReq = await apiPost("/shows/teampairentries", data);
                let tpRes = await tpReq.json();
                let firstRow = tpRes[0];
                obj.Pair = {};
                const secondDog = {
                  Id: firstRow.Id,
                  PetName: firstRow.PetName,
                  HeightId: firstRow.HeightId,
                  Sector: firstRow.Sector,
                  Handler: {
                    Id: firstRow.HandlerId,
                    Name: `${firstRow.FirstName} ${firstRow.LastName}`,
                  },
                };
                obj.Pair.SecondDog = secondDog;
              }

              Dog.Choices.push(obj);
            }
            output.push(Dog);
          }
          setDogs(output);
          setCurrentStep(2);
        } else {
          //Get a draft entry if there is one
          req = await apiGet(`/shows/draftentry/${ShowId}/${User.Id}`);
          if (req && req.ok) {
            res = await req.json();
            setDraftData(res);
            setShowDraftModal(true);
          } else {
            setCurrentStep(1);
          }
        }

        //User preferences
        req = await apiGet(`/members/account/${User.Id}`);
        res = await req.json();
        switch (res.PostRunningOrders) {
          case 0:
            setPostage(false);
            setShowPostage(true);
            break;

          case 1:
            setPostage(latesAreOpen ? false : true);
            setShowPostage(false);
            break;

          case 2:
            setPostage(false);
            setShowPostage(false);
            break;

          default:
            break;
        }
        setShowCamping(res.ShowCamping);
        setShowHelping(res.ShowHelping);
        setShowDraftCheck(false);
      } catch (error) {
        console.log(error.message);
        setModalText(
          <>
            <p>
              <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1x" />
              Error loading existing runs
            </p>
            <p className="mt-2x">
              <button className="button button-blue" onClick={() => setShowModal(false)}>
                OK
              </button>
            </p>
          </>
        );
        setShowModal(true);
        setShowDraftCheck(false);
      }
    };

    LoadData();
  }, [FirstTime, ShowId, User.Id]);

  const LoadDraftData = () => {
    const res = DraftData;

    setDogs(res.Dogs);

    let camping = res.Camping;
    if (camping.Choices) {
      camping.Choices.forEach((itm, idx) => {
        camping.Choices[idx] = moment(itm);
      });
    }
    setCamping(camping);

    setHelping(res.Helping);
    setNotes(res.Notes);
    setPostage(res.Postage);
    setCurrentStep(2);

    setShowDraftModal(false);
  };

  const RemoveDraftData = async () => {
    try {
      await apiGet(`/shows/deletedraftentry/${ShowId}/${User.Id}`);
      setDraftData();
      setShowDraftModal(false);
    } catch (error) {}
  };

  const TeamsPairsOK = () => {
    let tpOK = true;
    for (const dog of Dogs) {
      for (const choice of dog.Choices) {
        if (choice.SelectedClassType === 9) {
          const pairDog = choice.Pair?.SecondDog;
          if (!pairDog) tpOK = false;

          if (tpOK) {
            const fieldList = ["Id", "PetName", "HeightId", "Sector"];
            for (const field of fieldList) {
              if (!pairDog[field]) tpOK = false;
            }
          }

          if (tpOK) {
            if (!pairDog.Handler?.Id) tpOK = false;
          }
        }

        if (choice.SelectedClassType === 6) {
          const secondDog = choice.Team?.SecondDog;
          if (!secondDog) tpOK = false;

          if (tpOK) {
            let fieldList = ["Id", "PetName", "HeightId"];
            for (const field of fieldList) {
              if (!secondDog[field]) tpOK = false;
            }
          }

          if (tpOK) {
            if (!secondDog.Handler?.Id) tpOK = false;
          }

          if (tpOK) {
            const thirdDog = choice.Team?.ThirdDog;
            if (!thirdDog) tpOK = false;

            if (tpOK) {
              let fieldList = ["Id", "PetName", "HeightId"];
              for (const field of fieldList) {
                if (!thirdDog[field]) tpOK = false;
              }
            }

            if (tpOK) {
              if (!thirdDog.Handler?.Id) tpOK = false;
            }
          }

          if (tpOK)
            if (!choice.Team?.TeamName || choice.Team.TeamName === "") tpOK = false;
        }
      }
    }
    return tpOK;
  };

  const NextStep = () => {
    //Check to see if in Teams / Pairs but not complete
    const tpOK = TeamsPairsOK();

    if (CanContinue && tpOK) {
      setCurrentStep((prevState) => {
        let newVal = prevState + 1;

        if (prevState === 2 && (!FirstTime || LatesOpen)) return 6;

        //Depending on preferences we might move to any one of several screens
        if (newVal === 3 && ((UsePreferences && !ShowCamping) || !HasCamping))
          newVal += 1;

        if (newVal === 4 && UsePreferences && !ShowHelping) newVal += 1;

        if (newVal === 5 && UsePreferences && !ShowPostage) newVal += 1;

        return newVal;
      });
    } else {
      if (tpOK) {
        setModalText(
          <>
            <p>
              <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1x" />
              Cannot continue - please check you have entered all required form fields
            </p>
            <p className="mt-2x">
              <button className="button button-blue" onClick={() => setShowModal(false)}>
                OK
              </button>
            </p>
          </>
        );
      } else {
        setModalText(
          <>
            <p>
              <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1x" />
              Cannot continue - You have not completed your Pairs/Teams entry details.
              Please click on the blue button to do this before moving on.
            </p>
            <p className="mt-2x">
              <button className="button button-blue" onClick={() => setShowModal(false)}>
                OK
              </button>
            </p>
          </>
        );
      }
      setShowModal(true);
    }
  };

  const PrevStep = () => {
    setCurrentStep((prevState) => {
      let newVal = prevState - 1;

      if (prevState === 6 && (!FirstTime || LatesOpen)) return 2;

      if (newVal === 5 && ((UsePreferences && !ShowPostage) || !HasCamping)) newVal -= 1;

      if (newVal === 4 && UsePreferences && !ShowHelping) newVal -= 1;

      if (newVal === 3 && UsePreferences && !ShowCamping) newVal -= 1;

      return newVal;
    });
  };

  const SaveDraft = async () => {
    setShowConfirmSaveDraft(false);

    const data = {
      Dogs,
      Postage,
      Camping,
      Helping,
      Notes,
    };

    const obj = {
      ShowId,
      MemberId: User.Id,
      EntryData: JSON.stringify(data),
    };

    const req = await apiPost("/shows/draftentry", obj);
    if (req && req.ok) {
      setMessageModalContent(
        <>
          <p>
            <FontAwesomeIcon icon={faInfoCircle} className="mr-1x" />
            Your draft entry has been saved.
          </p>
          <p>
            <button className="button" onClick={() => props.history.push("/shows")}>
              OK
            </button>
          </p>
        </>
      );
      setShowMessageModal(true);
    } else {
      setModalText(
        <p>
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1x" />
          Error saving draft entry
        </p>
      );
      setShowModal(true);
    }
  };

  const SaveChoices = async () => {
    if (CanContinue) {
      const itm = sessionStorage.getItem("IsPaper");
      const isPaper = itm && itm === "true";

      setShowSaving(true);

      let obj = {
        ShowId,
        MemberId: User.Id,
        Dogs,
      };

      if (FirstTime) {
        obj.Camping = Camping;
        obj.Postage = Postage;
        obj.Helping = Helping;
        obj.Notes = Notes;
        obj.IsPaper = isPaper;
      }

      const req = await apiPost("/shows/classes/entries", obj);
      if (req && req.ok) {
        const res = await req.json();
        if (res.Fails.length === 0) {
          props.history.push(`/shows/entry/${ShowId}`);
        } else {
          setModalText(
            <>
              <p>
                <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1x" />
                Your entry could not be saved in full
              </p>
              {res.Fails.map((itm, idx) => (
                <p key={idx}>
                  {itm.Choice.Team ? `Team ${itm.Choice.Team.TeamName}` : "Pair"} - the
                  following dogs have been chosen by someone else since you began this
                  process.
                  <br />
                  {itm.Outcome.map((subItm, subIdx) => (
                    <React.Fragment key={subIdx}>
                      <span>
                        {subItm.PetName}, owned by {subItm.Owner}
                      </span>
                      <br />
                    </React.Fragment>
                  ))}
                </p>
              ))}
              <p>
                Please check your entry confirmation screen / email for exact details of
                your final entry.
              </p>
              <p className="mt-2x mb-2x">
                <button
                  className="button button-blue"
                  onClick={() => props.history.push(`/shows/entry/${ShowId}`)}
                >
                  OK
                </button>
              </p>
            </>
          );
          setShowSaving(false);
          setShowModal(true);
        }
      } else {
        setModalText(
          <>
            <p>
              <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1x" />
              Error saving entry
            </p>
            <p className="mt-2x mb-2x">
              <button className="button button-blue" onClick={() => setShowModal(false)}>
                OK
              </button>
            </p>
          </>
        );
        setShowModal(true);
      }
    } else {
      setModalText(
        <>
          <p>
            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1x" />
            You must agree to the declaration in order to save this entry
          </p>
          <p className="mt-2x mb-2x">
            <button className="button button-blue" onClick={() => setShowModal(false)}>
              OK
            </button>
          </p>
        </>
      );
      setShowModal(true);
    }
  };

  const AddDog = () => {
    const tpOK = TeamsPairsOK();

    if (tpOK) {
      window.scrollTo(0, 0);
      const dd = document.getElementById("SelectedDog");
      dd.focus();
      setResetDogCount((prev) => (prev += 1));
    } else {
      setModalText(
        <>
          <p>
            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1x" />
            Cannot continue - You have not completed your Pairs/Teams entry details.
            Please click on the blue button to do this before moving on.
          </p>
          <p className="mt-2x">
            <button className="button button-blue" onClick={() => setShowModal(false)}>
              OK
            </button>
          </p>
        </>
      );
      setShowModal(true);
    }
  };

  if (ShowSaving)
    return <ModalLoading Message="Saving your entry, please wait a moment..." />;

  if (ShowDraftCheck)
    return <ModalLoading Message="Checking for a draft entry, please wait a moment..." />;

  return (
    <div className="card">
      <div className="card-header">
        {FirstTime
          ? `Make an entry for ${ShowName}`
          : `Add runs for my entry into ${ShowName}`}
      </div>
      <div className="card-body">
        {ShowDraftModal && (
          <Modal>
            <h3>Draft Entry</h3>
            <p>
              You have a previously saved draft entry available. Do you wish to resume
              that entry? If you click 'No' that draft will be permanently deleted.
            </p>
            <p className="mt-2x">
              <button className="button button-green mr-1x" onClick={LoadDraftData}>
                Yes
              </button>
              <button className="button" onClick={RemoveDraftData}>
                No
              </button>
            </p>
          </Modal>
        )}
        {ShowConfirmSaveDraft && (
          <Modal>
            <p>
              You are not entered into this show until you return and complete this entry
              process. If the show caps in the meantime (if applicable), you will not be
              entered.
            </p>
            <p>
              <button className="button mr-1x" onClick={SaveDraft}>
                OK
              </button>
              <button className="button" onClick={() => setShowConfirmSaveDraft(false)}>
                Cancel
              </button>
            </p>
          </Modal>
        )}
        {ShowModal && <Modal>{ModalText}</Modal>}
        {ShowMessageModal && <Modal>{MessageModalContent}</Modal>}

        <div className="pt-2x pl-2x pb-2x">
          {
            {
              1: (
                <MyEntryPreferences
                  SetCanContinue={(val) => setCanContinue(val)}
                  onChoice={(val) => setUsePreferences(val)}
                  UsePreferences={UsePreferences}
                  SetUsePreferences={(val) => setUsePreferences(val)}
                />
              ),
              2: (
                <MyEntryDogsAndClasses
                  SetCanContinue={(val) => setCanContinue(val)}
                  ResetDogCount={ResetDogCount}
                  UsePreferences={UsePreferences}
                  SetDogs={(val) => setDogs(val)}
                  Dogs={Dogs}
                  FirstTime={FirstTime}
                />
              ),
              3: (
                <MyEntryCamping
                  SetCanContinue={(val) => setCanContinue(val)}
                  Camping={Camping}
                  SetCamping={(val) => setCamping(val)}
                />
              ),
              4: (
                <MyEntryHelping
                  SetCanContinue={(val) => setCanContinue(val)}
                  Helping={Helping}
                  SetHelping={(val) => setHelping(val)}
                  Notes={Notes}
                  SetNotes={(val) => setNotes(val)}
                />
              ),
              5: (
                <MyEntryPostage
                  SetCanContinue={(val) => setCanContinue(val)}
                  Postage={Postage}
                  SetPostage={(val) => setPostage(val)}
                />
              ),
              6: (
                <MyEntryDeclaration
                  LatesOpen={LatesOpen}
                  SetCanContinue={(val) => setCanContinue(val)}
                  HasCamping={HasCamping}
                  Dogs={Dogs}
                  Helping={Helping}
                  Notes={Notes}
                  Camping={Camping}
                  Postage={Postage}
                  FirstTime={FirstTime}
                />
              ),
            }[CurrentStep]
          }
        </div>
      </div>
      <div className="card-footer">
        {CurrentStep !== 1 && (
          <button className="button button-outline mr-1x" onClick={PrevStep}>
            Previous
          </button>
        )}
        {CurrentStep === 2 && Dogs && Dogs.length !== 0 && (
          <button className="button button-outline mr-1x" onClick={AddDog}>
            Add entries for another dog
          </button>
        )}
        {CurrentStep >= 2 &&
          CurrentStep !== TotalSteps.current &&
          Dogs &&
          Dogs.length > 0 &&
          FirstTime && (
            <button
              className="button button-outline mr-1x"
              onClick={() => setShowConfirmSaveDraft(true)}
            >
              Save and return later
            </button>
          )}
        {CurrentStep !== TotalSteps.current && (
          <button className="button button-green mr-1x" onClick={NextStep}>
            Next
          </button>
        )}
        {CurrentStep === TotalSteps.current && (
          <button className="button button-green mr-1x" onClick={SaveChoices}>
            Finish
          </button>
        )}
        {!FirstTime && (
          <button
            className="button"
            onClick={() => props.history.push(`/shows/entry/${ShowId}`)}
          >
            Cancel
          </button>
        )}
      </div>
    </div>
  );
};

export default withRouter(MyEntry);
