import React, { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { apiGet } from "../../../shared/api";
import { useInterval } from "beautiful-react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { LeaderboardAsPdf } from "./leaderboardaspdf";
import moment from "moment";
import { DownloadFile } from "../../../shared/clientsidefiledownload";
import ClassResult from "./classresult";
import { Document, pdf } from "@react-pdf/renderer";

const ResultsView = () => {
  const [All, setAll] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [FirstLoad, setFirstLoad] = useState(true);
  const history = useHistory();

  const { ClassId, LevelId, HeightId } = useParams();

  const RefreshData = useCallback(async () => {
    try {
      setLoading(true);
      let req, res;
      let itm = sessionStorage.getItem("ShowDate");
      if (itm) itm = moment(itm).utc();
      const ShowId = sessionStorage.getItem("ShowId");

      req = await apiGet(`/admin/shows/classes/listcombinations/${ShowId}`);
      res = await req.json();
      const today = res.filter((c) => moment(c.ClassDate).isSame(itm, "day"));
      if (ClassId && LevelId && HeightId) {
        if (ClassId === "36" || ClassId === "37") {
          let HeightLabel;
          switch (parseInt(HeightId)) {
            case 14:
              HeightLabel = "250";
              break;
            case 1:
              HeightLabel = "300";
              break;
            case 2:
              HeightLabel = "400";
              break;
            case 3:
              HeightLabel = "500";
              break;
            case 4:
              HeightLabel = "600";
              break;
            default:
              break;
          }

          if (HeightId !== "0") {
            if (ClassId === "36") {
              setAll([
                {
                  ClassId: 36,
                  ClassLabel: "The Performance Agility Masters Series Agility",
                  LevelLabel: "",
                  LevelId: 5,
                  ClassDate: today[0].ClassDate,
                  HeightId,
                  HeightLabel,
                },
              ]);
            } else if (ClassId === "37") {
              setAll([
                {
                  ClassId: 37,
                  ClassLabel: "The Performance Agility Masters Series Jumping",
                  LevelLabel: "",
                  LevelId: 5,
                  ClassDate: today[0].ClassDate,
                  HeightId,
                  HeightLabel,
                },
              ]);
            }
          } else {
            if (ClassId === "36") {
              setAll([
                {
                  ClassId: 36,
                  ClassLabel: "The Performance Agility Masters Series Agility",
                  LevelLabel: "",
                  LevelId: 5,
                  ClassDate: today[0].ClassDate,
                  HeightId: 14,
                  HeightLabel: "250",
                },
                {
                  ClassId: 36,
                  ClassLabel: "The Performance Agility Masters Series Agility",
                  LevelLabel: "",
                  LevelId: 5,
                  ClassDate: today[0].ClassDate,
                  HeightId: 1,
                  HeightLabel: "300",
                },
                {
                  ClassId: 36,
                  ClassLabel: "The Performance Agility Masters Series Agility",
                  LevelLabel: "",
                  LevelId: 5,
                  ClassDate: today[0].ClassDate,
                  HeightId: 2,
                  HeightLabel: "400",
                },
                {
                  ClassId: 36,
                  ClassLabel: "The Performance Agility Masters Series Agility",
                  LevelLabel: "",
                  LevelId: 5,
                  ClassDate: today[0].ClassDate,
                  HeightId: 3,
                  HeightLabel: "500",
                },
                {
                  ClassId: 36,
                  ClassLabel: "The Performance Agility Masters Series Agility",
                  LevelLabel: "",
                  LevelId: 5,
                  ClassDate: today[0].ClassDate,
                  HeightId: 4,
                  HeightLabel: "600",
                },
              ]);
            } else if (ClassId === "37") {
              setAll([
                {
                  ClassId: 37,
                  ClassLabel: "The Performance Agility Masters Series Jumping",
                  LevelLabel: "",
                  LevelId: 5,
                  ClassDate: today[0].ClassDate,
                  HeightId: 14,
                  HeightLabel: "250",
                },
                {
                  ClassId: 37,
                  ClassLabel: "The Performance Agility Masters Series Jumping",
                  LevelLabel: "",
                  LevelId: 5,
                  ClassDate: today[0].ClassDate,
                  HeightId: 1,
                  HeightLabel: "300",
                },
                {
                  ClassId: 37,
                  ClassLabel: "The Performance Agility Masters Series Jumping",
                  LevelLabel: "",
                  LevelId: 5,
                  ClassDate: today[0].ClassDate,
                  HeightId: 2,
                  HeightLabel: "400",
                },
                {
                  ClassId: 37,
                  ClassLabel: "The Performance Agility Masters Series Jumping",
                  LevelLabel: "",
                  LevelId: 5,
                  ClassDate: today[0].ClassDate,
                  HeightId: 3,
                  HeightLabel: "500",
                },
                {
                  ClassId: 37,
                  ClassLabel: "The Performance Agility Masters Series Jumping",
                  LevelLabel: "",
                  LevelId: 5,
                  ClassDate: today[0].ClassDate,
                  HeightId: 4,
                  HeightLabel: "600",
                },
              ]);
            }
          }
        } else {
          let found = today.filter((c) => c.ClassId === parseInt(ClassId) && c.LevelId === parseInt(LevelId));
          if (HeightId !== "0") found = found.filter((c) => c.HeightId === parseInt(HeightId));
          setAll(found);
        }
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
      setFirstLoad(false);
    }
  }, [ClassId, LevelId, HeightId]);

  useEffect(() => {
    RefreshData();
  }, [RefreshData]);

  useInterval(() => {
    RefreshData();
  }, 20000);

  const doPdf = async () => {
    try {
      const output = [];
      let dt = sessionStorage.getItem("ShowDate");
      const ShowId = sessionStorage.getItem("ShowId");
      let req, res;
      for (const itm of All) {
        req = await apiGet(`/admin/shows/classes/leaderboard/${ShowId}/${ClassId}/${LevelId}/${itm.HeightId}/${dt}`);
        res = await req.json();

        const data = JSON.parse(res.LeaderboardData);
        const result = await LeaderboardAsPdf(data);
        output.push(result.Page);
      }

      const doc = <Document>{output}</Document>;
      const blob = await pdf(doc).toBlob();

      DownloadFile(blob, "Output.pdf");
    } catch (error) {
      window.alert(error.message);
    }
  };

  return (
    <div className="mt-3x">
      <p>
        <button className="button button-blue" onClick={() => history.go(-1)}>
          Go back
        </button>
      </p>

      {Loading && FirstLoad ? (
        <h3 className="mt-3x">Checking for results</h3>
      ) : (
        <>
          {HeightId === "0" && (
            <p className="mt-3x">
              <span className="cursor-pointer text-link" onClick={doPdf}>
                <FontAwesomeIcon icon={faFilePdf} size="lg" className="mr-1x" />
                Get Pdf
              </span>
            </p>
          )}

          {All.map((itm, idx) => (
            <ClassResult
              key={idx}
              ClassId={itm.ClassId}
              LevelId={itm.LevelId}
              HeightId={itm.HeightId}
              ShowLastThree={All.length === 1}
              ShowPdfLink={All.length === 1}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default ResultsView;
