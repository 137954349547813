import React from "react";
import { Page, View, Text, Image } from "@react-pdf/renderer";
import { apiPost } from "../../../shared/api";
import moment from "moment";
import dayjs from "dayjs";

export const LeaderboardAsSinglePdf = async (data) => {
  console.log(data);

  const localStyles = {
    header: {
      row: {
        display: "flex",
        flexDirection: "row",
      },
      centre: {
        flex: 0.7,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },
      side: {
        flex: 0.15,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      sponsorImage: {
        height: 70,
        marginLeft: 15,
      },
    },
    margins: {
      t1: { marginTop: 5 },
      t2: { marginTop: 10 },
      t3: { marginTop: 15 },
      b1: { marginBottom: 5 },
      b2: { marginBottom: 10 },
      b3: { marginBottom: 15 },
      l1: { marginLeft: 5 },
      l2: { marginLeft: 10 },
      l3: { marginLeft: 15 },
      r1: { marginRight: 15 },
      r2: { marginRight: 20 },
      r3: { marginRight: 25 },
    },
    text: {
      small: { fontSize: 9 },
      medium: { fontSize: 11 },
      bold: { fontWeight: 500 },
    },
    table: {
      container: {
        display: "flex",
        flexDirection: "column",
        padding: 20,
        width: "100%",
      },
      row: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignItems: "flex-start",
      },
      cell1: {
        flex: 0.1,
      },
      cell2: {
        flex: 0.2,
      },
      cell3: {
        flex: 0.3,
      },
      cell4: { flex: 0.4 },
      cell5: { flex: 0.5 },
      cell6: { flex: 0.6 },
      cell7: { flex: 0.7 },
      cell8: { flex: 0.8 },
      cell9: { flex: 0.9 },
      cell10: { flex: 1 },
      cellHeader: { fontWeight: 500, fontSize: 12 },
      cellText: { fontSize: 11 },
    },
    sponsorImage: {
      width: "25mm",
      height: "auto",
    },
  };

  try {
    let sponsorImage;
    try {
      let itm = sessionStorage.getItem("ShowId");
      let ShowId = itm;
      itm = moment.utc(data.ClassDetails.ClassDate);
      let ShowDate = itm;
      const obj = {
        ShowId,
        ClassId: data.ClassId,
        ShowDate,
      };
      const req = await apiPost("/shows/class/logo", obj);
      if (req && req.ok) {
        const res = await req.json();
        sponsorImage = res.Image;
      }
    } catch (error) {
      console.log(error.message);
    }

    const ClassIdsWithFaultPoints = [30];
    const ClassIdsForHeats = [29, 30, 31];
    const isHeat = ClassIdsForHeats.some((i) => i === data.ClassId);
    const isFaultPoints = ClassIdsWithFaultPoints.some((i) => i === data.ClassId);
    const isComplete = data.Data.Status === "Closed";
    const isGamblers = data.ClassId === 4 || data.ClassId === 5 || data.ClassId === 6;
    const isSnooker = data.ClassId === 12;
    const isTFO = data.ClassId === 22;
    const isSnakes = data.ClassId === 11;
    const Eliminated = data.Others?.filter((r) => r.Eliminated) ?? [];
    const NFC = data.Others?.filter((r) => r.NFC) ?? [];
    const NoShows = data.Others?.filter((r) => r.NoShow) ?? [];
    const StillToRun = data.StillToRun ?? [];
    const IsMasters = data.ClassId === 36 || data.ClassId === 37;
    const IsMastersOverall = data.ClassId === 26;

    let HeightLabel = "",
      mastersClassName = "";
    if (data.ClassId === 26 || data.ClassId === 36 || data.ClassId === 37) {
      switch (data.HeightId) {
        case 14:
          HeightLabel = "250";
          break;
        case 1:
          HeightLabel = "300";
          break;
        case 2:
          HeightLabel = "400";
          break;
        case 3:
          HeightLabel = "500";
          break;
        case 4:
          HeightLabel = "600";
          break;
        default:
          break;
      }
      switch (data.ClassId) {
        case 26:
          mastersClassName = `The Performance Agility Masters Series ${HeightLabel}`;
          break;
        case 36:
          mastersClassName = `The Performance Agility Masters Series Agility ${HeightLabel}`;
          break;
        case 37:
          mastersClassName = `The Performance Agility Masters Series Jumping ${HeightLabel}`;
          break;
      }
    }
    let className;
    switch (data.ClassId) {
      case 29:
      case 30:
      case 31:
        className = data.ClassDetails.ClassLabel;
        break;
      case 36:
      case 37:
        className = mastersClassName;
        break;
      default:
        className = data.Data.ClassLabel;
        break;
    }

    return (
      <Page size="A4" orientation={IsMasters || IsMastersOverall ? "landscape" : "portrait"}>
        <View style={[localStyles.margins.b2, localStyles.margins.t2, localStyles.header.row]}>
          <View style={localStyles.header.side}>
            {sponsorImage && (
              <Image style={localStyles.header.sponsorImage} source={{ uri: `data:image;base64,${sponsorImage}` }} />
            )}
          </View>
          <View style={localStyles.header.centre}>
            <Text>
              {!IsMasters && !IsMastersOverall && `${data?.ClassDetails.ClassLabel} ${data?.ClassDetails.HeightLabel}`}
              {(IsMastersOverall || IsMasters) && className}
            </Text>
            {(IsMasters || IsMastersOverall) && (
              <Text style={{ fontSize: 16 }}>Produced {moment().format("Do MMM YYYY - HH:mm")}</Text>
            )}
            <View style={localStyles.margins.mb1}>
              <Text style={localStyles.text.small}>Produced {dayjs().format("Do MMM YYYY - HH:mm")}</Text>
            </View>
            {data.Data?.CourseLength !== "" && (
              <View style={localStyles.margins.t2}>
                <Text style={localStyles.text.medium}>
                  Course: {data.Data?.CourseLength}m, {data.Data?.CourseTime}
                  s. Judge: {data.Data?.JudgeName}
                </Text>
              </View>
            )}
          </View>
          <View style={localStyles.header.side}></View>
        </View>
        <View style={localStyles.table.container}>
          <View style={localStyles.table.row}>
            <View style={localStyles.table.cell1}>
              {!IsMasters && !IsMastersOverall && (
                <Text style={localStyles.table.cellHeader}>{isComplete ? "Award" : "Position"}</Text>
              )}
              {(IsMasters || IsMastersOverall) && <Text style={localStyles.table.cellHeader}>Position</Text>}
            </View>
            <View style={localStyles.table.cell3}>
              <Text style={localStyles.table.cellHeader}>Handler</Text>
            </View>
            {!IsMasters && !IsMastersOverall && (
              <>
                <View style={data.ClassId === 30 ? localStyles.table.cell2 : localStyles.table.cell3}>
                  <Text style={localStyles.table.cellHeader}>
                    {{
                      4: "Opening / Gamble / Total",
                      5: "Points",
                      6: "Points",
                      12: "Score",
                      11: "Obstacles",
                      22: "Obstacles",
                    }[data.ClassId] || "Faults"}
                  </Text>
                </View>
                <View style={data.ClassId === 30 ? localStyles.table.cell2 : localStyles.table.cell1}>
                  <Text style={localStyles.table.cellHeader}>Time</Text>
                </View>
              </>
            )}
            {IsMasters && (
              <>
                <View style={localStyles.table.cell2}>
                  <Text style={localStyles.table.cellHeader}>Faults</Text>
                </View>
                <View style={localStyles.table.cell2}>
                  <Text style={localStyles.table.cellHeader}>Time</Text>
                </View>
                <View style={localStyles.table.cell2}>
                  <Text style={localStyles.table.cellHeader}>Total Time</Text>
                </View>
              </>
            )}
            {IsMastersOverall && (
              <>
                <View style={localStyles.table.cell2}>
                  <Text style={localStyles.table.cellHeader}>Jumping Faults</Text>
                </View>
                <View style={localStyles.table.cell2}>
                  <Text style={localStyles.table.cellHeader}>Jumping Time</Text>
                </View>
                <View style={localStyles.table.cell2}>
                  <Text style={localStyles.table.cellHeader}>Jumping Score</Text>
                </View>
                <View style={localStyles.table.cell2}>
                  <Text style={localStyles.table.cellHeader}>Agility Faults</Text>
                </View>
                <View style={localStyles.table.cell2}>
                  <Text style={localStyles.table.cellHeader}>Agility Time</Text>
                </View>
                <View style={localStyles.table.cell2}>
                  <Text style={localStyles.table.cellHeader}>Agility Score</Text>
                </View>
                <View style={localStyles.table.cell2}>
                  <Text style={localStyles.table.cellHeader}>Total Score</Text>
                </View>
              </>
            )}
            {!isHeat && !IsMasters && !IsMastersOverall && isComplete && (
              <View style={localStyles.table.cell1}>
                <Text style={localStyles.table.cellHeader}>Points</Text>
              </View>
            )}
            {data.ClassId === 30 && (
              <>
                <View style={{ flex: 0.15 }}>
                  <Text style={localStyles.table.cellHeader}>Agility</Text>
                </View>
                <View style={{ flex: 0.15 }}>
                  <Text style={localStyles.table.cellHeader}>Jump</Text>
                </View>
              </>
            )}
          </View>
          <View style={[localStyles.margins.b3, { flexDirection: "column" }]}>
            {data.Runs?.length === 0 && (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  justifyContent: "center",
                }}
              >
                <Text>There are no results for this class</Text>
              </View>
            )}

            {data.Runs?.map((itm, idx) => {
              return (
                <View key={idx} style={[localStyles.table.row, localStyles.margins.b1]}>
                  <View style={localStyles.table.cell1}>
                    {!IsMasters && !IsMastersOverall && (
                      <Text style={localStyles.table.cellText}>
                        {isComplete
                          ? isHeat
                            ? itm.Position && parseInt(itm.Position.toString().replace("T", "")) < 3
                              ? itm.Position
                              : ""
                            : itm.Award
                          : itm.Position && parseInt(itm.Position.toString().replace("T", "")) < 3
                          ? itm.Position
                          : ""}
                        {isComplete && itm.Trophy && "(Tr)"}
                      </Text>
                    )}
                    {(IsMasters || IsMastersOverall) && (
                      <Text style={localStyles.table.cellText}>{idx < 2 && idx + 1}</Text>
                    )}
                  </View>
                  <View style={localStyles.table.cell3}>
                    <Text style={localStyles.table.cellText}>
                      {itm.HandlerFirst} {itm.HandlerLast} & {itm.PetName}
                    </Text>
                  </View>
                  {!IsMasters && !IsMastersOverall && (
                    <>
                      <View
                        style={[
                          data.ClassId === 30 ? localStyles.table.cell2 : localStyles.table.cell3,
                          { display: "flex", flexDirection: "row" },
                        ]}
                      >
                        {isGamblers && (
                          <Text style={localStyles.table.cellText}>
                            {itm.Gamblers.OpeningPoints} / {itm.Gamblers.GamblePoints}{" "}
                            {itm.Gamblers.GamblePoints > 0 && itm.TimeFaults && parseFloat(itm.TimeFaults).toFixed(3)
                              ? "T"
                              : ""}{" "}
                            / {itm.Gamblers.TotalPoints}
                          </Text>
                        )}

                        {isTFO && <Text style={localStyles.table.cellText}>{itm.ObstaclesCompleted}</Text>}

                        {isSnakes && <Text style={localStyles.table.cellText}>{itm.SnakesAndLadders.TotalScore}</Text>}

                        {isSnooker && <Text style={localStyles.table.cellText}>{itm.Snooker.TotalPoints}</Text>}

                        {!isFaultPoints &&
                          !isSnakes &&
                          !isTFO &&
                          !isGamblers &&
                          !isSnooker &&
                          itm.FaultList.length > 0 && (
                            <Text style={localStyles.table.cellText}>
                              {itm.FaultList.join(", ")}
                              {"    "}
                            </Text>
                          )}
                        {!isFaultPoints &&
                          !isSnakes &&
                          !isTFO &&
                          !isGamblers &&
                          !isSnooker &&
                          itm.FaultList.length === 0 && <Text style={localStyles.table.cellText}>{"0    "}</Text>}
                        {!isFaultPoints && itm.TimeFaults > 0 && !isSnakes && !isTFO && !isGamblers && !isSnooker && (
                          <Text style={localStyles.table.cellText}>T{parseFloat(itm.TimeFaults).toFixed(3)}</Text>
                        )}
                        {isFaultPoints && <Text style={localStyles.table.cellText}>{itm.Faults}</Text>}
                      </View>
                      <View style={data.ClassId === 30 ? localStyles.table.cell2 : localStyles.table.cell1}>
                        <Text style={localStyles.table.cellText}>{parseFloat(itm.TotalTime).toFixed(3)}</Text>
                      </View>
                    </>
                  )}
                  {!isHeat && !IsMasters && !IsMastersOverall && isComplete && (
                    <View style={localStyles.table.cell1}>
                      <Text style={localStyles.table.cellText}>{itm.ProgressionPoints}</Text>
                    </View>
                  )}
                  {IsMasters && (
                    <>
                      <View style={localStyles.table.cell2}>
                        <Text style={localStyles.table.cellHeader}>
                          <>
                            {itm.FaultList.length === 0 && "0"}
                            {itm.FaultList.join(", ")}
                            {itm.TimeFaults && itm.TimeFaults > 0 ? (
                              <Text> T{parseFloat(itm.TimeFaults).toFixed(3)}</Text>
                            ) : (
                              <></>
                            )}
                          </>
                        </Text>
                      </View>
                      <View style={localStyles.table.cell2}>
                        <Text style={localStyles.table.cellHeader}>
                          {itm.RunTime && parseFloat(itm.RunTime).toFixed(3)}
                        </Text>
                      </View>
                      <View style={localStyles.table.cell2}>
                        <Text style={localStyles.table.cellHeader}>
                          {itm.TotalTime && parseFloat(itm.TotalTime).toFixed(3)}
                        </Text>
                      </View>
                    </>
                  )}
                  {IsMastersOverall && (
                    <>
                      <View style={localStyles.table.cell2}>
                        <Text style={localStyles.table.cellHeader}>
                          {itm.JumpingRun.FaultList.join(", ")}
                          {itm.JumpingRun.FaultList.length === 0 &&
                            !itm.JumpingRun.Eliminated &&
                            !itm.JumpingRun.NoShow &&
                            "0"}
                          {itm.JumpingRun.FaultList.length === 0 && itm.JumpingRun.Eliminated && "50"}
                          {itm.JumpingRun.FaultList.length === 0 && itm.JumpingRun.NoShow && "1000"}
                          {itm.JumpingRun.TimeFaults ? ` T${parseFloat(itm.JumpingRun.TimeFaults).toFixed(3)}` : ""}
                        </Text>
                      </View>
                      <View style={localStyles.table.cell2}>
                        <Text style={localStyles.table.cellHeader}>
                          {!itm.JumpingRun.Eliminated &&
                            !itm.JumpingRun.NoShow &&
                            parseFloat(itm.JumpingRun.RunTime).toFixed(3)}
                          {(itm.JumpingRun.Eliminated || itm.JumpingRun.NoShow) && itm.JumpingRun.RunTime}
                        </Text>
                      </View>
                      <View style={localStyles.table.cell2}>
                        <Text style={localStyles.table.cellHeader}>
                          {!itm.JumpingRun.Eliminated &&
                            !itm.JumpingRun.NoShow &&
                            parseFloat(itm.JumpingRun.TotalTime).toFixed(3)}
                          {(itm.JumpingRun.Eliminated || itm.JumpingRun.NoShow) && itm.JumpingRun.TotalTime}
                        </Text>
                      </View>
                      <View style={localStyles.table.cell2}>
                        <Text style={localStyles.table.cellHeader}>
                          {itm.AgilityRun.FaultList.join(", ")}
                          {itm.AgilityRun.FaultList.length === 0 &&
                            !itm.AgilityRun.Eliminated &&
                            !itm.AgilityRun.NoShow &&
                            "0"}
                          {itm.AgilityRun.FaultList.length === 0 && itm.AgilityRun.Eliminated && "50"}
                          {itm.AgilityRun.FaultList.length === 0 && itm.AgilityRun.NoShow && "1000"}
                          {itm.AgilityRun.TimeFaults ? ` T${parseFloat(itm.AgilityRun.TimeFaults).toFixed(3)}` : ""}
                        </Text>
                      </View>
                      <View style={localStyles.table.cell2}>
                        <Text style={localStyles.table.cellHeader}>
                          {!itm.AgilityRun.Eliminated &&
                            !itm.AgilityRun.NoShow &&
                            parseFloat(itm.AgilityRun.RunTime).toFixed(3)}
                          {(itm.AgilityRun.Eliminated || itm.AgilityRun.NoShow) && itm.AgilityRun.RunTime}
                        </Text>
                      </View>
                      <View style={localStyles.table.cell2}>
                        <Text style={localStyles.table.cellHeader}>
                          {!itm.AgilityRun.Eliminated &&
                            !itm.AgilityRun.NoShow &&
                            parseFloat(itm.AgilityRun.TotalTime).toFixed(3)}
                          {(itm.AgilityRun.Eliminated || itm.AgilityRun.NoShow) && itm.AgilityRun.TotalTime}
                        </Text>
                      </View>
                      <View style={localStyles.table.cell2}>
                        <Text style={localStyles.table.cellHeader}>{parseFloat(itm.TotalTime).toFixed(3)}</Text>
                      </View>
                    </>
                  )}
                  {data.ClassId === 30 && (
                    <>
                      <View style={{ flex: 0.15 }}>
                        <Text style={localStyles.table.cellText}>
                          {itm.AgilityRun.Faults} ({parseFloat(itm.AgilityRun.RunTime).toFixed(3)})
                        </Text>
                      </View>
                      <View style={{ flex: 0.15 }}>
                        <Text style={localStyles.table.cellText}>
                          {itm.JumpingRun.Faults} ({parseFloat(itm.JumpingRun.RunTime).toFixed(3)})
                        </Text>
                      </View>
                    </>
                  )}
                </View>
              );
            })}
          </View>
          {Eliminated.length > 0 && (
            <View style={localStyles.margins.t3}>
              <View style={{ flexDirection: "column" }}>
                <View style={localStyles.margins.r1}>
                  <Text style={[localStyles.text.bold, localStyles.text.medium]}>Eliminated:</Text>
                </View>
                <View
                  style={[localStyles.margins.t2, localStyles.margins.b2, { flexDirection: "row", flexWrap: "wrap" }]}
                >
                  {Eliminated.map((itm, idx) => {
                    return (
                      <View>
                        <Text style={localStyles.text.medium}>
                          {itm.HandlerFirst} {itm.HandlerLast} &amp; {itm.PetName}
                          {itm.FaultList.length > 0 && <Text> ({itm.FaultList.join(", ")})</Text>}
                          {idx < Eliminated.length - 1 && ", "}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              </View>
            </View>
          )}
          {NFC.length > 0 && (
            <View style={localStyles.margins.t3}>
              <View style={{ flexDirection: "column" }}>
                <View style={localStyles.margins.r1}>
                  <Text style={[localStyles.text.bold, localStyles.text.medium]}>NFC:</Text>
                </View>
                <View
                  style={[localStyles.margins.t2, localStyles.margins.b2, { flexDirection: "row", flexWrap: "wrap" }]}
                >
                  {NFC.map((itm, idx) => {
                    return (
                      <View>
                        <Text style={localStyles.text.medium}>
                          {itm.HandlerFirst} {itm.HandlerLast} &amp; {itm.PetName}
                          {idx < NFC.length - 1 && ", "}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              </View>
            </View>
          )}
          {NoShows.length > 0 && (
            <View style={localStyles.margins.t3}>
              <View style={{ flexDirection: "column" }}>
                <View style={localStyles.margins.r1}>
                  <Text style={[localStyles.text.bold, localStyles.text.medium]}>No-Show:</Text>
                </View>
                <View
                  style={[localStyles.margins.t2, localStyles.margins.b2, { flexDirection: "row", flexWrap: "wrap" }]}
                >
                  {NoShows.map((itm, idx) => {
                    return (
                      <View>
                        <Text style={localStyles.text.medium}>
                          {itm.HandlerFirst} {itm.HandlerLast} &amp; {itm.PetName}
                          {idx < NoShows.length - 1 && ", "}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              </View>
            </View>
          )}
          {StillToRun.length > 0 && (
            <View style={localStyles.margins.t3}>
              <View style={{ flexDirection: "column" }}>
                <View style={localStyles.margins.r1}>
                  <Text style={[localStyles.text.bold, localStyles.text.medium]}>Did not run:</Text>
                </View>
                <View
                  style={[localStyles.margins.t2, localStyles.margins.b2, { flexDirection: "row", flexWrap: "wrap" }]}
                >
                  {StillToRun.map((itm, idx) => {
                    return (
                      <View>
                        <Text style={localStyles.text.medium}>
                          {itm.HandlerFirst} {itm.HandlerLast} &amp; {itm.PetName}
                          {idx < StillToRun.length - 1 && ", "}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              </View>
            </View>
          )}
        </View>
      </Page>
    );
  } catch (error) {
    console.log(error.message);
  }
};
