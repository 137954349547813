import React, {useState} from 'react';
import {apiPost} from '../../../shared/api.js';
import {GetPaymentMethod} from '../../../shared/wallet.js';
import moment from 'moment';
import numeral from 'numeral';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import {Workbook} from 'exceljs';
import {DownloadFile} from '../../../shared/clientsidefiledownload.js';
import InlineLoading from '../../../shared/InlineLoading';


function HandlerRegistrations(props) {

    const [Data, setData] = useState([]);
    const [StartDate, setStartDate] = useState(new Date());
    const [Loading, setLoading] = useState(false);


    const LoadData = async() => {

        const ERROR_MSG = 'Error loading data from server. Micky support time';

        try {

            setLoading(true);

            const data = {
                StartDate: moment(StartDate).format('DD/MM/YYYY')
            }

            const req = await apiPost('/admin/wallet/reports/memberfees', data);
            if(req && req.ok && req.status === 200) {
                const res = await req.json();
                setData(res);
                setLoading(false);
                return res;
            } else {
                setLoading(false);
                window.alert(ERROR_MSG);
            }
        }
        catch (error) {
            setLoading(false);
            console.log(error.message);
            window.alert(ERROR_MSG);
        }
    }



    const GetExcel = async() => {

        try {

            const data = await LoadData();

            let wb = new Workbook();
            let ws = wb.addWorksheet('Member Fees');

            ws.addRow(['Date', 'Id', 'Name', 'Description', 'Amount']);
            for (const itm of data)
                ws.addRow([`${moment(itm.DateTimeStamp).format('DD/MM/YYYY')}`,`${itm.Id}`,`${itm.FirstName} ${itm.LastName}`,`${itm.Detail}`,itm.Amount]); //', `${GetPaymentMethod(itm.Method)}`]);

            const buf = await wb.xlsx.writeBuffer();
            const blob =  new Blob([buf], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            DownloadFile(blob,'MemberFees.xlsx');

        }
        catch (error) {
            console.log(error.message);
            window.alert('Error producing Excel sheet');
        }

    }



    const LineItems = Data.map((itm,idx) => {


        return (
            <tr key={idx}>
                <td>{moment(itm.DateTimeStamp).format('DD/MM/YYYY')}</td>
                <td>{itm.Id}</td>
                <td>{itm.FirstName} {itm.LastName}</td>
                <td>{itm.Detail}</td>
                <td>£{numeral(itm.Amount).format('0.00')}</td>
                {/*<td>{GetPaymentMethod(itm)}</td>*/}
            </tr>
        );
    })


    return (
        <div className="card">
            <div className="card-header">Handler Registrations</div>
            <div className="card-body">

                <div className="mb-1x"><span className="mr-2x">Start Date: </span><DatePicker maxDate={new Date()} showMonthDropdown showYearDropdown className="form-control width-50 mr-2x" dateFormat="dd/MM/yyyy" value={StartDate} selected={StartDate} onChange={val => setStartDate(val)}></DatePicker></div>
                <div className="mb-2x"><button className="button mr-2x" onClick={LoadData}>View Data</button><button className="button" onClick={GetExcel}><FontAwesomeIcon icon={faFileExcel} className="mr-1x"></FontAwesomeIcon>Get Excel</button></div>

                {
                    Loading ? 
                    <div className="mb-2x"><InlineLoading /></div>
                    :
                    <></>
                }

                <table className="table">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Id</th>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Amount</th>
                            {/*<th>Method</th>*/}
                        </tr>
                    </thead>
                    <tbody>
                        {LineItems}
                    </tbody>
                </table>

            </div>
            <div className="card-footer"></div>
        </div>
    )
}

export default HandlerRegistrations;
