import React, {useEffect, useState, useCallback} from 'react';
import './progression.css';
import { apiGet } from '../../../shared/api.js';


function Progression(props) {
    
    const [PerformanceData, setPerformanceData] = useState([]);
    const [SteeplechaseData, setSteeplechaseData] = useState([]);
    const [Loading, setLoading] = useState(true);
    const [TransferText, setTransferText] = useState(<></>);
    const [AgilityPoints, setAgilityPoints] = useState(undefined);
    const [JumpingPoints, setJumpingPoints] = useState(undefined);
    const [GamesPoints, setGamesPoints] = useState(undefined);
    const [TotalPoints, setTotalPoints] = useState(undefined);
    const [AltAgilityPoints, setAltAgilityPoints] = useState(undefined);
    const [AltTotalPoints, setAltTotalPoints] = useState(undefined);
    const [SteeplechasePoints, setSteeplechasePoints] = useState(undefined);
    const [CurrentPerformanceLevel, setCurrentPerformanceLevel] = useState(null);
    const [CurrentSteeplechaseLevel, setCurrentSteeplechaseLevel] = useState(null);    
    const [NextPerformanceLevel, setNextPerformanceLevel] = useState(null);
    const [NextSteeplechaseLevel, setNextSteeplechaseLevel] = useState(null);
    const [YetToRunPerformance, setYetToRunPerformance] = useState(false);
    const [YetToRunSteeplechase, setYetToRunSteeplechase] = useState(false);
    const [ShowAltMessage, setShowAltMessage] = useState(false);


    const LoadData = useCallback(async() => {
        const  ERROR_MSG = 'Error loading progression data';

        try {

            const req = await apiGet(`/dogs/progressionpoints/${props.Dog.Id}`);
            if(req && req.ok && req.status === 200) {

                const res = await req.json();
                const pd = res[0]; //Performance
                const sd = res[1]; //Steeplechase
                const tl = res[2]; //Transfer-in, current and next levels
                setPerformanceData(pd);
                setSteeplechaseData(sd);
                if(tl[0].LevelLabel !== null)
                    setTransferText(`You transferred in at the ${tl[0].LevelLabel} level`);

                
                let LastRow;

                //Move-up required for Performance
                if(pd.length > 0 && tl.NextPerformanceLevel !== null) {
                    setCurrentPerformanceLevel(tl[0].CurrentPerformanceLevel);
                    setNextPerformanceLevel(tl[0].NextPerformanceLevel);
                    LastRow = pd[pd.length -1];
                    setAgilityPoints(LastRow.AgilityPointsNeeded);
                    setJumpingPoints(LastRow.JumpingPointsNeeded);
                    setGamesPoints(LastRow.GamesPointsNeeded);
                    setTotalPoints(LastRow.TotalPointsNeeded);
                    setAltAgilityPoints(LastRow.AltAgilityPointsNeeded);
                    setAltTotalPoints(LastRow.AltTotalPointsNeeded);

                    setShowAltMessage(
                        LastRow.AgilityPoints < LastRow.MU_AgilityPoints ||
                        LastRow.JumpingPoints < LastRow.MU_JumpingPoints ||
                        LastRow.GamesPoints < LastRow.MU_GamesPoints
                    );

                    //Dog may have moved up but not yet run at new level
                    setYetToRunPerformance(LastRow.LevelLabel !== tl[0].CurrentPerformanceLevel);
                }

                if(sd.length > 0 && tl.NextSteeplechaseLevel !== null) {
                    setCurrentSteeplechaseLevel(tl[0].CurrentSteeplechaseLevel);
                    setNextSteeplechaseLevel(tl[0].NextSteeplechaseLevel);
                    LastRow = sd[sd.length - 1];
                    setSteeplechasePoints(LastRow.SteeplechasePointsNeeded);

                    //Dog may have moved up but not yet run at new level
                    setYetToRunSteeplechase(LastRow.LevelLabel !== tl[0].CurrentSteeplechaseLevel);
                }

                setLoading(false);

            } else {
                window.alert(ERROR_MSG);
            }

        }
        catch(error) {
            window.alert(ERROR_MSG);
            console.log(error.message);
        }

        
    },[props.Dog]);



    //Get data
    useEffect(() => {
        LoadData();
    },[LoadData]);




    const PerformanceProgression = PerformanceData.map((itm,idx) => {

        const rowClass = idx + 1 < PerformanceData.length ? 'completed' : '';
        const itemClass = idx + 1 === PerformanceData.length ? 'text-red text-bold' : '';
        return (
            <tr key={idx} className={rowClass}>
                <td data-mobilelabel="Level:"><b>{itm.LevelLabel}</b></td>
                <td data-mobilelabel="Agility:" className={itemClass}>{itm.AgilityPoints}</td>
                <td data-mobilelabel="Jumping:" className={itemClass}>{itm.JumpingPoints}</td>
                <td data-mobilelabel="Games:" className={itemClass}>{itm.GamesPoints}</td>
                <td  data-mobilelabel="Total:"className={itemClass}>{itm.AgilityPoints + itm.JumpingPoints + itm.GamesPoints}</td>
            </tr>
        );

    });


    const SteeplechaseProgression = SteeplechaseData.map((itm,idx) => {

        const rowClass = idx + 1 < SteeplechaseData.length ? 'completed' : '';
        const itemClass = idx + 1 === SteeplechaseData.length ? 'text-red text-bold' : '';
        return (
            <tr key={idx} className={rowClass}>
                <td data-mobilelabel="Level:"><b>{itm.LevelLabel}</b></td>
                <td data-mobilelabel="Points:" className={itemClass}>{itm.SteeplechasePoints}</td>
            </tr>
        );

    });   


    return (
        <>
            <div className="card mb-4x">
                <div className="card-header">
                    Progression for {props.Dog.PetName} <span onClick={() => props.ScrollToResults()} className="text-bold cursor-pointer ml-2x"><small>(Click here or scroll down to view results)</small></span>
                </div>
                <div className="card-body">
                    {
                        !Loading ?
                        <>
                            {
                                TransferText !== '' ?
                                <p className="text-red text-bold mb-1x">{TransferText}</p>
                                : <></>
                            }

                            <div className="grid spaced">

                                <div className="col-6">

                                    <h4 className="mb-1x">Performance Level</h4>

                                    {
                                        PerformanceData.length !== 0 ?
                                        <>
                                            <table className="table width-75 mb-2x">
                                                <thead>
                                                    <tr>
                                                        <th>Level</th>
                                                        <th>Agility</th>
                                                        <th>Jumping</th>
                                                        <th>Games</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>{PerformanceProgression}</tbody>
                                            </table>
                                            {
                                                NextPerformanceLevel !== null && !YetToRunPerformance ?
                                                <p>
                                                    To progress to <span className="text-red text-bold">{NextPerformanceLevel}</span> level:<br />
                                                    {
                                                        TotalPoints > 0 
                                                        ?
                                                        <>
                                                            You need to score at least <span className="text-bold text-red">{TotalPoints}</span> more {TotalPoints === 1 ? 'point' : 'points'}.<br />
                                                        </>
                                                        :
                                                        <></>
                                                    }
                                                    {AgilityPoints > 0 ? <>You must achieve at least <span className="text-bold text-red">{AgilityPoints}</span> more agility {AgilityPoints === 1 ? 'point' : 'points'}.<br /></> : <></>}
                                                    {JumpingPoints > 0 ? <>You must achieve at least <span className="text-bold text-red">{JumpingPoints}</span> more jumping {JumpingPoints === 1 ? 'point' : 'points'}.<br /></> : <></>}
                                                    {(AgilityPoints > 0 || JumpingPoints > 0) && GamesPoints > 0 ? ' and you ' : (GamesPoints > 0 ? 'You ' : '')}{GamesPoints > 0 ? <>must achieve at least <span className="text-bold text-red">{GamesPoints}</span> more games {GamesPoints === 1 ? 'point' : 'points'}.</> : <></>}
                                                    {
                                                        ShowAltMessage && (AltTotalPoints > 0 || AltAgilityPoints > 0) ?
                                                        <>
                                                            <br />
                                                            {
                                                                AltTotalPoints < AltAgilityPoints ? 
                                                                <>Alternatively, you can move up by gaining a further <span className="text-red text-bold">{AltAgilityPoints}</span> {AltAgilityPoints > 1 ? 'points' : 'point'} in agility</>
                                                                :
                                                                <>
                                                                    Alternatively, you can move up by gaining a further <span className="text-red text-bold">{AltTotalPoints}</span> {AltTotalPoints > 1 ? 'points' : 'point'} across all classes  
                                                                    {AltAgilityPoints > 0 ? <>, of which at least <span className="text-red text-bold">{AltAgilityPoints}</span> must be achieved in agility</> : <></>}
                                                                </>
                                                            }
                                                        </>
                                                        :
                                                        <></>
                                                    }
                                                </p>
                                                :
                                                (
                                                    YetToRunPerformance ?
                                                    <>
                                                        Congratulations! You have reached <span className="text-red text-bold">{CurrentPerformanceLevel ?? 'Champion'}</span> level.
                                                    </>
                                                    :
                                                    <></>
                                                )
                                            }
                                        </>
                                        :
                                        (
                                            props.Dog.PerformanceLevel === 'Beginners'
                                            ?
                                            <p>
                                                There are no performance results yet for {props.Dog.PetName}
                                            </p>
                                            :
                                            <p>
                                                You transferred in to {props.Dog.PerformanceLevel} level. There are no results available yet
                                            </p>
                                        )

                                    }


                                </div>



                                <div className="col-6">

                                    <h4 className="mb-1x">Steeplechase Level</h4>
                                    {
                                        SteeplechaseData.length !== 0 ?

                                        <>

                                            <table className="table width-50 mb-2x">
                                                <thead>
                                                    <tr>
                                                        <th>Level</th>
                                                        <th>Points</th>
                                                    </tr>
                                                </thead>
                                                <tbody>{SteeplechaseProgression}</tbody>
                                            </table>
                                            {
                                                NextSteeplechaseLevel !== null && !YetToRunSteeplechase ?
                                                <>
                                                    To progress to <span className="text-red text-bold">{NextSteeplechaseLevel}</span> level, you need <span className="text-bold text-red">{SteeplechasePoints}</span> more {SteeplechasePoints === 1 ? 'point' : 'points'}.
                                                </>
                                                :
                                                (
                                                    YetToRunSteeplechase ?
                                                    <>
                                                        Congratulations! You have reached <span className="text-red text-bold">{CurrentSteeplechaseLevel ?? 'Champion'}</span> level.
                                                    </>
                                                    :
                                                    <></>
                                                )
                                            }
                                        </>
                                        :
                                        (
                                            props.Dog.SteeplechaseLevel === 'Beginners'
                                            ?
                                            <p>
                                                There are no steeplechase results yet for {props.Dog.PetName}
                                            </p>
                                            :
                                            <p>
                                                You transferred in to {props.Dog.SteeplechaseLevel} level. There are no results available yet
                                            </p>
                                        )
                                    }
                                </div>

                            </div>
                        </>
                        :
                        <p>
                            Loading data
                        </p>

                    }

                </div>
                <div className="card-footer">

                </div>
            </div>
        </>
    )

}

export default Progression