import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { apiDelete, apiGet } from "../../../shared/api";
import { useHistory } from "react-router-dom";

const News = () => {
  const [Articles, setArticles] = useState([]);

  const history = useHistory();

  const RefreshArticles = useCallback(async () => {
    try {
      const req = await apiGet("/admin/news");
      const res = await req.json();
      setArticles(res);
    } catch (error) {
      console.log(error.message);
      window.alert("Error loading list of articles");
    }
  }, []);

  useEffect(() => {
    (async () => {
      await RefreshArticles();
    })();
  }, [RefreshArticles]);

  const DeleteArticle = async (Id) => {
    try {
      const req = await apiDelete(`/admin/news/${Id}`);
      if (req && req.ok) {
        await RefreshArticles();
      } else {
        window.alert("Error deleting article");
      }
    } catch (error) {
      console.log(error.message);
      window.alert("Error deleting article");
    }
  };

  const Rows = Articles.map((itm) => {
    return (
      <tr key={itm.Id}>
        <td>{moment(itm.DateTimeStamp).format("DD/MM/YYYY")}</td>
        <td>{itm.Headline}</td>
        <td>
          <a href={`/admin/news/edit/${itm.Id}`}>Edit</a>{" "}
          <span
            className="text-link cursor-pointer"
            onClick={() => DeleteArticle(itm.Id)}
          >
            Delete
          </span>
        </td>
      </tr>
    );
  });

  return (
    <div className="card">
      <div className="card-header">News Articles</div>
      <div className="card-body">
        <p className="mt-2x mb-2x">
          <button
            className="button button-blue"
            onClick={() => history.push("/admin/news/edit/0")}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-1x" />
            Add News Article
          </button>
        </p>

        <table className="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Headline</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{Rows}</tbody>
        </table>
      </div>
    </div>
  );
};

export default News;
