import React, { useState, useEffect } from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";

const ClassView = ({ Loading, Data, RingId, ClassId, LevelId }) => {
  const [ClassLabel, setClassLabel] = useState();
  const [Heights, setHeights] = useState([]);

  const history = useHistory();

  useEffect(() => {
    if (!Data || Data.length === 0) {
      history.push("/liveresults/ringoverview");
    } else {
      const foundRing = Data.find((r) => r.RingId === RingId);
      const foundClass = foundRing.Classes.find(
        (c) => c.ClassId === ClassId && c.LevelId === LevelId
      );
      setClassLabel(foundClass.ClassLabel);
      setHeights(
        foundClass.Heights.sort((a, b) =>
          a.HeightSortOrder < b.HeightSortOrder ? -1 : 1
        )
      );
    }
  }, [Data, RingId, ClassId, LevelId]);

  return (
    <>
      {Loading && (
        <div className="mb-3x mt-3x">
          <FontAwesomeIcon icon={faSpinner} spin className="mr-1x" />
          Loading...
        </div>
      )}

      <h3 className="mb-1x">{ClassLabel}</h3>
      <h4>
        Not yet run in this class:{" "}
        {Heights.reduce(
          (acc, itm) =>
            (acc += itm.Runners.Paid - itm.Runners.RunsComplete - itm.Runners.DidNotRun),
          0
        )}
      </h4>
      <p className="mb-2x">
        <b>Key</b>
        <br />
        Not yet run in this class = All paid entries that have not yet run.
        <br />
        Entered = Total entries, paid and unpaid.
        <br />
        Paid = All pre-entries and all paid late entries.
      </p>
      <p className="mb-2x">
        A red highlight indicates a height has moved from the published ring plan.
      </p>
      <table className="table">
        <thead>
          <tr>
            <th data-mobilelabel="Height">Height</th>
            <th data-mobilelabel="Entered" style={{ textAlign: "center" }}>
              Entered
            </th>
            <th data-mobilelabel="Paid" style={{ textAlign: "center" }}>
              Paid
            </th>
            <th data-mobilelabel="Run" style={{ textAlign: "center" }}>
              Run
            </th>
            <th data-mobilelabel="Queued" style={{ textAlign: "center" }}>
              Queued
            </th>
            <th data-mobilelabel="Not booked in" style={{ textAlign: "center" }}>
              Not booked in
            </th>
            <th data-mobilelabel="Did not run" style={{ textAlign: "center" }}>
              Did Not Run
            </th>
            <th data-mobilelabel="Status">Status</th>
          </tr>
        </thead>
        <tbody>
          {Heights.map((h) => (
            <tr>
              <td style={{ whiteSpace: "nowrap" }}>
                <span className={h.HeightMoved ? "alert alert-red" : undefined}>
                  {h.HeightLabel}
                </span>
              </td>
              <td data-mobilelabel="Entered" style={{ textAlign: "center" }}>
                {h.Runners.Entered}
              </td>
              <td data-mobilelabel="Paid" style={{ textAlign: "center" }}>
                {h.Runners.Paid}
              </td>
              <td data-mobilelabel="Run" style={{ textAlign: "center" }}>
                {h.Runners.RunsComplete}
              </td>
              <td data-mobilelabel="Queued" style={{ textAlign: "center" }}>
                {h.Runners.InQueue < 0 ? 0 : h.Runners.InQueue}
              </td>
              <td data-mobilelabel="Not booked in" style={{ textAlign: "center" }}>
                {h.Runners.Paid -
                  h.Runners.RunsComplete -
                  h.Runners.DidNotRun -
                  (h.Runners.InQueue < 0 ? 0 : h.Runners.InQueue)}
              </td>
              <td data-mobilelabel="Did not run" style={{ textAlign: "center" }}>
                {h.Runners.DidNotRun}
              </td>
              <td data-mobilelabel="Status">{h.Status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ClassView;
