import React, { useState, useEffect } from "react";

function HandlerFilters(props) {
  const [LevelId, setLevelId] = useState("");
  const [ClassType, setClassType] = useState("");
  const [PointsOnly, setPointsOnly] = useState(false);

  //Notify of filter update when values change
  useEffect(() => {
    props.onFilterChange({ LevelId, ClassType, PointsOnly });
    //eslint-disable-next-line
  }, [LevelId, ClassType, PointsOnly]);

  return (
    <div className="grid mb-3x">
      <div className="col-2">
        <p>
          <b>Level</b>
        </p>
        <p>
          <select
            className="form-control"
            value={LevelId}
            onChange={(e) => setLevelId(e.target.value)}
          >
            <option value="">All</option>
            <option value="1">Nursery</option>
            <option value="2">Beginners</option>
            <option value="3">Novice</option>
            <option value="4">Senior</option>
            <option value="5">Champion</option>
            <option value="6">Junior</option>
            <option value="7">Casual</option>
          </select>
        </p>
      </div>
      <div className="col-3">
        <p>
          <b>Class type</b>
        </p>
        <p>
          <select
            className="form-control"
            value={ClassType}
            onChange={(e) => setClassType(e.target.value)}
          >
            <option value="">All</option>
            <option value="1">Agility classes</option>
            <option value="2">Jumping classes</option>
            <option value="3">Games classes</option>
            <option value="4">Steeplechase classes</option>
            <option value="5">Performance classes</option>
            <option value="6">Nursery classes</option>
            <option value="7">Casual classes</option>
          </select>
        </p>
      </div>
      <div className="col-6">
        <p className="mt-2x">
          <input
            type="checkbox"
            checked={PointsOnly}
            onChange={(e) => setPointsOnly(e.target.checked)}
          ></input>{" "}
          Just runs that scored progression points
        </p>
      </div>
    </div>
  );
}

export default HandlerFilters;
