import React, { useState, useContext, useEffect } from "react";
import ModalContent from "../../../shared/ModalContent";
import UserContext from "../../../context/user/UserContext";
import numeral from "numeral";
import InlineLoading from "../../../shared/InlineLoading";
import { apiPost, apiGet } from "../../../shared/api.js";
import { loadStripe } from "@stripe/stripe-js";

function Actions(props) {
  const [ShowBACSModal, setShowBACSModal] = useState(false);
  const [ShowChequeModal, setShowChequeModal] = useState(false);
  const [ShowCardModal, setShowCardModal] = useState(false);
  const [Amount, setAmount] = useState("");
  const [Charges, setCharges] = useState(0);
  const [Total, setTotal] = useState(0);

  let stripePromise;

  const { User } = useContext(UserContext);

  //Calculate Amount due
  useEffect(() => {
    if (props.Wallet) {
      const amnt = props.Wallet.Balance - props.Wallet.TotalDueSoon;
      CalculateAmount(amnt < 0 ? Math.abs(amnt) : 0);
    }
  }, [props]);

  const CalculateAmount = (val) => {
    if (val < 0) val = 0;

    let amnt = parseFloat(val);
    const total = (amnt + 0.2) / (1 - 0.014);
    const charges = total - amnt;
    setAmount(val);
    setCharges(numeral(charges).format("0.00"));
    setTotal(numeral(total).format("0.00"));
  };

  const MakePayment = async (Method) => {
    const ERROR_MSG =
      "Error creating transaction. Please check your wallet and try again";

    try {
      const AmountPaid = parseFloat(numeral(Amount).format("0.00"));
      if (AmountPaid < 0.3) {
        window.alert("You must make a payment of at least 30p");
        return;
      }

      const data = {
        Details: {
          MemberId: User.Id,
          Amount: AmountPaid,
          Charges: Method === 2 ? Charges : 0,
          Method,
        },
      };

      const req = await apiPost("/wallet/transaction/create", data);
      if (req && req.ok) {
        if (Method === 2) {
          const res = await req.json();
          const TransactionId = res.TransactionId;

          const StripeData = {
            TransactionId,
            Email: User.Email,
            Amount:
              parseFloat(numeral(Amount).format("0.00")) +
              parseFloat(numeral(Charges).format("0.00")),
          };
          const stripeSessionReq = await apiPost(
            "/stripe/sessionid",
            StripeData
          );
          const stripeSessionRes = await stripeSessionReq.json();
          const sessionId = stripeSessionRes.session_id;

          //Stripe stuff
          const stripeKeyReq = await apiGet("/stripe/publishablekey");
          const stripeKeyRes = await stripeKeyReq.json();
          stripePromise = loadStripe(stripeKeyRes.Key);
          const stripe = await stripePromise;
          const { error } = await stripe.redirectToCheckout({
            sessionId,
          });
          if (error) console.log(error);

          setShowCardModal(false);
        } else {
          setShowBACSModal(false);
          setShowChequeModal(false);
        }
      } else {
        window.alert(ERROR_MSG);
      }

      setAmount("");
    } catch (error) {
      console.log(error.message);
      window.alert(ERROR_MSG);
    }
  };

  if (!props.Wallet) return <InlineLoading />;

  return (
    <>
      <div className="card mb-5x">
        <div className="card-header">
          <h4>Actions</h4>
        </div>
        <div className="card-body">
          <h5>Make a payment</h5>
          <p>
            If you wish to add money to your wallet now, you may do so in any of
            the following ways:
          </p>
          <ul>
            <li
              className="cursor-pointer"
              onClick={(e) => setShowCardModal(true)}
            >
              Card
            </li>
            <li
              className="cursor-pointer"
              onClick={(e) => setShowBACSModal(true)}
            >
              BACS{" "}
              <span className="text-red">(New bank since November 2022)</span>
            </li>
            <li
              className="cursor-pointer"
              onClick={(e) => setShowChequeModal(true)}
            >
              Cheque
            </li>
          </ul>
          <p className="mb-2x text-red text-bold">
            Click a payment method for more information.
          </p>
          <p>
            <b>Any refund from your wallet will incur a £6 admin fee.</b>
            <br />
            Please be sure that you understand the costs associated with each
            method of payment prior to funding your account, as well as the
            refund administration fee.
          </p>
        </div>
        <div className="card-footer"></div>
      </div>
      {ShowBACSModal ? (
        <ModalContent>
          <h4>BACS Details</h4>
          <p className="text-red">
            New bank since November 2022.
            <br />
            Please check details carefully before sending as there will be an
            admin fee of £6 if payments are made into the wrong account.
          </p>
          <p>
            <b>Bank:</b> Tide
          </p>
          <p>
            <b>Account Name:</b> Agility 4 All Ltd
          </p>
          <p>
            <b>Account Number:</b> 19893841
          </p>
          <p>
            <b>Sort Code:</b> 04-06-05
          </p>
          <p>
            <b>Your Reference:</b>{" "}
            <span className="text-red text-bold">{User.Id}</span>
          </p>
          <p className="text-red text-bold mb-2x">
            You must use this reference in order for this payment to be assigned
            to your account. Please do not put any additional letters, numbers
            or words in the reference box. Any payments with incorrect
            references will be subject to a £6 admin fee to rectify this.
          </p>
          <p className="mb-2x text-red">
            <b>
              BACS payments may take up to 5 working days to appear in your
              wallet
            </b>
          </p>
          <button
            className="button button-outline"
            onClick={(e) => setShowBACSModal(false)}
          >
            Close
          </button>
        </ModalContent>
      ) : (
        <></>
      )}
      {ShowChequeModal ? (
        <ModalContent>
          <h4 className="mb-2x">Cheque Details</h4>
          <p>
            If you pay by cheque, please write your A4A Number on the back and
            send to our office address which you will find at the foot of this
            page.
          </p>
          <p className="mb-2x text-red">
            <b>
              Cheque payments may take up to 10 working days to appear in your
              wallet
            </b>
          </p>
          <button
            className="button button-outline"
            onClick={(e) => setShowChequeModal(false)}
          >
            Close
          </button>
        </ModalContent>
      ) : (
        <></>
      )}
      {ShowCardModal ? (
        <ModalContent>
          <h4 className="mb-2x">Card payments</h4>
          <p>
            Card payments are handled for us by Stripe. An instant top-up fee of
            1.4% + 20p will be included in the amount you pay.
            <br />
            If there's an amount in the box below, this will be the sum of any
            pending transactions on your account. You can change this figure if
            you wish.
          </p>
          <p>
            Amount you will be paying (£):{" "}
            <input
              min="1"
              step="0.01"
              type="number"
              value={Amount}
              onChange={(e) => CalculateAmount(e.target.value)}
              className="form-control width-25"
            />
          </p>
          <p className="text-red text-bold">Minimum amount payable is 30p</p>
          <p>
            You will leave this site and go to the Stripe payments service to
            complete your payment. You'll be returned here once your transaction
            is complete.
          </p>
          <p className="text-red text-bold">
            Your instant top up fee charges may alter if you're not paying with
            an EU card and an additional top up fee may be added by
            administrators in the future which might put your account in debt.
          </p>
          <p>
            <small>
              Your instant top up fee charges may alter if you're not paying
              with an EU card and an additional top up fee may be added by
              administrators in the future which might put your account in debt.
            </small>
          </p>
          <p>Fee: £{Charges}</p>
          <p className="mb-2x">Total you will pay: £{Total}</p>
          <button
            className="button button-green mr-2x"
            onClick={(e) => MakePayment(2)}
          >
            Make Payment
          </button>
          <button
            className="button button-outline"
            onClick={(e) => setShowCardModal(false)}
          >
            Close
          </button>
        </ModalContent>
      ) : (
        <></>
      )}
    </>
  );
}

export default Actions;
