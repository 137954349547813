import React, { useContext } from "react";
import UserContext from "../../../context/user/UserContext";
import "./MembersBar.css";
import { withRouter } from "react-router-dom";

function MembersBar(props) {
  const { User, Logout } = useContext(UserContext);

  const doLogout = () => {
    props.history.push("/");
    Logout();
  };

  return (
    <div className="members-bar">
      <p>Welcome {User.FirstName}</p>
      <p>A4A Number {User.Id}</p>
      <p className="button-link text-white" onClick={doLogout}>
        Log Out
      </p>
    </div>
  );
}

export default withRouter(MembersBar);
