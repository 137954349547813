import React from "react";

const ExpiredAccount = (props) => {
  return (
    <div className="alert alert-error mb-2x">
      Your A4A account has expired. Please top up your wallet with £6 if you would like to renew.
    </div>
  );
};

export default ExpiredAccount;
