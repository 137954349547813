import {apiGet} from './api.js';

const GetBalance = async(MemberId) => {
    try {

        //Request current balance from the API
        const req = await apiGet(`/Wallet/Balance/${MemberId}`);
        if(req && req.ok && req.status === 200) {
            const res = await req.json();
            return {Balance: res.Balance, TotalDueNow: res.TotalDueNow, TotalDueSoon: res.TotalDueSoon}
        } else {
            return undefined;
        }

    }
    catch (error) {
        return undefined;
    }
}


//Get the method of payment
const GetPaymentMethod = itm => {
    
    let output = '';
    
    if(itm.Detail === 'Membership fee' || itm.Detail === 'Membership Renewal') {
        output = 'Wallet';
    } else {

        switch(itm.Method) {
            case 1: output = 'BACS'; break;
            case 2: output = 'Card'; break;
            case 3: output = 'Cheque'; break;
            case 4: output = 'BACS (Admin)'; break;
            case 5: output = 'Card (Admin)'; break;
            case 6: output = 'Cheque'; break;
            case 7: output = 'Admin'; break;
            default: output = 'Wallet'; break;
        }

    }

    return output;

}

export {GetBalance, GetPaymentMethod}