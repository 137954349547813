import React, { useState, useRef, useEffect } from "react";
import { apiPost } from "../../shared/api.js";
import Message from "../../shared/Message";

function ForgottenPassword(props) {
  const [Email, setEmail] = useState("");
  const [MessageText, setMessageText] = useState("");
  const refEmail = useRef(null);

  //Focus on email address box
  useEffect(() => {
    refEmail.current.focus();
  }, []);

  //API call to reset password
  const doReset = async () => {
    const ERROR_MSG =
      "Error requesting password reset. Please contact the A4A office on enquiries@agility4all.co.uk";

    try {
      if (Email.toString().trim() === "") {
        setMessageText("Please enter a valid email address");
        return;
      }

      const req = await apiPost("/members/forgottenpassword", { Email });
      if (req && req.ok && req.status === 200) {
        const res = await req.json();
        if (res.Result === "Found") {
          if (res.Count === 1) {
            window.location.href = "/";
          } else {
            setMessageText(
              "There was more than one account found with that email address.\n\nPlease contact the office on enquiries@agility4all.co.uk for help."
            );
          }
        } else {
          setMessageText(
            "Sorry, no account was found matching that email address. Please contact the office on enquiries@agility4all.co.uk for help"
          );
        }
      } else {
        setMessageText(ERROR_MSG);
      }
    } catch (error) {
      console.log(error.message);
      window.alert(ERROR_MSG);
    }
  };

  return (
    <>
      {MessageText !== "" ? (
        <>
          <Message Type="Error" Content={MessageText}></Message>
          <br />
          <br />
        </>
      ) : (
        <></>
      )}
      <div className="card card-narrow">
        <div className="card-header">
          <h4>Forgotten Password</h4>
        </div>
        <div className="card-body">
          <p>
            Please enter your registered email address to receive a reset link.
          </p>
          <p className="mb-2x">
            If you have not received an email within 15 minutes, please ensure
            you have checked your Junk/Spam folder before contacting us at{" "}
            <a href="mailto:enquiries@agility4all.co.uk">
              enquiries@agility4all.co.uk
            </a>
            . Please also make sure that no-reply@email.agility4all.co.uk is
            added as a trusted sender to your email account.
          </p>
          <div className="form-group">
            <label className="form-label">Email Address:</label>
            <input
              ref={refEmail}
              type="text"
              className="form-control"
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="card-footer">
          <button
            className="button button-blue mr-2x"
            onClick={(e) => doReset()}
          >
            Send Reset Link
          </button>
          <button className="button button-link" onClick={props.onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}

export default ForgottenPassword;
