import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import InlineLoading from "../../../shared/InlineLoading";
import { apiGet } from "../../../shared/api";
import UserContext from "../../../context/user/UserContext";
import moment from "moment";

const DiaryCard = (props) => {
  const [ShowList, setShowList] = useState([]);
  const [Error, setError] = useState(false);
  const [Loading, setLoading] = useState(true);

  const { User } = useContext(UserContext);

  useEffect(() => {
    const loadData = async () => {
      try {
        setError(false);
        setLoading(true);

        const req = await apiGet(`/Shows/EntriesUpcomingForMember/${User.Id}`);
        if (req && req.ok && req.status === 200) {
          const res = await req.json();
          setShowList(res);
          setError(false);
          setLoading(false);
        } else {
          setError(true);
          setLoading(false);
        }
      } catch (error) {
        setError(true);
        console.log(error.message);
      }
    };

    loadData();
  }, [User.Id]);

  const ShowEntries = ShowList.map((itm) => (
    <p key={itm.ShowId}>
      {itm.DisplayName} - {moment(itm.StartDate).format("DD MMM")}
    </p>
  ));

  if (Loading) return <InlineLoading />;

  return (
    <div className="card">
      <div className="card-header">
        <h5>My Upcoming Shows</h5>
      </div>
      <div className="card-body">
        {Error ? (
          <p>Error loading show details</p>
        ) : ShowList.length === 0 ? (
          <p>You're not in any upcoming shows</p>
        ) : (
          <>
            <p>
              <b>Shows you've entered:</b>
            </p>
            {ShowEntries}
          </>
        )}
      </div>
      <div className="card-footer">
        {!User.Expired ? (
          <Link to="/entries">
            <FontAwesomeIcon icon={faCalendarAlt} className="mr-1x" />
            Diary
          </Link>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default DiaryCard;
