import React,{useContext} from 'react';
import Step1 from './regstep1';
import Step2 from './regstep2';
import Step3 from './regstep3';
import Step4 from './regstep4';
import Step5 from './regstep5';
import RegistrationContext from '../../context/registration/RegistrationContext';


function RegSteps(props) {

    const {CurrentStep} = useContext(RegistrationContext);


    return (
        <>
        {
            {
                1: <Step1 />,
                2: <Step2 />,
                3: <Step3 />,
                4: <Step4 />,
                5: <Step5 />,
            }[CurrentStep]
        }
        </>
    )
}

export default RegSteps;