import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { apiGet, apiPost, apiPostFile } from "../../../shared/api";

const EntrantDocuments = (props) => {
  const [DocumentList, setDocumentList] = useState([]);
  const [File, setFile] = useState();
  const [BaseUrl, setBaseUrl] = useState("");

  const LoadData = useCallback(async () => {
    try {
      const req = await apiGet(
        `/admin/shows/entrantdocuments/list/${props.ShowId}`
      );
      const res = await req.json();
      setDocumentList(res.FileList);
      setBaseUrl(res.BaseUrl);
    } catch (error) {
      console.log(error.message);
      window.alert("Error loading list of documents");
    }
  }, [props.ShowId]);

  //List docs on first load
  useEffect(() => {
    LoadData();
  }, [LoadData]);

  const DeleteFile = async (DocumentName) => {
    try {
      const obj = {
        ShowId: props.ShowId,
        DocumentName,
      };

      const req = await apiPost("/admin/shows/entrantdocuments/delete", obj);
      if (req && req.ok) {
        LoadData();
      } else {
        window.alert("Error deleting file");
      }
    } catch (error) {
      console.log(error.message);
      window.alert("Error deleting file");
    }
  };

  const UploadFile = async () => {
    try {
      const data = new FormData();
      data.append("file", File);

      const req = await apiPostFile(
        `/admin/shows/entrantdocuments/store/${props.ShowId}`,
        data
      );
      if (req && req.ok) {
        LoadData();
      } else {
        window.alert("Error uploading file");
      }
    } catch (error) {
      console.log(error.message);
      window.alert("Error uploading file");
    }
  };

  const Rows = DocumentList.map((itm, idx) => {
    return (
      <tr key={idx}>
        <td>
          <a href={`${BaseUrl}/${itm}`} target="_new">
            {itm.toString().replace(`${props.ShowId}/`, "")}
          </a>
        </td>
        <td>
          <span
            className="text-link cursor-pointer"
            onClick={() => DeleteFile(itm)}
          >
            <FontAwesomeIcon icon={faTrash} className="mr-1x" /> Delete
          </span>
        </td>
      </tr>
    );
  });

  return (
    <div className="card">
      <div className="card-header">Entrant Documents</div>
      <div className="card-body" style={{ backgroundColor: "rgb(200,200,200" }}>
        <p>Upload a file</p>
        <p className="mb-3x">
          <input type="file" onChange={(e) => setFile(e.target.files[0])} />{" "}
          <button className="button button-green" onClick={UploadFile}>
            <FontAwesomeIcon icon={faFileUpload} className="mr-1x" />
            Upload file
          </button>
        </p>

        <table className="table">
          <thead>
            <tr>
              <th>Document</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{Rows}</tbody>
        </table>
      </div>
    </div>
  );
};

export default EntrantDocuments;
