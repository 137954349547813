import React, { useState, useEffect, useCallback, useContext } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faQuestionCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import numeral from "numeral";
import { useParams, withRouter } from "react-router-dom";
import { apiGet, apiPost } from "../../../../shared/api";
import UserContext from "../../../../context/user/UserContext";
import InlineLoading from "../../../../shared/InlineLoading";
import Modal from "../../../../shared/ModalContent";
import ModalLoading from "../../../../shared/ModalLoading";

const SummaryDog = (props) => {
  const [Loading, setLoading] = useState(false);
  const [IsCondensed, setIsCondensed] = useState(props.IsCondensed);
  const [TotalRuns, setTotalRuns] = useState(0);
  const [TotalCost, setTotalCost] = useState(0);
  const [TPData, setTPData] = useState([]);
  const [AllRunData, setAllRunData] = useState([]);
  const [RunData, setRunData] = useState([]);
  const [DogId, setDogId] = useState();
  const [DogList, setDogList] = useState([]);
  const [DeleteDogId, setDeleteDogId] = useState("");
  const [OtherEntries, setOtherEntries] = useState();
  const [ShowAreYouSure, setShowAreYouSure] = useState(false);
  const [CallbackFunction, setCallbackFunction] = useState({});
  const [ShowLoading, setShowLoading] = useState(false);
  const [HasLateEntries, setHasLateEntries] = useState(false);

  const CanEdit = props.CanEdit;

  let { ShowId } = useParams();
  if (!ShowId) ShowId = props.ShowId;

  const { User } = useContext(UserContext);
  const { LatesOpen } = props;

  const LoadData = useCallback(async () => {
    try {
      let req,
        res,
        _hasLates = false;
      setLoading(true);

      req = await apiGet(`/shows/classes/entries/${ShowId}/${User.Id}`);
      res = await req.json();
      setRunData(res);
      setAllRunData(res);
      if (res.find((r) => r.IsLateEntry)) _hasLates = true;

      const DogIdList = new Set();
      if (LatesOpen) {
        for (const run of res.filter((r) => r.IsLateEntry)) DogIdList.add(run.DogId);
      } else {
        for (const run of res) DogIdList.add(run.DogId);
      }

      //Entries made by other members involving this member or their dogs
      req = await apiGet(`/shows/otherpeoplesentries/${ShowId}/${User.Id}`);
      res = await req.json();
      if (LatesOpen) {
        for (const run of res[0].filter((r) => r.IsLateEntry)) DogIdList.add(run.DogId);
        for (const run of res[1].filter((r) => r.IsLateEntry)) DogIdList.add(run.DogId);
      } else {
        for (const run of res[0]) DogIdList.add(run.DogId);
        for (const run of res[1]) DogIdList.add(run.DogId);
      }
      if (res[0].find((r) => r.IsLateEntry && r.MemberId === User.Id)) _hasLates = true;
      if (res[1].find((r) => r.IsLateEntry && r.MemberId === User.Id)) _hasLates = true;

      setOtherEntries(res);

      //Team/pair entries made by this member
      req = await apiGet(`/shows/teampairentries/${ShowId}/${User.Id}`);
      res = await req.json();
      if (LatesOpen) {
        for (const run of res[0].filter((r) => r.IsLateEntry))
          DogIdList.add(run.LeadDogId);
        for (const run of res[1].filter((r) => r.IsLateEntry))
          DogIdList.add(run.LeadDogId);
      } else {
        for (const run of res[0]) DogIdList.add(run.LeadDogId);
        for (const run of res[1]) DogIdList.add(run.LeadDogId);
      }
      if (res[0].find((r) => r.IsLateEntry)) _hasLates = true;
      if (res[1].find((r) => r.IsLateEntry)) _hasLates = true;

      setTPData(res);

      req = await apiGet(`/dogs/list/${User.Id}`);
      res = await req.json();
      const dogList = [];
      for (const Id of [...DogIdList]) {
        const Dog = res.find((r) => r.Id === Id);
        if (Dog) dogList.push({ Id, PetName: Dog.PetName });
      }
      setDogList(dogList);

      setHasLateEntries(_hasLates);

      setLoading(false);
    } catch (error) {
      console.log(error.message);
      window.alert("Error loading dog runs");
      setLoading(false);
    }
  }, [ShowId, User.Id, LatesOpen]);

  useEffect(() => {
    LoadData();
  }, [LoadData]);

  //Filter table for just a single dog
  useEffect(() => {
    if (!DogId || DogId === "") {
      setRunData(AllRunData);
    } else {
      setRunData(AllRunData.filter((r) => r.DogId === parseInt(DogId)));
    }
  }, [DogId, AllRunData]);

  //Delete all runs for a dog
  const DeleteRunsForDog = async () => {
    try {
      const NextAction = async () => {
        setShowLoading(true);

        const obj = {
          ShowId,
          DogId: DeleteDogId,
          MemberId: User.Id,
          LatesOnly: LatesOpen,
        };
        const req = await apiPost(`/shows/entries/deletefordog`, obj);

        if (req && req.ok) {
          const res = await req.json();
          if (res.InShow) {
            props.onSave();
            LoadData();
            props.RefreshCost();
            setDeleteDogId("");
            setShowAreYouSure(false);
            setShowLoading(false);
          } else {
            props.history.push("/shows");
          }
        } else {
          window.alert("Error deleting dog");
          setShowAreYouSure(false);
        }
      };

      ConfirmAction(NextAction);
    } catch (error) {
      console.log(error.message);
      setShowAreYouSure(false);
      setShowLoading(false);
      window.alert("Error deleting entry");
    }
  };

  //Delete a single run
  const DeleteRun = async (Id) => {
    try {
      const NextAction = async () => {
        setShowLoading(true);

        const obj = { Id, ShowId, MemberId: User.Id };
        const req = await apiPost(`/shows/classes/entries/delete`, obj);
        if (req && req.ok) {
          const res = await req.json();
          if (res.InShow) {
            props.onSave();
            LoadData();
            props.RefreshCost();
            setShowAreYouSure(false);
            setShowLoading(false);
          } else {
            props.history.push("/shows");
          }
        } else {
          setShowAreYouSure(false);
          setShowLoading(false);
          window.alert("Error deleting entry");
        }
      };

      ConfirmAction(NextAction);
    } catch (error) {
      console.log(error.message);
      setShowAreYouSure(false);
      setShowLoading(false);
      window.alert("Error deleting entry");
    }
  };

  const GetSecondDog = (ClassId, ClassDate, LeadDogId) => {
    const found =
      TPData.length > 0 &&
      TPData[0].filter(
        (c) =>
          c.ClassId === ClassId &&
          moment(c.ClassDate).isSame(moment(ClassDate)) &&
          c.LeadDogId === LeadDogId
      );
    return found && found.length > 0 ? found[0] : { PetName: "Unknown" };
  };

  const GetThirdDog = (ClassId, ClassDate, LeadDogId) => {
    const found =
      TPData.length > 0 &&
      TPData[0].filter(
        (c) =>
          c.ClassId === ClassId &&
          moment(c.ClassDate).isSame(moment(ClassDate)) &&
          c.LeadDogId === LeadDogId
      );
    return found && found.length > 1 ? found[1] : { PetName: "Unknown" };
  };

  //Table row for each run
  const DogRows = RunData.map((itm, idx) => {
    let SecondDog = GetSecondDog(itm.ClassId, itm.ClassDate, itm.DogId);
    const ThirdDog = GetThirdDog(itm.ClassId, itm.ClassDate, itm.DogId);

    return (
      <tr key={idx}>
        <td>{itm.PetName}</td>
        <td>
          {itm.FirstName} {itm.LastName}
        </td>
        <td>{moment.utc(itm.ClassDate).format("ddd Do")}</td>
        <td>
          {itm.ClassLabel}
          {itm.Sponsor ? (
            <>
              <br />
              {itm.Sponsor}
            </>
          ) : (
            <></>
          )}
          {itm.ClassType === 6 || itm.ClassType === 9 ? (
            <>
              <table className="table">
                <thead>
                  <tr>
                    <th>Dog</th>
                    <th>Handler</th>
                    <th>Height</th>
                    {itm.ClassType === 9 ? <th>Course</th> : <></>}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{SecondDog.PetName}</td>
                    <td>
                      {SecondDog.FirstName} {SecondDog.LastName}
                    </td>
                    <td>{SecondDog.HeightLabel}</td>
                    {itm.ClassType === 9 ? <td>{SecondDog.Sector}</td> : <></>}
                  </tr>
                  {itm.ClassType === 6 ? (
                    <tr>
                      <td>{ThirdDog.PetName}</td>
                      <td>
                        {ThirdDog.FirstName} {ThirdDog.LastName}
                      </td>
                      <td>{ThirdDog.HeightLabel}</td>
                    </tr>
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
              {itm.ClassType === 6 ? (
                <></>
              ) : (
                <p>
                  {itm.PetName} is running{" "}
                  {SecondDog.Sector === "Agility" ? "Jumping" : "Agility"}
                </p>
              )}
              {itm.ClassType === 9 ? (
                <></>
              ) : (
                <p>
                  <b>Team Name:</b> {SecondDog.TeamName}
                </p>
              )}
            </>
          ) : (
            <></>
          )}
        </td>
        <td>{itm.HeightLabel}</td>
        <td>{itm.LevelLabel}</td>
        <td>£{numeral(itm.RunCost).format("0.00")}</td>
        {CanEdit && (!props.IsClosed || (LatesOpen && itm.IsLateEntry)) ? (
          <td>
            <span onClick={() => DeleteRun(itm.Id)} className="cursor-pointer text-red">
              <FontAwesomeIcon icon={faTrash} className="mr-1x" />
              Delete run
            </span>
          </td>
        ) : (
          <td>Pre-entry</td>
        )}
      </tr>
    );
  });

  useEffect(() => {
    setTotalRuns(RunData.length);
    const Total = RunData.reduce((sum, itm) => (sum += itm.RunCost), 0);
    setTotalCost(Total);
  }, [RunData]);

  const DeleteHandlerRun = async (
    ShowId,
    ClassDate,
    ClassId,
    EnteringMemberId,
    CurrentMemberId
  ) => {
    try {
      const NextAction = async () => {
        setShowLoading(true);

        const obj = {
          ShowId,
          ClassDate,
          ClassId,
          EnteringMemberId,
          CurrentMemberId,
        };
        const req = await apiPost("/shows/entries/deletehandlerrun", obj);
        if (req && req.ok) {
          window.location.reload();
        } else {
          window.alert("Error deleting run");
          setShowAreYouSure(false);
          setShowLoading(false);
        }
      };
      ConfirmAction(NextAction);
    } catch (error) {
      console.log(error.message);
      setShowAreYouSure(false);
      setShowLoading(false);
      window.alert("Error deleting run");
    }
  };

  const DeleteTPRun = async (
    ShowId,
    ClassDate,
    ClassId,
    EnteringMemberId,
    CurrentMemberId
  ) => {
    try {
      const NextAction = async () => {
        setShowLoading(true);

        const obj = {
          ShowId,
          ClassDate,
          ClassId,
          EnteringMemberId,
          CurrentMemberId,
        };
        const req = await apiPost("/shows/entries/deletetprun", obj);
        if (req && req.ok) {
          const res = await req.json();
          if (res.InShow) {
            window.location.reload();
          } else {
            props.history.push("/shows");
          }
        } else {
          window.alert("Error deleting run");
          setShowAreYouSure(false);
          setShowLoading(false);
        }
      };

      ConfirmAction(NextAction);
    } catch (error) {
      console.log(error.message);
      setShowAreYouSure(false);
      setShowLoading(false);
      window.alert("Error deleting run");
    }
  };

  const DogsInShow = DogList.map((itm) => (
    <option key={itm.Id} value={itm.Id}>
      {itm.PetName}
    </option>
  ));

  const AddRuns = () => props.history.push(`/shows/myentry/${props.ShowId}?View=AddRuns`);

  const OtherHandlerRuns =
    OtherEntries &&
    OtherEntries[0].map((itm, idx) => {
      return (
        <tr key={idx}>
          <td>{moment.utc(itm.ClassDate).format("ddd Do")}</td>
          <td>{itm.ClassLabel}</td>
          <td>
            {itm.FirstName} {itm.LastName}
          </td>
          <td>{itm.PetName}</td>
          <td>{itm.HeightLabel}</td>
          <td>{itm.LevelLabel}</td>
          <td>
            {CanEdit && (!props.IsClosed || (LatesOpen && itm.IsLateEntry)) ? (
              <span
                className="text-link cursor-pointer text-red"
                onClick={() =>
                  DeleteHandlerRun(
                    ShowId,
                    itm.ClassDate,
                    itm.ClassId,
                    itm.MemberId,
                    User.Id
                  )
                }
              >
                <FontAwesomeIcon icon={faTrash} className="mr-1x" />
                Delete
              </span>
            ) : (
              <td>Pre-entry</td>
            )}
          </td>
        </tr>
      );
    });

  const OtherTeamRuns =
    OtherEntries &&
    OtherEntries[1].map((itm, idx) => {
      return (
        <tr key={idx}>
          <td>{moment.utc(itm.ClassDate).format("ddd Do")}</td>
          <td>{itm.ClassLabel}</td>
          <td>{itm.TeamName ?? "N/A"}</td>
          <td>
            {itm.MemberFirstName} {itm.MemberLastName}
          </td>
          <td>{itm.MemberDogName}</td>
          <td>
            {itm.HandlerFirstName} {itm.HandlerLastName}
          </td>
          <td>{itm.PetName}</td>
          <td>
            {CanEdit && (!props.IsClosed || (LatesOpen && itm.IsLateEntry)) ? (
              <span
                className="text-link cursor-pointer text-red"
                onClick={() =>
                  DeleteTPRun(ShowId, itm.ClassDate, itm.ClassId, itm.MemberId, User.Id)
                }
              >
                <FontAwesomeIcon icon={faTrash} className="mr-1x" />
                Delete
              </span>
            ) : (
              <span>Pre-entry</span>
            )}
          </td>
        </tr>
      );
    });

  const ConfirmAction = (func) => {
    setCallbackFunction(() => func);
    setShowAreYouSure(true);
  };

  if (ShowLoading) return <ModalLoading Message="Please wait a moment..." />;

  return (
    <div className="mb-3x">
      <h3 className="mt-2x mb-2x">Dog entries for this show</h3>
      {ShowAreYouSure ? (
        <Modal>
          <h3>
            <FontAwesomeIcon icon={faQuestionCircle} className="mr-1x" />
            Are you sure?
          </h3>
          <p>
            <button className="button button-red mr-1x" onClick={CallbackFunction}>
              Yes
            </button>
            <button className="button" onClick={() => setShowAreYouSure(false)}>
              No
            </button>
          </p>
        </Modal>
      ) : (
        <></>
      )}

      {Loading ? (
        <InlineLoading />
      ) : IsCondensed ? (
        <>
          <p>
            <b>
              Total runs: {TotalRuns}, at a cost of £{numeral(TotalCost).format("0.00")}
            </b>
          </p>
          <p>
            <button className="button" onClick={() => setIsCondensed(false)}>
              Show details
            </button>
          </p>
        </>
      ) : (
        <>
          <div className="grid mb-2x">
            <div className="col-3">
              <p>
                <b>Filter by dog</b>
              </p>
              <p>
                <select
                  className="form-control"
                  value={DogId}
                  onChange={(e) => setDogId(e.target.value)}
                >
                  <option value="">All dogs</option>
                  {DogsInShow}
                </select>
              </p>
            </div>
          </div>

          {RunData && RunData.length > 0 && (
            <>
              <h3>{props.IsPaper ? "Paper Entry" : "Entries by me"}</h3>
              {props.IsClosed === 1 && (
                <p className="text-red">Pre-entries cannot be altered or deleted</p>
              )}
              <table className="table mb-2x">
                <thead>
                  <tr>
                    <th>Dog</th>
                    <th>Handler</th>
                    <th>Date</th>
                    <th>Class</th>
                    <th>Height</th>
                    <th>Level</th>
                    <th>Cost</th>
                    {CanEdit ? <th>Actions</th> : <></>}
                  </tr>
                </thead>
                <tbody>{DogRows}</tbody>
              </table>
              <br />
              <br />
            </>
          )}

          {OtherEntries && (OtherEntries[0].length > 0 || OtherEntries[1].length > 0) && (
            <h3>Entries by other people</h3>
          )}

          {OtherEntries && OtherEntries[0].length > 0 ? (
            <>
              <p>
                <b>As a handler</b>
              </p>
              {props.IsClosed === 1 && (
                <p className="text-red">Pre-entries cannot be altered or deleted</p>
              )}
              <table className="table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Class</th>
                    <th>Entry by</th>
                    <th>Dog</th>
                    <th>Height</th>
                    <th>Level</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>{OtherHandlerRuns}</tbody>
              </table>
              <br />
              <br />
            </>
          ) : (
            <></>
          )}

          {OtherEntries && OtherEntries[1].length > 0 && (
            <>
              <p>
                <b>In a team / pair</b>
              </p>
              {props.IsClosed === 1 && (
                <p className="text-red">Pre-entries cannot be altered or deleted</p>
              )}
              <table className="table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Class</th>
                    <th>Team Name</th>
                    <th>Entry by</th>
                    <th>Dog</th>
                    <th>Handler</th>
                    <th>Dog</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>{OtherTeamRuns}</tbody>
              </table>
              <br />
              <br />
            </>
          )}
          {CanEdit && (!props.IsClosed || LatesOpen) && (
            <div className="grid">
              <div className="col-6">
                <h3>Add runs / dogs</h3>
                <p>Click the button below to add runs to your entry.</p>
                <p className="mb-2x">
                  {!LatesOpen && (
                    <button className="button" onClick={AddRuns}>
                      <FontAwesomeIcon icon={faPlus} className="mr-1x" />
                      Add / Edit runs
                    </button>
                  )}
                  {LatesOpen && (
                    <button className="button" onClick={AddRuns}>
                      <FontAwesomeIcon icon={faPlus} className="mr-1x" />
                      Add / Edit late entry runs
                    </button>
                  )}
                </p>
              </div>
              {DogList.length > 0 && (
                <div className="col-6">
                  <h3>Delete all {LatesOpen && `late `}runs for a dog</h3>
                  <p>
                    Select a dog from the dropdown and click 'Delete' to remove a dog from
                    your entry.
                  </p>
                  {HasLateEntries && (
                    <p className="text-red">
                      This will only delete late entries as pre-entries are locked
                    </p>
                  )}
                  <div className="form-group mb-2x">
                    <select
                      className="form-control width-25 mr-1x"
                      value={DeleteDogId}
                      onChange={(e) => setDeleteDogId(e.target.value)}
                    >
                      <option value="">Select...</option>
                      {DogsInShow}
                    </select>
                    {DeleteDogId !== "" && (
                      <button className="button button-red" onClick={DeleteRunsForDog}>
                        <FontAwesomeIcon icon={faTrash} className="mr-1x" />
                        Delete
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default withRouter(SummaryDog);
