import {useEffect, useContext} from 'react';
import {apiGet} from '../../shared/api';
import {withRouter, useParams, useLocation} from 'react-router-dom';
import UserContext from '../../context/user/UserContext';
import moment from 'moment';


const LoginAsUser = props => {

    const {UserGuid} = useParams();
    const {SetUser} = useContext(UserContext);

    const ref = new URLSearchParams(useLocation().search);

    useEffect(() => {

        const LoadData = async() => {

            const AuthToken = ref.get('AuthToken');

            const req = await apiGet(`/admin/adminlogin/${UserGuid}`);
            const res = await req.json();
            res.IsAdminLogin = true;

            res.AuthToken = AuthToken;
            sessionStorage.setItem('User', JSON.stringify(res));
            SetUser({
                User: {
                    Id: res.Id,
                    FirstName: res.FirstName,
                    LastName: res.LastName,
                    Email: res.Email,
                    Expired: moment().isAfter(moment(res.DateExpires).add(1,'day')),
                    IsAdminLogin: true
                }    
            });

            props.history.push('/');

        }

        LoadData();

    },[SetUser,UserGuid,props.history,ref]);


    return null;

}

export default withRouter(LoginAsUser)