import React,{useState,useEffect,useContext, useRef} from 'react';
import UserContext from '../../../context/user/UserContext';
import {apiGet, apiPost} from '../../../shared/api';
import ModalLoading from '../../../shared/ModalLoading';
import { withRouter } from 'react-router-dom';
import Message from '../../../shared/Message';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import {ValidatePassword} from '../../../shared/functions.js';


function Account(props) {

    const {User, SetUser} = useContext(UserContext);

    const [Loading, setLoading] = useState(true);
    const [Error, setError] = useState(false);
    const [UserDetails, setUserDetails] = useState([]);
    const [Password, setPassword] = useState('');
    const [DateOfBirth, setDateOfBirth] = useState('');
    const [ShowSaved, setShowSaved] = useState(false);
    const [EmailOK, setEmailOK] = useState(true);
    const [ShowPassword, setShowPassword] = useState(false);
    const [PasswordValid, setPasswordValid] = useState(false);

    const cpRef = useRef(null);
    const cpNewPassword = useRef(null);


    const ScrollToChangePassword = () => {
        window.scrollTo(0, cpRef.current.offsetTop);
        cpNewPassword.current.focus();
    }



    const ShowSavedMessage = () => {
        window.scrollTo(0,0);
        setShowSaved(true);
        setTimeout(() => {
            setShowSaved(false);
        }, 3000);
    }


    const SavePassword = async() => {

        try {

            //UserDetails object to POST to API
            const UserDetails = {
                UserDetails: {
                    Id: User.Id,
                    Password
                }
            }

            //API call
            const req = await apiPost('/Members/SetPassword', UserDetails);
            if(!req || !req.ok || !req.status === 204) {
                setError(true);
                return;
            }

            //Scroll to the top and show 'Saved' message
            ShowSavedMessage();

        }
        catch (error) {

        }

    }




    const UpdateValue = value => {
        setUserDetails(prevState => {
            return {...prevState, ...value};
        });
    }


    //API call to save user
    const SaveUser = async() => {

        try {

            if(!EmailOK) {
                window.alert('Email address is currently in use by another active account - you cannot save with this address');
                return;
            }

            //Save via API POST
            UserDetails.DateExpires = null;
            const data = {UserDetails};

            UserDetails.DateOfBirth = DateOfBirth === '' ? null : moment(DateOfBirth).format('DD/MM/YYYY')
            const req = await apiPost('/members/update', data);
            if(!req || !req.ok || !req.status === 204) {
                setError(true);
                return;
            }

            //Save for context also
            SetUser({
                User: {
                    Id: User.Id,
                    FirstName: UserDetails.FirstName,
                    LastName: UserDetails.LastName,
                    Email: UserDetails.Email
                }
            });

            //Show 'Saved' message for three seconds
            ShowSavedMessage();

        }
        catch (error) {

        }

    }


    useEffect(() => {

        const LoadUserDetails = async() => {

            try {

                setError(false);

                const req = await apiGet(`/members/account/${User.Id}`);
                if(req && req.ok && req.status === 200) {
                    const res = await req.json();
                    setUserDetails(res);
                    setDateOfBirth(res.DateOfBirth !== null ? moment(res.DateOfBirth).toDate() : '');
                    setLoading(false);
                } else {
                    setError(true);
                    setLoading(false);
                }

            }
            catch (error) {
                setError(true);
                setLoading(false);
            }
        }

        LoadUserDetails();
    },[User.Id]);


    //Check password is valid
    useEffect(() => {
        setPasswordValid(ValidatePassword(Password));
    },[Password]);



    //Show/hide passwords
    useEffect(() => {

        if(cpNewPassword && cpNewPassword.current)
            cpNewPassword.current.type = ShowPassword ? 'text' : 'password';

    },[ShowPassword]);




    //Validate email address when it changes
    useEffect(() => {

        const CheckEmail = async() => {

            const ERROR_MSG = 'Error validating email address - please check your Internet connection';

            try {

                const data = {
                    EmailAddress: UserDetails.Email
                }
                const req = await apiPost('/registration/checkemail', data);
                if(req && req.ok && req.status === 200) {
                    const res = await req.json();
                    setEmailOK(res.Outcome === 0 || (res.Outcome === 1 && res.Id === UserDetails.Id));
                } else {
                    window.alert(ERROR_MSG);
                }

            }
            catch (error) {
                console.log(error);
                window.alert(ERROR_MSG);
            }

        }

        if(UserDetails && UserDetails.Email !== undefined) {
            CheckEmail();
        }

    },[UserDetails]);




    if(Loading)
        return <ModalLoading />


    if(Error)
        return <Message Type="Error" Content="Sorry, there was an error loading your account details. Please try again" />


    return (
        <div className="form-centered">

            {
                ShowSaved ?
                <div className="alert alert-success mb-5x">
                    <p><b>Data saved</b></p>
                    <p>
                        Your account details have been successfully updated
                    </p>
                </div>
                :
                <></>
            }


            <div className="card mb-4x">
                <div className="card-header">
                    <h4>My Account</h4>
                </div>
                <div className="card-body">

                    <p className="mb-2x">Alter your details as necessary below and click 'Save Details' to update the database</p>
                    <p className="mb-2x">
                        <b>Changing your password</b><br />
                        If you wish to change your password, please <span className="cursor-pointer" onClick={ScrollToChangePassword}>click here</span>.
                    </p>

                    <div className="form-group">
                        <label className="form-label">First Name:</label>
                        <input type="text" value={UserDetails.FirstName} onChange={e => UpdateValue({FirstName: e.target.value})} className="form-control width-50" />
                    </div>
                    <div className="form-group">
                        <label className="form-label">Last Name:</label>
                        <input type="text" value={UserDetails.LastName} onChange={e => UpdateValue({LastName: e.target.value})} className="form-control width-50" />
                    </div>
                    <div className="form-group mb-2x">
                        <label className="form-label">Email Address:</label>
                        <input type="text" value={UserDetails.Email} onChange={e => UpdateValue({Email: e.target.value})} className="form-control width-50" />
                        {
                            EmailOK ? <></> : <><p>Email address already in use</p></>
                        }
                    </div>    

                    <div className="form-group">
                        <label className="form-label">Street Address:</label>
                        <input type="text" value={UserDetails.StreetAddress} onChange={e => UpdateValue({StreetAddress: e.target.value})} className="form-control width-50" />
                    </div>
                    <div className="form-group">
                        <label className="form-label">Town:</label>
                        <input type="text" value={UserDetails.Town} onChange={e => UpdateValue({Town: e.target.value})} className="form-control width-50" />
                    </div>
                    <div className="form-group mb-2x">
                        <label className="form-label">Postcode:</label>
                        <input type="text" value={UserDetails.Postcode} onChange={e => UpdateValue({Postcode: e.target.value})} className="form-control width-25" />
                    </div>

                    <div className="form-group">
                        <label className="form-label">Mobile Phone:</label>
                        <input type="text" value={UserDetails.MobilePhone} onChange={e => UpdateValue({MobilePhone: e.target.value})} className="form-control width-25" />
                    </div>
                    <div className="form-group">
                        <label className="form-label">Other Phone:</label>
                        <input type="text" value={UserDetails.OtherPhone} onChange={e => UpdateValue({OtherPhone: e.target.value})} className="form-control width-25" />
                    </div>  
                    <div className="form-group">
                        <label className="form-label">Junior handler date of birth:</label>
                        <DatePicker maxDate={new Date()} showMonthDropdown showYearDropdown className="form-control width-50" selected={DateOfBirth} dateFormat="dd/MM/yyyy" value={DateOfBirth} onChange={val => setDateOfBirth(val)}></DatePicker>
                    </div>

                </div>
                <div className="card-footer">
                    <button className="button button-green" onClick={SaveUser}>Save Details</button>
                </div>
            </div>


            <div className="card" ref={cpRef}>
                <div className="card-header">
                    <h5>Change my password</h5>
                </div>
                <div className="card-body">
                    <p className="mb-2x">
                        If you wish to create a new password, please enter it below and click the 'Save Password' button
                    </p>
                    <div className="form-group">
                        <label>New Password</label>
                        <input type="password" ref={cpNewPassword} className="form-control" value={Password} onChange={e => setPassword(e.target.value)} />
                        Show password <input type="checkbox" value={ShowPassword} onChange={e => setShowPassword(e.target.checked)}></input>
                    </div>
                </div>
                <div className="card-footer">
                    {
                        PasswordValid ? 
                        <button className="button button-green" onClick={SavePassword}>Save Password</button>
                        :
                        <p>Password not yet valid - cannot Save</p>   
                    }
                </div>
            </div>


        </div>
    );
}

export default withRouter(Account);