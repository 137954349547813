import React, { useState, useEffect } from "react";
import moment from "moment";

const DayView = ({ Dog, ...props }) => {
  const [Classes, setClasses] = useState([]);
  const [DaySelected, setDaySelected] = useState(props.Data?.SingleDayShow);
  const [Capped, setCapped] = useState(false);

  //Store selected classes for this date
  useEffect(() => {
    if (Classes.length === 0 || !props.ClassDate) return;

    let entrance = sessionStorage.getItem("Classes") ?? null;
    entrance = entrance === null ? [] : JSON.parse(entrance);
    let foundDay = entrance.find((c) =>
      moment(c.ClassDate).isSame(props.ClassDate)
    );

    const selectedClasses = DaySelected ? Classes : [];
    if (foundDay) {
      foundDay.Classes = selectedClasses;
    } else {
      entrance.push({ ClassDate: props.ClassDate, Classes: selectedClasses });
    }
    sessionStorage.setItem("Classes", JSON.stringify(entrance));
  }, [DaySelected, Classes, props.ClassDate]);

  useEffect(() => {
    if (props.Data?.ExistingEntry.length > 0) setDaySelected(true);

    const found = props.Data.AllClassCombinations.find((c) =>
      moment(c.ClassDate).isSame(moment(props.ClassDate))
    );
    if (found && found.Capped) setCapped(true);
  }, [props.Data, props.ClassDate]);

  useEffect(() => {
    if (!Dog) {
      setClasses([]);
    } else {
      const PerformanceLevelId = props.Data.LevelList.find(
        (l) => l.LevelLabel === Dog.PerformanceLevel
      ).LevelId;
      const SteeplechaseLevelId = props.Data.LevelList.find(
        (l) => l.LevelLabel === Dog.SteeplechaseLevel
      ).LevelId;
      let PerformanceHeightId = parseInt(props.Data.HeightId);
      let SteeplechaseHeightId = parseInt(props.Data.HeightId);
      let AllCombos = props.Data.AllClassCombinations.filter(
        (c) =>
          moment(c.ClassDate).isSame(moment(props.ClassDate)) &&
          c.IsEnterable === true &&
          (c.Capped === false || props.Data?.ExistingEntry?.length > 0) &&
          (((c.ClassType === 1 || c.ClassType === 2 || c.ClassType === 3) &&
            (c.LevelId === PerformanceLevelId || c.LevelId === 99) &&
            c.HeightId === parseInt(PerformanceHeightId)) ||
            (c.ClassType === 4 &&
              (c.LevelId === SteeplechaseLevelId || c.LevelId === 99) &&
              c.HeightId === parseInt(SteeplechaseHeightId)) ||
            (c.ClassType > 5 &&
              c.ClassType < 8 &&
              c.HeightId === parseInt(PerformanceHeightId) &&
              (c.LevelId === PerformanceLevelId || c.LevelId === 99)) ||
            (c.ClassType === 9 &&
              c.HeightId === parseInt(PerformanceHeightId) &&
              (c.LevelId === PerformanceLevelId || c.LevelId === 99)))
      );

      AllCombos = AllCombos.sort((a, b) => {
        if (a.LevelId < b.LevelId) return -1;
        if (a.LevelId > b.LevelId) return 1;
        if (a.ClassId < b.ClassId) return -1;
        if (a.ClassId > b.ClassId) return 1;
        if (a.HeightId < b.HeightId) return -1;
        if (a.HeightId > b.HeightId) return 1;
        return 0;
      });

      setClasses(AllCombos);
    }
  }, [Dog, props.Data, props.ClassDate]);

  //Get classes this dog can compete in
  const ClassRows = () => {
    return (
      <span>
        <br />
        {Classes.map(
          (itm) => `${itm.LevelLabel} ${itm.ClassLabel} ${itm.HeightLabel}`
        ).join(", ")}{" "}
        <span className="ml-2x">
          Entry cost:{" "}
          <b>
            £
            {(
              Classes.reduce((acc, itm) => (acc += itm.RunCost), 0) /
              Classes.length
            ).toFixed(2)}
          </b>
        </span>
      </span>
    );
  };

  return (
    <div className="mb-2x">
      <div className="grid">
        <div className="col-2">
          <h2>{moment(props.ClassDate).format("ddd Do MMM")}</h2>
        </div>
        <div className="col-4">
          {Capped && props.Data?.ExistingEntry?.length !== 0 && (
            <b>
              Sorry, this day has reached it's capping level and your entry
              cannot now be altered.
            </b>
          )}

          {Capped && props.Data?.ExistingEntry?.length === 0 && (
            <b>
              Sorry, this day has reached it's capping level. Please see the
              schedule for details of how to join the waiting list
            </b>
          )}
          {!Capped && (
            <label className="switch ml-3x">
              <input
                type="checkbox"
                checked={DaySelected}
                onChange={(e) => setDaySelected(e.target.checked)}
              />
              <span className="slider round"></span>
            </label>
          )}
        </div>
      </div>
      {!Capped && ClassRows()}
    </div>
  );
};

export default DayView;
