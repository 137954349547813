import React, { useState, useEffect } from "react";
import { apiGet, apiPost } from "../../../shared/api";

const Item = ({ Details }) => {
  const [Value, setValue] = useState("");
  const [Outcome, setOutcome] = useState(null);

  useEffect(() => {
    (async () => {
      const req = await apiGet(`/helpers/systemparameter/${Details.ParamName}`);
      const res = await req.json();
      setValue(res);
    })();
  }, [Details]);

  const Save = async () => {
    const obj = { ParamName: Details.ParamName, ParamValue: Value };
    const req = await apiPost("/helpers/systemparameter", obj);
    if (req && req.ok) {
      setOutcome("Saved");
    } else {
      setOutcome("ERROR SAVING");
    }
    setTimeout(() => {
      setOutcome(null);
    }, 5000);
  };

  return (
    <div className="mb-3x">
      <p>{Details.Name}</p>
      <input
        type="text"
        className="form-control width-25 mr-1x"
        value={Value}
        onChange={(e) => setValue(e.target.value)}
      />
      {!Outcome && (
        <button className="button button-green" onClick={Save}>
          Save
        </button>
      )}
      {Outcome && <span>{Outcome}</span>}
    </div>
  );
};

export default Item;
