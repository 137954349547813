module.exports.DownloadFile = (blob,filename) => {

    try {
        const lnk = document.createElement('a');
        lnk.style.display = 'none';
        const url = window.URL.createObjectURL(blob);
        lnk.href = url;
        lnk.download = filename;
        lnk.click();
        window.URL.revokeObjectURL(url);

        if (lnk && lnk.parentElement)
            lnk.parentElement.removeChild(lnk);

    }
    catch(error) {
        console.log(error.message);
        window.alert('Error downloading file');
    }
}