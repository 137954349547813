import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { apiGet, apiPost } from "../../../shared/api";
import { useParams, withRouter, Link } from "react-router-dom";
import ModalLoading from "../../../shared/ModalLoading";
import Message from "../../../shared/Message";
import CreateSelect from "react-select/async-creatable";

function EditDog(props) {
  const [Loading, setLoading] = useState(true);
  const [Error, setError] = useState(false);
  const [PetName, setPetName] = useState("");
  const [Gender, setGender] = useState("");
  const [Breed, setBreed] = useState("");
  const [DateOfBirth, setDateOfBirth] = useState("");
  const [PerformanceLevel, setPerformanceLevel] = useState("");
  const [SteeplechaseLevel, setSteeplechaseLevel] = useState("");
  const [HeightId, setHeightId] = useState("");
  const [Status, setStatus] = useState("");
  const [Notes, setNotes] = useState("");
  const [BreedList, setBreedList] = useState([]);

  const { Id } = useParams();

  //Find a breed for the list
  const FindBreed = async (SearchString) => {
    const matches = BreedList.filter((b) =>
      b.BreedLabel.toLowerCase().startsWith(SearchString.toLowerCase())
    );
    return matches.map((itm) => {
      return { value: itm.BreedLabel, label: itm.BreedLabel };
    });
  };

  //Load list of breeds
  useEffect(() => {
    const LoadData = async () => {
      const ERROR_MSG = "Error loading list of dog breeds";

      try {
        const req = await apiGet("/dogs/breeds");
        if (req && req.ok && req.status === 200) {
          const res = await req.json();
          setBreedList(res);
        } else {
          window.alert(ERROR_MSG);
        }
      } catch (error) {
        console.log(error.message);
        window.alert(ERROR_MSG);
      }
    };

    LoadData();
  }, []);

  const SaveDog = async () => {
    try {
      //Save via API POST
      const data = {
        Id,
        PetName,
        Breed: Breed.value,
        Gender,
        PerformanceLevel,
        SteeplechaseLevel,
        HeightId,
        Status,
        Notes,
      };

      data.DateOfBirth =
        DateOfBirth === "" ? null : moment(DateOfBirth).format("DD/MM/YYYY");
      const req = await apiPost("/dogs/update", data);
      if (!req || !req.ok || !req.status === 204) {
        setError(true);
        return;
      }

      props.history.push("/dogs");
    } catch (error) {
      setError(true);
      console.log(error.message);
    }
  };

  useEffect(() => {
    const LoadDogDetails = async () => {
      try {
        setError(false);

        const req = await apiGet(`/dogs/${Id}`);
        if (req && req.ok && req.status === 200) {
          const res = await req.json();
          setPetName(res.PetName);
          setDateOfBirth(
            res.DateOfBirth !== null ? moment(res.DateOfBirth).toDate() : ""
          );
          setGender(res.Gender);
          setBreed({ value: res.Breed, label: res.Breed });
          setPerformanceLevel(res.PerformanceLevel);
          setSteeplechaseLevel(res.SteeplechaseLevel);
          setHeightId(res.HeightId);
          setStatus(res.Status);
          setNotes(res.Notes);
          setLoading(false);
        } else {
          setError(true);
          setLoading(false);
        }
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    };

    LoadDogDetails();
    window.scrollTo(0, 0);
  }, [Id]);

  if (Loading) return <ModalLoading />;

  if (Error)
    return (
      <Message
        Type="Error"
        Content="Sorry, there was an error with these dog details. Please try again"
      />
    );

  return (
    <>
      <div className="card mb-2x">
        <div className="card-header">
          <h4>Dog Details</h4>
        </div>
        <div className="card-body">
          <p className="mb-2x">
            Alter details as necessary below and click 'Save Details' to update
            the database
          </p>

          <div className="form-group">
            <label className="form-label">Pet Name</label>
            <input
              type="text"
              className="form-control width-50"
              value={PetName}
              onChange={(e) => setPetName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label className="form-label">Date of birth</label>
            <DatePicker
              className="form-control width-50"
              selected={DateOfBirth}
              dateFormat="dd/MM/yyyy"
              value={DateOfBirth}
              onChange={(val) => setDateOfBirth(val)}
            ></DatePicker>
          </div>
          <div className="form-group">
            <label className="form-label">Gender</label>
            <select
              className="form-control width-25"
              value={Gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <option value="M">Male</option>
              <option value="F">Female</option>
            </select>
          </div>
          <div className="form-group">
            <label className="form-label">Breed</label>
            <CreateSelect
              isClearable
              value={Breed}
              className="width-50"
              placeholder="Start typing a breed"
              noOptionsMessage={() => "Start typing a breed"}
              loadOptions={FindBreed}
              onChange={(e) => setBreed(e || "")}
            />
          </div>
          <div className="form-group">
            <label className="form-label">Status</label>
            <select
              className="form-control width-25"
              value={Status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="1">Active</option>
              <option value="5">Not Competing</option>
              <option value="2">Retired</option>
              <option value="3">Rainbow Bridge</option>
            </select>
          </div>
        </div>

        <div className="card-footer">
          <button className="button button-green mr-1x" onClick={SaveDog}>
            Save Details
          </button>
          <Link to="/dogs">Cancel</Link>
        </div>
      </div>
    </>
  );
}

export default withRouter(EditDog);
