import React from 'react';
import '../styles/Message.css';

function Message(props) {

    let classList = '';
    switch(props.Type) {
        case 'Success': classList = 'Message Message-Green'; break;
        case 'Error': classList = 'Message Message-Red'; break;
        case 'Warning': classList = 'Message Message-Gold'; break;
        case 'Info': classList = 'Message Message-Blue'; break;
        default: classList = 'Message';
    }

    return (
        <div className={classList}>
            <p>{props.Content}</p>
            {props.children}
        </div>
    )  
}

export default Message;