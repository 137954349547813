import React, { useState, useEffect } from "react";
import { apiGet, apiPost } from "../../../shared/api";
import { withRouter, useParams } from "react-router-dom";

const PINCodes = (props) => {
  const [TentPIN, setTentPIN] = useState("");
  const [MeasurePIN, setMeasurePIN] = useState("");
  const [RingPIN, setRingPIN] = useState("");
  const [AccountsPIN, setAccountsPIN] = useState("");

  const { Id } = useParams();

  useEffect(() => {
    const LoadData = async () => {
      try {
        const req = await apiGet(`/admin/shows/pincodes/${Id}`);
        const res = await req.json();
        setTentPIN(res.TentPIN);
        setMeasurePIN(res.MeasurePIN);
        setRingPIN(res.RingPIN);
        setAccountsPIN(res.AccountsPIN);
      } catch (error) {
        console.log(error.message);
        window.alert("Error loading PIN codes");
      }
    };
    LoadData();
  }, [Id]);

  const SavePINs = async () => {
    try {
      const req = await apiPost("/admin/shows/pincodes", {
        ShowId: Id,
        TentPIN,
        MeasurePIN,
        RingPIN,
        AccountsPIN,
      });
      if (req && req.ok) {
        props.history.push("/showmanagers");
      } else {
        window.alert("Error saving Show PINs");
      }
    } catch (error) {
      console.log(error.message);
      window.alert("Error saving Show PINs");
    }
  };

  return (
    <div className="card">
      <div className="card-header">Show tablet PIN codes</div>
      <div className="card-body">
        <p>
          Please set the PIN codes you wish to use on the tablet at your show
        </p>
        <div className="form-group">
          <label>Tent PIN</label>
          <input
            type="number"
            className="form-control width-20"
            value={TentPIN}
            onChange={(e) => setTentPIN(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Measure PIN</label>
          <input
            type="number"
            className="form-control width-20"
            value={MeasurePIN}
            onChange={(e) => setMeasurePIN(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Ring PIN</label>
          <input
            type="number"
            className="form-control width-20"
            value={RingPIN}
            onChange={(e) => setRingPIN(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Show Managers PIN</label>
          <input
            type="number"
            className="form-control width-20"
            value={AccountsPIN}
            onChange={(e) => setAccountsPIN(e.target.value)}
          />
        </div>
        <button className="button button-green mr-1x" onClick={SavePINs}>
          Save
        </button>
        <button
          className="button button-outline"
          onClick={() => props.history.push("/showmanagers")}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default withRouter(PINCodes);
