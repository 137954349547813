import React from "react";
import SpecialClass from "./specialclass";

const SpecialClasses = () => {
  const IdList = [26];

  return (
    <div className="card">
      <div className="card-header">Special Class costs</div>
      <div className="card-body">
        {IdList.map((Id) => (
          <SpecialClass ClassId={Id} key={Id} />
        ))}
      </div>
    </div>
  );
};

export default SpecialClasses;
