import React from "react";
import { Link } from "react-router-dom";

function Reports(props) {
  return (
    <div className="card">
      <div className="card-header">Wallet Reports</div>
      <div className="card-body">
        <ul>
          <li>
            <Link to="/admin/wallet/balances">Wallet Balances</Link>
          </li>
          <li>
            <Link to="/admin/wallet/balances/previousday">
              Wallet Balances (Previous Day)
            </Link>
          </li>
          <li>
            <Link to="/admin/wallet/handlerregistration">
              Handler Registration
            </Link>
          </li>
          <li>
            <Link to="/admin/wallet/handlerrenewal">Handler Renewals</Link>
          </li>
          <li>
            <Link to="/admin/wallet/dogfees">Dog Fees</Link>
          </li>
          <li>
            <Link to="/admin/wallet/feesreport">All Collected Fees</Link>
          </li>
          <li>
            <Link to="/admin/wallet/credits">Credits</Link>
          </li>
          <li>
            <Link to="/admin/wallet/activity">Activity</Link>
          </li>
          <li>
            <Link to="/admin/wallet/unknownbacs">Unknown BACS</Link>
          </li>
          <li>
            <Link to="/admin/wallet/unknownbacsassigned">
              Unknown BACS Assigned
            </Link>
          </li>
        </ul>
      </div>
      <div className="card-footer"></div>
    </div>
  );
}

export default Reports;
