import React, { useState, useEffect, useCallback } from "react";
import { ReactSortable } from "react-sortablejs";
import { apiGet, apiPost } from "../../../../shared/api";
import moment from "moment";
import { useParams } from "react-router-dom";
import InlineLoading from "../../../../shared/InlineLoading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { useInterval } from "beautiful-react-hooks";

const RingEditorRing = (props) => {
  const [List, setList] = useState([]);
  const [Heights, setHeights] = useState([]);
  const [ShowLoading, setShowLoading] = useState(false);
  const [SelectedItem, setSelectedItem] = useState();
  const [JudgeName, setJudgeName] = useState("");
  const [ReverseVisible, setReverseVisible] = useState(false);
  const [FlipVisible, setFlipVisible] = useState(false);
  const [HeightLoading, setHeightLoading] = useState(false);
  const [SavingInProgress, setSavingInProgress] = useState(false);
  const [IsDirty, setIsDirty] = useState(false);

  const { Id } = useParams();

  useEffect(() => setIsDirty(true), [List]);

  const LoadCombinations = useCallback(async () => {
    const HandleError = () => {
      setList([]);
      window.alert("Error loading combination data");
    };

    try {
      //Combinations
      let req, res;
      req = await apiGet(`/admin/shows/classes/listcombinations_ring/${Id}`);
      if (req && req.ok) {
        res = await req.json();
        res = res.filter((r) => moment(r.ClassDate).format("YYYYMMDD") === props.Date);

        return res;
      } else {
        HandleError();
        return [];
      }
    } catch (error) {
      HandleError();
      return [];
    }
  }, [Id, props.Date]);

  const SaveHeights = async () => {
    if (props.Date && props.RingNumber) {
      try {
        setSavingInProgress(true);
        let obj = {
          ShowId: Id,
          ClassDate: props.Date,
        };
        let rowCount = 1;

        for (const row of Heights) {
          const idParts = SelectedItem.split("_");
          obj.ClassId = idParts[0];
          obj.LevelId = idParts[1];
          obj.HeightId = row.id;
          obj.SortOrder = rowCount;
          obj.RingNumber = props.RingNumber;
          await apiPost(`/admin/shows/classes/setheightorder`, obj);
          rowCount += 1;
        }

        console.log("Save complete");
      } catch (error) {
        console.log(error.message);
      } finally {
        setSavingInProgress(false);
      }
    }
  };

  const SaveJudge = async () => {
    try {
      const obj = {
        ShowId: Id,
        RingId: props.RingNumber,
        ClassDate: props.Date,
        JudgeName,
      };
      await apiPost(`/admin/shows/rings/judgedetails`, obj);
    } catch (error) {
      console.log(error.message);
      window.alert("Error saving judge");
    }
  };

  const SaveRing = async () => {
    if (props.Date && props.RingNumber) {
      try {
        let obj = {
          ShowId: Id,
          ClassDate: props.Date,
        };
        let rowCount = 1;
        for (const row of List) {
          const idParts = row.id.split("_");
          obj.ClassId = idParts[0];
          obj.LevelId = idParts[1];
          obj.SortOrder = rowCount;
          obj.RingNumber = props.RingNumber;
          await apiPost(`/admin/shows/classes/setlevelorder`, obj);
          rowCount += 1;
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const LoadInitialData = useCallback(async () => {
    if (props.Date && props.RingNumber) {
      try {
        setShowLoading(true);
        setSelectedItem();
        setHeights([]);

        let req, res;

        //Judge details
        req = await apiGet(
          `/admin/shows/rings/judgedetails/${Id}/${props.RingNumber}/${props.Date}`
        );
        res = await req.json();
        setJudgeName(res.JudgeName || "TBA");

        //Combinations
        res = await LoadCombinations();
        let _filtered = [];
        for (const row of res)
          if (
            !_filtered.find((r) => r.ClassId === row.ClassId && r.LevelId === row.LevelId)
          )
            _filtered.push(row);

        _filtered = _filtered
          .filter((r) => r.RingNumber === parseInt(props.RingNumber))
          .sort((a, b) => (a.LevelSortOrder < b.LevelSortOrder ? -1 : 1));
        const list = _filtered.map((itm) => {
          return { id: `${itm.ClassId}_${itm.LevelId}`, name: itm.ClassLabel };
        });

        setList(list);

        setShowLoading(false);
      } catch (error) {
        setShowLoading(false);
        console.log(error.message);
      }
    }
  }, [Id, props.RingNumber, props.Date, LoadCombinations]);

  useEffect(() => {
    if (props.Date) {
      setSelectedItem();
      LoadInitialData();
    }
  }, [LoadInitialData, props.RefreshCount, props.Date]);

  //Handle selecting an item in the class list
  const SetItem = async (Id) => {
    const idParts = Id.split("_");

    //Is this the first level in the list for this class?
    const foundClass = List.find((i) => i.id === Id);
    const myPos = List.indexOf(foundClass);
    setReverseVisible(myPos !== 0);
    setSelectedItem(Id);
    setFlipVisible(true);

    //Load combinations and get heights
    try {
      setHeightLoading(true);
      const combinations = await LoadCombinations();
      const _heights = combinations
        .filter(
          (c) => c.ClassId === parseInt(idParts[0]) && c.LevelId === parseInt(idParts[1])
        )
        .sort((a, b) => {
          return a.HeightSortOrder < b.HeightSortOrder ? -1 : 1;
        });
      setHeights(
        _heights.map((itm) => {
          return { id: itm.HeightId, name: itm.HeightLabel };
        })
      );
      setHeightLoading(false);
    } catch (error) {
      setHeightLoading(false);
    }
  };

  const doFlipClass = async () => {
    try {
      if (List.length < 2) return;
      setSavingInProgress(true);
      let _heights = Array.from(Heights).reverse();
      let obj = {
        ShowId: Id,
        ClassDate: props.Date,
      };

      const idParts = SelectedItem.split("_");
      const cId = parseInt(idParts[0]);
      if (![32, 33, 34, 35].some((i) => i === cId)) {
        let rowCount = 1;
        for (const height of _heights) {
          obj.ClassId = idParts[0];
          obj.LevelId = idParts[1];
          obj.HeightId = height.id;
          obj.SortOrder = rowCount;
          obj.RingNumber = props.RingNumber;
          await apiPost(`/admin/shows/classes/setheightorder`, obj);
          rowCount += 1;
        }
      }
      setHeights(_heights);
      setSavingInProgress(false);
    } catch (error) {
      console.log(error.message);
      setSavingInProgress(false);
      window.alert("Error saving data");
    }
  };

  //Reverse this and subsequent classes
  const doReverse = async () => {
    try {
      setSavingInProgress(true);

      if (List.length < 2) return;

      let obj = {
        ShowId: Id,
        ClassDate: props.Date,
      };

      //Find where we are in the list
      const found = List.find((i) => i.id === SelectedItem);
      let startPos = List.indexOf(found) - 1;
      const prevItem = List[startPos];
      const idParts = prevItem.id.split("_");

      //Load heights for previous item in list
      const combinations = await LoadCombinations();
      let _heights = combinations
        .filter(
          (c) => c.ClassId === parseInt(idParts[0]) && c.LevelId === parseInt(idParts[1])
        )
        .sort((a, b) => {
          return a.HeightSortOrder < b.HeightSortOrder ? -1 : 1;
        });

      const first = Array.from(_heights).reverse();

      for (const itm of List.slice(startPos + 1, List.length)) {
        const idParts = itm.id.split("_");
        const cId = parseInt(idParts[0]);
        if (![32, 33, 34, 35].some((i) => i === cId)) {
          _heights = _heights.reverse();
          let rowCount = 1;
          for (const height of _heights) {
            obj.ClassId = idParts[0];
            obj.LevelId = idParts[1];
            obj.HeightId = height.HeightId;
            obj.SortOrder = rowCount;
            obj.RingNumber = props.RingNumber;
            await apiPost(`/admin/shows/classes/setheightorder`, obj);
            rowCount += 1;
          }
        }
      }

      setHeights(
        first.map((itm) => {
          return { id: itm.HeightId, name: itm.HeightLabel };
        })
      );

      setSavingInProgress(false);
    } catch (error) {
      console.log(error.message);
      setSavingInProgress(false);
      window.alert("Error saving data");
    }
  };

  useInterval(async () => {
    if (IsDirty) {
      setIsDirty(false);
      await SaveRing();
    }
  }, 2000);

  return (
    <div className="card mb-3x">
      <div className="card-header">
        <p>
          <b>Ring {props.RingNumber}</b>
        </p>
      </div>
      <div className="card-body">
        {ShowLoading ? (
          <InlineLoading />
        ) : (
          <>
            <p>
              Judge Name:{" "}
              <input
                type="text"
                className="form-control"
                value={JudgeName}
                onChange={(e) => setJudgeName(e.target.value)}
                onBlur={SaveJudge}
              />
            </p>
            <div className="grid grid-50-50">
              <div>
                <p>Classes</p>
                <ReactSortable
                  group="show"
                  className="ring"
                  list={List}
                  setList={setList}
                >
                  {List.map((itm) => {
                    const classes = ["cursor-pointer", "ring-class"];
                    if (SelectedItem === itm.id) classes.push("ring-class-selected");
                    return (
                      <p
                        className={[...classes]}
                        onClick={() => SetItem(itm.id)}
                        key={itm.id}
                      >
                        {itm.name}
                      </p>
                    );
                  })}
                </ReactSortable>
              </div>
              <div>
                {HeightLoading ? (
                  <div className="mt-2x">
                    <InlineLoading />
                  </div>
                ) : (
                  <>
                    <p>Heights for this class</p>
                    <ReactSortable
                      className="ring"
                      list={Heights}
                      setList={setHeights}
                      onEnd={SaveHeights}
                    >
                      {Heights.map((itm) => (
                        <p key={itm.id} className="cursor-pointer">
                          {itm.name}
                        </p>
                      ))}
                    </ReactSortable>
                  </>
                )}
              </div>
            </div>
            {ReverseVisible && (
              <p>
                <small>
                  <FontAwesomeIcon icon={faQuestionCircle} className="mr-1x" />
                  <b>Reverse heights?</b> Set heights in the reverse order for this and
                  subsquent levels within this class
                </small>
              </p>
            )}
            {(ReverseVisible || FlipVisible) && (
              <div>
                {SavingInProgress && <InlineLoading Message="Saving..." />}
                {ReverseVisible && !SavingInProgress && (
                  <button
                    className="button button-small button-blue mr-1x"
                    onClick={doReverse}
                  >
                    Reverse heights
                  </button>
                )}
                {FlipVisible && !SavingInProgress && (
                  <button
                    className="button button-small button-blue"
                    onClick={doFlipClass}
                  >
                    Flip this class
                  </button>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RingEditorRing;
