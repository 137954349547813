import React, { useState, useEffect, useCallback } from "react";
import { apiGet, apiPost } from "../../../shared/api";
import moment from "moment";
import { Link } from "react-router-dom";

const ShowList = (props) => {
  const [ShowList, setShowList] = useState([]);
  const [CurrentPage, setCurrentPage] = useState();
  const [PageCount, setPageCount] = useState();
  const [VisibleRecords, setVisibleRecords] = useState([]);
  const PageSize = 12;

  const LoadData = useCallback(async () => {
    try {
      const req = await apiGet("/shows/list");
      const res = await req.json();
      setShowList(res);
    } catch (error) {
      console.log(error.message);
      window.alert("Error loading list of shows");
    }
  }, []);

  useEffect(() => {
    LoadData();
  }, [LoadData]);

  //Works out how many pages we have
  useEffect(() => {
    setPageCount(Math.ceil(ShowList.length / PageSize));
    setCurrentPage(1);
  }, [ShowList]);

  //Gets the current 'page' of records
  useEffect(() => {
    const Matches = ShowList.slice(
      0 + (CurrentPage - 1) * PageSize,
      CurrentPage * PageSize
    );
    setVisibleRecords(Matches);
  }, [CurrentPage, ShowList]);

  const DeleteShow = async (ShowId) => {
    try {
      if (window.confirm("Are you sure?")) {
        const req = await apiPost("/admin/shows/delete", { ShowId });
        if (req && req.ok) {
          LoadData();
        } else {
          window.alert("Error deleting show");
        }
      }
    } catch (error) {}
  };

  //List of ShowLine controls
  const Rows = VisibleRecords.map((itm) => {
    return (
      <tr key={itm.ShowId}>
        <td>{itm.ShowId}</td>
        <td>
          {itm.DisplayName}
          {itm.NoOnlineLates && (
            <>
              <br />
              <span style={{ color: "red" }}>No online lates</span>
            </>
          )}
        </td>
        <td>
          {itm.VenueName}
          {itm.DontRunDebtors && (
            <>
              <br />
              <span style={{ color: "red" }}>Debtors runs disabled</span>
            </>
          )}
        </td>
        <td>
          {moment(itm.StartDate).isSame(moment(itm.EndDate)) ? (
            moment(itm.StartDate).format("DD MMM YYYY")
          ) : (
            <>
              {moment(itm.StartDate).format("DD MMM")}-
              {moment(itm.EndDate).format("DD MMM")} {moment(itm.EndDate).format("YYYY")}
            </>
          )}
        </td>
        <td>
          {itm.EntriesOpen ? (
            <>
              {moment(itm.EntriesOpen).format("DD/MM/YYYY")}
              <br />
              {moment(itm.EntriesClose).format("DD/MM/YYYY")}
            </>
          ) : (
            <></>
          )}
        </td>
        <td>
          <Link className="visible-hover mr-1x" to={`/admin/shows/edit/${itm.ShowId}`}>
            Edit
          </Link>
          {moment(itm.StartDate).isAfter(moment()) && (
            <>
              <span
                className="cursor-pointer visible-hover mr-1x"
                onClick={() => DeleteShow(itm.ShowId)}
              >
                Delete
              </span>
              <br />
            </>
          )}
          <Link
            className="visible-hover mr-1x"
            to={`/admin/shows/processing/${itm.ShowId}`}
          >
            Processing
          </Link>
          {moment(itm.StartDate).isBefore(moment()) ? (
            <Link className="visible-hover mr-1x" to={`/Results/Show/${itm.ShowId}`}>
              Results
            </Link>
          ) : (
            <></>
          )}
        </td>
      </tr>
    );
  });

  const PageList = () => {
    const output = [];

    for (let p = 1; p <= PageCount; p++)
      output.push(
        <option key={p} value={p}>
          {p}
        </option>
      );
    return output;
  };

  return (
    <>
      <table className="table table-hover mb-2x">
        <thead>
          <tr>
            <th>Id</th>
            <th>Display Name</th>
            <th>Venue</th>
            <th>Dates</th>
            <th>Open / Close</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{Rows}</tbody>
      </table>

      <p>
        Jump to page:{" "}
        <select
          className="form-control width-10"
          value={CurrentPage}
          onChange={(e) => setCurrentPage(e.target.value)}
        >
          {PageList()}
        </select>
      </p>
    </>
  );
};

export default ShowList;
