import React, { useState } from 'react';
import { apiPost } from '../../../shared/api';

const ClassLine = props => {

    const [Val, setVal] = useState(props.Class.ClassLabel);

    const Save = async() => {
        try {

            const obj = {
                ClassId: props.Class.ClassId,
                ClassLabel: Val
            }
            const req = await apiPost('/admin/classes/update', obj);
            if(req && req.ok) {
                window.alert('Save complete');
            } else {
                window.alert('Error saving class');
            }

        }
        catch (error) {
            console.log(error.message);
            window.alert('Error saving class');
        }
    }

    return ( 
        <div className="grid mb-1x">
            <div className="col-10">
                <input type="text" className="form-control" value={Val} onChange={e => setVal(e.target.value)} />
            </div>
            <div className="col-2">
                <button className="button button-green" onClick={Save}>Save</button>
            </div>
        </div>
     );
}
 
export default ClassLine;