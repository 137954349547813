import React,{useState, useEffect} from 'react';
import {apiPost} from '../../../shared/api.js';
import { useParams } from 'react-router-dom';
import numeral from 'numeral';


function QuickAddItem(props) {

    const [TransactionType, setTransactionType] = useState('C');
    const [Amount, setAmount] = useState(undefined);
    const [Charges, setCharges] = useState(0);
    const [Method, setMethod] = useState('4');
    const [Detail, setDetail] = useState('');
    const [Notes, setNotes] = useState('');
    const [CanSave, setCanSave] = useState(false);


    const {Id} = useParams();


    //Resets form to default
    const ResetDefaults = () => {
        setTransactionType('C');
        setAmount('');
        setCharges(0);
        setMethod('4');
        setDetail('');
        setNotes('');
    }


    //Update charges when method or amount changes
    useEffect(() => {

        switch(Method) {
            case '1': setCharges(0); break;
            case '2': setCharges(numeral(((Amount * 1.014) - Amount) + 0.2).format('0.00')); break;
            case '3': setCharges(0); break;
            case '4': setCharges(0); break;
            case '5': setCharges(numeral(((Amount * 1.014) - Amount) + 0.2).format('0.00')); break;
            case '6': setCharges(0); break;
            case '7': setCharges(0); break;
            default: setCharges(0); break;
        }

    },[Amount,Method]);



    //Show/hide save button depending on values
    useEffect(() => {
        setCanSave(Amount !== '' && Detail !== '' ? true : false);
    },[Amount,Detail]);



    //Save item to the database
    const SaveItem = async() => {

        const ERROR_MSG = 'Error saving item';

        try {

            const data = {
                Data: {
                    MemberId: Id,
                    TransactionType,Amount,Charges,Method,Detail,Notes,IsAdmin:false
                }
            }      

            const req = await apiPost('/admin/wallet/quickadditem', data);
            if(req && req.ok && req.status === 201) {
                ResetDefaults();
                props.onRefresh();
            } else {
                window.alert(ERROR_MSG);
            }

        }
        catch (error) {
            console.log(error.message);
            window.alert(ERROR_MSG);
        }
    }


    return (
        <div className="card mb-3x">
            <div className="card-header">
                <h4>Add an item to this member wallet</h4>
            </div>
            <div className="card-body">
                <p className="mb-2x">
                    Enter details of the item you wish to add below {CanSave ? <button className="button button-green" onClick={SaveItem}>Save Item</button> : <span className="text-red">Amount and Detail both required to save</span>}
                </p>
                <div className="grid mb-2x">
                    <div className="col-2">
                        <label className="form-label">Transaction Type</label>
                        <select className="form-control" value={TransactionType} onChange={e => setTransactionType(e.target.value)}>
                            <option value="C">Credit</option>
                            <option value="D">Debit</option>
                        </select>
                    </div>
                    <div className="col-2">
                        <label className="form-label">Amount</label>
                        <input type="number" min="1" step="0.01" className="form-control width-75" value={Amount} onChange={e => setAmount(e.target.value)}></input>
                    </div>
                    <div className="col-2">
                        <label className="form-label">Charges</label>
                        {Charges}
                    </div>    
                    <div className="col-2">
                        <label className="form-label">Method</label>
                        <select className="form-control" value={Method} onChange={e => setMethod(e.target.value)}>
                            <option value="4">BACS</option>
                            <option value="5">Card</option>
                            <option value="3">Cheque</option>
                            <option value="6">Cash</option>
                            <option value="7">Admin</option>
                        </select>
                    </div>                                    
                </div>
                <div className="grid">
                    <div className="col-6">
                        <label className="form-label">Details (Visible to member)</label>
                        <input type="text" className="form-control width-75" maxLength="255" value={Detail} onChange={e => setDetail(e.target.value)}></input>
                    </div>
                    <div className="col-6">
                        <label className="form-label text-red">Admin Notes (Not visible to member)</label>
                        <input type="text" className="form-control width-75" maxLength="255" value={Notes} onChange={e => setNotes(e.target.value)}></input>
                    </div>                    
                </div>
            </div>
            <div className="card-footer"></div>
        </div>        
    )
}

export default QuickAddItem;