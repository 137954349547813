import React, { useState, useContext } from "react";
import RegistrationContext from "../../context/registration/RegistrationContext";
import UserContext from "../../context/user/UserContext";
import moment from "moment";
import InlineLoading from "../../shared/InlineLoading";
import { apiPost } from "../../shared/api.js";

function RegStep5(props) {
  const data = useContext(RegistrationContext);
  const { SetUser } = useContext(UserContext);
  const [SaveInProgress, setSaveInProgress] = useState(false);

  //Save details to database
  const SaveRegistration = async () => {
    const ERROR_MSG =
      "Sorry, there was an error creating your account. Please check and try again";

    try {
      setSaveInProgress(true);

      if (data.DateOfBirth && data.DateOfBirth !== "") {
        const minAge = moment().add(-16, "years").add(1, "days");
        const dt = moment(data.DateOfBirth);
        if (dt.isAfter(minAge)) {
          data.IsJunior = 1;
        } else {
          data.DateOfBirth = "";
          data.IsJunior = 0;
        }
      } else {
        data.IsJunior = 0;
      }

      const details = {
        UserDetails: {
          FirstName: data.FirstName,
          LastName: data.LastName,
          Email: data.Email,
          StreetAddress: data.StreetAddress,
          Town: data.Town,
          Postcode: data.Postcode,
          MobilePhone: data.MobilePhone,
          OtherPhone: data.OtherPhone,
          DateOfBirth: data.DateOfBirth !== "" ? data.DateOfBirth : "",
          IsJunior: data.IsJunior,
          Password: data.Password,
        },
      };

      let req = await apiPost("/members/create", details);
      if (req && req.ok && req.status === 201) {
        //Do login
        const LOGIN_ERROR_MSG =
          "There was an error logging you into the system";

        try {
          //API to authenticate
          const body = {
            Email: data.Email,
            Password: data.Password,
          };

          req = await apiPost("/members/authenticate", body);
          if (req && req.ok && req.status === 200) {
            const res = await req.json();

            //Store data
            sessionStorage.setItem("User", JSON.stringify(res));

            //Set the user context also
            SetUser({
              User: {
                Id: res.Id,
                FirstName: res.FirstName,
                LastName: res.LastName,
                Email: res.Email,
              },
            });

            window.location.href = "/";
          } else {
            window.alert(LOGIN_ERROR_MSG);
          }
        } catch (error) {
          console.log(error.msg);
          window.alert(LOGIN_ERROR_MSG);
        }
      } else {
        setSaveInProgress(false);
        window.alert(ERROR_MSG);
      }
    } catch (error) {
      console.log(error);
      window.alert(ERROR_MSG);
      setSaveInProgress(false);
    }
  };

  return (
    <>
      <h4>Confirmation of details</h4>
      <p className="mb-2x">
        Please check the details below prior to submitting your registration
        request.
      </p>

      <div className="form-group">
        <label className="form-label">First Name:</label>
        <p>{data.FirstName}</p>
      </div>
      <div className="form-group">
        <label className="form-label">Last Name:</label>
        <p>{data.LastName}</p>
      </div>
      <div className="form-group">
        <label className="form-label">Email Address:</label>
        <p>{data.Email}</p>
      </div>
      <div className="form-group">
        <label className="form-label">Mobile Phone:</label>
        <p>{data.MobilePhone}</p>
      </div>
      <div className="form-group">
        <label className="form-label">Other Phone:</label>
        <p>{data.OtherPhone}</p>
      </div>
      <div className="form-group">
        <label className="form-label">Street Address:</label>
        <p>{data.StreetAddress}</p>
      </div>
      <div className="form-group">
        <label className="form-label">Town:</label>
        <p>{data.Town}</p>
      </div>
      <div className="form-group">
        <label className="form-label">Postcode:</label>
        <p>{data.Postcode}</p>
      </div>
      {data.DateOfBirth && data.DateOfBirth !== "" ? (
        <div className="form-group mb-4x">
          <label className="form-label">Date of birth:</label>
          <p>{moment(data.DateOfBirth).format("DD/MM/YYYY")}</p>
        </div>
      ) : (
        <></>
      )}

      <p className="mt-2x">
        I agree that these details are correct and wish to apply for
        registration as a A4A member now. In doing this, I agree to comply with
        all A4A rules and regulations as they currently stand, as well as any
        future changes that may be made.
      </p>
      <p className="text-red text-bold mb-2x">
        I understand that handler registration costs £6 for 5 years and I must
        pay this within 7 days or my account will be deleted.
        <br />
        If you are registering as a junior handler there will be no charge until
        your 16th birthday.
      </p>
      {SaveInProgress ? (
        <>
          <InlineLoading Message="Your account is being created. Please wait a moment..." />
        </>
      ) : (
        <>
          <p>
            <button
              className="button button-outline mr-1x"
              onClick={() => data.UpdateValue({ CurrentStep: 4 })}
            >
              Previous
            </button>{" "}
            <button className="button button-green" onClick={SaveRegistration}>
              Submit Registration
            </button>
          </p>
        </>
      )}
    </>
  );
}

export default RegStep5;
