import React, { useState, useEffect, useCallback } from "react";
import InlineLoading from "../../../shared/InlineLoading";
import { apiPost } from "../../../shared/api";
import TransactionItem from "./transactionitem";
import Upcoming from "./upcoming";
import Message from "../../../shared/Message";
import { GetBalance } from "../../../shared/wallet.js";

function Transactions(props) {
  const [Timeframe, setTimeframe] = useState("ThisYear");
  const [Type, setType] = useState("All");
  const [TransactionList, setTransactionList] = useState([]);
  const [TransactionItems, setTransactionItems] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [Error, setError] = useState(false);

  const LoadData = useCallback(async () => {
    try {
      const data = {
        Data: {
          UserId: props.UserId,
          StartDate: "",
          EndDate: "",
        },
      };

      const req = await apiPost("/wallet/transactions", data);
      if (req && req.ok && req.status === 200) {
        const res = await req.json();
        setTransactionList(res);
        setError(false);
        setLoading(false);
      } else {
        setError(true);
        setLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setError(true);
    }
  }, [props.UserId]);

  //Fetch data
  useEffect(() => {
    LoadData();
  }, [Timeframe, Type, props, LoadData]);

  //List of transaction line items
  useEffect(() => {
    const DoWork = async () => {
      const Transactions = TransactionList.filter(
        (itm) =>
          (itm.TransactionType === "C" &&
            (Type === "All" || Type === "Credits")) ||
          (itm.TransactionType === "D" &&
            (Type === "All" || Type === "Debits")) ||
          Type === "All"
      );

      let RunningBalance = await GetBalance(props.UserId);
      RunningBalance = parseFloat(RunningBalance.Balance);

      const LinesToShow = Transactions.map((trans, idx) => {
        const obj = (
          <TransactionItem
            key={idx}
            View={props.View}
            Type={Type}
            Item={trans}
            RunningBalance={RunningBalance}
            onRefresh={LoadData}
          />
        );
        trans.TransactionType === "C"
          ? (RunningBalance -= parseFloat(trans.Amount))
          : (RunningBalance += parseFloat(trans.Amount));
        return obj;
      });

      setTransactionItems(LinesToShow);
    };

    DoWork();
  }, [TransactionList, Type, LoadData, props.UserId, props.View]);

  if (Error)
    return (
      <Message
        Type="Error"
        Content="Sorry, there was an error loading your transaction list. Please try again"
      />
    );

  if (!props.UserId)
    return <Message Type="Info" Content="Waiting for User Id"></Message>;

  return (
    <div className="card">
      <div className="card-header">
        <h4>Transactions</h4>
      </div>
      <div className="card-body">
        <Upcoming UserId={props.UserId} />
        <br />
        <br />
        <br />

        <h4>Completed transactions</h4>
        <p>These transactions are already in your wallet.</p>
        <p className="mb-2x">
          Show entries made on paper do not appear in your wallet. Click on 'My
          Entries' to view.
        </p>
        <div className="grid mb-2x">
          <div className="col-6">
            <div className="form-group">
              <label>Timeframe</label>
              <select
                className="form-control"
                value={Timeframe}
                onChange={(e) => setTimeframe(e.target.value)}
              >
                <option value="All">All</option>
                <option value="ThisYear">This year</option>
                <option value="3Months">Last 3 months</option>
                <option value="12Months">Last 12 months</option>
              </select>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label>Show me</label>
              <select
                className="form-control"
                value={Type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="All">Everything</option>
                <option value="Credits">Just credits</option>
                <option value="Debits">Just debits</option>
              </select>
            </div>
          </div>
        </div>

        <table className="table">
          <thead>
            <tr>
              <th>Date</th>
              <th className="spaced">Details</th>
              <th className="spaced">Method</th>
              <th className="spaced">Amount</th>
              {Type === "All" ? <th>Balance</th> : <></>}
            </tr>
          </thead>
          <tbody>
            {Loading ? (
              <tr>
                <td colSpan="4">
                  <InlineLoading />
                </td>
              </tr>
            ) : TransactionItems.length === 0 ? (
              <tr>
                <td colSpan="3">No transactions found</td>
              </tr>
            ) : (
              <>{TransactionItems}</>
            )}
          </tbody>
        </table>
      </div>
      <div className="card-footer"></div>
    </div>
  );
}

export default Transactions;
