import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import ModalLoading from "../../../shared/ModalLoading";
import Message from "../../../shared/Message";
import { apiGet } from "../../../shared/api";
import ResultsLine from "./resultsline";
import ShowFilters from "./showfilters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import EditDialog from "./editdialog";

function Show(props) {
  const [Loading, setLoading] = useState(true);
  const [Error, setError] = useState(false);
  const [AllResults, setAllResults] = useState([]);
  const [Results, setResults] = useState([]);
  const [ResultList, setResultList] = useState([]);
  const [IsAdmin, setIsAdmin] = useState(false);
  const [ShowAddResult, setShowAddResult] = useState(false);
  const [IsSophie, setIsSophie] = useState(false);

  const [FilterCriteria, setFilterCriteria] = useState({
    LevelId: "",
    ClassType: "",
    PointsOnly: false,
    HeightId: "",
  });
  const [ShowName, setShowName] = useState("");

  const { Id } = useParams();

  useEffect(() => {
    let val = sessionStorage.getItem("User");
    if (val) {
      val = JSON.parse(val);
      if (val.IsAdmin) setIsAdmin(true);
      if (val.Id === 21896) setIsSophie(true);
    }
  }, []);

  const LoadData = useCallback(async () => {
    try {
      if (Id) {
        const req = await apiGet(`/results/show/${Id}`);
        if (req && req.ok && req.status === 200) {
          const res = await req.json();
          setAllResults(res);

          //Store show name from results
          if (res.length > 0) setShowName(res[0].DisplayName);

          setLoading(false);
          //res.length > 0 ? setError(false) : setError(true);
          setError(false);
        } else {
          setLoading(false);
          setError(true);
        }
      }
    } catch (error) {
      setLoading(false);
      setError(true);
      console.log(error);
    }
  }, [Id]);

  //Load results for show
  useEffect(() => {
    LoadData();
  }, [LoadData]);

  //Updates matching results
  useEffect(() => {
    //Apply filters if needed
    let FilteredResults = Array.from(AllResults);
    if (FilterCriteria.LevelId !== "") {
      const levelId = parseInt(FilterCriteria.LevelId);
      FilteredResults = FilteredResults.filter((r) => r.LevelId === levelId);
    }
    if (FilterCriteria.ClassType !== "") {
      const classType = parseInt(FilterCriteria.ClassType);
      switch (classType) {
        case 7:
          FilteredResults = FilteredResults.filter((r) => r.ClassType === 8);
          break;

        case 6:
          FilteredResults = FilteredResults.filter((r) => r.ClassType === 5);
          break;

        case 5:
          FilteredResults = FilteredResults.filter(
            (r) =>
              (r.ClassType === 1 || r.ClassType === 2 || r.ClassType === 3) &&
              (r.LevelId === 2 || r.LevelId === 3 || r.LevelId === 4)
          );
          break;

        default:
          FilteredResults = FilteredResults.filter(
            (r) => r.ClassType === classType
          );
          break;
      }
    }
    if (FilterCriteria.PointsOnly)
      FilteredResults = FilteredResults.filter((r) => r.ProgressionPoints > 0);

    if (FilterCriteria.HeightId !== "")
      FilteredResults = FilteredResults.filter(
        (r) => r.HeightId === parseInt(FilterCriteria.HeightId)
      );
    setResults(FilteredResults);
  }, [FilterCriteria, AllResults]);

  //Renders the table of results
  useEffect(() => {
    const results = Results.map((itm, idx) => {
      return (
        <ResultsLine
          onRefresh={LoadData}
          View="Show"
          Data={itm}
          key={itm.LineId}
          ShowId={Id}
        />
      );
    });
    setResultList(results);
  }, [Results, LoadData, Id]);

  const ShowAddResultDialog = () => {
    setShowAddResult(true);
  };

  if (Loading) return <ModalLoading />;

  if (Error)
    return (
      <Message
        Type="Error"
        Content="Sorry, there was an error loading the data for this show. Please try again. If the show is currently running there may not yet be results available"
      />
    );

  return (
    <div className="card">
      <div className="card-header">Results for {ShowName}</div>
      <div className="card-body">
        {ShowAddResult && (
          <EditDialog
            ShowId={Id}
            Data={null}
            CloseModal={() => {
              window.location.reload();
            }}
          />
        )}

        {AllResults.length !== 0 && (
          <ShowFilters
            onFilterChange={(e) => setFilterCriteria(e)}
            ShowId={Id}
          />
        )}

        {(IsAdmin || IsSophie) && (
          <p className="mb-2x">
            <button
              className="button button-green"
              onClick={ShowAddResultDialog}
            >
              <FontAwesomeIcon icon={faPlus} className="mr-1x" /> Add Result
            </button>
          </p>
        )}

        {Results.length === 0 ? (
          <p>No results to show</p>
        ) : (
          <table className="table font-90">
            <thead>
              <tr>
                {IsAdmin && <th></th>}
                <th>Date</th>
                <th>Class</th>
                <th>Level</th>
                <th>Height</th>
                <th>Handler</th>
                <th>Dog</th>
                <th className="text-center">Faults</th>
                <th className="text-center">Games Points</th>
                <th className="text-center">Time</th>
                <th className="text-center">Award</th>
                <th className="text-center">Progression Points</th>
              </tr>
            </thead>
            <tbody>{ResultList}</tbody>
          </table>
        )}
      </div>
      <div className="card-footer"></div>
    </div>
  );
}

export default Show;
