import React, { useState, useEffect, useCallback, useContext } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faQuestionCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import numeral from "numeral";
import { useParams, withRouter } from "react-router-dom";
import { apiGet, apiPost } from "../../../../shared/api";
import UserContext from "../../../../context/user/UserContext";
import InlineLoading from "../../../../shared/InlineLoading";
import Modal from "../../../../shared/ModalContent";
import ModalLoading from "../../../../shared/ModalLoading";

const SummaryDog = (props) => {
  const [Loading, setLoading] = useState(false);
  const [IsCondensed, setIsCondensed] = useState(props.IsCondensed);
  const [TotalRuns, setTotalRuns] = useState(0);
  const [TotalCost, setTotalCost] = useState(0);
  const [AllRunData, setAllRunData] = useState([]);
  const [RunData, setRunData] = useState([]);
  const [DogId, setDogId] = useState();
  const [DogList, setDogList] = useState([]);
  const [DeleteDogId, setDeleteDogId] = useState("");
  const [OtherEntries, setOtherEntries] = useState();
  const [ShowAreYouSure, setShowAreYouSure] = useState(false);
  const [CallbackFunction, setCallbackFunction] = useState({});
  const [ShowLoading, setShowLoading] = useState(false);
  const [EntryCost, setEntryCost] = useState(0);
  const [RunCost, setRunCost] = useState(0);
  const [IsAdminLogin, setIsAdminLogin] = useState(false);

  const CanEdit = props.CanEdit;

  let { ShowId } = useParams();
  if (!ShowId) ShowId = props.ShowId;

  const { User } = useContext(UserContext);

  const LoadData = useCallback(async () => {
    try {
      let req, res;
      setLoading(true);

      req = await apiGet(`/shows/entry/${ShowId}/${User.Id}`);
      res = await req.json();
      setEntryCost(res.EntryCost);
      setRunCost(res.RunCost);

      req = await apiGet(`/shows/classes/entries/${ShowId}/${User.Id}`);
      res = await req.json();
      setRunData(res);
      setAllRunData(res);

      const DogIdList = new Set();
      for (const run of res) DogIdList.add(run.DogId);

      //Entries made by other members involving this member or their dogs
      req = await apiGet(`/shows/otherpeoplesentries/${ShowId}/${User.Id}`);
      res = await req.json();
      for (const run of res[0]) DogIdList.add(run.DogId);
      for (const run of res[1]) DogIdList.add(run.DogId);

      setOtherEntries(res);

      req = await apiGet(`/dogs/list/${User.Id}`);
      res = await req.json();
      const dogList = [];
      for (const Id of [...DogIdList]) {
        const Dog = res.find((r) => r.Id === Id);
        if (Dog) dogList.push({ Id, PetName: Dog.PetName });
      }
      setDogList(dogList);

      //Check for Admin login
      let itm = sessionStorage.getItem("User");
      itm = JSON.parse(itm);
      if (itm.IsAdminLogin) setIsAdminLogin(true);

      setLoading(false);
    } catch (error) {
      console.log(error.message);
      window.alert("Error loading dog runs");
      setLoading(false);
    }
  }, [ShowId, User.Id]);

  useEffect(() => {
    LoadData();
  }, [LoadData]);

  //Filter table for just a single dog
  useEffect(() => {
    if (!DogId || DogId === "") {
      setRunData(AllRunData);
    } else {
      setRunData(AllRunData.filter((r) => r.DogId === parseInt(DogId)));
    }
  }, [DogId, AllRunData]);

  //Delete all runs for a dog
  const DeleteRunsForDog = async () => {
    try {
      const NextAction = async () => {
        setShowLoading(true);

        const obj = {
          ShowId,
          DogId: DeleteDogId,
          MemberId: User.Id,
          LatesOnly: 0,
        };
        const req = await apiPost(`/shows/entries/deletefordog`, obj);

        if (req && req.ok) {
          const res = await req.json();
          if (res.InShow) {
            props.onSave();
            LoadData();
            props.RefreshCost();
            setDeleteDogId("");
            setShowAreYouSure(false);
            setShowLoading(false);
          } else {
            props.history.push("/shows");
          }
        } else {
          window.alert("Error deleting dog");
          setShowAreYouSure(false);
        }
      };

      ConfirmAction(NextAction);
    } catch (error) {
      console.log(error.message);
      setShowAreYouSure(false);
      setShowLoading(false);
      window.alert("Error deleting entry");
    }
  };

  //Table row for each run
  const DogRows = RunData.map((itm, idx) => {
    return (
      <React.Fragment key={idx}>
        <div className="grid-fixed mb-small">
          <div className="col-full-2 col-mobile-4">{itm.PetName}</div>
          <div className="col-full-2 col-mobile-4">
            {itm.FirstName} {itm.LastName}
          </div>
          <div className="col-full-2 col-mobile-4">
            {moment(itm.ClassDate).format("ddd Do")}
          </div>
          <div className="col-full-2 col-mobile-4">
            {itm.ClassLabel}
            {itm.Sponsor && (
              <>
                <br />
                {itm.Sponsor}
              </>
            )}
          </div>
          <div className="col-full-2 col-mobile-4">{itm.HeightLabel}</div>
          <div className="col-full-2 col-mobile-4">{itm.LevelLabel}</div>
        </div>
      </React.Fragment>
    );
  });

  useEffect(() => {
    setTotalRuns(RunData.length);
    //const Total = RunData.reduce((sum, itm) => (sum += itm.RunCost), 0);
    setTotalCost(EntryCost);
  }, [RunData, EntryCost]);

  const DogsInShow = DogList.map((itm) => (
    <option key={itm.Id} value={itm.Id}>
      {itm.PetName}
    </option>
  ));

  const AddRuns = () =>
    props.history.push(`/shows/scmyentry/${props.ShowId}?View=AddRuns`);

  const OtherHandlerRuns =
    OtherEntries &&
    OtherEntries[0].map((itm, idx) => {
      return (
        <React.Fragment key={idx}>
          <div className="grid-fixed mb-small">
            <div className="col-full-2 col-mobile-4">{itm.PetName}</div>
            <div className="col-full-2 col-mobile-4">
              {itm.FirstName} {itm.LastName}
            </div>
            <div className="col-full-2 col-mobile-4">
              {moment(itm.ClassDate).format("ddd Do")}
            </div>
            <div className="col-full-2 col-mobile-4">
              {itm.ClassLabel}
              {itm.Sponsor && (
                <>
                  <br />
                  {itm.Sponsor}
                </>
              )}
            </div>
            <div className="col-full-2 col-mobile-4">{itm.HeightLabel}</div>
            <div className="col-full-2 col-mobile-4">{itm.LevelLabel}</div>
          </div>
        </React.Fragment>
      );
    });

  const ConfirmAction = (func) => {
    setCallbackFunction(() => func);
    setShowAreYouSure(true);
  };

  if (ShowLoading) return <ModalLoading Message="Please wait a moment..." />;

  return (
    <div className="mb-3x">
      <h3 className="mt-2x mb-2x">Dog entries for this show</h3>
      {ShowAreYouSure ? (
        <Modal>
          <h3>
            <FontAwesomeIcon icon={faQuestionCircle} className="mr-1x" />
            Are you sure?
          </h3>
          <p>
            <button
              className="button button-red mr-1x"
              onClick={CallbackFunction}
            >
              Yes
            </button>
            <button className="button" onClick={() => setShowAreYouSure(false)}>
              No
            </button>
          </p>
        </Modal>
      ) : (
        <></>
      )}

      {Loading ? (
        <InlineLoading />
      ) : IsCondensed ? (
        <>
          <p>
            <b>
              Total runs: {TotalRuns}, at a cost of £
              {numeral(RunCost).format("0.00")}
            </b>
          </p>
          <p>
            <button className="button" onClick={() => setIsCondensed(false)}>
              Show details
            </button>
          </p>
        </>
      ) : (
        <>
          <div className="grid mb-2x">
            <div className="col-3">
              <p>
                <b>Filter by dog</b>
              </p>
              <p>
                <select
                  className="form-control"
                  value={DogId}
                  onChange={(e) => setDogId(e.target.value)}
                >
                  <option value="">All dogs</option>
                  {DogsInShow}
                </select>
              </p>
            </div>
          </div>

          {RunData && RunData.length > 0 && (
            <>
              <h3>{props.IsPaper ? "Paper Entry" : "Entries by me"}</h3>
              {props.IsClosed === 1 && (
                <p className="text-red">
                  Pre-entries cannot be altered or deleted
                </p>
              )}

              <div className="grid-fixed">
                <div className="col-full-2 col-mobile-4">
                  <b>Dog</b>
                </div>
                <div className="col-full-2 col-mobile-4">
                  <b>Handler</b>
                </div>
                <div className="col-full-2 col-mobile-4">
                  <b>Date</b>
                </div>
                <div className="col-full-2 col-mobile-4">
                  <b>Class</b>
                </div>
                <div className="col-full-2 col-mobile-4">
                  <b>Height</b>
                </div>
                <div className="col-full-2 col-mobile-4">
                  <b>Level</b>
                </div>
              </div>
              {DogRows}
              <br />
              <br />
            </>
          )}

          {OtherEntries &&
            (OtherEntries[0].length > 0 || OtherEntries[1].length > 0) && (
              <h3>Entries by other people</h3>
            )}

          {OtherEntries && OtherEntries[0].length > 0 ? (
            <>
              <p>
                <b>As a handler</b>
              </p>
              {props.IsClosed === 1 && (
                <p className="text-red">
                  Pre-entries cannot be altered or deleted
                </p>
              )}
              <div className="grid-fixed">
                <div className="col-full-2 col-mobile-4">
                  <b>Dog</b>
                </div>
                <div className="col-full-2 col-mobile-4">
                  <b>Entrant</b>
                </div>
                <div className="col-full-2 col-mobile-4">
                  <b>Date</b>
                </div>
                <div className="col-full-2 col-mobile-4">
                  <b>Class</b>
                </div>
                <div className="col-full-2 col-mobile-4">
                  <b>Height</b>
                </div>
                <div className="col-full-2 col-mobile-4">
                  <b>Level</b>
                </div>
              </div>
              {OtherHandlerRuns}
              <br />
              <br />
            </>
          ) : (
            <></>
          )}

          {((CanEdit && !props.IsClosed) || IsAdminLogin) && (
            <div className="grid">
              <div className="col-6">
                <h3>Add runs / dogs</h3>
                <p>Click the button below to add runs to your entry.</p>
                <p className="mb-2x">
                  <button className="button" onClick={AddRuns}>
                    <FontAwesomeIcon icon={faPlus} className="mr-1x" />
                    Add / Edit runs
                  </button>
                </p>
              </div>
              {!props.AnyDaysCapped && (
                <div className="col-6">
                  <h3>Delete all runs for a dog</h3>
                  <p>
                    Select a dog from the dropdown and click 'Delete' to remove
                    a dog from your entry.
                  </p>

                  <div className="form-group mb-2x">
                    <select
                      className="form-control width-25 mr-1x"
                      value={DeleteDogId}
                      onChange={(e) => setDeleteDogId(e.target.value)}
                    >
                      <option value="">Select...</option>
                      {DogsInShow}
                    </select>
                    {DeleteDogId !== "" ? (
                      <button
                        className="button button-red"
                        onClick={DeleteRunsForDog}
                      >
                        <FontAwesomeIcon icon={faTrash} className="mr-1x" />
                        Delete
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default withRouter(SummaryDog);
