import React, { useEffect, useCallback } from "react";
import { Workbook } from "exceljs";
import { DownloadFile } from "../../../shared/clientsidefiledownload.js";
import { apiGet } from "../../../shared/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";

const MoneyReport = (props) => {
  const LoadData = useCallback(async () => {
    try {
      const req = await apiGet("/admin/shows/processing/moneyforexcel");
      const res = await req.json();

      let wb = new Workbook();
      let ws = wb.addWorksheet("Show Money");

      ws.addRow([
        "Show Name",
        "Online entry income",
        "Paper entry income",
        "Total income",
        "Collected from Wallet",
        "Paid to Show Manager",
        "Paid to A4A",
        "A4A Balance in client",
      ]);
      let count = 2;
      for (const row of res) {
        ws.addRow([
          row.DisplayName,
          row.Online,
          row.Paper,
          row.Total,
          row.Collected,
          row.PaidSM,
          row.PaidUKA,
          row.Balance,
        ]);
        for (const lett of ["B", "C", "D", "E", "F", "G", "H"])
          ws.getCell(`${lett}${count}`).numFmt = "0.00";
        count += 1;
      }

      const buf = await wb.xlsx.writeBuffer();
      const blob = new Blob([buf], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      DownloadFile(blob, "ShowMoney.xlsx");
    } catch (error) {
      console.log(error.message);
      window.alert("Error loading data");
    }
  }, []);

  useEffect(() => {
    LoadData();
  }, [LoadData]);

  return (
    <div className="card">
      <div className="card-header">Show Money - Excel Output</div>
      <div className="card-body">
        <span className="text-link cursor-pointer" onClick={LoadData}>
          <FontAwesomeIcon icon={faFileExcel} size="2x" className="mr-1x" />
          Click here if your file does not automatically download
        </span>
      </div>
    </div>
  );
};

export default MoneyReport;
