import React, { useState, useEffect } from "react";
import moment from "moment";
import { apiGet } from "../../../../shared/api.js";
import { useParams } from "react-router-dom";

const MyEntryHelping = (props) => {
  const [Days, setDays] = useState([]);
  const [Choices, setChoices] = useState([]);
  const [Notes, setNotes] = useState(props.Notes || "");

  const { ShowId } = useParams();

  useEffect(() => {
    props.SetCanContinue(true);
  }, [props]);

  useEffect(() => {
    const LoadData = async () => {
      try {
        //Show details
        let req = await apiGet(`/shows/${ShowId}`);
        let res = await req.json();

        const dates = [];
        const choices = [];
        let CurrentDate = moment(res.StartDate);
        const LastDate = moment(res.EndDate);
        while (CurrentDate.isSameOrBefore(LastDate)) {
          const CurrVal = moment(CurrentDate);
          dates.push(CurrVal);
          choices.push({
            Date: CurrVal,
            Availability: { AM: false, PM: false },
          });
          CurrentDate = CurrentDate.add(1, "days");
        }
        if (props.Helping) {
          for (const choice of props.Helping) {
            const found = choices.find((c) => moment(c.Date).isSame(moment(choice.Date)));
            found.Availability.AM = choice.Availability.AM;
            found.Availability.PM = choice.Availability.PM;
          }
        }
        setDays(dates);
        setChoices(choices);
      } catch (error) {
        console.log(error.message);
        window.alert("Error loading show data");
      }
    };

    window.scrollTo(0, 0);

    if (ShowId) LoadData();
  }, [ShowId, props.Helping]);

  const HelpingDayChoices = Days.map((itm, idx) => {
    let AMAvailable, PMAvailable;
    const found = Choices.find((c) => moment(c.Date).isSame(itm));
    AMAvailable = found?.Availability.AM;
    PMAvailable = found?.Availability.PM;

    return (
      <tr key={idx}>
        <td>
          <span className="mr-5x">{moment.utc(itm).format("ddd Do MMM")}</span>
        </td>
        <td>
          AM:{" "}
          <input
            type="checkbox"
            checked={AMAvailable}
            onChange={(e) => SelectHelp(itm, "AM", e.target.checked)}
          />{" "}
          PM:{" "}
          <input
            type="checkbox"
            checked={PMAvailable}
            onChange={(e) => SelectHelp(itm, "PM", e.target.checked)}
          />
        </td>
      </tr>
    );
  });

  const SelectHelp = (dt, when, selected) => {
    const choices = Array.from(Choices);
    const found = choices.find((c) => moment(c.Date).isSame(moment(dt)));
    found.Availability[when] = selected;
    setChoices(choices);

    props.SetHelping(choices);
  };

  const SetNotes = (val) => {
    setNotes(val);
    props.SetNotes(val);
  };

  return (
    <div className="mb-3x">
      <h3>Helping choices</h3>
      <div className="mt-3x">
        <p>I am able to help on the following days:</p>
        <table className="table width-50">
          <thead>
            <tr>
              <th>Date</th>
              <th>Availability</th>
            </tr>
          </thead>
          <tbody>{HelpingDayChoices}</tbody>
        </table>
        <div className="form-group">
          <label>Notes:</label>
          <textarea
            className="form-control width-50"
            value={Notes}
            onChange={(e) => SetNotes(e.target.value)}
          ></textarea>
        </div>
      </div>
    </div>
  );
};

export default MyEntryHelping;
