import React, { useEffect, useContext, useState } from "react";
import UserContext from "../../../context/user/UserContext";
import InlineLoading from "../../../shared/InlineLoading";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { apiGet } from "../../../shared/api";
import moment from "moment";

function UserCard(props) {
  const { User } = useContext(UserContext);

  const [Loading, setLoading] = useState(true);
  const [Error, setError] = useState(false);

  const [StreetAddress, setStreetAddress] = useState("");
  const [Town, setTown] = useState("");
  const [Postcode, setPostcode] = useState("");
  const [Email, setEmail] = useState("");
  const [MobilePhone, setMobilePhone] = useState("");
  const [OtherPhone, setOtherPhone] = useState("");
  const [DateExpires, setDateExpires] = useState("");
  const [ExpiresClass, setExpiresClass] = useState("");

  useEffect(() => {
    const LoadUserDetails = async () => {
      try {
        setError(false);

        const req = await apiGet(`/members/account/${User.Id}`);
        if (req && req.ok && req.status === 200) {
          const res = await req.json();
          setStreetAddress(res.StreetAddress);
          setTown(res.Town);
          setPostcode(res.Postcode);
          setEmail(res.Email);
          setMobilePhone(res.MobilePhone);
          setOtherPhone(res.OtherPhone);
          setDateExpires(moment(res.DateExpires).format("MMM DD, yyyy"));
          setLoading(false);

          const fourteen_days = moment().add(14, "days");
          const dtExpires = moment(res.DateExpires);
          if (
            dtExpires < moment() ||
            (dtExpires > moment() && dtExpires < fourteen_days)
          )
            setExpiresClass("text-red text-bold");
        } else {
          setError(true);
          setLoading(false);
        }
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    };

    LoadUserDetails();
  }, [User.Id]);

  if (Loading) return <InlineLoading />;

  return (
    <div className="card borderless">
      <div className="card-header alternate">
        <h5>User Details</h5>
      </div>
      <div className="card-body">
        {Error ? (
          <p>Error loading details</p>
        ) : (
          <>
            <p>
              <b>
                {User.FirstName} {User.LastName}
              </b>
            </p>
            <p>
              {StreetAddress}, {Town}, {Postcode}
            </p>
            <p className="mb-1x">{Email}</p>
            {MobilePhone !== "" ? (
              <p>
                <b>Mobile:</b> {MobilePhone}
              </p>
            ) : (
              <></>
            )}
            {OtherPhone !== "" ? (
              <p>
                <b>Phone:</b> {OtherPhone}
              </p>
            ) : (
              <></>
            )}
            <p className="mt-1x mb-1x">
              <b>A4A Number:</b> {User.Id}
            </p>
            <p>
              <b>Membership Expires:</b>
              <br />
              <span className={ExpiresClass}>{DateExpires}</span>
            </p>
          </>
        )}
      </div>
      <div className="card-footer">
        {Error ? (
          <></>
        ) : !User.Expired ? (
          <Link className="button button-link" to="/account">
            <FontAwesomeIcon icon={faUserEdit} className="mr-1x" />
            Edit Details
          </Link>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default UserCard;
