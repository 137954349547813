import React, { useState, useEffect, useCallback } from "react";
import { useParams, withRouter } from "react-router-dom";
import { apiDelete, apiGet, apiPost } from "../../../../shared/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import numeral from "numeral";
import ModalContent from "../../../../shared/ModalContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faTrash } from "@fortawesome/free-solid-svg-icons";

const MoneyOut = (props) => {
  const { Id } = useParams();

  const [Amount, setAmount] = useState("");
  const [PaymentType, setPaymentType] = useState("1");
  const [DateStamp, setDateStamp] = useState(new Date());
  const [LineItems, setLineItems] = useState([]);
  const [ShowManagerTotal, setShowManagerTotal] = useState();
  const [UKATotal, setUKATotal] = useState();
  const [ShowName, setShowName] = useState("");
  const [TotalMoney, setTotalMoney] = useState(0);
  const [ShowAreYouSure, setShowAreYouSure] = useState(false);
  const [NextActionCallback, setNextActionCallback] = useState();

  useEffect(() => {
    const LoadShowData = async () => {
      try {
        let req, res;
        req = await apiGet(`/shows/${Id}`);
        res = await req.json();
        setShowName(res.DisplayName);

        req = await apiGet(`/admin/shows/processing/money/revenue/${Id}`);
        res = await req.json();
        setTotalMoney(res.Total);
      } catch (error) {
        console.log(error.message);
        window.alert("Error loading show details");
      }
    };

    if (Id) LoadShowData();
  }, [Id]);

  const LoadData = useCallback(async () => {
    try {
      let req, res;
      req = await apiGet(`/admin/shows/processing/money/list/${Id}`);
      res = await req.json();
      setLineItems(res);

      req = await apiGet(`/admin/shows/processing/money/summary/${Id}`);
      res = await req.json();
      setShowManagerTotal(res.find((r) => r.PaymentType === 1)?.Amount);
      setUKATotal(res.find((r) => r.PaymentType === 2)?.Amount);
    } catch (error) {
      console.log(error.message);
      window.alert("Error loading money data");
    }
  }, [Id]);

  useEffect(() => {
    LoadData();
  }, [LoadData]);

  const doCancel = () => props.history.push(`/admin/shows/processing/${Id}`);

  const doSave = async () => {
    try {
      const obj = {
        ShowId: Id,
        PaymentType: parseInt(PaymentType),
        Amount,
        DateStamp: moment(DateStamp, "DD/MM/YYYY").format("YYYYMMDD"),
      };
      await apiPost("/admin/shows/processing/money/insert", obj);
      await LoadData();
      setAmount("");
      setPaymentType("1");
      setDateStamp(new Date());
      setShowAreYouSure(false);
    } catch (error) {
      setShowAreYouSure(false);
      window.alert("Error saving data");
    }
  };

  const ConfirmPayment = () => AreYouSure(() => doSave());

  const PromptDelete = (Id) => {
    const NextAction = async (Id) => {
      try {
        const req = await apiDelete(
          `/admin/shows/processing/money/delete/${Id}`
        );
        if (req && req.ok) {
          LoadData();
          setShowAreYouSure(false);
        } else {
          window.alert("Error deleting line");
          setShowAreYouSure(false);
        }
      } catch (error) {
        console.log(error.message);
        window.alert("Error deleting row");
        setShowAreYouSure(false);
      }
    };

    AreYouSure(() => NextAction(Id));
  };

  const AreYouSure = (func) => {
    setNextActionCallback(() => func);
    setShowAreYouSure(true);
  };

  const SummaryRows = LineItems.map((itm, idx) => {
    return (
      <tr>
        <td>{moment(itm.DateStamp).format("ddd Do MMM")}</td>
        <td>
          {
            {
              1: "Show Manager",
              2: "Agility4All",
            }[itm.PaymentType]
          }
        </td>
        <td>£{itm.Amount}</td>
        <td>
          <span
            className="text-red cursor-pointer"
            onClick={() => PromptDelete(itm.Id)}
          >
            <FontAwesomeIcon icon={faTrash} className="mr-1x" />
            Delete
          </span>
        </td>
      </tr>
    );
  });

  return (
    <div className="card">
      <div className="card-header">Money Out for {ShowName}</div>
      <div className="card-body">
        {ShowAreYouSure ? (
          <ModalContent>
            <p>
              <FontAwesomeIcon
                icon={faQuestionCircle}
                size="2x"
                className="mr-1x"
              />
              Are you sure?
            </p>
            <p className="mt-2x">
              <button
                className="button button-red mr-1x"
                onClick={NextActionCallback}
              >
                Yes
              </button>
              <button
                className="button"
                onClick={() => setShowAreYouSure(false)}
              >
                No
              </button>
            </p>
          </ModalContent>
        ) : (
          <></>
        )}

        <p className="mt-2x mb-2x">
          <b className="mr-1x">Total revenue for show:</b>£
          {numeral(TotalMoney).format("0.00")}
        </p>

        <p>
          <b>Totals</b>
        </p>
        <p className="mb-2x">
          Show Manager: £{ShowManagerTotal ?? 0}, A4A: £{UKATotal ?? 0}
        </p>

        <table className="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Recipient</th>
              <th>Amount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{SummaryRows}</tbody>
        </table>

        <br />
        <br />
        <br />

        <h4>Take money</h4>
        <p className="mb-2x">Enter details of money taken below</p>
        <div className="form-group">
          <label>Type</label>
          <select
            value={PaymentType}
            onChange={(e) => setPaymentType(e.target.value)}
            className="form-control width-25"
          >
            <option value="1">Show Manager</option>
            <option value="2">Agility4All</option>
          </select>
        </div>

        <div className="form-group">
          <label>Amount</label>
          <input
            type="number"
            value={Amount}
            onChange={(e) => setAmount(e.target.value)}
            className="form-control width-25"
          />
        </div>

        <div className="form-group">
          <label>Date</label>
          <DatePicker
            showMonthDropdown
            showYearDropdown
            className="form-control width-50"
            selected={DateStamp}
            dateFormat="dd/MM/yyyy"
            value={DateStamp}
            onChange={(val) => setDateStamp(val)}
          ></DatePicker>
        </div>
      </div>
      <div className="card-footer">
        <button className="button button-green mr-1x" onClick={ConfirmPayment}>
          Save Payment
        </button>
        <button className="button button-outline" onClick={doCancel}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default withRouter(MoneyOut);
