import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { apiGet } from "../../../shared/api";
import { Link } from "react-router-dom";
import moment from "moment";
import RingPlan from "../../admin/shows/processing/ringplan.js";
import RingBoard from "../../admin/shows/processing/ringboard.js";
import CompetitorSheet from "../../admin/shows/processing/competitorsheet.js";
import { DownloadFile } from "../../../shared/clientsidefiledownload";
import { pdf } from "@react-pdf/renderer";
import ModalLoading from "../../../shared/ModalLoading";
import { LeaderboardAsSinglePdf } from "./leaderboardassinglepdf";
import { SortClasses, SortHeights } from "../../../shared/functions";
import JSZip from "jszip";
import { Document } from "@react-pdf/renderer";
import dayjs from "dayjs";

const Processing = (props) => {
  const { Id } = useParams();
  const [ShowName, setShowName] = useState();
  const [HasClosed, setHasClosed] = useState(false);
  const [Processing, setProcessing] = useState(false);
  const [Show, setShow] = useState();
  const [ZipProcess, setZipProcess] = useState("");

  useEffect(() => {
    const LoadShow = async () => {
      try {
        const req = await apiGet(`/shows/${Id}`);
        const res = await req.json();
        setShowName(res.DisplayName);
        if (moment(res.EntriesClose).isBefore(moment())) setHasClosed(true);
        setShow(res);
      } catch (error) {
        console.log(error.message);
        window.alert("Error loading show data");
      }
    };

    if (Id) LoadShow();
  }, [Id]);

  const doResultsPDFs = async () => {
    try {
      sessionStorage.setItem("ShowId", Show.ShowId);
      setZipProcess("Loading data...");
      const req = await apiGet(
        `/admin/shows/classes/listcombinationswithleaderboards/${Show.ShowId}`
      );
      const res = await req.json();

      //ZIP file
      const zip = new JSZip();

      let ShowDate = moment(Show.StartDate);
      while (ShowDate.isSameOrBefore(moment(Show.EndDate))) {
        let source = res.filter((c) => {
          return (
            moment(c.ClassDate).isSame(ShowDate, "day") &&
            (c.IsEnterable || c.ClassId === 31) &&
            c.ClassId !== 23
          );
        });
        const heats = res.filter(
          (c) =>
            moment(c.ClassDate).isSame(ShowDate, "day") &&
            c.ClassId !== 31 &&
            !c.IsEnterable
        );

        const heatClassIds = new Set();
        const heatLevelIds = new Set();
        for (const heat of heats) {
          heatClassIds.add(heat.ClassId);
          heatLevelIds.add(heat.LevelId);
        }

        for (const c of [...heatClassIds]) {
          let foundClass = res.filter(
            (f) => f.ClassId === c && f.LevelId === [...heatLevelIds][0]
          );
          if (foundClass) {
            foundClass.forEach((e) => {
              e.ClassLabel = e.ClassLabel.replace("Beginners ", "");
              e.ClassLabel = e.ClassLabel.replace("Novice ", "");
              e.ClassLabel = e.ClassLabel.replace("Senior ", "");
              e.ClassLabel = e.ClassLabel.replace("Champion ", "");
            });
            source = source.concat(foundClass);
          }
        }

        const classes = SortClasses(source);
        for (const c of classes) {
          const pages = [];
          let strFilename;
          setZipProcess(`${moment(ShowDate).format("DD MMM")}, ${c.ClassLabel}`);

          for (const h of [9, 5, 14, 15, 1, 6, 2, 7, 3, 8, 4]) {
            const found = res.find(
              (r) =>
                moment(r.ClassDate).isSame(ShowDate, "day") &&
                r.ClassId === c.ClassId &&
                r.LevelId === c.LevelId &&
                r.HeightId === h
            );

            if (found && found.LeaderboardData) {
              const data = JSON.parse(found.LeaderboardData);
              const page = await LeaderboardAsSinglePdf(data);
              pages.push(page);

              const dateOfRun = dayjs(data.ClassDetails.ClassDate);
              const className =
                data.ClassId === 29 || data.ClassId === 30 || data.ClassId === 31
                  ? data.ClassDetails.ClassLabel
                  : data.Data.ClassLabel;
              strFilename = `${dateOfRun.format(
                "dddd"
              )} ${className.trim()} ${dateOfRun.format("YYYYMMDD")}.pdf`;
            }
          }
          if (pages.length > 0) {
            const output = <Document>{pages.map((itm) => itm)}</Document>;
            const blob = await pdf(output).toBlob();
            zip.file(strFilename, blob);
          }
        }

        ShowDate = ShowDate.add(1, "day");
      }

      const zipFile = await zip.generateAsync({ type: "blob" });
      DownloadFile(zipFile, "ShowData.zip");
    } catch (error) {
      console.log(error);
    } finally {
      setZipProcess("");
    }
  };

  const doCompetitorSheet = async () => {
    setProcessing(true);
    const doc = await CompetitorSheet(Id);
    const blob = await pdf(doc).toBlob();
    DownloadFile(blob, "CompetitorSheet.pdf");
    setProcessing(false);
  };

  const doRingboard = async () => {
    setProcessing(true);
    const doc = await RingBoard(Id);
    const blob = await pdf(doc).toBlob();
    DownloadFile(blob, "RingBoard.pdf");
    setProcessing(false);
  };

  const doRingPlan = async () => {
    setProcessing(true);
    const doc = await RingPlan(Id);
    const blob = await pdf(doc).toBlob();
    DownloadFile(blob, "RingPlan.pdf");
    setProcessing(false);
  };

  return (
    <div className="card">
      <div className="card-header">Show Processing</div>
      <div className="card-body">
        {Processing && <ModalLoading />}

        <h3>Processing for {ShowName}</h3>
        <ul>
          {!HasClosed && (
            <li>
              <Link to={`/showmanagers/ringeditor/${Id}`}>Ring Editor</Link>
            </li>
          )}
          <li>
            <span className="text-link cursor-pointer" onClick={doRingboard}>
              Ring Board
            </span>
          </li>
          <li>
            <span className="text-link cursor-pointer" onClick={doRingPlan}>
              Ring Plan
            </span>
          </li>
          <li>
            <span className="text-link cursor-pointer" onClick={doCompetitorSheet}>
              Competitor List
            </span>
          </li>
          <li className="cursor-pointer">
            <Link to={`/showmanagers/excelprocess/${Id}`}>
              <span className="cursor-pointer">Excel output</span>
            </Link>
          </li>
          <li className="cursor-pointer">
            <Link to={`/showmanagers/excellatesprocess/${Id}`}>
              <span className="cursor-pointer">Excel output - late entries</span>
            </Link>
          </li>
          <li>
            <span className="cursor-pointer text-link" onClick={doResultsPDFs}>
              Results PDFs
            </span>
            <p>{ZipProcess}</p>
          </li>
          <li className="cursor-pointer">
            <Link to={`/showmanagers/documents/${Id}`}>
              <span className="cursor-pointer">Other show documents</span>
            </Link>
          </li>
          <li className="cursor-pointer">
            <Link to={`/showmanagers/authorisations/${Id}`}>
              <span className="cursor-pointer">At-show authorisations</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Processing;
