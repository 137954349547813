import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import { apiGet, apiPost } from "../../../shared/api";
import moment from "moment";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DogMeasure = ({ onCancel, onSave }) => {
  const { Id } = useParams();
  const [Dog, setDog] = useState();
  const [Height, setHeight] = useState();
  const [Measurer, setMeasurer] = useState();
  const [SelectedMeasurer, setSelectedMeasurer] = useState();
  const [IsProvisional, setIsProvisional] = useState(false);
  const [Show, setShow] = useState();
  const [SelectedShow, setSelectedShow] = useState();
  const [SpecialMeasure, setSpecialMeasure] = useState();
  const [NotAtShow, setNotAtShow] = useState(false);
  const [DateMeasured, setDateMeasured] = useState(new Date());
  const ShowList = useRef([]);
  const MemberList = useRef([]);

  useEffect(() => {
    (async () => {
      try {
        if (!Id) return;
        const req = await apiGet(`/dogs/${Id}`);
        const res = await req.json();
        setDog(res);
      } catch (error) {
        console.log(error.message);
      }
    })();
  }, [Id]);

  useEffect(() => {
    (async () => {
      try {
        let req = await apiGet("/shows/list?PastOnly=1");
        let res = await req.json();
        ShowList.current = res;
      } catch (error) {
        console.log(error.message);
      }
    })();
  }, []);

  const SelectShow = (val) => {
    if (!val) setShow(null);

    const found = ShowList.current.find((s) => s.ShowId === parseInt(val.value));
    if (found) setShow(found);
    setSelectedShow(val);
  };

  const SelectMeasurer = (val) => {
    const found = MemberList.current.find((m) => m.Id === parseInt(val.value));
    if (found) setMeasurer(found);
    setSelectedMeasurer(val);
  };

  const GetShowSuggestions = async (val) => {
    if (val.length > 1) {
      try {
        const req = await apiGet(`/shows/match/${val}`);
        if (req && req.ok && req.status === 200) {
          const res = await req.json();
          return res
            .filter((s) => moment(s.StartDate).isSameOrBefore(moment()))
            .map((itm) => {
              let displayString = `${itm.DisplayName}`;

              if (itm.StartDate !== undefined) {
                if (itm.StartDate === itm.EndDate)
                  displayString += `: ${moment(itm.EndDate).format("Do MMM yyyy")}`;
                if (itm.StartDate !== itm.EndDate)
                  displayString += `: ${moment(itm.StartDate).format(
                    "Do MMM"
                  )} - ${moment(itm.EndDate).format("Do MMM yyyy")}`;
              }

              return { value: itm.ShowId, label: displayString };
            });
        } else {
          return [];
        }
      } catch (error) {
        console.log(error.message);
        return [];
      }
    }
  };

  const GetHandlerSuggestions = async (val) => {
    try {
      const req = await apiGet(`/members/match/${val}`);
      if (req && req.ok && req.status === 200) {
        let res = await req.json();
        MemberList.current = res;
        return res.map((itm) => {
          const displayString = `${itm.Id} - ${itm.FirstName} ${itm.LastName}`;
          return { value: itm.Id, label: displayString };
        });
      } else {
        return [];
      }
    } catch (error) {
      console.log(error.message);
      return [];
    }
  };

  const HandleSave = async () => {
    let AssignedHeight = 600;

    if (parseInt(Height) < 500.01) AssignedHeight = 500;
    if (parseInt(Height) < 440.01) AssignedHeight = 400;
    if (parseInt(Height) < 380.01) AssignedHeight = 300;
    if (parseInt(Height) < 320.01) AssignedHeight = 250;

    const obj = {
      Dog,
      Height,
      IsProvisional,
      Measurer,
      Show: NotAtShow ? null : Show,
      DateStamp: moment(DateMeasured).toDate(),
      SpecialMeasure,
      AssignedHeight,
      SecondBirthday: moment(Dog.DateOfBirth).format("DD/MM/YYYY"),
    };

    const req = await apiPost(`/atshow/dogs/createmeasure`, obj);
    if (req && req.ok) {
      onSave();
    } else {
      window.alert("Error saving data");
    }
  };

  return (
    <>
      <div className="form-group">
        <label>Height</label>
        <input
          type="number"
          className="form-control"
          value={Height}
          onChange={(e) => setHeight(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Provisional</label>
        <input
          type="checkbox"
          checked={IsProvisional}
          onChange={(e) => setIsProvisional(e.target.checked)}
        />
      </div>
      <div className="form-group">
        <label>Special?</label>
        <select
          className="form-control"
          value={SpecialMeasure}
          onChange={(e) => setSpecialMeasure(e.target.value)}
        >
          <option value="">(No)</option>
          <option value="Challenge">Challenge</option>
          <option value="Dispute">Dispute</option>
        </select>
      </div>
      <div className="form-group">
        <label>Show</label>
        <AsyncSelect
          placeholder="Enter at least the first two characters of a show name"
          value={SelectedShow}
          onChange={(e) => SelectShow(e)}
          className="width-75"
          loadOptions={GetShowSuggestions}
        />
        <input
          type="checkbox"
          checked={NotAtShow}
          onChange={(e) => setNotAtShow(e.target.value)}
        />{" "}
        Not at a show
      </div>
      <div className="form-group">
        <label>Measure Date</label>
        <DatePicker
          maxDate={new Date()}
          showMonthDropdown
          showYearDropdown
          className="form-control width-50"
          selected={DateMeasured}
          dateFormat="dd/MM/yyyy"
          value={DateMeasured}
          onChange={(val) => setDateMeasured(val)}
        ></DatePicker>
      </div>

      <div className="form-group">
        <label>Measurer</label>
        <AsyncSelect
          value={SelectedMeasurer}
          onChange={(e) => SelectMeasurer(e)}
          loadOptions={GetHandlerSuggestions}
        />
      </div>
      <p>
        <button className="button button-green mr-1x" onClick={HandleSave}>
          Save
        </button>
        <button className="button" onClick={onCancel}>
          Cancel
        </button>
      </p>
    </>
  );
};

export default DogMeasure;
