import React from "react";
import { apiGet } from "../../../../shared/api";
import moment from "moment";
import { Page, Text, View, Document } from "@react-pdf/renderer";
import CommonStyles from "./commonStyles";

let ShowStartDate, ShowEndDate;

const CompetitorSheet = async (Id) => {
  let HandlerList = [],
    ShowName = "",
    ShowDates = "";

  try {
    let req, res;

    req = await apiGet(`/shows/${Id}`);
    res = await req.json();
    ShowName = res.DisplayName;
    ShowDates = `${moment(res.StartDate).format("ddd Do MMM")} - ${moment(res.EndDate).format("ddd Do MMM")}`;
    ShowEndDate = moment(res.EndDate).add(1, "day");

    req = await apiGet(`/shows/getallhandlers/${Id}`);
    res = await req.json();
    HandlerList = res;
  } catch (error) {
    console.log(error.message);
    window.alert("Error loading list of handlers");
  }

  const Handlers = [];
  for (const itm of HandlerList) {
    const page = await HandlerSheetLocal(Id, itm.Id, HandlerList);
    Handlers.push(page);
  }

  return (
    <Document>
      <Page size="A4" style={{ padding: 8, paddingBottom: 20 }}>
        <View
          style={{
            marginBottom: 5,
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <View style={CommonStyles.documentHeadingCell}>
            <Text style={CommonStyles.documentHeadingText}>Competitor List</Text>
          </View>
          <View style={CommonStyles.documentHeadingCell}>
            <Text style={CommonStyles.documentHeadingText}>{ShowName}</Text>
          </View>
          <View style={CommonStyles.documentHeadingCell}>
            <Text style={CommonStyles.documentHeadingText}>{ShowDates}</Text>
          </View>
        </View>
        {Handlers}
        <Text
          style={{ fontSize: 10, paddingTop: 5 }}
          render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
};

/******************************** */

const HandlerSheetLocal = async (Id, _MemberId, AllHandlers) => {
  let Postage = "";
  let CampingString = "",
    HelpingString = "";
  let Handler = AllHandlers.find((h) => h.Id === _MemberId);

  try {
    let req, res;

    req = await apiGet(`/shows/${Id}`);
    res = await req.json();

    //Show entry details
    req = await apiGet(`/shows/entry/${Id}/${_MemberId}`);
    res = await req.json();
    Postage = res.PostRunningOrders;

    //Camping starts here
    //Camping nights chosen
    req = await apiGet(`/shows/singlenight/list/${Id}`);
    res = await req.json();

    //Show entry details
    req = await apiGet(`/shows/campingchoices/${Id}/${_MemberId}`);
    res = await req.json();
    const row = res[0][0];
    const PackId = row.CampingPackId;
    let _CampingString = "No camping selected";
    if (PackId && PackId !== 0)
      _CampingString = `You've chosen the '${row.Description}' pack. Arriving ${moment(row.ArrivalDate).format(
        "ddd Do MMM"
      )}, departing ${moment(row.DepartureDate).format("ddd Do MMM")}`;

    const singleNights = [];
    for (const dt of res[1]) singleNights.push(dt.ShowDate);

    if (singleNights.length > 0) {
      const arrive = moment(singleNights[0]);
      const depart = moment(singleNights.pop()).add(1, "day");
      _CampingString = `Arriving ${arrive.format("ddd Do MMM")}, departing ${depart.format("ddd Do MMM")}`;
    }

    CampingString = _CampingString;

    //Camping ends here

    //Helping starts here
    req = await apiGet(`/shows/${Id}`);
    res = await req.json();
    const choices = [];
    let CurrentDate = moment(res.StartDate);
    const LastDate = moment(res.EndDate);
    while (CurrentDate.isSameOrBefore(LastDate)) {
      const CurrVal = moment(CurrentDate);
      choices.push({ Date: CurrVal, Availability: { AM: false, PM: false } });
      CurrentDate = CurrentDate.add(1, "days");
    }

    req = await apiGet(`/shows/helpingchoices/${Id}/${_MemberId}`);
    res = await req.json();

    let _HelpString = "You have chosen not to help at this show but please do volunteer if you have any time available";

    for (const choice of res) {
      const found = choices.find((c) => moment(c.Date).isSame(moment(choice.ShowDate)));
      found.Availability.AM = choice.AvailableAM;
      found.Availability.PM = choice.AvailablePM;
    }

    const outputs = [];
    for (const row of choices) {
      if (row.Availability.AM) outputs.push(`${moment(row.Date).format("ddd Do MMM")} AM`);
      if (row.Availability.PM) outputs.push(`${moment(row.Date).format("ddd Do MMM")} PM`);
    }
    if (outputs.length > 0) _HelpString = outputs.join(", ");

    HelpingString = _HelpString;

    //Helping ends here
  } catch (error) {
    console.log(error.message);
    window.alert("Error loading data");
  }

  const Dogs = async () => {
    let req = await apiGet(`/shows/getdogsforhandler/${Id}/${_MemberId}`);
    let res = await req.json();
    const dogSections = [];
    for (const dog of res) {
      const section = await DogSheetLocal(Id, _MemberId, dog.Id);
      dogSections.push(section);
    }
    return dogSections;
  };

  return (
    <View key={`Id_${Id}`}>
      <View style={{ marginBottom: 5, marginTop: 5 }}>
        <Text style={CommonStyles.memberName}>
          {_MemberId} - {Handler.FirstName} {Handler.LastName}
        </Text>
      </View>
      <View style={[CommonStyles.row, { marginTop: 5 }]}>
        <Text style={[CommonStyles.bold, CommonStyles.cell]}>Camping: </Text>
        <Text style={CommonStyles.cell}>{CampingString}</Text>
      </View>
      <View style={CommonStyles.row}>
        <Text style={[CommonStyles.bold, CommonStyles.cell]}>Helping: </Text>
        <Text style={CommonStyles.cell}>{HelpingString}</Text>
      </View>
      <View style={{ marginBottom: 14 }}>
        <View style={CommonStyles.row}>
          <Text style={[CommonStyles.bold, CommonStyles.cell]}>Postage: </Text>
          <Text style={CommonStyles.cell}>
            You chose{Postage ? "" : " not"} to receive the show information by post
          </Text>
        </View>
      </View>

      {await Dogs()}
    </View>
  );
};

const DogSheetLocal = async (Id, _MemberId, DogId) => {
  let DogName = "",
    MeasureNeeded = "",
    HasTeam = 0,
    HasPair = 0;
  let TeamString,
    PairString,
    ShowHandlers = false,
    OtherEntries = [],
    OtherPairString,
    OtherTeamString;
  let ClassRuns = [];

  const GetRingOrder = (val) => {
    switch (val) {
      case 1:
        return "1st";
      case 2:
        return "2nd";
      case 3:
        return "3rd";
      default:
        return `${val}th`;
    }
  };

  try {
    let req, res;

    req = await apiGet(`/dogs/${DogId}`);
    res = await req.json();
    DogName = res.PetName;
    const _IsOverTwo = moment(res.DateOfBirth).add(2, "years").isSameOrBefore(ShowEndDate, "day");
    const _IsUnderTwo = moment(res.DateOfBirth).add(2, "years").isAfter(ShowStartDate, "day");
    const _IsMaxi = res.HeightId === 4;

    switch (res.Measure) {
      case null:
        if (!_IsMaxi && !_IsOverTwo) MeasureNeeded = "This dog requires a first measure before it can run";
        if (!_IsMaxi && _IsOverTwo) MeasureNeeded = "This dog requires a permanent measure before it can run";
        break;

      case false:
        break;

      default:
        if (!_IsMaxi && _IsOverTwo && !_IsUnderTwo)
          MeasureNeeded = "This dog requires a permanent measure before it can run";
        if (!_IsMaxi && _IsOverTwo && _IsUnderTwo) MeasureNeeded = "";
        break;
    }

    //Standard class entries
    req = await apiGet(`/shows/classes/entries/${Id}/${_MemberId}`);
    res = await req.json();
    res = res.filter((r) => r.DogId === parseInt(DogId));
    const _TeamClass = res.find((r) => r.ClassType === 6);
    const _PairClass = res.find((r) => r.ClassType === 9);
    HasTeam = _TeamClass;
    HasPair = _PairClass;
    ClassRuns = res;
    ShowHandlers = !ClassRuns.every((r) => r.HandlerId === r.MemberId);
    const notNurseryOrCasual = res.filter(
      (r) => r.LevelId !== 1 && r.LevelId !== 7 && (r.ClassId < 32 || r.ClassId > 35)
    );

    //No measure needed if all entries are nursery, casual, or 500(s) / 600
    if (notNurseryOrCasual.length === 0) MeasureNeeded = "";
    if (ClassRuns.every((r) => r.HeightId === 4 || r.HeightId === 8)) MeasureNeeded = "";

    //Team / Pair members
    req = await apiGet(`/shows/teampairentries/${Id}/${_MemberId}`);
    res = await req.json();
    const _TPData = res[0].filter((r) => r.LeadDogId === parseInt(DogId));

    if (_TeamClass) {
      const team = _TPData.filter((r) => r.ClassId === _TeamClass.ClassId);
      TeamString = (
        <View style={{ marginBottom: 5 }}>
          <View>
            <Text style={CommonStyles.bold}>{`${moment(_TeamClass.ClassDate).format("ddd Do MMM")} - ${
              _TeamClass.FinalClassLabel
            } - Ring ${_TeamClass.RingNumber} (${GetRingOrder(_TeamClass.LevelSortOrder)}) - Team Name: ${
              team[0].TeamName
            }`}</Text>
          </View>
          <View>
            <Text style={CommonStyles.cell}>
              {team[0].FirstName} {team[0].LastName} and {team[0].PetName} ({team[0].HeightLabel})
            </Text>
            <Text style={CommonStyles.cell}>
              {team[1].FirstName} {team[1].LastName} and {team[1].PetName} ({team[1].HeightLabel})
            </Text>
          </View>
        </View>
      );
    }

    if (_PairClass) {
      const pair = _TPData.filter((r) => r.ClassId === _PairClass.ClassId);
      PairString = (
        <View style={{ marginBottom: 5 }}>
          <Text style={CommonStyles.bold}>{`${moment(_PairClass.ClassDate).format("ddd Do MMM")} - ${
            _PairClass.FinalClassLabel
          } - Ring ${_PairClass.RingNumber} (${GetRingOrder(_PairClass.LevelSortOrder)})`}</Text>
          <Text style={CommonStyles.cell}>
            {pair[0].FirstName} {pair[0].LastName} and {pair[0].PetName} ({pair[0].HeightLabel}) running{" "}
            {pair[0]?.Sector}
          </Text>
        </View>
      );
    }

    //Entries made by other members involving this member or their dogs
    req = await apiGet(`/shows/getteamdata/${Id}/${DogId}`);
    res = await req.json();
    OtherEntries = res;
    //Pairs
    let pairRuns = OtherEntries.filter((r) => r.ClassType === 9);
    if (pairRuns.length) {
      OtherPairString = (
        <View style={{ marginBottom: 5 }}>
          <View>
            <Text style={CommonStyles.bold}>{`${moment(pairRuns[0].ClassDate).format("ddd Do MMM")} - ${
              pairRuns[0].FinalClassLabel
            } - Ring ${pairRuns[0].RingNumber} (${GetRingOrder(pairRuns[0].LevelSortOrder)})`}</Text>
          </View>
          <View>
            <Text style={CommonStyles.cell}>
              {pairRuns[0].MemberFirstName} {pairRuns[0].MemberLastName} and {pairRuns[0].MemberDogName} running{" "}
              {pairRuns[0]?.Sector === "Agility" ? "Jumping" : "Agility"} ({pairRuns[0].MemberHeight})
            </Text>
          </View>
        </View>
      );
    }

    //Pairs
    let teamRuns = OtherEntries.filter((r) => r.ClassType === 6);
    if (teamRuns.length) {
      OtherTeamString = (
        <View style={{ marginBottom: 5 }}>
          <View>
            <Text style={CommonStyles.bold}>
              {`${moment(teamRuns[0].ClassDate).format("ddd Do MMM")} - ${teamRuns[0].FinalClassLabel} - Ring ${
                teamRuns[0].RingNumber
              } (${GetRingOrder(teamRuns[0].LevelSortOrder)}) `}
              <Text>Team Name: </Text>
              <Text>{teamRuns[0].TeamName}</Text>
            </Text>
          </View>
          <View>
            <Text style={CommonStyles.cell}>
              {teamRuns[0].MemberFirstName} {teamRuns[0].MemberLastName} and {teamRuns[0].MemberDogName} (
              {teamRuns[0].MemberHeight})
            </Text>
          </View>
          <View>
            <Text style={CommonStyles.cell}>
              {teamRuns[0].HandlerFirstName} {teamRuns[0].HandlerLastName} and {teamRuns[0].PetName} (
              {teamRuns[0].HeightLabel})
            </Text>
          </View>
        </View>
      );
    }
  } catch (error) {
    console.log(error.message);
    window.alert("Error loading data");
  }

  const ClassRunLines = ClassRuns.filter((r) => r.ClassType !== 6 && r.ClassType !== 9).map((itm, idx) => {
    return (
      <View key={idx} style={CommonStyles.row}>
        <Text style={[CommonStyles.cell, { flex: 2 }]}>{moment(itm.ClassDate).format("ddd Do MMM")}</Text>
        <Text style={[CommonStyles.cell, { flex: 1 }]}>
          {itm.RingNumber === 0 ? "Unallocated" : `${itm.RingNumber} (${GetRingOrder(itm.LevelSortOrder)})`}
        </Text>
        <Text style={[CommonStyles.cell, { flex: 5 }]}>{itm.FinalClassLabel}</Text>
        <Text style={[CommonStyles.cell, { flex: 2 }]}>{itm.HeightLabel}</Text>
        <Text style={[CommonStyles.cell, { flex: 2 }]}>{itm.RunningOrder === 0 ? "Entered" : itm.RunningOrder}</Text>
        {ShowHandlers ? (
          <Text style={[CommonStyles.cell, { flex: 2 }]}>
            {itm.FirstName} {itm.LastName}
          </Text>
        ) : (
          <></>
        )}
      </View>
    );
  });

  return (
    <View key={`Dog_${DogId}`}>
      <View style={{ marginBottom: 5, display: "flex", flexDirection: "row" }}>
        <Text style={{ fontSize: 10, marginRight: 20, textDecoration: "underline" }}>
          {DogId} {DogName}
        </Text>
        <Text style={{ fontSize: 10, marginRight: 20, color: "red" }}>{MeasureNeeded}</Text>
      </View>
      {ClassRuns.length > 0 || OtherEntries.length > 0 ? (
        <View>
          {ClassRuns.length > 0 && (
            <View style={{ marginBottom: 5 }}>
              <View style={CommonStyles.row}>
                <Text style={{ flex: 2, fontSize: 12 }}>Day</Text>
                <Text style={{ flex: 1, fontSize: 12 }}>Ring</Text>
                <Text style={{ flex: 5, fontSize: 12 }}>Class</Text>
                <Text style={{ flex: 2, fontSize: 12 }}>Height</Text>
                <Text style={{ flex: 2, fontSize: 12 }}>R/O</Text>
                {ShowHandlers && <Text style={{ flex: 2 }}>Handler</Text>}
              </View>
              <View>{ClassRunLines}</View>
            </View>
          )}
          {HasTeam && TeamString}
          {HasPair && PairString}
          {OtherPairString}
          {OtherTeamString}
        </View>
      ) : (
        <></>
      )}
    </View>
  );
};

/******************************** */

export default CompetitorSheet;
