import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useCallback } from "react";
import AsyncSelect from "react-select/async";
import { apiDelete, apiGet, apiPost } from "../../../shared/api";

const Managers = (props) => {
  const [Data, setData] = useState([]);
  const [NewManager, setNewManager] = useState();

  const GetHandlerSuggestions = async (val) => {
    try {
      const req = await apiGet(`/members/match/${val}`);
      if (req && req.ok && req.status === 200) {
        let res = await req.json();
        return res
          .filter((r) => !Data.some((d) => d.MemberId === r.Id))
          .map((itm) => {
            const displayString = `${itm.Id} - ${itm.FirstName} ${itm.LastName}`;
            return { value: itm.Id, label: displayString };
          });
      } else {
        return [];
      }
    } catch (error) {
      console.log(error.message);
      return [];
    }
  };

  const LoadData = useCallback(async () => {
    try {
      const req = await apiGet(`/admin/shows/managers/${props.ShowId}`);
      const res = await req.json();
      setData(res);
    } catch (error) {
      console.log(error.message);
      window.alert("Error loading list of managers");
    }
  }, [props.ShowId]);

  useEffect(() => {
    if (props.ShowId) LoadData();
  }, [props.ShowId, LoadData]);

  const DeleteManager = async (Id) => {
    try {
      await apiDelete(`/admin/shows/managers/${Id}`);
      LoadData();
    } catch (error) {
      console.log(error.message);
      window.alert("Error deleting manager");
    }
  };

  const SaveManager = async () => {
    try {
      const obj = { MemberId: NewManager.value, ShowId: props.ShowId };
      await apiPost("/admin/shows/managers", obj);
      LoadData();
      setNewManager(undefined);
    } catch (error) {
      console.log(error.message);
      window.alert("Error saving new manager");
    }
  };

  const ManagerRows = Data.map((itm) => (
    <tr key={itm.Id}>
      <td>
        {itm.FirstName} {itm.LastName}
      </td>
      <td>
        <span
          className="cursor-pointer text-red"
          onClick={() => DeleteManager(itm.Id)}
        >
          <FontAwesomeIcon icon={faTrash} className="mr-1x" />
          Delete
        </span>
      </td>
    </tr>
  ));

  return (
    <div className="card">
      <div className="card-header">Managers</div>
      <div className="card-body">
        {Data.length === 0 ? (
          <p>No managers defined</p>
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th>Member</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{ManagerRows}</tbody>
          </table>
        )}

        <div className="form-group mt-3x">
          <label>Add a member</label>
          <AsyncSelect
            className="width-50"
            value={NewManager}
            onChange={(e) => setNewManager(e)}
            loadOptions={GetHandlerSuggestions}
          />
        </div>
        <div className="form-group">
          {NewManager ? (
            <button className="button button-green" onClick={SaveManager}>
              Save
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default Managers;
