import React, { useState, useEffect, useContext, useCallback } from "react";
import moment from "moment";
import { apiGet, apiPost } from "../../../../shared/api.js";
import UserContext from "../../../../context/user/UserContext.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import InlineLoading from "../../../../shared/InlineLoading";

const SummaryHelping = (props) => {
  const [Days, setDays] = useState([]);
  const [ShowAlter, setShowAlter] = useState(false);
  const [SavedChoices, setSavedChoices] = useState([]);
  const [Choices, setChoices] = useState([]);
  const [Notes, setNotes] = useState("");
  const [CanHelp, setCanHelp] = useState(false);
  const [Loading, setLoading] = useState(false);

  const CanEdit = props.CanEdit;
  const { User } = useContext(UserContext);

  const LoadData = useCallback(async () => {
    try {
      setLoading(true);

      //Show details
      let req = await apiGet(`/shows/${props.ShowId}`);
      let res = await req.json();

      const dates = [];
      const choices = [];
      const savedChoices = [];
      let CurrentDate = moment(res.StartDate);
      const LastDate = moment(res.EndDate);
      while (CurrentDate.isSameOrBefore(LastDate)) {
        const CurrVal = moment(CurrentDate);
        dates.push(CurrVal);
        choices.push({ Date: CurrVal, Availability: { AM: false, PM: false } });
        savedChoices.push({ Date: CurrVal, Availability: { AM: false, PM: false } });
        CurrentDate = CurrentDate.add(1, "days");
      }
      setDays(dates);

      //Entry details for notes
      req = await apiGet(`/shows/entry/${props.ShowId}/${User.Id}`);
      res = await req.json();
      setNotes(res.HelpingNotes);

      //Get helping choices
      req = await apiGet(`/shows/helpingchoices/${props.ShowId}/${User.Id}`);
      res = await req.json();

      let canHelp = false;

      for (const choice of res) {
        const found = choices.find((c) => moment(c.Date).isSame(moment(choice.ShowDate)));
        found.Availability.AM = choice.AvailableAM;
        found.Availability.PM = choice.AvailablePM;
        const foundSaved = savedChoices.find((c) =>
          moment(c.Date).isSame(moment(choice.ShowDate))
        );
        foundSaved.Availability.AM = choice.AvailableAM;
        foundSaved.Availability.PM = choice.AvailablePM;

        if (choice.AvailableAM || choice.AvailablePM) canHelp = true;
      }
      setCanHelp(canHelp);
      setChoices(choices);
      setSavedChoices(savedChoices);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      window.alert("Error loading show data");
      setLoading(false);
    }
  }, [props.ShowId, User.Id]);

  useEffect(() => {
    if (props.ShowId) LoadData();
  }, [LoadData, props.ShowId]);

  const SaveChoices = async () => {
    try {
      const obj = {
        ShowId: props.ShowId,
        MemberId: User.Id,
        Choices,
        Notes,
      };
      const req = await apiPost("/shows/helpingchoices", obj);
      if (req && req.ok) {
        setShowAlter(false);
        setSavedChoices(Choices);
      } else {
        window.alert("Error saving choices");
      }

      props.onSave();
    } catch (error) {
      console.log(error.message);
      window.alert("Error saving choices");
    }
  };

  const HelpingDayChoices = Days.map((itm, idx) => {
    let AMAvailable, PMAvailable;
    const found = Choices.find((c) => moment(c.Date).isSame(itm));
    AMAvailable = found?.Availability.AM;
    PMAvailable = found?.Availability.PM;

    return (
      <tr key={idx}>
        <td>
          <span className="mr-5x">{moment.utc(itm).format("ddd Do MMM")}</span>
        </td>
        <td>
          AM:{" "}
          <input
            type="checkbox"
            checked={AMAvailable}
            onChange={(e) => SelectHelp(itm, "AM", e.target.checked)}
          />{" "}
          PM:{" "}
          <input
            type="checkbox"
            checked={PMAvailable}
            onChange={(e) => SelectHelp(itm, "PM", e.target.checked)}
          />
        </td>
      </tr>
    );
  });

  const ChoiceRows = SavedChoices.map((itm, idx) => {
    if (!itm.Availability.AM && !itm.Availability.PM) return <></>;

    return (
      <tr key={idx}>
        <td>{moment.utc(itm.Date).format("ddd Do")}</td>
        <td>
          {itm.Availability.AM ? (
            <FontAwesomeIcon className="text-green" icon={faCheck} />
          ) : (
            <></>
          )}
        </td>
        <td>
          {itm.Availability.PM ? (
            <FontAwesomeIcon className="text-green" icon={faCheck} />
          ) : (
            <></>
          )}
        </td>
      </tr>
    );
  });

  const SelectHelp = (dt, when, selected) => {
    const choices = Array.from(Choices);
    const found = choices.find((c) => moment(c.Date).isSame(moment(dt)));
    found.Availability[when] = selected;
    setChoices(choices);

    let canHelp = false;
    for (const choice of choices)
      if (choice.Availability.AM || choice.Availability.PM) canHelp = true;

    setCanHelp(canHelp);
  };

  return (
    <div className="mb-3x">
      <h3>Helping choices</h3>

      {Loading ? <InlineLoading /> : <></>}

      {!CanHelp ? (
        <p>You're not able to help at this show</p>
      ) : (
        <>
          <p>
            You have indicated that you are able to help at this show, on the following
            days:
          </p>
          <table className="table width-25">
            <thead>
              <tr>
                <th>Date</th>
                <th>AM</th>
                <th>PM</th>
              </tr>
            </thead>
            <tbody>{ChoiceRows}</tbody>
          </table>
          {(!CanEdit || (CanEdit && !ShowAlter)) && Notes !== "" ? (
            <p>
              <b>Notes</b>
              <br />
              {Notes}
            </p>
          ) : (
            <></>
          )}
        </>
      )}
      {ShowAlter ? (
        <>
          <div className="mt-3x">
            <p>I am able to help on the following days:</p>
            <table className="table width-75">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Availability</th>
                </tr>
              </thead>
              <tbody>{HelpingDayChoices}</tbody>
            </table>
            <div className="form-group">
              <label>
                <b>Notes:</b>
              </label>
              <textarea
                className="form-control width-50"
                value={Notes}
                onChange={(e) => setNotes(e.target.value)}
              ></textarea>
            </div>
            {CanEdit ? (
              <p className="mt-3x">
                <button className="button button-green" onClick={SaveChoices}>
                  Save choices
                </button>
              </p>
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
      {!ShowAlter && CanEdit && !props.IsClosed ? (
        <button className="button" onClick={() => setShowAlter(true)}>
          Alter my choices
        </button>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SummaryHelping;
