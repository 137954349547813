import React, { useState, useEffect } from 'react';

const MyEntryPreferences = props => {

    const [UsePreferences, setUsePreferences] = useState('Yes');
    const [IsPaper, setIsPaper] = useState(false);
    const [IsAdminLogin, setIsAdminLogin] = useState(false);

    useEffect(() => {
        sessionStorage.setItem('UsePreferences', UsePreferences);
        sessionStorage.setItem('IsPaper', IsPaper);
        props.onChoice(UsePreferences === 'Yes' ? true : false);
    },[UsePreferences,IsPaper,props]);


    useEffect(() => {
        let itm;
        itm = sessionStorage.getItem('UsePreferences');
        if(itm)
            setUsePreferences(itm);
        itm = sessionStorage.getItem('IsPaper');
        if(itm && itm === 'true')
            setIsPaper(itm);     
            
        itm = sessionStorage.getItem('User');
        itm = JSON.parse(itm);
        if(itm.IsAdminLogin)
            setIsAdminLogin(true);
    },[]);


    useEffect(() => {
        window.scrollTo(0,0);
    },[]);   

    return ( 
        <>
            <h3>Pre-selected preferences</h3>        
            <div className="form-group">
                <label>Do you wish to use pre-selected preferences for this show?</label>
                <select value={UsePreferences} onChange={e => setUsePreferences(e.target.value)} className="form-control width-10">
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
            </div>
            {
                IsAdminLogin ? 
                <div className="form-group">
                    <br /><br />
                    <label>I am an admin making a paper entry</label>
                    <label className="switch">
                        <input type="checkbox" checked={IsPaper} onChange={e => setIsPaper(e.target.checked)} />
                        <span className="slider round"></span>
                    </label>                
                </div>
                :
                <></>
            }
        </>
     );
}
 
export default MyEntryPreferences;