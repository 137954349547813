import React, { useState, useEffect } from "react";
import { apiGet } from "../../../shared/api";

const League = () => {
  const [Data, setData] = useState([]);
  const [Level, setLevel] = useState("Beginners");
  const [Type, setType] = useState("Performance");
  const [Height, setHeight] = useState("14");
  const [MatchData, setMatchData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const req = await apiGet("/dogs/leaguetabledata");
        const res = await req.json();
        setData(res);
      } catch (error) {
        window.alert("Error loading league data");
        console.log(error.message);
      }
    })();
  }, []);

  useEffect(() => {
    if (Data && Data.length && Level && Height) {
      const filterResults = Data.filter(
        (r) =>
          r.HeightId === parseInt(Height) &&
          ((Type === "Performance" &&
            r.PerfLevel === Level &&
            r.PerfPoints !== null) ||
            (Type === "Steeplechase" &&
              r.SteepLevel === Level &&
              r.SteepPoints !== null))
      )
        .sort((a, b) => {
          if (Type === "Performance") {
            return a.PerfPoints > b.PerfPoints ? -1 : 1;
          } else {
            return a.SteepPoints > b.SteepPoints ? -1 : 1;
          }
        })
        .map((itm) => {
          return {
            Id: itm.Id,
            PetName: itm.PetName,
            Points: Type === "Performance" ? itm.PerfPoints : itm.SteepPoints,
            FirstName: itm.FirstName,
            LastName: itm.LastName,
          };
        });
      setMatchData(filterResults);
    }
  }, [Height, Level, Type, Data]);

  return (
    <div className="card">
      <div className="card-header">League</div>
      <div className="card-body">
        <p className="mt-2x mb-2x">
          Please note that your dog is ranked in the league at the level it had
          achived at the start of each calendar year.
        </p>

        <div className="grid mb-1x">
          <div className="col-2">Classes:</div>
          <div className="col-3">
            <select
              className="form-control"
              value={Type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="Performance">Performance</option>
              <option value="Steeplechase">Steeplechase</option>
            </select>
          </div>
        </div>
        <div className="grid mb-1x">
          <div className="col-2">Level:</div>
          <div className="col-3">
            <select
              className="form-control"
              value={Level}
              onChange={(e) => setLevel(e.target.value)}
            >
              <option value="Beginners">Beginners</option>
              <option value="Novice">Novice</option>
              <option value="Senior">Senior</option>
              <option value="Champion">Champion</option>
            </select>
          </div>
        </div>
        <div className="grid mb-3x">
          <div className="col-2">Height:</div>
          <div className="col-3">
            <select
              className="form-control"
              value={Height}
              onChange={(e) => setHeight(e.target.value)}
            >
              <option value="14">250</option>
              <option value="1">300</option>
              <option value="2">400</option>
              <option value="3">500</option>
              <option value="4">600</option>
            </select>
          </div>
        </div>

        {MatchData.length > 0 && (
          <table>
            <thead>
              <tr>
                <th>Id</th>
                <th>Dog</th>
                <th>Owner</th>
                <th>Total Progression Points</th>
              </tr>
            </thead>
            <tbody>
              {MatchData.map((itm) => (
                <tr key={itm.Id}>
                  <td>{itm.Id}</td>
                  <td>{itm.PetName}</td>
                  <td>
                    {itm.FirstName} {itm.LastName}
                  </td>
                  <td>{itm.Points}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default League;
