import { faFileExcel, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { apiPost } from "../../../shared/api";
import moment from "moment";
import PaymentBreakdownExcel from "./paymentbreakdownexcel";

const Transactions = () => {
  const [Transactions, setTransactions] = useState([]);
  const [Total, setTotal] = useState(0);
  const [FilterData, setFilterData] = useState([]);
  const [TotalCard, setTotalCard] = useState(0);
  const [TotalCash, setTotalCash] = useState(0);
  const [TotalComp, setTotalComp] = useState(0);
  const [TotalCheque, setTotalCheque] = useState(0);
  const [Dates, setDates] = useState([]);
  const [SelectedDate, setSelectedDate] = useState();
  const [SearchString, setSearchString] = useState("");
  const [SearchAmount, setSearchAmount] = useState("");
  const [ShowMe, setShowMe] = useState("0");
  const [Loading, setLoading] = useState(false);

  const { Id } = useParams();

  useEffect(() => {
    const numeric = parseFloat(SearchString);
    let found = Transactions;
    if (isNaN(numeric) && SearchString > "") {
      found = found.filter(
        (t) =>
          t.FirstName.toString()
            .toLowerCase()
            .startsWith(SearchString.toString().toLowerCase()) ||
          t.LastName.toString()
            .toLowerCase()
            .startsWith(SearchString.toString().toLowerCase())
      );
    } else if (SearchString > "") {
      found = found.filter((t) => t.MemberId.toString().startsWith(SearchString));
    }

    const amnt = parseFloat(SearchAmount);
    if (amnt) {
      found = found.filter((t) => {
        return t.Payments.filter((p) => p.Amount === amnt).length;
      });
    }

    if (SelectedDate && SelectedDate !== "")
      found = found.filter((t) =>
        moment(t.DateTimeStamp).isSame(moment(SelectedDate, "DD/MM/YYYY"), "day")
      );

    found = found.sort((a, b) =>
      moment(a.DateTimeStamp).isBefore(moment(b.DateTimeStamp)) ? -1 : 1
    );

    setFilterData(found);
  }, [SearchAmount, SearchString, Transactions, SelectedDate]);

  useEffect(() => {
    (async () => {
      try {
        let req, res;

        setLoading(true);

        //Transactions
        let ct = null;
        let output = [];

        const FetchData = async () => {
          try {
            req = await apiPost(`/admin/shows/managers/transactions/${Id}`, {
              ContinuationToken: ct,
            });
            res = await req.json();

            for (const itm of res.Data.entries) {
              const obj = JSON.parse(itm.Details._);
              obj.MemberName = `${obj.FirstName} ${obj.LastName}`;
              output.push(obj);
            }
            if (res.Data.continuationToken !== null) {
              ct = res.Data.continuationToken;
              await FetchData();
            }
          } catch (error) {
            console.log(error.message);
          }
        };

        await FetchData();
        output = output.sort((a, b) =>
          moment(a.DateTimeStamp).isBefore(moment(b).DateTimeStamp) ? -1 : 1
        );
        setTransactions(output);
        setFilterData(output);
        let dates = output.reduce((acc, itm) => {
          const dt = moment(itm.DateTimeStamp).hour(0).minute(0).second(0).millisecond(0);
          return acc.indexOf(dt.format("DD/MM/YYYY")) === -1
            ? acc.concat(dt.format("DD/MM/YYYY"))
            : acc;
        }, []);
        dates = dates.sort((a, b) =>
          moment(a, "DD/MM/YYYY").isBefore(moment(b, "DD/MM/YYYY")) ? -1 : 1
        );
        setDates(dates);
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (Transactions && Transactions.length > 0) {
      if (ShowMe === "0") {
        setFilterData(Transactions);
      } else {
        switch (ShowMe) {
          case "1":
            setFilterData(
              Transactions.filter(
                (t) => t.Payments.filter((s) => s.PaymentMethod === "Cheque").length
              )
            );
            break;

          case "2":
            setFilterData(
              Transactions.filter(
                (t) =>
                  t.Payments.filter((s) => s.PaymentMethod === "Complimentary").length
              )
            );
            break;

          case "3":
            setFilterData(
              Transactions.filter(
                (t) => t.Payments.filter((s) => s.PaymentMethod === "Cash").length
              )
            );
            break;

          case "4":
            setFilterData(
              Transactions.filter(
                (t) => t.Payments.filter((s) => s.PaymentMethod === "Card").length
              )
            );
            break;
        }
      }
    }
  }, [Transactions, ShowMe]);

  useEffect(() => {
    setTotal(
      Transactions.reduce(
        (acc, itm) =>
          (acc += itm.Payments.reduce((subacc, subitm) => (subacc += subitm.Amount), 0)),
        0
      )
    );

    setTotalCard(
      Transactions.reduce(
        (acc, itm) =>
          (acc += itm.Payments.reduce(
            (subacc, subitm) =>
              subitm.PaymentMethod === "Card" ? (subacc += subitm.Amount) : subacc,
            0
          )),
        0
      )
    );

    setTotalCheque(
      Transactions.reduce(
        (acc, itm) =>
          (acc += itm.Payments.reduce(
            (subacc, subitm) =>
              subitm.PaymentMethod === "Cheque" ? (subacc += subitm.Amount) : subacc,
            0
          )),
        0
      )
    );

    setTotalComp(
      Transactions.reduce(
        (acc, itm) =>
          (acc += itm.Payments.reduce(
            (subacc, subitm) =>
              subitm.PaymentMethod === "Complimentary"
                ? (subacc += subitm.Amount)
                : subacc,
            0
          )),
        0
      )
    );

    setTotalCash(
      Transactions.reduce(
        (acc, itm) =>
          (acc += itm.Payments.reduce(
            (subacc, subitm) =>
              subitm.PaymentMethod === "Cash" ? (subacc += subitm.Amount) : subacc,
            0
          )),
        0
      )
    );
  }, [Transactions]);

  const doClear = () => {
    setSearchString();
    setSearchAmount();
    setShowMe("0");
  };

  const Rows = FilterData.map((itm, idx) => {
    return (
      <tr key={idx}>
        <td valign="top">
          <p>{moment(itm.DateTimeStamp).format("ddd Do HH:mm")}</p>
        </td>
        <td valign="top">
          <p>
            {itm.MemberId} {itm.MemberName}
          </p>
        </td>
        <td valign="top">
          {itm.List.map((sub, idx) => {
            return (
              <p key={idx}>
                {
                  {
                    OnlineLateFees: "LE Fees",
                    MemberReg: "Registration",
                    DogReg: "Dog Reg",
                    Renewal: "Renewal",
                  }[sub.Type]
                }
              </p>
            );
          })}
        </td>
        <td valign="top">
          {itm.Payments.map((sub, idx) => (
            <p key={idx}>
              {sub.PaymentMethod} - £{sub.Amount}
            </p>
          ))}
        </td>
        <td valign="top">
          <p>£{itm.Payments.reduce((acc, sub) => (acc += sub.Amount), 0)}</p>
        </td>
      </tr>
    );
  });

  return (
    <div className="card">
      <div className="card-header">Transactions</div>
      <div className="card-body">
        {Loading && (
          <p className="mb-3x">
            <FontAwesomeIcon icon={faSpinner} spin size="2x" className="mr-1x" /> Loading
            data...
          </p>
        )}

        <div className="flex-row mb-1x">
          <div className="form-group mr-3x">
            <label>Date</label>
            <select
              className="form-control"
              value={SelectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
            >
              <option value="">All days</option>
              {Dates.map((itm, idx) => (
                <option key={idx} value={itm}>
                  {itm}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group mr-3x">
            <label>Search</label>
            <div className="row">
              <input
                className="form-control mr-1x"
                type="text"
                value={SearchString}
                onChange={(e) => setSearchString(e.target.value)}
              />
            </div>
          </div>
          <div className="form-group mr-3x">
            <label>Amount</label>
            <div className="row">
              <input
                className="form-control mr-1x"
                type="text"
                value={SearchAmount}
                onChange={(e) => setSearchAmount(e.target.value)}
              />
            </div>
          </div>
          <div className="form-group mr-3x">
            <label>Show me</label>
            <select
              className="form-control"
              value={ShowMe}
              onChange={(e) => setShowMe(e.target.value)}
            >
              <option value="0">Everything</option>
              <option value="1">Cheques only</option>
              <option value="2">Comp only</option>
              <option value="3">Cash only</option>
              <option value="4">Card only</option>
            </select>
          </div>
          <div
            className="form-group mr-3x"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <button className="button button-blue" onClick={doClear}>
              Clear
            </button>
          </div>
        </div>

        <div className="mb-3x">
          <p>
            <button
              className="button"
              onClick={() => PaymentBreakdownExcel({ transactions: FilterData })}
            >
              <FontAwesomeIcon icon={faFileExcel} className="mr-1x" /> Download as Excel
              file
            </button>
          </p>
        </div>

        {Transactions.length > 0 && (
          <>
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Id</th>
                  <th>Item</th>
                  <th>Method(s)</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>{Rows}</tbody>
              <tfoot>
                <tr>
                  <td colSpan="4">Total</td>
                  <td>£{Total}</td>
                </tr>
              </tfoot>
            </table>

            <p className="mt-3x">
              <b>Totals: </b> Comp: £{TotalComp}, Cash: £{TotalCash}, Cheque: £
              {TotalCheque}, Card: £{TotalCard}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default Transactions;
