import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { DownloadFile } from "../../../../shared/clientsidefiledownload";
import { pdf, View, Text, Document, Page } from "@react-pdf/renderer";
import { Link } from "react-router-dom";
import RingBoard from "./ringboard.js";
import RingPlan from "./ringplan.js";
import RingPlanWord from "./ringplanword.js";
import AddressLabels from "./addresslabels.js";
import ModalLoading from "../../../../shared/ModalLoading";
import moment from "moment";
import {
  apiDelete,
  apiGet,
  apiPost,
  apiPostFile,
} from "../../../../shared/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faFileExcel,
  faFilePdf,
  faFileWord,
} from "@fortawesome/free-solid-svg-icons";
import CompetitorSheet from "./competitorsheet.js";
import PostageSheet from "./postagesheet.js";
import { Workbook } from "exceljs";

const Processing = (props) => {
  const { Id } = useParams();
  const [ShowName, setShowName] = useState();
  const [Show, setShow] = useState();
  const [HasClosed, setHasClosed] = useState(false);
  const [Processing, setProcessing] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (!Id) return;

        const req = await apiGet(`/shows/${Id}`);
        const res = await req.json();
        setShowName(res.DisplayName);
        setShow(res);
        if (moment(res.EntriesClose).add(1, "day").isBefore(moment()))
          setHasClosed(true);
      } catch (error) {
        console.log(error.message);
        window.alert("Error loading show data");
      }
    })();
  }, [Id]);

  const doDebtorsList = async () => {
    setProcessing(true);

    const req = await apiGet(`/admin/shows/processing/debtors/${Id}`);
    const res = await req.json();

    let wb = new Workbook();
    let ws = wb.addWorksheet("Debtors");

    ws.addRow(["Email", "FirstName", "LastName", "Id", "Balance", "Due"]);

    for (const itm of res) {
      ws.addRow([
        itm.Email,
        itm.FirstName,
        itm.LastName,
        itm.MemberId,
        itm.Balance,
        itm.Due,
      ]);
    }

    const buf = await wb.xlsx.writeBuffer();
    const blob = new Blob([buf], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    DownloadFile(blob, "Debtors.xlsx");
    setProcessing(false);
  };

  const doPostageSheet = async () => {
    setProcessing(true);
    const doc = await PostageSheet(Id);
    const blob = await pdf(doc).toBlob();
    DownloadFile(blob, "EntryListPostage.pdf");
    setProcessing(false);
  };

  const doCompetitorSheet = async () => {
    setProcessing(true);
    const doc = await CompetitorSheet(Id);
    const blob = await pdf(doc).toBlob();
    DownloadFile(blob, "CompetitorSheet.pdf");
    setProcessing(false);
  };

  const doAddressLabels = async () => {
    setProcessing(true);
    const doc = await AddressLabels(Id);
    const blob = await pdf(doc).toBlob();
    DownloadFile(blob, "AddressLabels.pdf");
    setProcessing(false);
  };

  const doRingboard = async () => {
    setProcessing(true);
    const doc = await RingBoard(Id);
    const blob = await pdf(doc).toBlob();
    DownloadFile(blob, "RingBoard.pdf");
    setProcessing(false);
  };

  const GetRingPlan = async (Id) => {
    try {
      return await RingPlan(Id);
    } catch (error) {
      console.log(error.message);
      return null;
    }
  };

  const doRingPlan = async () => {
    setProcessing(true);
    const doc = await GetRingPlan(Id);
    const blob = await pdf(doc).toBlob();
    DownloadFile(blob, "RingPlan.pdf");
    setProcessing(false);
  };

  const doRingPlanWord = async () => {
    setProcessing(true);
    const doc = await RingPlanWord(Id);
    DownloadFile(doc, "RingPlanWord.docx");
    setProcessing(false);
  };

  const doPublishRingPlan = async () => {
    setProcessing(true);
    const doc = await GetRingPlan(Id);
    const blob = await pdf(doc).toBlob();
    const fd = new FormData();
    fd.append("file", blob, "Ring Plan.pdf");
    await apiPostFile(`/admin/shows/entrantdocuments/store/${Id}`, fd);
    setProcessing(false);
  };

  const BuildMeasuresDocument = async () => {
    try {
      const req = await apiGet(`/admin/shows/processing/exceloutput/${Id}`);
      const res = await req.json();
      const data = res[9];
      const localStyles = {
        header: {
          display: "flex",
          flexDirection: "row",
        },
        row: {
          display: "flex",
          flexDirection: "row",
          marginBottom: 4,
        },
        data: { fontSize: 9 },
      };

      const header = (
        <>
          <View style={{ marginBottom: 10 }}>
            <Text style={{ fontSize: 14 }}>
              Measure list for {Show.DisplayName},{" "}
              {moment(Show.StartDate).format("DD MMM")} -{" "}
              {moment(Show.EndDate).format("DD MMM")}
            </Text>
          </View>
          <View style={{ marginBottom: 20 }}>
            <Text style={{ fontSize: 10 }}>
              This is a list of dogs entered at this show who require a measure
              before they can run.
            </Text>
            <Text style={{ fontSize: 10 }}>
              You do not need to prebook a measure, just listen out for the
              announcements at the show.
            </Text>
          </View>
          <View style={localStyles.header}>
            <View style={{ flex: 0.25 }}>
              <Text>Name</Text>
            </View>
            <View style={{ flex: 0.25 }}>
              <Text>Pet Name</Text>
            </View>
            <View style={{ flex: 0.15 }}>
              <Text>Required</Text>
            </View>
          </View>
        </>
      );

      const output = data.reduce((acc, row) => {
        const foundHeight = res[14].find(
          (r) => r.DogId === row.Id && r.HeightId !== 4 && r.HeightId !== 8
        );
        const age = row.AgeInYears > 1;
        const hasPerm = parseInt(row.Measure) === 0;
        const hasFirst = parseInt(row.Measure) === 1;
        let line = null;
        if (
          foundHeight &&
          ((age && !hasPerm) || (!age && row.Measure === null))
        ) {
          let measureNeeded = "Unknown";
          switch (row.Measure) {
            case null:
              if (foundHeight && !hasFirst) measureNeeded = "First";
              break;

            case false:
              measureNeeded = "";
              break;

            default:
              if (age && !hasPerm) measureNeeded = "Permanent";
              break;
          }

          if (measureNeeded !== "")
            line = (
              <View style={localStyles.row}>
                <View style={{ flex: 0.25 }}>
                  <Text style={localStyles.data}>
                    {row.FirstName} {row.LastName}
                  </Text>
                </View>
                <View style={{ flex: 0.25 }}>
                  <Text style={localStyles.data}>{row.PetName}</Text>
                </View>
                <View style={{ flex: 0.15 }}>
                  <Text style={localStyles.data}>{measureNeeded}</Text>
                </View>
              </View>
            );
        }
        return line !== null ? acc.concat(line) : acc;
      }, []);

      const page = (
        <Page
          wrap
          orientation="portrait"
          size="A4"
          style={{ margin: 16, paddingTop: 24, paddingBottom: 24 }}
        >
          {header}
          {output}
        </Page>
      );
      const doc = <Document>{page}</Document>;
      return doc;
    } catch (error) {
      return null;
    }
  };

  const doMeasuresDoc = async () => {
    try {
      setProcessing(true);

      const doc = await BuildMeasuresDocument();
      const blob = await pdf(doc).toBlob();

      DownloadFile(blob, "Measures Needed.pdf");
    } catch (error) {
      console.log(error.message);
    } finally {
      setProcessing(false);
    }
  };

  const doPublishMeasures = async () => {
    try {
      setProcessing(true);

      const doc = await BuildMeasuresDocument();
      const blob = await pdf(doc).toBlob();

      const fd = new FormData();
      fd.append("file", blob, "Measures Needed.pdf");
      await apiPostFile(`/admin/shows/entrantdocuments/store/${Id}`, fd);
    } catch (error) {
      console.log(error.message);
    } finally {
      setProcessing(false);
    }
  };

  const doTakeMoney = async () => {
    try {
      setProcessing(true);
      const req = await apiPost("/admin/shows/processing/takemoney", {
        ShowId: Id,
      });
      if (!req || !req.ok) {
        window.alert("Error completing task");
      } else {
        window.location.reload();
      }
    } catch (error) {
      window.alert("Error taking money");
    } finally {
      setProcessing(false);
    }
  };

  const doSendDebtorEmails = async (NumberOfDays) => {
    try {
      setProcessing(true);
      const req = await apiPost("/admin/shows/processing/sendreminderemails", {
        ShowId: Id,
        NumberOfDays,
      });
      if (!req || !req.ok) {
        window.alert("Error completing task");
      } else {
        window.location.reload();
      }
    } catch (error) {
      window.alert("Error taking money");
    } finally {
      setProcessing(false);
    }
  };

  const doDeleteDebtors = async () => {
    try {
      setProcessing(true);
      const req = await apiDelete(`/admin/shows/processing/debtors/${Id}`);
      if (!req || !req.ok) {
        window.alert("Error completing task");
      } else {
        window.location.reload();
      }
    } catch (error) {
      window.alert("Error completing task");
    } finally {
      setProcessing(false);
    }
  };

  const doPublishRunningOrders = async () => {
    try {
      setProcessing(true);
      const req = await apiPost(
        "/admin/shows/processing/publishrunningorders",
        {
          ShowId: Id,
        }
      );
      if (!req || !req.ok) window.alert("Error completing task");
    } catch (error) {
      window.alert("Error completing task");
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className="card">
      <div className="card-header">Show Processing</div>
      <div className="card-body">
        {Processing && <ModalLoading />}

        <h3 className="mb-2x">Processing for {ShowName}</h3>

        <p>
          <b>Tools</b>
        </p>
        <div className="mb-1x">
          <Link to={`/admin/shows/processing/ringeditor/${Id}`}>
            Ring Editor
          </Link>{" "}
          {HasClosed && <span className="text-red">Show has closed</span>}
          <br />
          <Link to={`/admin/shows/processing/moneyout/${Id}`}>Money Out</Link>
          <br />
          <div className="text-link cursor-pointer" onClick={doPublishRingPlan}>
            Publish Ring Plan
          </div>
          <div className="text-link cursor-pointer" onClick={doPublishMeasures}>
            Publish Measures document
          </div>
        </div>
        <div className="mb-1x">
          <p>
            <b>Show closing tasks</b>
          </p>
          <div>
            <span
              className="text-link cursor-pointer mr-1x"
              onClick={doDebtorsList}
            >
              Get debtors list
            </span>
          </div>
          <div>
            <span
              className="text-link cursor-pointer mr-1x"
              onClick={doTakeMoney}
            >
              Take any outstanding money
            </span>
            {Show?.LastMoneyTaken && (
              <span>{moment(Show.LastMoneyTaken).format("DD/MM/YYYY")}</span>
            )}
          </div>
          <div>
            <span className="mr-1x">Send debtors email</span>
            <span
              className="text-link cursor-pointer mr-1x"
              onClick={() => doSendDebtorEmails(7)}
            >
              7 Day
            </span>
            <span
              className="text-link cursor-pointer mr-1x"
              onClick={() => doSendDebtorEmails(3)}
            >
              3 Day
            </span>
            <span
              className="text-link cursor-pointer mr-1x"
              onClick={() => doSendDebtorEmails(0)}
            >
              Closing Day
            </span>
            <span
              className="text-link cursor-pointer mr-1x"
              onClick={() => doSendDebtorEmails(99)}
            >
              Capped
            </span>
            {Show?.LastEmailChase && (
              <span>{moment(Show.LastEmailChase).format("DD/MM/YYYY")}</span>
            )}
          </div>
          <div className="text-link cursor-pointer" onClick={doDeleteDebtors}>
            <span>Delete debtors and send email</span>
            {Show?.LastDebtorsDelete && (
              <span>{moment(Show.LastDebtorsDelete).format("DD/MM/YYYY")}</span>
            )}
          </div>
          <div
            className="text-link cursor-pointer"
            onClick={doPublishRunningOrders}
          >
            Create and publish running orders
          </div>
        </div>
        <br />

        <p>
          <b>Documents</b>
        </p>
        <ul className="no-bullets">
          <li>
            <FontAwesomeIcon
              icon={faFilePdf}
              className="text-red mr-1x"
              size="lg"
            />
            <span className="text-link cursor-pointer" onClick={doRingboard}>
              Ring Board
            </span>
          </li>
          <li>
            <FontAwesomeIcon
              icon={faFilePdf}
              className="text-red mr-1x"
              size="lg"
            />
            <span className="text-link cursor-pointer" onClick={doRingPlan}>
              Ring Plan (Pdf)
            </span>
          </li>
          {/*<li>
            <FontAwesomeIcon
              icon={faFileWord}
              className="text-red mr-1x"
              size="lg"
            />
            <span className="text-link cursor-pointer" onClick={doRingPlanWord}>
              Ring Plan (Word)
            </span>
          </li>*/}
          <li>
            <FontAwesomeIcon
              icon={faFilePdf}
              className="text-red mr-1x"
              size="lg"
            />
            <span className="text-link cursor-pointer" onClick={doMeasuresDoc}>
              Measures List
            </span>
          </li>
          <li>
            <Link to={`/admin/shows/processing/entrylist/${Id}`}>
              <FontAwesomeIcon icon={faFile} className="mr-1x" size="lg" />
              <span>Handler Info Sheets (Entry List)</span>
            </Link>
          </li>
          <li>
            <FontAwesomeIcon
              icon={faFilePdf}
              className="text-red mr-1x"
              size="lg"
            />
            <span className="text-link cursor-pointer" onClick={doPostageSheet}>
              Handler Info Sheet for postage
            </span>
          </li>
          <li>
            <FontAwesomeIcon
              icon={faFilePdf}
              className="text-red mr-1x"
              size="lg"
            />
            <span
              className="text-link cursor-pointer"
              onClick={doCompetitorSheet}
            >
              Competitor List
            </span>
          </li>
          <li className="cursor-pointer">
            <Link to={`/admin/shows/processing/excelprocess/${Id}`}>
              <FontAwesomeIcon
                icon={faFileExcel}
                className="text-green mr-1x"
                size="lg"
              />
              <span className="cursor-pointer">Excel output</span>
            </Link>
          </li>
          <li className="cursor-pointer">
            <Link to={`/admin/shows/processing/excellatesprocess/${Id}`}>
              <FontAwesomeIcon
                icon={faFileExcel}
                className="text-green mr-1x"
                size="lg"
              />
              <span className="cursor-pointer">
                Excel output - Late Entries
              </span>
            </Link>
          </li>
          <li>
            <FontAwesomeIcon
              icon={faFilePdf}
              className="text-red mr-1x"
              size="lg"
            />
            <span
              className="text-link cursor-pointer"
              onClick={doAddressLabels}
            >
              Address labels
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Processing;
