import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useHistory } from "react-router-dom";

const WhichDay = () => {
  const [ShowDate, setShowDate] = useState();

  const history = useHistory();

  const Go = () => {
    const dt = moment(ShowDate).format("YYYYMMDD");
    sessionStorage.setItem("ShowDate", dt);
    history.push("/liveresults/selectshow");
  };

  return (
    <>
      <h2>Select date</h2>
      <p>We're faking the date. So what day do you want it to be?</p>
      <DatePicker
        selected={ShowDate}
        value={ShowDate}
        onChange={(e) => setShowDate(e)}
        dateFormat="dd/MM/yyyy"
      />
      <p>
        <button className="button button-green" onClick={Go}>
          Go
        </button>
      </p>
    </>
  );
};

export default WhichDay;
