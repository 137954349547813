import React, { useState, useEffect } from "react";
import { apiPost } from "../../../shared/api.js";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { Workbook } from "exceljs";
import { DownloadFile } from "../../../shared/clientsidefiledownload.js";
import AsyncSelect from "react-select/async";
import { apiGet } from "../../../shared/api";
import ModalContent from "../../../shared/ModalContent";

const UnknownBACS = () => {
  const [Data, setData] = useState([]);
  const [ShowModal, setShowModal] = useState(false);
  const [SelectedMember, setSelectedMember] = useState();
  const [TransactionId, setTransactionId] = useState();
  const [TransactionDetails, setTransactionDetails] = useState();
  const [Amount, setAmount] = useState(0);

  useEffect(() => {
    if (TransactionId) {
      const found = Data.find((t) => t.Id === TransactionId);
      if (found) {
        const details = JSON.parse(found.JSONData);
        setTransactionDetails(
          `${details.counterPartyName} - £${details.amount.minorUnits / 100}`
        );
        setAmount(details.amount.minorUnits / 100);
      } else {
        setTransactionDetails("");
      }
    }
  }, [TransactionId, Data]);

  //Gets suggested handler names from the server
  const GetHandlerSuggestions = async (val) => {
    try {
      const req = await apiGet(`/members/match/${val}`);
      if (req && req.ok && req.status === 200) {
        const res = await req.json();
        return res.map((itm) => {
          const displayString = `${itm.Id} - ${itm.FirstName} ${itm.LastName}`;
          return {
            value: itm.Id,
            label: displayString,
            name: `${itm.FirstName} ${itm.LastName}`,
          };
        });
      } else {
        return [];
      }
    } catch (error) {
      console.log(error.message);
      return [];
    }
  };

  const LoadData = async () => {
    const ERROR_MSG = "Error loading data from server. Micky support time";

    try {
      const req = await apiPost("/admin/wallet/reports/unknownbacs");
      if (req && req.ok && req.status === 200) {
        const res = await req.json();
        setData(res);
        return res;
      } else {
      }
    } catch (error) {
      console.log(error.message);
      window.alert(ERROR_MSG);
    }
  };

  //Excel output
  const GetExcel = async () => {
    try {
      const data = await LoadData();

      let wb = new Workbook();
      let ws = wb.addWorksheet("Credits");

      ws.addRow(["Date/Time", "Reference", "Amount", "Note"]);
      for (const itm of data) {
        const fullItem = JSON.parse(itm.JSONData);
        ws.addRow([
          moment(itm.DateTimeStamp).format("DD/MM/YYYY HH:mm"),
          fullItem.reference,
          fullItem.amount.minorUnits / 100,
          fullItem.counterPartyName,
        ]);
      }

      const buf = await wb.xlsx.writeBuffer();
      const blob = new Blob([buf], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      DownloadFile(blob, "UnknownBACS.xlsx");
    } catch (error) {
      console.log(error.message);
      window.alert("Error producing Excel sheet");
    }
  };

  const SelectTransaction = (Id) => {
    setTransactionId(Id);
    setShowModal(true);
  };

  const AssignTransaction = async () => {
    try {
      const obj = {
        Id: TransactionId,
        MemberId: SelectedMember.value,
        Amount,
      };

      const req = await apiPost("/admin/wallet/unknownbacs/assign", obj);
      setShowModal(false);
      if (req && req.ok) {
        LoadData();
      } else {
        window.alert("Error assigning transaction");
      }
    } catch (error) {
      console.log(error.message);
      window.alert("Error assigning transaction");
    }
  };

  //Summary
  const LineItems = Data.map((itm, idx) => {
    const fullItem = JSON.parse(itm.JSONData);

    return (
      <tr key={idx}>
        <td>{moment(itm.DateTimeStamp).format("DD/MM/YYYY HH:mm")}</td>
        <td>{fullItem.reference}</td>
        <td>£{fullItem.amount.minorUnits / 100}</td>
        <td>{fullItem.counterPartyName}</td>
        <td>
          <button
            className="button button-small"
            onClick={() => SelectTransaction(itm.Id)}
          >
            Assign
          </button>
        </td>
      </tr>
    );
  });

  return (
    <div className="card">
      <div className="card-header">Unknown BACS payments</div>
      <div className="card-body">
        {ShowModal ? (
          <ModalContent>
            <h3>Assign payment</h3>
            <p>
              <b>{TransactionDetails}</b>
            </p>
            <p>Please select the member you wish to assign this payment to:</p>
            <AsyncSelect
              onChange={(e) => setSelectedMember(e)}
              value={SelectedMember}
              loadOptions={(e) => GetHandlerSuggestions(e)}
            />
            <p className="mt-2x mb-2x">
              {SelectedMember ? (
                <button className="button button-green mr-1x" onClick={AssignTransaction}>
                  Assign
                </button>
              ) : (
                <></>
              )}
              <button className="button" onClick={() => setShowModal(false)}>
                Cancel
              </button>
            </p>
          </ModalContent>
        ) : (
          <></>
        )}

        <div className="mb-2x">
          <button className="button mr-2x" onClick={LoadData}>
            View Data
          </button>
          <button className="button mr-2x" onClick={GetExcel}>
            <FontAwesomeIcon icon={faFileExcel} /> Excel
          </button>
        </div>

        {Data.length > 0 ? (
          <>
            <table className="table mb-2x">
              <thead>
                <tr>
                  <th>Date/Time</th>
                  <th>Reference</th>
                  <th>Amount</th>
                  <th>Note</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>{LineItems}</tbody>
            </table>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="card-footer"></div>
    </div>
  );
};

export default UnknownBACS;
