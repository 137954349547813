import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { apiGet, apiPost } from "../../../shared/api";

const Edit = (props) => {
  const [Headline, setHeadline] = useState("");
  const [Article, setArticle] = useState("");
  const [Facebook, setFacebook] = useState(0);

  const history = useHistory();
  const { Id } = useParams();

  useEffect(() => {
    (async () => {
      if (parseInt(Id) !== 0) {
        try {
          const req = await apiGet(`/admin/news/${Id}`);
          const res = await req.json();
          setHeadline(res.Headline);
          setArticle(res.Article);
        } catch (error) {
          console.log(error.message);
          window.alert("Error loading article");
        }
      }
    })();
  }, [Id]);

  const SaveArticle = async () => {
    try {
      const obj = {
        Id,
        Headline,
        Article,
      };
      const req = await apiPost("/admin/news", obj);
      if (req && req.ok) {
        history.push("/admin/news");
      } else {
        window.alert("Error saving article");
      }
    } catch (error) {
      console.log(error.message);
      window.alert("Error saving article");
    }
  };

  return (
    <div className="card">
      <div className="card-header">Edit News Article</div>
      <div className="card-body">
        <div className="form-group">
          <label>Headline</label>
          <input
            type="text"
            value={Headline}
            className="form-control width-50"
            onChange={(e) => setHeadline(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Article</label>
          <textarea
            rows="8"
            cols="50"
            value={Article}
            className="form-control"
            onChange={(e) => setArticle(e.target.value)}
          ></textarea>
        </div>
        <div className="form-group">
          <input
            type="checkbox"
            value={Facebook}
            onChange={(e) => setFacebook(e.target.checked)}
          />{" "}
          Post to Facebook
        </div>
      </div>
      <div className="card-footer">
        <button className="button button-green mr-1x" onClick={SaveArticle}>
          <FontAwesomeIcon icon={faSave} className="mr-1x" />
          Save Article
        </button>
        <a href="/admin/news">Cancel</a>
      </div>
    </div>
  );
};

export default Edit;
