import React, { useState, useEffect } from "react";
import moment from "moment";
import numeral from "numeral";
import { apiGet } from "../../../shared/api";
import Modal from "../../../shared/ModalContent";

const InfoSheet = (props) => {
  const [InfoSheetData, setInfoSheetData] = useState([]);
  const [SingleRate, setSingleRate] = useState(0);
  const [MaxRuns, setMaxRuns] = useState(0);
  const [Packs, setPacks] = useState([]);
  const [AllData, setAllData] = useState([]);

  useEffect(() => {
    (async () => {
      let req, res;
      req = await apiGet(`/shows/infosheet/${props.Show.ShowId}`);
      res = await req.json();
      setSingleRate(res[1][0].SingleNightCampingPrice);
      setMaxRuns(res[1][0].MaxRuns);
      setPacks(res[2]);

      const data = res[0];
      //Get distinct dates
      const dtList = [...new Set(data.map((c) => c.ClassDate))];
      const output = [];
      for (const dt of dtList) {
        const firstClass = data.find((c) =>
          moment(c.ClassDate).isSame(moment(dt))
        );
        output.push({
          ClassDate: dt,
          Capped: firstClass.Capped,
          Classes: data.filter((c) => c.ClassDate === dt),
        });
      }

      setInfoSheetData(output);

      req = await apiGet(
        `/admin/shows/classes/listcombinations/${props.Show.ShowId}`
      );
      res = await req.json();
      setAllData(res);
    })();
  }, []);

  const ClassRows = InfoSheetData.map((itm, idx) => {
    return (
      <div key={idx}>
        <p>
          <b>
            {moment.utc(itm.ClassDate).format("ddd Do MMM YYYY")}
            {itm.Capped ? (
              <span className="text-red"> (This day has capped)</span>
            ) : (
              <></>
            )}
          </b>
        </p>
        <p>
          {itm.Classes.map((sub, subidx) => {
            return (
              <React.Fragment key={subidx}>
                <span className={sub.IsQualifier ? "text-bold" : ""}>
                  {sub.ClassLabel}
                  {subidx !== itm.Classes.length - 1 ? ", " : ""}
                </span>
              </React.Fragment>
            );
          })}
        </p>
      </div>
    );
  });

  const PackRows = Packs.map((itm) => {
    return (
      <tr key={itm.PackId}>
        <td>{itm.Description}</td>
        <td>£{numeral(itm.Price).format("0.00")}</td>
      </tr>
    );
  });

  return (
    <Modal>
      <h3>Venue</h3>
      <p className="mb-2x">
        {props.Show?.VenueName}, {props.Show?.VenueAddress},{" "}
        {props.Show?.VenuePostalCode}
      </p>
      {props.EntryData && (
        <>
          <h3>Entries</h3>
          <table className="table mb-1x" style={{ width: "75%" }}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Runs</th>
                <th>People</th>
              </tr>
            </thead>
            <tbody>
              {props.EntryData.RunData.map((itm, idx) => (
                <tr key={idx}>
                  <td data-mobilelabel="Date">
                    {moment.utc(itm.ShowDate).format("DD/MM/YYYY")}
                  </td>
                  <td data-mobilelabel="Runs">{itm.Runs}</td>
                  <td data-mobilelabel="People">{itm.People}</td>
                </tr>
              ))}
            </tbody>
            {props.EntryData.RunData.length > 0 && (
              <tfoot>
                <tr>
                  <td colSpan={3}>
                    <p>
                      <b>
                        Revenue: £
                        {props.EntryData.RunData[0].Revenue.toFixed(2)}
                      </b>
                    </p>
                  </td>
                </tr>
                {props.EntryData.RunData &&
                  props.EntryData.RunData.length > 0 && (
                    <tr>
                      <td>
                        <p>
                          <b className="mr-1x">Camping:</b> Camping cap:
                          {props.EntryData.RunData[0].CampingCap}
                          <br />
                          Campers so far:{" "}
                          {props.EntryData.RunData[0].PackCampers +
                            props.EntryData.RunData[0].SingleNightCampers}
                        </p>
                        <p>
                          <b className="mr-1x">Camping revenue:</b> £
                          {props.EntryData.RunData[0].CampingRevenue.toFixed(2)}
                        </p>
                      </td>
                    </tr>
                  )}
              </tfoot>
            )}
          </table>
        </>
      )}
      {(props.View === "Managers" || MaxRuns != 9999) && (
        <>
          <h3>Capping</h3>
          <p className="mb-2x">
            Maximum runs per day: <b>{MaxRuns}</b>
          </p>
        </>
      )}

      {(props.Show?.ClassesLive || props.View === "Managers") && (
        <>
          <h3>Classes</h3>
          <p className="text-blue mb-2x">
            <b>
              Prices: Pre-entry: £{props.Show?.PreEntryPrice?.toFixed(2)}{" "}
              {!props.Show.SingleEntryShow && (
                <>
                  Late entry: £{props.Show?.LateEntryPrice?.toFixed(2)} At show:
                  £{props.Show?.AtShowPrice?.toFixed(2)}
                </>
              )}
            </b>
          </p>
          {ClassRows}
          <br />

          <h3>Camping</h3>
          {SingleRate !== 0 ? (
            <p>
              Single night camping: £{numeral(SingleRate).format("0.00")}
              <br />
              <br />
            </p>
          ) : (
            <p>
              No single night camping available
              <br />
              <br />
            </p>
          )}
          {Packs.length > 0 ? (
            <>
              <p>
                <b>Camping packs</b>
              </p>
              <table className="table">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>{PackRows}</tbody>
              </table>
            </>
          ) : (
            <>
              <p>No camping packs available</p>
            </>
          )}
        </>
      )}

      {props.Show.DisplayNotes > "" && (
        <>
          <h3 className="mt-2x">Notes</h3>
          <p className="mb-2x">{props.Show.DisplayNotes}</p>
        </>
      )}

      <br />
      <p>
        <button className="button" onClick={() => props.onClose()}>
          Close
        </button>
      </p>
    </Modal>
  );
};

export default InfoSheet;
