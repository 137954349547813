import React, { useState, useEffect, useContext, useCallback } from "react";
import moment from "moment";
import { apiGet, apiPost } from "../../../../shared/api.js";
import numeral from "numeral";
import UserContext from "../../../../context/user/UserContext";
import InlineLoading from "../../../../shared/InlineLoading";
import Modal from "../../../../shared/ModalContent";

const SummaryCamping = (props) => {
  const [ShowAlter, setShowAlter] = useState(false);
  const [PackList, setPackList] = useState([]);
  const [Days, setDays] = useState([]);
  const [SingleNightRate, setSingleNightRate] = useState();
  const [CampingPackId, setCampingPackId] = useState("");
  const [SingleNightChoices, setSingleNightChoices] = useState([]);
  const [CampingString, setCampingString] = useState("");
  const [Loading, setLoading] = useState(false);
  const [ShowCappedModal, setShowCappedModal] = useState(false);
  const [CampingCapped, setCampingCapped] = useState(false);
  const [CampingClosed, setCampingClosed] = useState(false);

  const { User } = useContext(UserContext);

  const CanEdit = props.CanEdit;

  const LoadData = useCallback(async () => {
    try {
      setLoading(true);

      let req, res;

      //Camping nights
      req = await apiGet(`/shows/singlenight/list/${props.ShowId}`);
      res = await req.json();
      const dates = res.map((itm) => itm.CampingDate);
      setDays(dates);

      //Show details
      req = await apiGet(`/shows/${props.ShowId}`);
      res = await req.json();
      const singleNightRate = res.SingleNightCampingPrice;
      setSingleNightRate(singleNightRate);
      setCampingClosed(res.CampingClosed);

      //List of camping packs
      req = await apiGet(`/admin/shows/camping/packs/${props.ShowId}`);
      res = await req.json();
      const _packList = res;
      setPackList(_packList);

      //Show entry details
      req = await apiGet(`/shows/campingchoices/${props.ShowId}/${User.Id}`);
      res = await req.json();
      const row = res[0][0];
      const PackId = row.CampingPackId;
      if (PackId !== 0 && PackId !== null) {
        setCampingPackId(PackId);
        setCampingString(
          `You've chosen the '${row.Description}' pack at a price of £${numeral(
            row.Price
          ).format("0.00")}`
        );
      } else {
        setCampingString(
          res[1].length === 0
            ? _packList.length === 0 && singleNightRate === 0
              ? "No camping available"
              : "No camping selected"
            : `You've selected ${
                res[1].length
              } night(s) camping at a cost of £${numeral(
                res[1].length * singleNightRate
              ).format("0.00")}`
        );
      }

      const singleNights = [];
      for (const dt of res[1]) singleNights.push(dt.ShowDate);
      setSingleNightChoices(singleNights);

      const capLimit = res[2][0].CampingCap;
      const campers = res[2][0].Campers;
      setCampingCapped(capLimit > 0 && capLimit <= campers);

      setLoading(false);
    } catch (error) {
      console.log(error.message);
      window.alert("Error loading show data");
      setLoading(false);
    }
  }, [props.ShowId, User.Id]);

  useEffect(() => {
    LoadData();
  }, [LoadData]);

  const SaveChoices = async () => {
    try {
      let hasSaved = true;

      //Camping details
      let obj = {
        ShowId: props.ShowId,
        MemberId: User.Id,
        CampingType: CampingPackId !== "" ? 1 : 0,
        CampingPackId: CampingPackId !== "" ? CampingPackId : 0,
      };
      const req = await apiPost("/shows/campingchoices", obj);
      if (req && req.ok) {
        const res = await req.json();
        if (res.CampingOutcome === "Capped") {
          hasSaved = false;
          setShowCappedModal(true);
        }
      }

      //Single night choices
      obj = {
        ShowId: props.ShowId,
        MemberId: User.Id,
        Choices: CampingPackId !== "" ? [] : SingleNightChoices,
      };
      await apiPost("/shows/campingnightchoices", obj);

      LoadData();
      setShowAlter(false);

      if (hasSaved) props.onSave();
    } catch (error) {}
  };

  const SelectSingleNight = (dt, selected) => {
    let choices = Array.from(SingleNightChoices);
    if (selected) {
      choices.push(dt);
    } else {
      choices = choices.filter((c) => !moment(c).isSame(dt));
    }
    setSingleNightChoices(choices);
  };

  const CampingPackRows = PackList.map((itm) => (
    <option key={itm.PackId} value={itm.PackId}>
      {itm.Description}, £{numeral(itm.Price).format("0.00")}
    </option>
  ));

  const CampingNightChoices = Days.map((itm, idx) => {
    const found = SingleNightChoices.find((c) => moment(c).isSame(itm));
    const checked = found ? true : false;
    return (
      <span key={idx} className="mr-1x">
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) => SelectSingleNight(itm, e.target.checked)}
        />{" "}
        {moment(itm).format("ddd Do MMM")}
      </span>
    );
  });

  return (
    <div className="mb-3x">
      <h3>Camping choices</h3>

      {Loading ? <InlineLoading /> : <></>}

      <p>{CampingString}</p>
      {!ShowAlter &&
        CanEdit &&
        !props.IsClosed &&
        !CampingClosed &&
        !CampingCapped &&
        (PackList.length > 0 || SingleNightRate > 0) && (
          <button className="button" onClick={() => setShowAlter(true)}>
            Alter my choices
          </button>
        )}

      {CampingClosed && (
        <p>Camping is closed for this show and your choice cannot be altered</p>
      )}
      {CampingCapped && (
        <p>Camping is capped for this show and your choice cannot be altered</p>
      )}
      {ShowCappedModal && (
        <Modal>
          <h3>Sorry, camping is capped for this show</h3>
          <p>
            You cannot select a camping pack as camping has now capped for this
            show.
          </p>
          <button
            className="button button-blue"
            onClick={() => setShowCappedModal(false)}
          >
            OK
          </button>
        </Modal>
      )}
      {ShowAlter ? (
        <>
          <div className="mt-3x">
            {PackList.length !== 0 ? (
              <>
                <h4>Camping Packs</h4>
                <div className="form-group">
                  <label>Select a camping pack</label>
                  <select
                    className="form-control width-50"
                    value={CampingPackId}
                    onChange={(e) => setCampingPackId(e.target.value)}
                  >
                    <option value="">No camping pack</option>
                    {CampingPackRows}
                  </select>
                </div>
              </>
            ) : (
              <></>
            )}
            {SingleNightRate > 0 ? (
              <>
                <h4>Per-night camping</h4>
                <p>
                  Camping is charged at £
                  {numeral(SingleNightRate).format("0.00")} / night
                </p>
                <p>{CampingNightChoices}</p>
              </>
            ) : (
              <></>
            )}
            {CanEdit ? (
              <p className="mt-3x">
                <button className="button button-green" onClick={SaveChoices}>
                  Save choices
                </button>
              </p>
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SummaryCamping;
