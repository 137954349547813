import React, { useState, useEffect } from "react";
import { apiGet, apiPost } from "../../../shared/api";

const SpecialClass = ({ ClassId }) => {
  const [ClassLabel, setClassLabel] = useState("");
  const [RunCost, setRunCost] = useState(1);
  const [LateCost, setLateCost] = useState(1);
  const [AtShowCost, setAtShowCost] = useState(1);
  const [SaveMessage, setSaveMessage] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const req = await apiGet(`/admin/classes/${ClassId}`);
        const res = await req.json();
        setClassLabel(res.ClassLabel);
        setRunCost(res.DefaultRunCost);
        setLateCost(res.DefaultOnlineLateCost);
        setAtShowCost(res.DefaultAtShowLateCost);
      } catch (error) {
        window.alert("Error loading data for class");
      }
    })();
  }, []);

  const Save = async () => {
    try {
      setSaveMessage("Saving...");
      const obj = {
        RunCost,
        LateCost,
        AtShowCost,
      };
      const req = await apiPost(`/admin/classes/${ClassId}`, obj);
      if (req && req.ok) {
        setSaveMessage("Saved!");
      } else {
        setSaveMessage("Error saving data");
      }
    } catch (error) {
      console.log(error.message);
      setSaveMessage("Error saving data");
    } finally {
      setTimeout(() => {
        setSaveMessage("");
      }, 3000);
    }
  };

  return (
    <div style={{ marginBottom: 20 }}>
      <div>
        <b>{ClassLabel}</b>
      </div>
      <table>
        <tbody>
          <tr>
            <td>
              <label>Pre-Entry:</label>
              <input
                type="number"
                value={RunCost}
                onChange={(e) => setRunCost(e.target.value)}
                min={1}
                max={99}
                step={0.01}
                className="form-control width-50"
              />
            </td>
            <td>
              <label>Online Late:</label>
              <input
                type="number"
                value={LateCost}
                onChange={(e) => setLateCost(e.target.value)}
                min={1}
                max={99}
                step={0.01}
                className="form-control width-50"
              />
            </td>
            <td>
              <label>At-Show Late:</label>
              <input
                type="number"
                value={AtShowCost}
                onChange={(e) => setAtShowCost(e.target.value)}
                min={1}
                max={99}
                step={0.01}
                className="form-control width-50"
              />
            </td>
            <td>
              <p>
                <br />
                {SaveMessage === "" && (
                  <button className="button button-green" onClick={Save}>
                    Save
                  </button>
                )}
                {SaveMessage > "" && <span>{SaveMessage}</span>}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SpecialClass;
