import { StyleSheet } from "@react-pdf/renderer";

const CommonStyles = StyleSheet.create({
  documentHeadingCell: {
    flex: 1,
  },
  documentHeadingText: {
    fontSize: 14,
  },
  bold: {
    fontStyle: "bold",
    fontSize: 12,
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  cell: {
    fontSize: 10,
    paddingRight: 2,
  },
  memberName: {
    fontSize: 12,
  },
});

export default CommonStyles;
