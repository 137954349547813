import React,{useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import {apiPostFile} from '../../../shared/api.js';
import InlineLoading from '../../../shared/InlineLoading';


function UploadResults(props) {

    const [File, setFile] = useState(undefined);
    const [Uploading, setUploading] = useState(false);
    const [Data, setData] = useState([]);


    const doFileUpload = async() => {

        try {
            const data = new FormData();
            data.append('file',File);

            setUploading(true);

            const req = await apiPostFile('/admin/shows/uploadresults', data);
            if(req && req.ok && req.status === 201) {
                const res = await req.json();
                setData(res);
            }
                
            setUploading(false);

        }
        catch (error) {
            console.log(error.message);
            window.alert('Error uploading data');
            setUploading(false);
        }

    }


    const Output = Data.map((itm,idx) => {
        return <p key={idx}>Row {itm.RowNumber}: {itm.Message}</p>
    });


    return (
        <div className="card">
            <div className="card-header">
                <h4>Upload Show Results</h4>
            </div>
            <div className="card-body">

                <p className="mt-1x mb-1x">Select an Excel sheet from your computer and click 'Upload Results' to send to the server</p>
                <p className="mb-2x"><input type="file" onChange={e => setFile(e.target.files[0])}></input></p>
                {
                    Uploading ? 
                    <InlineLoading Message="Upload in progress" />
                    :
                    <p>
                        <button onClick={doFileUpload} className="button button-green"><FontAwesomeIcon icon={faFileUpload} className="mr-1x" />Upload</button>
                    </p>
                }
                <br /><br />
                {
                    Output
                }
            </div>
        </div>
    );

}

export default UploadResults;