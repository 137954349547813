import { Workbook } from "exceljs";
import { DownloadFile } from "../../../shared/clientsidefiledownload";

const PaymentBreakdownExcel = async ({ transactions }) => {
  try {
    let items = {
      Late: { Amount: 0, Count: 0 },
      Masters: { Amount: 0, Count: 0 },
      Handler: { Amount: 0, Count: 0 },
      Dog: { Amount: 0, Count: 0 },
      Renewal: { Amount: 0, Count: 0 },
    };

    let amounts = {
      Cash: { Amount: 0, Count: 0 },
      Cheque: { Amount: 0, Count: 0 },
      Card: { Amount: 0, Count: 0 },
      Complimentary: { Amount: 0, Count: 0 },
    };

    let splits = {
      Late: { Show: 0, UKA: 0 },
      Masters: { Show: 0, UKA: 0 },
      Handler: { Show: 0, UKA: 0 },
      Dog: { Show: 0, UKA: 0 },
      Renewal: { Show: 0, UKA: 0 },
    };

    //Items counts
    let total;

    //Lates
    let qualifying = transactions.filter((t) => t.RunIds?.length > 0);
    items.Late.Count = qualifying.reduce(
      (acc, itm) => (acc += itm.RunIds?.length || 0),
      0
    );
    items.Late.Amount = qualifying.reduce((acc, itm) => {
      return (acc += itm.List.reduce((subAcc, subItm) => {
        return (subAcc +=
          subItm.Type === "OnlineLateFees" || subItm.Type === "AtShowLateFees"
            ? subItm.Amount
            : 0);
      }, 0));
    }, 0);
    splits.Late.UKA = items.Late.Count * 1.4;
    splits.Late.Show = items.Late.Amount - splits.Late.UKA;

    //Masters
    qualifying = transactions.filter((t) => t.MastersRunIds?.length > 0);
    items.Masters.Count = transactions.reduce(
      (acc, itm) => (acc += itm.MastersRunIds?.length || 0),
      0
    );
    total = qualifying.reduce((acc, itm) => {
      return (acc += itm.List.reduce((subAcc, subItm) => {
        return (subAcc +=
          subItm.Type === "MastersLateFees" ? subItm.Amount : 0);
      }, 0));
    }, 0);
    items.Masters.Amount = total;
    splits.Masters.UKA = items.Masters.Count * 3.55;
    splits.Masters.Show = items.Masters.Amount - splits.Masters.UKA;

    //Member reg
    qualifying = transactions.filter(
      (t) => t.List.filter((l) => l.Type === "MemberReg").length > 0
    );
    items.Handler.Count = qualifying.reduce(
      (acc, itm) =>
        (acc += itm.List.filter((i) => i.Type === "MemberReg").length),
      0
    );
    total = qualifying.reduce((acc, itm) => {
      return (acc += itm.List.reduce((subAcc, subItm) => {
        return (subAcc += subItm.Type === "MemberReg" ? subItm.Amount : 0);
      }, 0));
    }, 0);
    items.Handler.Amount = total;
    splits.Handler.UKA = total;

    //Dog reg
    qualifying = transactions.filter(
      (t) => t.List.filter((l) => l.Type === "DogReg").length > 0
    );
    items.Dog.Count = qualifying.reduce(
      (acc, itm) => (acc += itm.List.filter((i) => i.Type === "DogReg").length),
      0
    );
    total = qualifying.reduce((acc, itm) => {
      return (acc += itm.List.reduce((subAcc, subItm) => {
        return (subAcc += subItm.Type === "DogReg" ? subItm.Amount : 0);
      }, 0));
    }, 0);
    items.Dog.Amount = total;
    splits.Dog.UKA = total;

    //Renewal reg
    qualifying = transactions.filter(
      (t) => t.List.filter((l) => l.Type === "Renewal").length > 0
    );
    items.Renewal.Count = qualifying.reduce(
      (acc, itm) =>
        (acc += itm.List.filter((i) => i.Type === "Renewal").length),
      0
    );
    total = qualifying.reduce((acc, itm) => {
      return (acc += itm.List.reduce((subAcc, subItm) => {
        return (subAcc += subItm.Type === "Renewal" ? subItm.Amount : 0);
      }, 0));
    }, 0);
    items.Renewal.Amount = total;
    splits.Renewal.UKA = total;

    //Amounts
    let methods = ["Cash", "Cheque", "Card", "Complimentary"];
    for (const method of methods) {
      qualifying = transactions.filter(
        (t) => t.Payments.filter((p) => p.PaymentMethod === method).length > 0
      );
      amounts[method].Amount = qualifying.reduce((acc, itm) => {
        const total = itm.Payments.reduce((subAcc, subItm) => {
          return (subAcc +=
            subItm.PaymentMethod === method ? subItm.Amount : 0);
        }, 0);
        return (acc += total);
      }, 0);
      amounts[method].Count = qualifying.length;
    }

    //Quick stats
    const totalCard = transactions.reduce(
      (acc, itm) =>
        (acc += itm.Payments.reduce(
          (subacc, subitm) =>
            subitm.PaymentMethod === "Card"
              ? (subacc += subitm.Amount)
              : subacc,
          0
        )),
      0
    );

    const totalCheque = transactions.reduce(
      (acc, itm) =>
        (acc += itm.Payments.reduce(
          (subacc, subitm) =>
            subitm.PaymentMethod === "Cheque"
              ? (subacc += subitm.Amount)
              : subacc,
          0
        )),
      0
    );

    const totalChequeCount = transactions.reduce(
      (acc, itm) =>
        (acc += itm.Payments.reduce(
          (subacc, subitm) =>
            subitm.PaymentMethod === "Cheque" ? (subacc += 1) : subacc,
          0
        )),
      0
    );

    const totalComp = transactions.reduce(
      (acc, itm) =>
        (acc += itm.Payments.reduce(
          (subacc, subitm) =>
            subitm.PaymentMethod === "Complimentary"
              ? (subacc += subitm.Amount)
              : subacc,
          0
        )),
      0
    );

    const totalCash = transactions.reduce(
      (acc, itm) =>
        (acc += itm.Payments.reduce(
          (subacc, subitm) =>
            subitm.PaymentMethod === "Cash"
              ? (subacc += subitm.Amount)
              : subacc,
          0
        )),
      0
    );

    //Excel starts here
    const wb = new Workbook();
    let ws;
    ws = wb.addWorksheet("Quick");
    //ws.getCell("B3").value = "This is for TODAY only";
    ws.getCell("B4").value = "Comp";
    ws.getCell("C4").value = totalComp;
    ws.getCell("B5").value = "Cash";
    ws.getCell("C5").value = totalCash;
    ws.getCell("B6").value = "Card";
    ws.getCell("C6").value = totalCard;
    ws.getCell("B7").value = "Cheque";
    ws.getCell("C7").value = totalCheque;
    ws.getCell("B8").value = "Total number of cheques";
    ws.getCell("C8").value = totalChequeCount;

    ws = wb.addWorksheet("Details");

    ws.getCell("B3").value = "Items";
    ws.getCell("B4").value = "Item";
    ws.getCell("C4").value = "Number";
    ws.getCell("D4").value = "Amount";
    ws.getCell("B5").value = "Late";
    ws.getCell("C5").value = items.Late.Count;
    ws.getCell("D5").value = items.Late.Amount;
    ws.getCell("B6").value = "Masters";
    ws.getCell("C6").value = items.Masters.Count;
    ws.getCell("D6").value = items.Masters.Amount;
    ws.getCell("B7").value = "Handler Reg";
    ws.getCell("C7").value = items.Handler.Count;
    ws.getCell("D7").value = items.Handler.Amount;
    ws.getCell("B8").value = "Dog Reg";
    ws.getCell("C8").value = items.Dog.Count;
    ws.getCell("D8").value = items.Dog.Amount;
    ws.getCell("B9").value = "Handler Renewal";
    ws.getCell("C9").value = items.Renewal.Count;
    ws.getCell("D9").value = items.Renewal.Amount;

    ws.getCell("B11").value = "Payment Amounts";
    ws.getCell("B12").value = "Type";
    ws.getCell("C12").value = "Amount";
    ws.getCell("D12").value = "Number of Transactions";
    ws.getCell("B13").value = "Cash";
    ws.getCell("C13").value = amounts.Cash.Amount;
    ws.getCell("D13").value = amounts.Cash.Count;
    ws.getCell("B14").value = "Cheque";
    ws.getCell("C14").value = amounts.Cheque.Amount;
    ws.getCell("D14").value = amounts.Cheque.Count;
    ws.getCell("B15").value = "Card";
    ws.getCell("C15").value = amounts.Card.Amount;
    ws.getCell("D15").value = amounts.Card.Count;
    ws.getCell("B16").value = "Comp";
    ws.getCell("C16").value = amounts.Complimentary.Amount;
    ws.getCell("D16").value = amounts.Complimentary.Count;
    ws.getCell("B17").value = "Total";
    ws.getCell("C17").value =
      amounts.Cash.Amount +
      amounts.Cheque.Amount +
      amounts.Card.Amount +
      amounts.Complimentary.Amount;
    ws.getCell("D17").value =
      amounts.Cash.Count +
      amounts.Card.Count +
      amounts.Cheque.Count +
      amounts.Complimentary.Count;

    ws.getCell("B19").value = "Show / A4A Split";
    ws.getCell("B20").value = "Item";
    ws.getCell("C20").value = "Show";
    ws.getCell("D20").value = "A4A";
    ws.getCell("E20").value = "Total";
    ws.getCell("B21").value = "Late";
    ws.getCell("C21").value = splits.Late.Show;
    ws.getCell("D21").value = splits.Late.UKA;
    ws.getCell("E21").value = splits.Late.Show + splits.Late.UKA;
    ws.getCell("B22").value = "Masters";
    ws.getCell("C22").value = splits.Masters.Show;
    ws.getCell("D22").value = splits.Masters.UKA;
    ws.getCell("E22").value = splits.Masters.Show + splits.Masters.UKA;
    ws.getCell("B23").value = "Handler Reg";
    ws.getCell("C23").value = splits.Handler.Show;
    ws.getCell("D23").value = splits.Handler.UKA;
    ws.getCell("E23").value = splits.Handler.Show + splits.Handler.UKA;
    ws.getCell("B24").value = "Dog Reg";
    ws.getCell("C24").value = splits.Dog.Show;
    ws.getCell("D24").value = splits.Dog.UKA;
    ws.getCell("E24").value = splits.Dog.Show + splits.Dog.UKA;
    ws.getCell("B25").value = "Handler Renewal";
    ws.getCell("C25").value = splits.Renewal.Show;
    ws.getCell("D25").value = splits.Renewal.UKA;
    ws.getCell("E25").value = splits.Renewal.Show + splits.Renewal.UKA;
    ws.getCell("B26").value = "Total";
    ws.getCell("C26").value =
      splits.Late.Show +
      splits.Masters.Show +
      splits.Handler.Show +
      splits.Dog.Show +
      splits.Renewal.Show;
    ws.getCell("D26").value =
      splits.Late.UKA +
      splits.Masters.UKA +
      splits.Handler.UKA +
      splits.Dog.UKA +
      splits.Renewal.UKA;
    ws.getCell("E26").value =
      splits.Late.Show +
      splits.Masters.Show +
      splits.Handler.Show +
      splits.Dog.Show +
      splits.Renewal.Show +
      splits.Late.UKA +
      splits.Masters.UKA +
      splits.Handler.UKA +
      splits.Dog.UKA +
      splits.Renewal.UKA;

    ws.getCell("B28").value = `Total Cash to send to A4A: £${(
      splits.Late.UKA +
      splits.Masters.UKA +
      splits.Handler.UKA +
      splits.Dog.UKA +
      splits.Renewal.UKA
    ).toFixed(2)}`;
    ws.getCell("B29").value = `Cash for show manager: £${
      amounts.Cash.Amount -
      (
        splits.Late.UKA +
        splits.Masters.UKA +
        splits.Handler.UKA +
        splits.Dog.UKA +
        splits.Renewal.UKA
      ).toFixed(2)
    }`;

    const buf = await wb.xlsx.writeBuffer();
    const blob = new Blob([buf], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    DownloadFile(blob, "Data.xlsx");
  } catch (error) {
    return null;
  }
};

export default PaymentBreakdownExcel;
