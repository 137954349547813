import React, { useContext } from "react";
import UserContext from "../../context/user/UserContext";
import Login from "../login/login";
import Members from "../members/members";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import LoginAsUser from "../admin/loginasuser";
import ResetPassword from "../login/resetpassword";
import LiveResults from "../liveresults/liveresults";

function Content(props) {
  const { User } = useContext(UserContext);
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/adminlogin/:UserGuid">
          <LoginAsUser />
        </Route>
        <Route exact path="/resetpassword/:ResetKey">
          <ResetPassword />
        </Route>
        <Route path="/liveresults">
          <LiveResults />
        </Route>
        <Route>
          <div className="container">
            {User.Id === 0 ? <Login /> : <Members />}
          </div>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default Content;
