import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { apiGet, apiPost } from "../../../shared/api";
import Modal from "../../../shared/ModalContent";

const DogTransfer = (props) => {
  const [DogName, setDogName] = useState();
  const [MemberName, setMemberName] = useState();
  const [TargetMember, setTargetMember] = useState();
  const [ShowModal, setShowModal] = useState(false);
  const { Id, MemberId } = useParams();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      if (Id) {
        try {
          const req = await apiGet(`/dogs/${Id}`);
          const res = await req.json();
          setDogName(res.PetName);
        } catch (error) {
          console.log(error.message);
          window.alert("Error loading dog");
        }
      }
    })();
  }, [Id]);

  useEffect(() => {
    if (ShowModal) {
      setTimeout(() => {
        history.push(`/admin/member/${MemberId}`);
      }, 3000);
    }
  }, [ShowModal, history, MemberId]);

  useEffect(() => {
    (async () => {
      if (MemberId) {
        try {
          const req = await apiGet(`/members/account/${MemberId}`);
          const res = await req.json();
          setMemberName(`${res.FirstName} ${res.LastName}`);
        } catch (error) {
          console.log(error.message);
          window.alert("Error loading member");
        }
      }
    })();
  }, [MemberId]);

  const GetHandlerSuggestions = async (val) => {
    try {
      const req = await apiGet(`/members/match/${val}`);
      if (req && req.ok && req.status === 200) {
        let res = await req.json();
        return res.map((itm) => {
          const displayString = `${itm.Id} - ${itm.FirstName} ${itm.LastName}`;
          return { value: itm.Id, label: displayString };
        });
      } else {
        return [];
      }
    } catch (error) {
      console.log(error.message);
      return [];
    }
  };

  const TransferDog = async () => {
    try {
      const obj = {
        DogId: Id,
        MemberId: TargetMember.value,
      };
      const req = await apiPost("/dogs/transfer", obj);
      if (req && req.ok) {
        setShowModal(true);
      } else {
        window.alert("Error transferring dog");
      }
    } catch (error) {
      window.alert("Error transferring dog");
    }
  };

  return (
    <div className="card">
      <div className="card-header">Dog Transfer</div>
      <div className="card-body">
        {ShowModal && (
          <Modal>
            <h3>
              <FontAwesomeIcon icon={faCheck} className="text-green mr-1x" />
              Dog transfer complete
            </h3>
          </Modal>
        )}
        <p>
          You are transferring {DogName} ({Id}) belonging to {MemberName}
        </p>
        <div className="form-group">
          <label>Transfer to</label>
          <AsyncSelect
            value={TargetMember}
            onChange={(e) => setTargetMember(e)}
            loadOptions={GetHandlerSuggestions}
          />
        </div>
        {TargetMember && (
          <p>
            <button className="button button-green" onClick={TransferDog}>
              Transfer dog
            </button>
          </p>
        )}
      </div>
    </div>
  );
};

export default DogTransfer;
