import React from 'react';
import RegProgress from './regprogress';
import RegistrationContextProvider from '../../context/registration/RegistrationContextProvider';
import RegSteps from './regsteps';


function Signup(props) {

    return (
        <RegistrationContextProvider>
            <div className="card">
                <div className="card-header">
                    Member Registration
                </div>
                <div className="card-body">

                    <div className="grid pt-2x">
                        <div className="col-2">
                            <RegProgress />
                        </div>
                        <div className="col-8 pl-5x">
                            <RegSteps />
                        </div>
                    </div>

                </div>
                <div className="card-footer">
                    <button className="button button-link" onClick={() => props.onCancel()}>Cancel</button>
                </div>
            </div>
        </RegistrationContextProvider>
    )

}


export default Signup;