import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import TimerBar from "./timerbar/timerbar";
import MembersBar from "./membersbar/membersbar";
import Navbar from "./navbar/navbar";
import Home from "./home/home";
import Dogs from "./dogs/dogs";
import AddDog from "./dogs/adddog";
import EditDog from "./dogs/editdog";
import Account from "./account/account";
import Wallet from "./wallet/wallet";
import Results from "./results/results";
import DogResults from "./results/dog";
import ShowResults from "./results/show";
import HandlerResults from "./results/handler";
import Admin from "../admin/admin";
import Entries from "./entries/entries";
import Shows from "./shows/shows";
import AdminRoute from "../../shared/AdminRoute";
import Emails from "./emails/emails";
import EntryPreferences from "./shows/entrypreferences";
import SCEntry from "./shows/singleclickentries/entry";
import SCMyEntry from "./shows/singleclickentries/myentry";
import Entry from "./shows/entries/entry";
import MyEntry from "./shows/entries/myentry";
import ShowManagers from "./showmanagers/showmanagers";
import ShowManagersProcess from "./showmanagers/process";
import ShowManagersProcessDocuments from "./showmanagers/documents";
import ShowManagersTransactions from "./showmanagers/transactions";
import ShowAuthorisations from "./showmanagers/managerauth";
import Documents from "./entries/documents";
import RingEditor from "../admin/shows/processing/ringeditor";
import ExcelProcess from "../admin/shows/processing/excelprocess";
import ExcelLatesProcess from "../admin/shows/processing/excellatesprocess";
import PINCodes from "./showmanagers/pins";
import RingOverview from "./shows/ringoverview";
import JuniorLeague from "./results/juniorleague";
import LiveResults from "./liveresults/liveresults";
import League from "./leagues/league";

function Members(props) {
  return (
    <BrowserRouter>
      <TimerBar />
      <MembersBar />
      <Navbar />
      <div className="sub-container">
        <Switch>
          <Route path="/members/liveresults">
            <LiveResults />
          </Route>
          <Route exact path="/account">
            <Account />
          </Route>
          <Route exact path="/entries">
            <Entries />
          </Route>
          <Route exact path="/emails">
            <Emails />
          </Route>
          <Route exact path="/shows">
            <Shows />
          </Route>
          <Route exact path="/shows/entrypreferences">
            <EntryPreferences />
          </Route>
          <Route path="/shows/entry/:ShowId">
            <Entry />
          </Route>
          <Route path="/shows/scentry/:ShowId">
            <SCEntry />
          </Route>
          <Route path="/shows/documents/:ShowId">
            <Documents />
          </Route>
          <Route path="/shows/myentry/:ShowId">
            <MyEntry />
          </Route>
          <Route path="/shows/scmyentry/:ShowId">
            <SCMyEntry />
          </Route>
          <Route path="/shows/ringoverview/:ShowId">
            <RingOverview />
          </Route>
          <Route exact path="/dogs">
            <Dogs />
          </Route>
          <Route exact path="/dogs/add">
            <AddDog />
          </Route>
          <Route exact path="/dogs/edit/:Id">
            <EditDog />
          </Route>
          <Route exact path="/wallet">
            <Wallet />
          </Route>
          <AdminRoute path="/admin">
            <Admin />
          </AdminRoute>
          <Route exact path="/results">
            <Results />
          </Route>
          <Route exact path="/showmanagers">
            <ShowManagers />
          </Route>
          <Route exact path="/showmanagers/process/:Id">
            <ShowManagersProcess />
          </Route>
          <Route exact path="/showmanagers/pincodes/:Id">
            <PINCodes />
          </Route>
          <Route exact path="/showmanagers/transactions/:Id">
            <ShowManagersTransactions />
          </Route>
          <Route exact path="/showmanagers/ringeditor/:Id">
            <RingEditor />
          </Route>
          <Route exact path="/showmanagers/excelprocess/:Id">
            <ExcelProcess />
          </Route>
          <Route exact path="/showmanagers/excellatesprocess/:Id">
            <ExcelLatesProcess />
          </Route>
          <Route exact path="/showmanagers/documents/:Id">
            <ShowManagersProcessDocuments />
          </Route>
          <Route exact path="/showmanagers/authorisations/:Id">
            <ShowAuthorisations />
          </Route>
          <Route path="/results/dog/:Id">
            <DogResults />
          </Route>
          <Route path="/results/show/:Id">
            <ShowResults />
          </Route>
          <Route path="/results/handler">
            <HandlerResults />
          </Route>
          <Route path="/results/juniorleague">
            <JuniorLeague />
          </Route>
          <Route path="/league">
            <League />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default Members;
