import React, { useState, useEffect } from "react";
import UserContext from "./UserContext.js";
import moment from "moment";

const UserContextProvider = (props) => {
  //Updates the user account to reflect the successfully authenticated user
  const SetUser = (user) => {
    setState((prevState) => {
      return { ...prevState, ...user };
    });
  };

  //Removes the logged in user from the context and destroys the browser's sessionStorage item
  //Will punt user back to the login screen
  const Logout = () => {
    sessionStorage.removeItem("User");
    setState(defaultState);
  };

  //Default state
  const defaultState = {
    User: {
      Id: 0,
      FirstName: "",
      LastName: "",
      Email: "",
      Expired: undefined,
    },
    SetUser,
    Logout,
  };

  const [state, setState] = useState(defaultState);

  //First time the browser loads, it checks the sessionStorage to see if a User object exists
  //Context doesn't survive a browser refresh event so data is cached and loaded if found.
  useEffect(() => {
    try {
      //Check to see if user is in browser session storage
      let UserItem = sessionStorage.getItem("User");
      if (UserItem) {
        UserItem = JSON.parse(UserItem);
        let foundUser = {
          User: {
            Id: UserItem.Id,
            FirstName: UserItem.FirstName,
            LastName: UserItem.LastName,
            Email: UserItem.Email,
            Expired: moment().isAfter(
              moment(UserItem.DateExpires).add(1, "days")
            ),
          },
        };
        foundUser.AuthToken = UserItem.AuthToken;
        setState((prevState) => {
          return { ...prevState, ...foundUser };
        });
      }
    } catch (error) {}
  }, []);

  return (
    <UserContext.Provider value={state}>{props.children}</UserContext.Provider>
  );
};

export default UserContextProvider;
