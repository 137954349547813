import React, { useState, useEffect } from "react";
import { apiPost } from "../../../shared/api.js";
import { GetPaymentMethod } from "../../../shared/wallet.js";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { Workbook } from "exceljs";
import { DownloadFile } from "../../../shared/clientsidefiledownload.js";

function FeesReport(props) {
  const [Data, setData] = useState({
    HandlerFees: [],
    RenewalFees: [],
    DogFees: [],
  });
  const [StartDate, setStartDate] = useState(new Date());

  const LoadData = async () => {
    const ERROR_MSG = "Error loading data from server. Micky support time";

    let output = {
      DogFees: [],
      HandlerFees: [],
      RenewalFees: [],
    };

    try {
      let req, res;

      const data = {
        StartDate: moment(StartDate).format("DD/MM/YYYY"),
      };

      //Dog fees
      req = await apiPost("/admin/wallet/reports/dogfees", data);
      if (req && req.ok && req.status === 200) {
        res = await req.json();
        output.DogFees = res;
      } else {
        window.alert(ERROR_MSG);
      }

      //Handler fees
      req = await apiPost("/admin/wallet/reports/memberfees", data);
      if (req && req.ok && req.status === 200) {
        res = await req.json();
        output.HandlerFees = res;
      } else {
        window.alert(ERROR_MSG);
      }

      //Handler renewal fees
      req = await apiPost("/admin/wallet/reports/memberrenewals", data);
      if (req && req.ok && req.status === 200) {
        res = await req.json();
        output.RenewalFees = res;
      } else {
        window.alert(ERROR_MSG);
      }

      return output;
    } catch (error) {
      console.log(error.message);
      window.alert(ERROR_MSG);
    }
  };

  const DisplayData = async () => {
    const ERROR_MSG = "Error loading data from server. Micky support time";

    try {
      const data = await LoadData();
      setData(data);
    } catch (error) {
      console.log(error.message);
      window.alert(ERROR_MSG);
    }
  };

  const GetExcel = async () => {
    try {
      const data = await LoadData();

      let wb = new Workbook();
      let ws = wb.addWorksheet("All Fees");

      let total = 0;
      let overallTotal = 0;

      //Handler registration
      ws.addRow(["Date", "Id", "Name", "Description", "Amount"]);
      for (const itm of data.HandlerFees) {
        ws.addRow([
          `${moment(itm.DateTimeStamp).format("DD/MM/YYYY")}`,
          `${itm.Id}`,
          `${itm.FirstName} ${itm.LastName}`,
          `${itm.Detail}`,
          itm.Amount,
        ]); //, `${GetPaymentMethod(itm.Method)}`]);
        total += itm.Amount;
        overallTotal += itm.Amount;
      }
      ws.addRow(["", "", "", "", total]);
      total = 0;
      ws.addRow([]);
      ws.addRow([]);

      //Handler renewals
      for (const itm of data.RenewalFees) {
        ws.addRow([
          `${moment(itm.DateTimeStamp).format("DD/MM/YYYY")}`,
          `${itm.Id}`,
          `${itm.FirstName} ${itm.LastName}`,
          `${itm.Detail}`,
          itm.Amount,
        ]); //, `${GetPaymentMethod(itm.Method)}`]);
        total += itm.Amount;
        overallTotal += itm.Amount;
      }
      ws.addRow(["", "", "", "", total]);
      total = 0;
      ws.addRow([]);
      ws.addRow([]);

      //Dog registrations
      ws.addRow([
        "Date",
        "Dog Id",
        "Name",
        "Member Id",
        "Member Name",
        "Amount",
      ]);
      for (const itm of data.DogFees) {
        ws.addRow([
          `${moment(itm.DateTimeStamp).format("DD/MM/YYYY")}`,
          `${itm.DogId}`,
          `${itm.PetName}`,
          `${itm.MemberId}`,
          `${itm.FirstName} ${itm.LastName}`,
          itm.Amount,
        ]);
        total += itm.Amount;
        overallTotal += itm.Amount;
      }
      ws.addRow(["", "", "", "", "", total]);
      ws.addRow(["", "", "", "", "", overallTotal]);

      const buf = await wb.xlsx.writeBuffer();
      const blob = new Blob([buf], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      DownloadFile(blob, "AllFees.xlsx");
    } catch (error) {
      console.log(error.message);
      window.alert("Error producing Excel sheet");
    }
  };

  return (
    <div className="card">
      <div className="card-header">Fees Report</div>
      <div className="card-body">
        <div className="mb-1x">
          <span className="mr-2x">Start Date: </span>
          <DatePicker
            maxDate={new Date()}
            showMonthDropdown
            showYearDropdown
            className="form-control width-50 mr-2x"
            dateFormat="dd/MM/yyyy"
            value={StartDate}
            selected={StartDate}
            onChange={(val) => setStartDate(val)}
          ></DatePicker>
        </div>
        <div className="mb-2x">
          <button className="button mr-2x" onClick={DisplayData}>
            View Data
          </button>
          <button className="button" onClick={GetExcel}>
            <FontAwesomeIcon
              icon={faFileExcel}
              className="mr-1x"
            ></FontAwesomeIcon>
            Get Excel
          </button>
        </div>

        {Data.HandlerFees.length > 0 && (
          <>
            <h3>Handler Registration</h3>
            <table className="mb-2x">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {Data.HandlerFees.map((itm, idx) => {
                  return (
                    <tr>
                      <td>{moment(itm.DateTimeStamp).format("DD/MM/YYYY")}</td>
                      <td>{itm.Id}</td>
                      <td>
                        {itm.FirstName} {itm.LastName}
                      </td>
                      <td>{itm.Detail}</td>
                      <td>£{itm.Amount}</td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="4"></td>
                  <td>
                    £
                    {Data.HandlerFees.reduce((acc, itm) => {
                      return acc + itm.Amount;
                    }, 0)}
                  </td>
                </tr>
              </tfoot>
            </table>
          </>
        )}

        {Data.RenewalFees.length > 0 && (
          <>
            <h3>Handler Renewals</h3>
            <table className="mb-2x">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {Data.RenewalFees.map((itm, idx) => {
                  return (
                    <tr>
                      <td>{moment(itm.DateTimeStamp).format("DD/MM/YYYY")}</td>
                      <td>{itm.Id}</td>
                      <td>
                        {itm.FirstName} {itm.LastName}
                      </td>
                      <td>{itm.Detail}</td>
                      <td>£{itm.Amount}</td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="4"></td>
                  <td>
                    £
                    {Data.RenewalFees.reduce((acc, itm) => {
                      return acc + itm.Amount;
                    }, 0)}
                  </td>
                </tr>
              </tfoot>
            </table>
          </>
        )}

        {Data.DogFees.length > 0 && (
          <>
            <h3>Dog Registration</h3>
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Dog Id</th>
                  <th>Name</th>
                  <th>Member Id</th>
                  <th>Member Name</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {Data.DogFees.map((itm, idx) => {
                  return (
                    <tr>
                      <td>{moment(itm.DateTimeStamp).format("DD/MM/YYYY")}</td>
                      <td>{itm.DogId}</td>
                      <td>{itm.PetName}</td>
                      <td>{itm.MemberId}</td>
                      <td>
                        {itm.FirstName} {itm.LastName}
                      </td>
                      <td>£{itm.Amount}</td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="5"></td>
                  <td>
                    £
                    {Data.DogFees.reduce((acc, itm) => {
                      return acc + itm.Amount;
                    }, 0)}
                  </td>
                </tr>
              </tfoot>
            </table>
          </>
        )}
      </div>

      <div className="card-footer"></div>
    </div>
  );
}

export default FeesReport;
