import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import UserContext from "../../../context/user/UserContext.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { apiDelete } from "../../../shared/api";
import EditDialog from "./editdialog";

function ResultsLine({ ShowId, ...props }) {
  const { User } = useContext(UserContext);
  const [IsAdmin, setIsAdmin] = useState(false);
  const [ShowModal, setShowModal] = useState(false);
  const itm = props.Data;

  useEffect(() => {
    let val = sessionStorage.getItem("User");
    if (val) {
      val = JSON.parse(val);
      if (val.IsAdmin) setIsAdmin(true);
    }
  }, []);

  const DeleteRun = async () => {
    try {
      if (window.confirm("Are you sure?")) {
        const { LineId } = itm;
        const req = await apiDelete(`/results/${LineId}`);
        if (req && req.ok) {
          props.onRefresh();
        } else {
          window.alert("Error deleting run (failed)");
        }
      }
    } catch (error) {
      window.alert("Error deleting run (error)");
    }
  };

  const EditRun = async () => {
    try {
      setShowModal(true);
    } catch (error) {
      window.alert(error.message);
      setShowModal(false);
    }
  };

  return (
    <tr>
      {ShowModal && (
        <EditDialog
          ShowId={ShowId}
          CloseModal={() => {
            setShowModal(false);
            props.onRefresh();
          }}
          Data={itm}
        />
      )}

      {IsAdmin && (
        <td data-mobilelabel="Admin:">
          <span
            className="cursor-pointer"
            style={{ marginRight: "0.5rem" }}
            onClick={EditRun}
          >
            <FontAwesomeIcon icon={faEdit} />
          </span>
          <span
            className="cursor-pointer"
            style={{ marginRight: "0.5rem" }}
            onClick={DeleteRun}
          >
            <FontAwesomeIcon icon={faTrash} className="text-red" />
          </span>
        </td>
      )}

      <td data-mobilelabel="Run Date:">{moment.utc(itm.RunDate).format("DD/MM/YYYY")}</td>
      {parseInt(ShowId) === 0 && <td data-mobilelabel="Show:">{itm.ShowName}</td>}
      <td data-mobilelabel="Class:">
        {itm.ClassLabel}
        {props.View === "Dog" && itm.HandlerId !== itm.OwnerId && (
          <small>
            <br />(
            <i>
              Handler:{" "}
              {itm.FirstName === "Unknown" ? (
                "Unknown handler"
              ) : (
                <>
                  {itm.FirstName} {itm.LastName}
                </>
              )}
              )
            </i>
          </small>
        )}
      </td>
      <td data-mobilelabel="Level:">{itm.LevelLabel}</td>
      <td data-mobilelabel="Height:">{itm.HeightLabel}</td>
      {props.View === "Show" && (
        <td data-mobilelabel="Handler:">
          {itm.FirstName === "Unknown"
            ? itm.FirstName
            : `${itm.FirstName} ${itm.LastName}`}
        </td>
      )}
      {(props.View === "Handler" || props.View === "Show") && (
        <td data-mobilelabel="Dog:">
          {itm.PetName}
          {props.View === "Handler" && itm.OwnerId !== User.Id && (
            <>
              {itm.FirstName !== "Unknown" && (
                <small>
                  <br />
                  <i>
                    (Owner {itm.FirstName} {itm.LastName})
                  </i>
                </small>
              )}
            </>
          )}
        </td>
      )}
      <td data-mobilelabel="Faults:" className="text-center">
        {/*Games classes show - or E unless it's Power & Speed*/}
        {/*Other classes show Faults*/}

        {
          //Games classes show - or E unless it's Power & Speed
          itm.ClassType === 3 && itm.ClassId !== 10
            ? itm.Eliminated
              ? "E"
              : itm.NFC
              ? "NFC"
              : "-"
            : //Other classes show Faults
            itm.Eliminated
            ? "E"
            : itm.NFC
            ? "NFC"
            : itm.Faults
        }
      </td>
      <td data-mobilelabel="Points:" className="text-center">
        {
          //Games classes show - or E unless it's Power & Speed
          itm.ClassType === 3 && itm.ClassId !== 10
            ? itm.Eliminated || itm.NFC
              ? "-"
              : itm.Points
            : //Other classes show Points
              "-"
        }
      </td>
      <td data-mobilelabel="Time:" className="text-center">
        {!itm.Eliminated && !itm.NFC && itm.Time && itm.Time.toFixed(3)}
      </td>
      <td data-mobilelabel="Award:" className="text-center">
        {!itm.Eliminated ? itm.Rank : ""}
      </td>
      <td data-mobilelabel="Progression Points:" className="text-center">
        {itm.ProgressionPoints}
      </td>
    </tr>
  );
}

export default ResultsLine;
