import React, { useState, useEffect } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { apiGet, apiPost } from "../../../shared/api";

const EmailEditor = (props) => {
  const [TemplateList, setTemplateList] = useState([]);
  const [Content, setContent] = useState("");
  const [TemplateId, setTemplateId] = useState("");
  const [Subject, setSubject] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const req = await apiGet("/admin/emailtemplates/list");
        if (req && req.ok) {
          const res = await req.json();
          setTemplateList(res);
        } else {
          window.alert("Error loading list of templates");
        }
      } catch (error) {
        console.log(error.message);
        window.alert("Error loading list of templates");
      }
    })();
  }, []);

  const LoadTemplate = async () => {
    if (TemplateId !== "") {
      const req = await apiGet(`/admin/emailtemplates/${TemplateId}`);
      const res = await req.json();
      setContent(res.TemplateContent);
      setSubject(res.Subject || "");
    }
  };

  const SaveTemplate = async () => {
    try {
      const obj = {
        Id: parseInt(TemplateId),
        TemplateContent: Content,
        Subject,
      };
      const req = await apiPost("/admin/emailtemplates/save", obj);
      if (req && req.ok) {
        window.alert("Saved");
      } else {
        window.alert("Error saving template");
      }
    } catch (error) {
      console.log(error.message);
      window.alert("Error saving template");
    }
  };

  return (
    <div className="card">
      <div className="card-header">Emails Editor</div>
      <div className="card-body">
        <div className="grid mb-3x">
          <div className="col-6">
            <p>Select a template</p>
            <p>
              <select
                className="form-control"
                value={TemplateId}
                onChange={(e) => setTemplateId(e.target.value)}
              >
                <option value="">Select...</option>
                {TemplateList.map((itm) => (
                  <option key={itm.Id} value={itm.Id}>
                    {itm.TemplateName}
                  </option>
                ))}
              </select>
            </p>
          </div>
          <div className="col-3">
            <p>
              <br />
            </p>
            <p>
              <button className="button mr-1x" onClick={LoadTemplate}>
                Load
              </button>
              <button
                className="button button-green"
                disabled={TemplateId === ""}
                onClick={SaveTemplate}
              >
                Save
              </button>
            </p>
          </div>
        </div>

        {Content > "" && (
          <>
            <div style={{ marginBottom: 10 }}>
              <label>Subject:</label>
              <input
                type="text"
                className="form-control"
                value={Subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>
            <CKEditor
              editor={ClassicEditor}
              data={Content}
              onBlur={(evt, editor) => setContent(editor.getData())}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default EmailEditor;
