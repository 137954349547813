import React, { useState, useEffect } from "react";
import { apiPost, GetAuthToken } from "../../shared/api.js";
import InlineLoading from "../../shared/InlineLoading";
import moment from "moment";
import { Link } from "react-router-dom";

function SearchResults(props) {
  const [Results, setResults] = useState([]);
  const [Loading, setLoading] = useState(false);
  const AuthToken = GetAuthToken();

  //Search
  useEffect(() => {
    const doSearch = async () => {
      try {
        const req = await apiPost("/admin/search", props.Criteria);
        if (req && req.ok && req.status === 200) {
          const res = await req.json();
          setResults(res);
          setLoading(false);
        }
      } catch (error) {
        console.log(error.message);
        setLoading(false);
      }
    };

    if (!Loading && props.Criteria.Field && props.Criteria.Value) doSearch();
  }, [props.Criteria, Loading]);

  const MemberMatches = Results.map((itm) => {
    return (
      <tr key={itm.Id}>
        <td data-mobilelabel="Id:">{itm.Id}</td>
        <td data-mobilelabel="Name:">
          <Link
            to={`/adminlogin/${itm.UserGUID}?AuthToken=${AuthToken}`}
            target="_blank"
          >
            {itm.FirstName} {itm.LastName}
          </Link>
          {
            {
              2: " (Blocked)",
              3: " (Deleted)",
            }[itm.Status]
          }
        </td>
        <td data-mobilelabel="Email:">
          {itm.Email !== "" ? (
            <a href={`mailto:${itm.Email}`}>{itm.Email}</a>
          ) : (
            <></>
          )}
        </td>
        <td data-mobilelabel="Mobile:">{itm.MobilePhone}</td>
        <td data-mobilelabel="Phone:">{itm.OtherPhone}</td>
        <td data-mobilelabel="Expires:">
          {moment(itm.DateExpires).format("DD/MM/YYYY")}
        </td>
        <td data-mobilelabel="Actions:">
          <Link className="mr-1x visible-hover" to={`/admin/member/${itm.Id}`}>
            Select
          </Link>
        </td>
      </tr>
    );
  });

  const DogMatches = Results.map((itm) => {
    return (
      <tr key={itm.Id}>
        <td data-mobilelabel="Id:">{itm.Id}</td>
        <td data-mobilelabel="Pet Name:">{itm.PetName}</td>
        <td data-mobilelabel="Handler:">
          {itm.FirstName} {itm.LastName}
        </td>
        <td data-mobilelabel="Email:">
          {itm.Email !== "" ? (
            <a href={`mailto:${itm.Email}`}>{itm.Email}</a>
          ) : (
            <></>
          )}
        </td>
        <td data-mobilelabel="Actions:">
          <Link className="mr-1x visible-hover" to={`/admin/dogedit/${itm.Id}`}>
            Edit
          </Link>
          <Link className="mr-1x visible-hover" to={`/admin/dogedit/${itm.Id}`}>
            Heights
          </Link>
          <Link className="mr-1x visible-hover" to={`/admin/dogedit/${itm.Id}`}>
            Results
          </Link>
          <Link
            className="mr-1x visible-hover"
            to={`/admin/memberview/transfer/${itm.Id}/${itm.MemberId}`}
          >
            Transfer
          </Link>
        </td>
      </tr>
    );
  });

  if (!props.Criteria.Field) return <p>Waiting for search...</p>;

  if (Loading) return <InlineLoading />;

  return (
    <>
      {props.Criteria.Field !== "DogId" &&
      props.Criteria.Field !== "DogName" ? (
        <>
          <h4>Member matches</h4>
          <p className="mb-2x">Click a person's name to login as them</p>
          {Results.length > 0 ? (
            <table className="table table-hover font-90">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Other Phone</th>
                  <th>Expires</th>
                  <th className="width-10">Actions</th>
                </tr>
              </thead>
              <tbody>{MemberMatches}</tbody>
            </table>
          ) : (
            <p>No member matches</p>
          )}
        </>
      ) : (
        <></>
      )}

      {props.Criteria.Field === "DogId" ||
      props.Criteria.Field === "DogName" ? (
        <>
          <h4 className="mt-2x">Dog matches</h4>
          {Results.length > 0 ? (
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Handler Name</th>
                  <th>Handler Email</th>
                  <th className="width-25">Actions</th>
                </tr>
              </thead>
              <tbody>{DogMatches}</tbody>
            </table>
          ) : (
            <p>No dog matches</p>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default SearchResults;
