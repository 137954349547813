import React, { useCallback, useEffect, useState } from "react";
import { Switch, Route, useHistory } from "react-router";
import Rings from "./rings";
import RingView from "./ringview";
import ClassView from "./classview";
import { useInterval } from "beautiful-react-hooks";
import { apiGet } from "../../../../shared/api";

const RingOverview = () => {
  const [Data, setData] = useState([]);
  const [RingId, setRingId] = useState();
  const [ClassId, setClassId] = useState();
  const [LevelId, setLevelId] = useState();
  const [Loading, setLoading] = useState(false);

  const history = useHistory();

  const RefreshData = useCallback(async () => {
    try {
      setLoading(true);
      const ShowId = sessionStorage.getItem("ShowId");
      const req = await apiGet(`/results/ringoverview/${ShowId}`);
      const res = await req.json();
      const parsed = JSON.parse(res.Data);
      setData(parsed.sort((a, b) => (a.RingId < b.RingId ? -1 : 1)));
    } catch (error) {
      console.error(error.message);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, []);

  useEffect(() => {
    RefreshData();
  }, []);

  useInterval(() => RefreshData(), 30000);

  return (
    <Switch>
      <Route exact path="/members/liveresults/ringoverview">
        <Rings
          Loading={Loading}
          Data={Data}
          onRingSelected={(e) => {
            setRingId(e);
            history.push(`/members/liveresults/ringoverview/ring`);
          }}
        />
      </Route>
      <Route exact path="/members/liveresults/ringoverview/ring">
        <RingView
          Loading={Loading}
          Data={Data}
          RingId={RingId}
          onClassSelected={(ClassId, LevelId) => {
            setClassId(ClassId);
            setLevelId(LevelId);
            history.push(`/members/liveresults/ringoverview/class`);
          }}
        />
      </Route>
      <Route exact path="/members/liveresults/ringoverview/class">
        <ClassView
          Loading={Loading}
          Data={Data}
          RingId={RingId}
          ClassId={ClassId}
          LevelId={LevelId}
        />
      </Route>
    </Switch>
  );
};

export default RingOverview;
