import React from "react";
import "./pagefooter.css";
import moment from "moment";

function PageFooter(props) {
  return (
    <footer>
      <div className="text-center">
        <div className="mb-1x">
          <p>Agility 4 All Ltd, 146 Lamberton Drive, Brymbo, LL11 5FW</p>
          <p>
            <a
              className="text-white mr-1x"
              href="mailto:enquiries@agility4all.co.uk"
            >
              enquiries@agility4all.co.uk
            </a>
          </p>
        </div>
        <div>Copyright &copy; {moment().year()} Agility 4 All Ltd</div>
      </div>
    </footer>
  );
}

export default PageFooter;
